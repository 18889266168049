import React from 'react';
import { Newspaper, TrendingDown, TrendingUp } from 'lucide-react';
import { playAlertClickSound } from '../../utils/SoundEffects';
import './NewsAlert.css';

function NewsAlert({ news, onClose }) {
  if (!news) return null;

  const handleClick = () => {
    playAlertClickSound();
    onClose();
  };

  const getImpactIcon = () => {
    if (news.type === 'negative') return <TrendingDown className="news-icon negative" />;
    if (news.type === 'positive') return <TrendingUp className="news-icon positive" />;
    return null;
  };

  return (
    <div className="news-alert-overlay">
      <div className="news-alert">
        <div className="news-header">
          <Newspaper className="news-icon header-icon" />
          <h2 className="news-title">News Alert</h2>
        </div>

        <div className="news-content">
          <div className="news-amount">
            {getImpactIcon()}
            <span className={news.type}>
              {news.impacts[0].value > 0 ? '+' : ''}{news.impacts[0].value}%
            </span>
          </div>
          <div className="news-text">
            <p className="news-headline">{news.headline}</p>
            <p className="news-subheadline">{news.impacts[0].description}</p>
          </div>
        </div>

        <button className="news-button" onClick={handleClick}>
          Continue
        </button>
      </div>
    </div>
  );
}

export default NewsAlert; 