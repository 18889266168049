import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Check } from 'lucide-react';
import ContactDialog from './ChatBot';

const benefits = [
  "24/7 tech support",
  "30-days money back",
  "Train the trainers"
];

const CallToAction = () => {
  const [showContact, setShowContact] = useState(false);

  return (
    <div className="w-full py-12 sm:py-24">
      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="relative rounded-2xl overflow-hidden bg-gradient-to-br from-black via-game-dark to-black/95 p-8 sm:p-20"
        >
          {/* Gradient Border */}
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-br from-game-accent/30 via-purple-500/20 to-pink-500/30 animate-[gradient-rotate_8s_linear_infinite]" />
            <div className="absolute inset-[1px] bg-gradient-to-br from-black via-game-dark to-black/95 rounded-2xl" />
          </div>

          <div className="relative space-y-8 sm:space-y-12">
            {/* Title */}
            <h2 className="text-2xl sm:text-3xl font-bold font-['Space_Grotesk'] text-white text-center">
              Have questions? We're here to help
            </h2>

            {/* Benefits */}
            <div className="flex flex-col sm:flex-row justify-center items-center gap-4 sm:gap-8">
              {benefits.map((benefit, index) => (
                <div key={index} className="flex items-center gap-2">
                  <Check className="w-4 h-4 sm:w-5 sm:h-5 text-game-accent" />
                  <span className="text-sm sm:text-base text-gray-400 font-['Space_Grotesk']">
                    {benefit}
                  </span>
                </div>
              ))}
            </div>

            {/* Single Button */}
            <div className="flex justify-center">
              <motion.div className="relative">
                <motion.div
                  whileHover={{ opacity: 1, scale: 1.02 }}
                  initial={{ opacity: 0, scale: 1 }}
                  className="absolute -inset-1 bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 
                            rounded-full blur-md transition-all duration-300"
                />
                <motion.div
                  whileHover="hover"
                  className="relative"
                >
                  <button 
                    onClick={() => setShowContact(true)}
                    className="relative inline-flex items-center px-6 py-3
                             bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600
                             hover:from-indigo-500 hover:via-purple-500 hover:to-pink-500
                             text-white text-sm font-medium
                             rounded-full transition-all duration-300
                             shadow-lg hover:shadow-purple-500/25
                             font-['Space_Grotesk'] tracking-wide
                             overflow-hidden"
                  >
                    <motion.div
                      className="relative z-10 flex items-center gap-2"
                      variants={{
                        hover: { x: -4 }
                      }}
                    >
                      Ask for a demo
                      <motion.span
                        variants={{
                          hover: { x: 0, opacity: 1 }
                        }}
                        initial={{ x: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className="relative"
                      >
                        <span className="absolute inset-0 animate-ping">→</span>
                        →
                      </motion.span>
                    </motion.div>
                  </button>
                </motion.div>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Contact Dialog */}
      <ContactDialog 
        isOpen={showContact}
        onClose={() => setShowContact(false)}
      />
    </div>
  );
};

export default CallToAction; 