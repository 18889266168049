import React, { useState, useEffect } from 'react';
import Character from './Characters';
import Workers from './Workers';
import Tasks from './Tasks';
import gameConfig from '../content/game-config.json';
import gameContent from '../content/game-content.json';
import repairBoatImage from '../assets/images/repair-boat.webp';
import shelterImage from '../assets/images/task-shelter.webp';
import restImage from '../assets/images/task-rest.webp';
import foodImage from '../assets/images/task-food.webp';
import woodImage from '../assets/images/task-wood.webp';
import healthImage from '../assets/images/task-health.webp';
import storeFoodImage from '../assets/images/task-store-food.webp';
import './Tactics.css';

// Helper function to get task explanations by officer
const getTaskExplanations = (officerId) => {
  return Object.entries(gameConfig.tasks)
    .filter(([_, task]) => task.explanation.officer === officerId)
    .map(([taskId, task]) => {
      // Find the task in game-content.json to get its imageKey
      const taskContent = [
        ...gameContent.tasks.survive,
        ...gameContent.tasks.escape
      ].find(t => t.id === taskId);

      return {
        title: taskId.toUpperCase(),
        content: task.explanation.description,
        type: 'task',
        taskId: taskId,
        imageKey: taskContent.imageKey
      };
    });
};

const taskImages = {
  'repair-boat': repairBoatImage,
  'shelter': shelterImage,
  'task-rest': restImage,
  'task-food': foodImage,
  'task-wood': woodImage,
  'task-health': healthImage,
  'task-store-food': storeFoodImage
};

const tacticsContent = {
  captain: {
    title: gameContent.characters.captain.name,
  },
  firstOfficer: {
    title: gameContent.characters.firstOfficer.name,
    moraleCards: [
      {
        number: "1",
        title: "MORALE WARNINGS",
        content: {
          main: [
            "One crew member will be lost to despair each round the Morale tracker is below 20%",
            `Making tired crews work costs ${gameConfig.fatigue.penalties.morale} morale points per worker`
          ]
        },
        type: 'rule',
        showAllDividers: true
      },
      {
        number: "2",
        title: "FINAL SCORE",
        content: {
          main: [
            `Survivors: ${gameConfig.survivorScore.calculation.weights.survivors * 100}% of score`,
            `Morale: ${gameConfig.survivorScore.calculation.weights.morale * 100}% of score`,
            `Health: ${gameConfig.survivorScore.calculation.weights.health * 100}% of score`,
            `Boat: ${gameConfig.survivorScore.calculation.weights.boat * 100}% of score`,
            `Food Storage: ${gameConfig.survivorScore.calculation.weights.foodStorage * 100}% of score`
          ]
        },
        type: 'score',
        showAllDividers: true
      },
      ...getTaskExplanations('firstOfficer').map((task, index) => ({
        number: String(index + 3),
        title: task.title,
        content: task.content,
        type: task.type,
        taskId: task.taskId,
        imageKey: task.imageKey
      }))
    ]
  },
  medic: {
    title: gameContent.characters.medic.name,
    healthCards: [
      {
        number: "1",
        title: "HEALTH WARNINGS",
        content: {
          main: [
            "In Round 1, all 23 crew members are tired after the voyage",
            "One crew member will die each round the Health tracker is below 20%",
            `Making tired crews work costs ${gameConfig.fatigue.penalties.health} health points per worker`
          ]
        },
        type: 'rule',
        showAllDividers: true
      },
      {
        number: "2",
        title: "FOOD CONSUMPTION",
        content: {
          main: [
            `Crew consumes 1 food per ${gameConfig.roundEffects.foodConsumption.workersPerFood} members each round`,
            `When starving, crew loses ${Math.abs(gameConfig.roundEffects.foodConsumption.healthFallback.healthPerMissingFood)} health point per missing food unit`
          ]
        },
        type: 'rule',
        showAllDividers: true
      },
      ...getTaskExplanations('medic').map((task, index) => ({
        number: String(index + 3),
        title: task.title,
        content: task.content,
        type: task.type,
        taskId: task.taskId,
        imageKey: task.imageKey
      }))
    ]
  },
  carpenter: {
    title: gameContent.characters.carpenter.name,
    resourceCards: getTaskExplanations('carpenter').map((task, index) => ({
      number: String(index + 1),
      title: task.title,
      content: task.content,
      type: task.type,
      taskId: task.taskId,
      imageKey: task.imageKey
    }))
  },
  navigator: {
    title: gameContent.characters.navigator.name,
    weatherCards: [
      {
        number: "1",
        title: "NAVIGATOR'S ADVICE",
        content: "Rest all crews in Round 19 to ensure everyone can escape in Round 20",
        type: 'advice'
      },
      ...getTaskExplanations('navigator').map((task, index) => ({
        number: String(index + 2),
        title: task.title,
        content: task.content,
        type: task.type,
        taskId: task.taskId,
        imageKey: task.imageKey
      }))
    ]
  }
};

function Tactics({ selectedCharacterId, strategy, onClose }) {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleRoundFinished = () => {
      setIsVisible(false);
      if (onClose) {
        onClose();
      }
    };

    const handleCloseTactics = () => {
      setIsVisible(false);
      if (onClose) {
        onClose();
      }
    };

    window.addEventListener('roundFinished', handleRoundFinished);
    window.addEventListener('closeTactics', handleCloseTactics);
    
    return () => {
      window.removeEventListener('roundFinished', handleRoundFinished);
      window.removeEventListener('closeTactics', handleCloseTactics);
    };
  }, [onClose]);

  if (!selectedCharacterId || !isVisible) return null;

  const content = tacticsContent[selectedCharacterId];
  const showWorkers = selectedCharacterId === 'captain';
  const showHealthCards = selectedCharacterId === 'medic';
  const showMoraleCards = selectedCharacterId === 'firstOfficer';
  const showWeatherCards = selectedCharacterId === 'navigator';
  const showResourceCards = selectedCharacterId === 'carpenter';

  return (
    <div className="tactics">
      <div className="tactics__content">
        <div className="tactics__header">
          <div className="tactics__titles">
            <h2 className="tactics__title">{content.title}</h2>
            {(showHealthCards || showMoraleCards || showWeatherCards || showResourceCards) && (
              <div className="tactics__cards">
                {(content.healthCards || content.moraleCards || content.weatherCards || content.resourceCards).map((card, index) => (
                  <div 
                    key={index} 
                    className={`tactics__card ${card.type === 'task' ? 'tactics__card--task' : ''} 
                      ${card.type === 'score' ? 'tactics__card--score' : ''} 
                      ${card.type === 'rule' ? 'tactics__card--rule' : ''}
                      ${card.type === 'advice' ? 'tactics__card--advice' : ''}`}
                  >
                    <div className="tactics__card-number">{card.number}</div>
                    {card.type === 'task' && (
                      <>
                        <div className="tactics__card-task-label">TASK</div>
                        <div className="tactics__card-task-image">
                          <img 
                            src={taskImages[card.imageKey]} 
                            alt={card.title}
                          />
                        </div>
                      </>
                    )}
                    {card.type === 'score' && (
                      <div className="tactics__card-score-label">VICTORY</div>
                    )}
                    {card.type === 'rule' && (
                      <div className="tactics__card-rule-label">RULE</div>
                    )}
                    {card.type === 'advice' && (
                      <div className="tactics__card-advice-label">ADVICE</div>
                    )}
                    <h3 className="tactics__card-title">{card.title}</h3>
                    {typeof card.content === 'object' && card.content.main ? (
                      <div className="tactics__card-main">
                        {Array.isArray(card.content.main) && card.content.main.map((text, i) => (
                          <React.Fragment key={i}>
                            <p>{text}</p>
                            {i < card.content.main.length - 1 && card.showAllDividers && (
                              <div className="tactics__card-divider" />
                            )}
                            {i === 0 && !card.showAllDividers && (
                              <div className="tactics__card-divider" />
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    ) : (
                      <p className="tactics__card-content">{card.content}</p>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="tactics__character">
            <Character 
              characterId={selectedCharacterId} 
              size="large"
            />
          </div>
        </div>
        {showWorkers && (
          <>
            <Tasks strategy={strategy} />
            <Workers />
          </>
        )}
      </div>
    </div>
  );
}

export default Tactics; 