// Import the audio files directly
import flagSound from '../assets/audio/flag-sound.mp3';
import nextRoundSound from '../assets/audio/next-round.mp3';
import nextAlertSound from '../assets/audio/next-alert.mp3';
import omniusOpenSound from '../assets/audio/omnius-open.mp3';
import omniusLockSound from '../assets/audio/omnius-lock.mp3';
import wrongPasscodeSound from '../assets/audio/wrong-passcode.mp3';
import roundStartSound from '../assets/audio/round-start.mp3';
import itemBoughtSound from '../assets/audio/item-bought.mp3';

// Create audio instances once
const flagAudio = new Audio(flagSound);
const nextRoundAudio = new Audio(nextRoundSound);
const alertClickAudio = new Audio(nextAlertSound);
const omniusAudio = new Audio(omniusOpenSound);
const omniusLockAudio = new Audio(omniusLockSound);
const wrongPasscodeAudio = new Audio(wrongPasscodeSound);
const roundStartAudio = new Audio(roundStartSound);
const itemBoughtAudio = new Audio(itemBoughtSound);

export const playFlagSound = () => {
  flagAudio.currentTime = 0;
  flagAudio.play().catch(error => {
    console.warn('Flag sound playback failed:', error);
  });
};

export const playNextRoundSound = () => {
  nextRoundAudio.currentTime = 0;
  nextRoundAudio.play().catch(error => {
    console.warn('Next round sound playback failed:', error);
  });
};

export const playAlertClickSound = () => {
  alertClickAudio.currentTime = 0;
  alertClickAudio.play().catch(error => {
    console.warn('Alert click sound playback failed:', error);
  });
};

export const playOmniusSound = () => {
  omniusAudio.currentTime = 0;
  omniusAudio.play().catch(error => {
    console.warn('Omnius sound playback failed:', error);
  });
};

export const playOmniusLockSound = () => {
  omniusLockAudio.currentTime = 0;
  omniusLockAudio.play().catch(error => {
    console.warn('Omnius lock sound playback failed:', error);
  });
};

export const playWrongPasscodeSound = () => {
  wrongPasscodeAudio.currentTime = 0;
  wrongPasscodeAudio.play().catch(error => {
    console.warn('Wrong passcode sound playback failed:', error);
  });
};

export const playRoundStartSound = () => {
  roundStartAudio.currentTime = 0;
  roundStartAudio.play().catch(error => {
    console.warn('Round start sound playback failed:', error);
  });
};

export const playItemBoughtSound = () => {
  itemBoughtAudio.currentTime = 0;
  itemBoughtAudio.play().catch(error => {
    console.warn('Item bought sound playback failed:', error);
  });
};