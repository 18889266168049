import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import EditorTop from './components/EditorTop';
import EditorSide from './components/EditorSide';
import EditorSettings from './components/EditorSettings';
import ScenariosView from './components/ScenariosView';
import './SimulationEditor.css';
import { toast } from 'react-hot-toast';
import { initialKPIValues } from '../../utils/KPICalculator';
import { createSimulation, getSimulation, updateSimulation } from '../../services/simulationService';
import scenarios from '../../components/content/ShadowMoney'; // Import default scenarios

const SimulationEditor = () => {
  const location = useLocation();
  const simulationId = location.state?.simulationId;

  // Initialize defaultKpis
  const defaultKpis = Object.entries(initialKPIValues).map(([name, value]) => ({
    id: name.toLowerCase().replace(/ & /g, '-').replace(/ /g, '-'),
    name: name,
    description: `Impact on ${name.toLowerCase()}`,
    startingValue: value
  }));

  const [currentView, setCurrentView] = useState('settings');
  const [kpis, setKpis] = useState(defaultKpis);
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [simulationData, setSimulationData] = useState({
    title: 'Untitled Simulation',
    description: 'Click to add a description',
    participants: '1-10',
    duration: '30',
    targetAudience: [],
    category: '',
    topic: '',
    competencies: [],
    kpis: defaultKpis
  });
  const [hasVisitedScenarios, setHasVisitedScenarios] = useState(false);
  const [scenariosList, setScenariosList] = useState(scenarios); // Initialize with default scenarios

  // Load existing simulation data if editing
  useEffect(() => {
    const loadSimulation = async () => {
      if (simulationId) {
        try {
          const loadedSimulation = await getSimulation(simulationId);
          setSimulationData(loadedSimulation);
          setKpis(loadedSimulation.kpis);
          if (loadedSimulation.scenarios?.length > 0) {
            setSelectedScenario(loadedSimulation.scenarios[0]);
          }
        } catch (error) {
          console.error('Error loading simulation:', error);
          toast.error('Failed to load simulation');
        }
      }
    };

    loadSimulation();
  }, [simulationId]);

  const updateKpis = useCallback((newKpis) => {
    if (newKpis && newKpis.length > 0) {
      setKpis(newKpis);
      setSimulationData(prev => ({
        ...prev,
        kpis: newKpis
      }));
    }
  }, []);

  const handleSimulationDataChange = useCallback((newData) => {
    setSimulationData(prev => ({
      ...prev,
      ...newData
    }));
  }, []);

  const handleSave = async (type) => {
    try {
      const simulationToSave = {
        ...simulationData,
        scenarios: scenariosList // Use the scenariosList state
      };

      if (type === 'new') {
        const simulationId = await createSimulation(simulationToSave);
        console.log('Created new simulation with ID:', simulationId);
        toast.success('Simulation saved successfully!');
        navigate('/'); // Redirect to library after saving
      } 
      else if (type === 'replace' && simulationData?.id) {
        // Update existing simulation
        await updateSimulation(simulationData.id, simulationToSave);
        console.log('Updated simulation with ID:', simulationData.id);
        toast.success('Simulation updated successfully!');
        navigate('/'); // Redirect to library after updating
      }
    } catch (error) {
      console.error('Save failed:', error);
      toast.error('Failed to save simulation');
    }
  };

  const handleViewChange = (view) => {
    if (view === 'scenarios' && !hasVisitedScenarios) {
      setHasVisitedScenarios(true);
    }
    setCurrentView(view);
  };

  const navigate = useNavigate();

  return (
    <div className="simulation-editor">
      <EditorTop onSave={handleSave} />
      <div className="editor-main">
        <EditorSide onViewChange={handleViewChange} />
        {currentView === 'settings' ? (
          <EditorSettings 
            onKpisChange={updateKpis}
            onSimulationDataChange={handleSimulationDataChange}
            simulationData={simulationData}
          />
        ) : (
          <ScenariosView 
            kpis={kpis} 
            selectedScenario={selectedScenario}
            onScenarioSelect={setSelectedScenario}
            simulationId={simulationData?.id}
            simulationData={simulationData}
            scenariosList={scenariosList}
            setScenariosList={setScenariosList}
          />
        )}
      </div>
    </div>
  );
};

export default SimulationEditor;