import React from 'react';
import { AlertTriangle, TrendingDown, TrendingUp } from 'lucide-react';
import { playAlertClickSound } from '../../utils/SoundEffects';
import './RiskAlert.css';

function RiskAlert({ impact, onClose }) {
  if (!impact) return null;

  const handleClick = () => {
    playAlertClickSound();
    onClose();
  };

  const isPositive = impact < 0;
  const getImpactIcon = () => {
    return isPositive ? <TrendingDown className="risk-icon positive" /> : <TrendingUp className="risk-icon negative" />;
  };

  return (
    <div className="risk-alert-overlay">
      <div className="risk-alert">
        <div className="risk-header">
          <AlertTriangle className="risk-icon header-icon" />
          <h2 className="risk-title">Risk Level Impact</h2>
        </div>

        <div className="risk-content">
          <div className="risk-amount">
            {getImpactIcon()}
            <span className={isPositive ? 'positive' : 'negative'}>
              {impact > 0 ? '+' : ''}{impact}%
            </span>
          </div>
          <p className="risk-description">
            {isPositive ? 
              'Security measures have reduced overall risk exposure.' :
              'Recent decisions have increased risk exposure levels.'
            }
          </p>
        </div>

        <button className="risk-button" onClick={handleClick}>
          Continue
        </button>
      </div>
    </div>
  );
}

export default RiskAlert; 