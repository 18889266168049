import React, { useState, useEffect } from 'react';
import './WorkerParticles.css';

const PARTICLE_TYPES = {
  RED: { color: '#FF6B6B', direction: 'top-right' },
  GREEN: { color: '#96CEB4', direction: 'left' },
  BLUE: { color: '#45B7D1', direction: 'top' },
  TURQUOISE: { color: '#4ECDC4', direction: 'bottom-left' }
};

function WorkerParticles({ isActive }) {
  const [showDestinations, setShowDestinations] = useState(false);

  useEffect(() => {
    if (isActive) {
      // Show destinations after particles have moved
      setTimeout(() => {
        setShowDestinations(true);
      }, 9500); // Show just before animation ends (10s - 500ms for pulse animation)

      // Clean up
      setTimeout(() => {
        setShowDestinations(false);
      }, 15000); // Give time for pulse animation (10s + 5s for pulses)

      return () => {
        setShowDestinations(false);
      };
    }
  }, [isActive]);

  return (
    <div className="worker-particles">
      {Object.entries(PARTICLE_TYPES).map(([type, config]) => (
        <div 
          key={type}
          className={`worker-particle direction-${config.direction}`}
        >
          <div 
            className="worker-particle__dot"
            style={{
              background: config.color,
              boxShadow: `0 0 10px ${config.color}`
            }}
          />
        </div>
      ))}
      {showDestinations && (
        <>
          <div className="destination-pulse top-right" style={{ background: PARTICLE_TYPES.RED.color }} />
          <div className="destination-pulse left" style={{ background: PARTICLE_TYPES.GREEN.color }} />
          <div className="destination-pulse top" style={{ background: PARTICLE_TYPES.BLUE.color }} />
          <div className="destination-pulse bottom-left" style={{ background: PARTICLE_TYPES.TURQUOISE.color }} />
        </>
      )}
    </div>
  );
}

export default WorkerParticles; 