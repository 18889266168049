import { IMPORTANCE_LEVELS, FLAG_TYPES } from './constants';

export const calculateBankBalanceScore = (scenario, chosenFlag) => {
  const { correctFlag, importance } = scenario;
  
  // For RED flag scenarios
  if (correctFlag === FLAG_TYPES.RED) {
    // High importance
    if (importance === IMPORTANCE_LEVELS.HIGH) {
      return chosenFlag === FLAG_TYPES.RED ? 12000 : -12000;
    }
    // Low importance
    return chosenFlag === FLAG_TYPES.RED ? 5000 : -5000;
  }
  
  // For GREEN flag scenarios
  if (correctFlag === FLAG_TYPES.GREEN) {
    // High importance
    if (importance === IMPORTANCE_LEVELS.HIGH) {
      return chosenFlag === FLAG_TYPES.GREEN ? 5000 : -5000;
    }
    // Low importance
    return chosenFlag === FLAG_TYPES.GREEN ? 2000 : -2000;
  }
  
  return 0;
};

export const calculateRiskLevelScore = (scenario, chosenFlag) => {
  const { correctFlag, importance } = scenario;
  
  // For RED flag scenarios
  if (correctFlag === FLAG_TYPES.RED) {
    // High importance RED scenario
    if (importance === IMPORTANCE_LEVELS.HIGH) {
      return chosenFlag === FLAG_TYPES.RED ? -2 : +16; // Correct: -2%, Wrong: +16%
    }
    // Low importance RED scenario
    return chosenFlag === FLAG_TYPES.RED ? -1 : +8; // Correct: -1%, Wrong: +8%
  }
  
  // For GREEN flag scenarios
  if (correctFlag === FLAG_TYPES.GREEN) {
    // High importance GREEN scenario
    if (importance === IMPORTANCE_LEVELS.HIGH) {
      return chosenFlag === FLAG_TYPES.GREEN ? -1 : +8; // Correct: -1%, Wrong: +8%
    }
    // Low importance GREEN scenario
    return chosenFlag === FLAG_TYPES.GREEN ? 0 : +4; // Correct: -0%, Wrong: +4%
  }
  
  return 0;
};

export const calculateComplianceScore = (scenario, chosenFlag) => {
  const { correctFlag, importance } = scenario;
  
  // For RED flag scenarios
  if (correctFlag === FLAG_TYPES.RED) {
    // High importance RED scenario
    if (importance === IMPORTANCE_LEVELS.HIGH) {
      return chosenFlag === FLAG_TYPES.RED ? 1 : -30; // Correct: +3, Wrong: -30
    }
    // Low importance RED scenario
    return chosenFlag === FLAG_TYPES.RED ? 0 : -15; // Correct: +1, Wrong: -15
  }
  
  // For GREEN flag scenarios
  if (correctFlag === FLAG_TYPES.GREEN) {
    // High importance GREEN scenario
    if (importance === IMPORTANCE_LEVELS.HIGH) {
      return chosenFlag === FLAG_TYPES.GREEN ? 1 : 0; // Correct: +2, Wrong: 0
    }
    // Low importance GREEN scenario
    return chosenFlag === FLAG_TYPES.GREEN ? 0 : 0; // Correct: +0, Wrong: 0
  }
  
  return 0;
}; 