import React, { useState, useRef } from 'react';
import { Flag } from 'lucide-react';
import { playFlagSound } from '../../utils/SoundEffects';
import { useSimulation } from '../../ai-gone-rogue/SimulationContext';
import './ScenarioCard.css';

function ScenarioCard({ 
  cardNumber,
  title,
  description,
  onChoice,
  currentChoice,
  disabled,
  correctFlag,
  importance
}) {
  const { hasEffect } = useSimulation();
  const showRedFlags = hasEffect('show_red_flags');
  const showGreenFlags = hasEffect('show_green_flags');
  const showImportance = hasEffect('show_importance');
  const [position, setPosition] = useState('center');
  const [isDragging, setIsDragging] = useState(false);
  const cardRef = useRef(null);
  const startXRef = useRef(0);
  const currentXRef = useRef(0);

  const showRedFlagHint = showRedFlags && correctFlag === 'red' && !currentChoice;
  const showGreenFlagHint = showGreenFlags && correctFlag === 'green' && !currentChoice;
  const showImportanceHint = showImportance && importance === 'HIGH' && !currentChoice;

  console.log('Card Debug:', {
    title,
    importance,
    showImportance,
    shouldShow: showImportance && importance === 'HIGH'
  });

  React.useEffect(() => {
    if (currentChoice === 'red') setPosition('left');
    else if (currentChoice === 'green') setPosition('right');
    else setPosition('center');
  }, [currentChoice]);

  const handleMouseDown = (e) => {
    if (disabled) return;
    setIsDragging(true);
    startXRef.current = e.clientX - currentXRef.current;
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    
    const newX = e.clientX - startXRef.current;
    const maxMove = 50;
    const boundedX = Math.max(Math.min(newX, maxMove), -maxMove);
    
    currentXRef.current = boundedX;
    cardRef.current.style.transform = `translateX(${boundedX}px) rotate(${boundedX * 0.1}deg)`;

    if (boundedX < -20) {
      setPosition('left');
    } else if (boundedX > 20) {
      setPosition('right');
    } else {
      setPosition('center');
    }
  };

  const handleMouseUp = () => {
    if (!isDragging) return;
    
    setIsDragging(false);
    cardRef.current.style.transform = 'translateX(0) rotate(0)';
    currentXRef.current = 0;

    if (position === 'left' && currentChoice === 'red') {
      onChoice(null);
      setPosition('center');
    } else if (position === 'right' && currentChoice === 'green') {
      onChoice(null);
      setPosition('center');
    } else if (position === 'left') {
      playFlagSound();
      onChoice('red');
    } else if (position === 'right') {
      playFlagSound();
      onChoice('green');
    }
  };

  return (
    <div 
      className={`scenario-card ${disabled ? 'disabled' : ''} ${currentChoice ? 'flagged' : ''} 
        ${showRedFlagHint ? 'red-flag-hint' : ''} 
        ${showGreenFlagHint ? 'green-flag-hint' : ''}
        ${showImportanceHint ? 'importance-hint' : ''}`}
      ref={cardRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      {showRedFlagHint && (
        <div className="flag-hint-overlay red">
          <Flag className="flag-hint-icon" />
        </div>
      )}
      {showGreenFlagHint && (
        <div className="flag-hint-overlay green">
          <Flag className="flag-hint-icon" />
        </div>
      )}
      {showImportanceHint && (
        <div className="importance-overlay">
          <span className="importance-icon">!</span>
        </div>
      )}
      
      <div className="card-background-pattern" />
      <div className="circuit-lines" />
      <div className="data-stream" />
      
      <div className="corner-decoration top-left" />
      <div className="corner-decoration top-right" />
      <div className="corner-decoration bottom-left" />
      <div className="corner-decoration bottom-right" />
      
      <div className="card-number">{cardNumber}</div>
      
      <div className={`flag-overlay left ${position === 'left' ? 'visible' : ''}`}>
        <Flag className="flag-icon" />
      </div>
      <div className={`flag-overlay right ${position === 'right' ? 'visible' : ''}`}>
        <Flag className="flag-icon" />
      </div>
      
      <div className="card-content">
        <h2 className="card-title">{title}</h2>
        <p className="card-info">{description}</p>
      </div>

      <div className="drag-hint">
        {disabled ? 'Max choices reached' : 
         currentChoice ? 'Drag again to unflag' : 
         '← Red Flag | Green Flag →'}
      </div>
    </div>
  );
}

export default ScenarioCard;