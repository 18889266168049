import React from 'react';
import { useAntarctica } from '../context/AntarcticaContext';
import gameConfig from '../content/game-config.json';
import shelterIcon from '../assets/images/shelter-status.webp';
import boatIcon from '../assets/images/boat-status.webp';
import foodStorageIcon from '../assets/images/task-store-food.webp';
import './SurviveEscapeTracker.css';

function SurviveEscapeTracker() {
  const { survivalStatus } = useAntarctica();
  const { 
    container, 
    shelter: shelterConfig, 
    boat: boatConfig,
    foodStorage: foodStorageConfig 
  } = gameConfig.survivalTracker;

  const statusIcons = {
    shelter: shelterIcon,
    boat: boatIcon,
    foodStorage: foodStorageIcon
  };

  const renderStatusItem = (type, config) => {
    const value = survivalStatus?.[type] ?? config.initialValue;
    const formattedValue = config.ui.valueFormat.replace('%d', value);

    return (
      <div className={config.ui.className}>
        <img 
          src={statusIcons[type]} 
          alt={config.ui.iconAlt} 
          className={config.ui.iconClassName} 
        />
        <div className={config.ui.valueClassName}>
          {formattedValue}
        </div>
      </div>
    );
  };

  return (
    <div className={container.className}>
      {renderStatusItem('shelter', shelterConfig)}
      {renderStatusItem('boat', boatConfig)}
      {renderStatusItem('foodStorage', foodStorageConfig)}
    </div>
  );
}

export default SurviveEscapeTracker; 