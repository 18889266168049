import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SimulationProvider } from './ai-gone-rogue/SimulationContext';
import { AntarcticaProvider } from './antarctica/context/AntarcticaContext';
import { ThemeProvider } from './context/ThemeContext';
import { OperationAresProvider } from './operation-ares/context/OperationAresContext';
import SimulationView from './components/SimulationView';
import AntarcticaView from './antarctica/AntarcticaView';
import OperationAres from './operation-ares/OperationAres';
import LandingPage from './home/LandingPage';
import MainContainer from './dark-money/layout/MainContainer';
import {DarkMoneyProvider } from './dark-money/context/SimulationContext';
import 'react-tooltip/dist/react-tooltip.css';
import SimulationEditor from './dark-money/components/editor/SimulationEditor';
import PricingCalculator from './home/PricingCalculator';
import PricingSummary from './home/PricingSummary';

function App() {
  return (
    <ThemeProvider>
      <DarkMoneyProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route 
            path="/ai-gone-rogue" 
            element={
              <SimulationProvider>
                <SimulationView />
              </SimulationProvider>
            } 
          />
          <Route 
            path="/antarctica" 
            element={
              <AntarcticaProvider>
                <AntarcticaView />
              </AntarcticaProvider>
            } 
          />
          <Route 
            path="/operation-ares" 
            element={
              <OperationAresProvider>
                <OperationAres />
              </OperationAresProvider>
            } 
          />
          <Route path="/dark-money" element={<MainContainer />} />
          <Route path="/dark-money/editor" element={<SimulationEditor />} />
          <Route path="/pricing-calculator" element={<PricingCalculator />} />
          <Route path="/summary/:type" element={<PricingSummary />} />
        </Routes>
      </Router>
      </DarkMoneyProvider>
    </ThemeProvider>
  );
}

export default App;
