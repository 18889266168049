import React, { useState } from 'react';
import { useTheme } from '../context/ThemeContext';
import { Shield, Cpu, Rocket } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import FacilitatedPrice from './FacilitatedPrice';
import { 
  LicenseGameSelector, 
  LicenseFloatingSummary 
} from './LicensePrice';
import { 
  CustomGameConfigurator, 
  CustomFloatingSummary 
} from './CustomPrice';

const options = [
  {
    id: 'facilitated',
    title: 'Facilitated',
    subtitle: 'Facilitated by our trainers',
    icon: Shield,
    description: "Let our expert trainers deliver engaging sessions at your location.",
    color: 'from-blue-500 to-blue-600',
    activeColor: 'bg-blue-500',
  },
  {
    id: 'license',
    title: 'License',
    subtitle: 'Licenses to deploy on your own',
    icon: Cpu,
    description: "Train your own facilitators and run unlimited sessions.",
    color: 'from-purple-500 to-purple-600',
    activeColor: 'bg-purple-500',
  },
  {
    id: 'custom',
    title: 'Custom',
    subtitle: 'Custom games for you',
    icon: Rocket,
    description: "Get your own unique training game in just 2 weeks.",
    color: 'from-amber-500 to-amber-600',
    activeColor: 'bg-amber-500',
  }
];

const PricingCalculator = () => {
  const { isDark } = useTheme();
  const [selectedOption, setSelectedOption] = useState('facilitated');
  const [selectedLicenseGames, setSelectedLicenseGames] = useState([]);
  
  // Add state for custom game configuration
  const [gameType, setGameType] = useState('content');
  const [needsContent, setNeedsContent] = useState(false);
  const [hasAIGuide, setHasAIGuide] = useState(false);
  const [participantRange, setParticipantRange] = useState(null);
  const [deploymentTypes, setDeploymentTypes] = useState(['face-to-face']);

  const selectedDetails = options.find(opt => opt.id === selectedOption);

  return (
    <div className={`h-screen flex ${isDark ? 'bg-zinc-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
      {/* Left Side Command Panel */}
      <div className="w-48 bg-zinc-900 border-r border-game-accent/20">
        <div className="flex flex-col h-full">
          {options.map((option) => {
            const Icon = option.icon;
            return (
              <button
                key={option.id}
                onClick={() => setSelectedOption(option.id)}
                className={`flex-1 group relative flex flex-col items-center justify-center gap-4
                  transition-all duration-300
                  ${selectedOption === option.id
                    ? `bg-gradient-to-r ${option.color} text-white`
                    : 'hover:bg-zinc-800 text-gray-400 hover:text-white'
                  }
                `}
              >
                <Icon className={`w-8 h-8 transition-colors`} />
                <span className="font-['Space_Grotesk'] font-bold tracking-wide uppercase text-base">
                  {option.title}
                </span>
              </button>
            );
          })}
        </div>
      </div>

      {/* Main Content Area */}
      <div className="flex-1">
        <AnimatePresence mode="wait">
          <motion.div
            key={selectedOption}
            initial={{ opacity: 0, x: 10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -10 }}
            className="p-12 space-y-8"
          >
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="space-y-2"
            >
              <h1 className={`text-4xl font-['Space_Grotesk'] font-bold tracking-tight
                bg-gradient-to-r ${selectedDetails.color} bg-clip-text text-transparent`}>
                {selectedDetails.subtitle}
              </h1>
              <p className="text-gray-400 font-['Space_Grotesk'] text-lg">
                {selectedDetails.description}
              </p>
            </motion.div>

            {selectedOption === 'facilitated' && <FacilitatedPrice />}

            {selectedOption === 'license' && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="space-y-8"
              >
                <LicenseGameSelector
                  selectedGames={selectedLicenseGames}
                  setSelectedGames={setSelectedLicenseGames}
                />
              </motion.div>
            )}

            {selectedOption === 'custom' && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="space-y-8"
              >
                <CustomGameConfigurator
                  gameType={gameType}
                  setGameType={setGameType}
                  needsContent={needsContent}
                  setNeedsContent={setNeedsContent}
                  hasAIGuide={hasAIGuide}
                  setHasAIGuide={setHasAIGuide}
                  participantRange={participantRange}
                  setParticipantRange={setParticipantRange}
                  deploymentTypes={deploymentTypes}
                  setDeploymentTypes={setDeploymentTypes}
                />
              </motion.div>
            )}
          </motion.div>
        </AnimatePresence>
      </div>

      {/* Conditional Floating Summary - Only for license and custom now */}
      {selectedOption === 'license' && (
        <LicenseFloatingSummary
          selectedGames={selectedLicenseGames}
        />
      )}

      {selectedOption === 'custom' && (
        <CustomFloatingSummary
          gameType={gameType}
          needsContent={needsContent}
          hasAIGuide={hasAIGuide}
          deploymentTypes={deploymentTypes}
          participantRange={participantRange}
        />
      )}
    </div>
  );
};

export default PricingCalculator; 