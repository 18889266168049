import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Tooltip } from 'react-tooltip';
import { initialKPIValues } from '../../utils/KPICalculator';

// Define tooltipContent before the component
const tooltipContent = {
  "Profitability": {
    short: "Profitability increases as high-risk clients are accepted quickly.",
    long: "Profitability plummets as the company faces substantial fines."
  },
  "Revenue growth": {
    short: "Revenue growth accelerates as new clients generate more business.",
    long: "Revenue drops sharply as regulatory sanctions lead to loss of business."
  },
  "Market share": {
    short: "Market share expands as the company gains more clients.",
    long: "Market share shrinks as competitors take advantage."
  },
  "Legal expenses": {
    short: "Legal expenses are minimal with fewer compliance checks.",
    long: "Legal expenses skyrocket due to fines and lawsuits."
  },
  "Customer loyalty": {
    short: "Customer loyalty improves with faster service.",
    long: "Loyalty declines as customers distance themselves."
  },
  "Customer satisfaction": {
    short: "Satisfaction improves with quicker onboarding.",
    long: "Satisfaction drops as trust is lost."
  },
  "Reputation": {
    short: "Reputation remains intact initially.",
    long: "Reputation is severely damaged after legal actions."
  },
  "Shareholders satisfaction": {
    short: "Shareholder satisfaction increases with profits.",
    long: "Satisfaction plummets with negative impacts."
  },
  "Employee morale & engagement": {
    short: "Morale improves with efficiency praise.",
    long: "Morale declines with legal investigations."
  }
};

const KPIGroup = ({ item, updatedValues, isUpdating }) => {
  const [previousValues, setPreviousValues] = useState({});

  useEffect(() => {
    if (!isUpdating) {
      setPreviousValues(updatedValues || {});
    }
  }, [updatedValues, isUpdating]);

  const getValueConfig = (value) => {
    if (value >= 90) return {
      color: 'text-emerald-600'
    };
    if (value >= 70) return {
      color: 'text-amber-600'
    };
    return {
      color: 'text-red-600'
    };
  };

  const currentValue = updatedValues?.[item.label] ?? initialKPIValues[item.label];
  const valueConfig = getValueConfig(currentValue);
  const tooltipId = `tooltip-${item.label.replace(/\s+/g, '-').toLowerCase()}`;

  // Get first letter of each word in the label
  const getInitials = (label) => {
    return label
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  const tooltipHtml = `
    <div class="w-[200px] bg-gray-900 p-2 rounded-lg border border-gray-800">
      <div class="flex items-center gap-1 mb-2">
        <div class="bg-emerald-600/20 p-1 rounded-lg">
          <svg class="w-3 h-3 text-emerald-400" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
        <h3 class="text-sm font-bold text-gray-200">${item.label}</h3>
      </div>
      
      <div class="space-y-2">
        <div class="bg-gray-800 rounded-lg p-2 border border-emerald-500/20">
          <div class="flex items-center gap-1 mb-1">
            <svg class="w-3 h-3 text-emerald-400" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <h4 class="font-semibold text-emerald-400 text-[10px]">Short Term Impact</h4>
          </div>
          <p class="text-[10px] text-gray-300 leading-relaxed">
            ${tooltipContent[item.label]?.short || ''}
          </p>
        </div>

        <div class="bg-gray-800 rounded-lg p-2 border border-blue-500/20">
          <div class="flex items-center gap-1 mb-1">
            <svg class="w-3 h-3 text-blue-400" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
            <h4 class="font-semibold text-blue-400 text-[10px]">Long Term Impact</h4>
          </div>
          <p class="text-[10px] text-gray-300 leading-relaxed">
            ${tooltipContent[item.label]?.long || ''}
          </p>
        </div>
      </div>
    </div>
  `;

  return (
    <div className="flex items-center justify-center h-full">
      <div 
        className="flex items-center gap-2 bg-gray-900 px-2 py-1 rounded-lg"
        data-tooltip-id={tooltipId}
        data-tooltip-html={tooltipHtml}
      >
        <div className="flex items-center gap-1">
          <span className="text-[8px] font-medium text-gray-500 uppercase">
            {getInitials(item.label)}
          </span>
          <span className={`text-sm font-bold ${valueConfig.color}`}>
            {currentValue}%
          </span>
        </div>
      </div>

      <Tooltip 
        id={tooltipId}
        place="bottom"
        className="!p-0 !bg-transparent !border-0 !shadow-2xl z-[9999]"
        html={true}
        style={{ zIndex: 9999 }}
      />
    </div>
  );
};

export default KPIGroup;
