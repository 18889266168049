import React, { useState } from 'react';

const QuoteDetailsForm = ({ onSubmit, type = 'custom' }) => {
  const [details, setDetails] = useState({
    companyName: '',
    contactName: '',
    email: ''
  });

  // Helper function to get the right color based on type
  const getButtonColor = () => {
    switch (type) {
      case 'facilitated':
        return 'bg-blue-500 hover:bg-blue-600';
      case 'license':
        return 'bg-purple-500 hover:bg-purple-600';
      case 'custom':
        return 'bg-amber-500 hover:bg-amber-600';
      default:
        return 'bg-zinc-700 hover:bg-zinc-600';
    }
  };

  return (
    <div className="fixed inset-0 z-[60] flex items-center justify-center bg-black/50">
      <div className="bg-zinc-900 p-6 rounded-lg w-[400px] space-y-4">
        <h3 className="text-lg font-['Space_Grotesk'] text-white">Personalize Your Quote</h3>
        <div className="space-y-3">
          <input
            type="text"
            placeholder="Company Name (optional)"
            value={details.companyName}
            onChange={(e) => setDetails({ ...details, companyName: e.target.value })}
            className="w-full px-3 py-2 bg-zinc-800 border border-zinc-700 rounded text-white text-sm"
          />
          <input
            type="text"
            placeholder="Contact Name (optional)"
            value={details.contactName}
            onChange={(e) => setDetails({ ...details, contactName: e.target.value })}
            className="w-full px-3 py-2 bg-zinc-800 border border-zinc-700 rounded text-white text-sm"
          />
          <input
            type="email"
            placeholder="Email (optional)"
            value={details.email}
            onChange={(e) => setDetails({ ...details, email: e.target.value })}
            className="w-full px-3 py-2 bg-zinc-800 border border-zinc-700 rounded text-white text-sm"
          />
        </div>
        <button
          onClick={() => onSubmit(details)}
          className="w-full px-4 py-2 bg-zinc-700 hover:bg-zinc-600
                   text-white font-['Space_Grotesk'] text-sm
                   rounded-lg transition-colors duration-200"
        >
          Go to print
        </button>
      </div>
    </div>
  );
};

export default QuoteDetailsForm; 