import React, { useState, useEffect, useRef } from 'react';
import KpiContainer from './KpiContainer';
import ScenarioContainer from './ScenarioContainer';
import NavigationContainer from './NavigationContainer';
import TutorialMessage from '../components/tutorial/TutorialMessage';
import scenarios from '../components/content/ShadowMoney';
import backgroundMusic from '../assets/sounds/aml-sound.ogg';
import { useSimulation } from '../context/SimulationContext';
import HelpDrawer from '../components/tutorial/HelpDrawer';
import { useParams } from 'react-router-dom';
import { initialKPIValues } from '../utils/KPICalculator';
import StartScreen from '../components/startscreen/StartScreen';
import { motion } from 'framer-motion';

const MainContainer = () => {
  const [showStartScreen, setShowStartScreen] = useState(true);
  const [currentScenarioId, setCurrentScenarioId] = useState(scenarios[0].id);
  const [kpiValues, setKpiValues] = useState(initialKPIValues);
  const [isUpdatingKPIs, setIsUpdatingKPIs] = useState(false);
  const [showKPITutorial, setShowKPITutorial] = useState(false);
  const [showNavTutorial, setShowNavTutorial] = useState(false);
  const { isScenarioAvailable } = useSimulation();
  const [isMuted, setIsMuted] = useState(false);
  const audioRef = useRef(new Audio(backgroundMusic));
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [companyInfo, setCompanyInfo] = useState(null);
  const { companySlug } = useParams();
  const [visitedGroups, setVisitedGroups] = useState([]);
  const [scale, setScale] = useState(1);

  useEffect(() => {
    if (companySlug) {
      const storedCompanyInfo = localStorage.getItem(`company_${companySlug}`);
      if (storedCompanyInfo) {
        setCompanyInfo(JSON.parse(storedCompanyInfo));
      }
    }
  }, [companySlug]);

  useEffect(() => {
    const audio = audioRef.current;
    audio.loop = true;
    audio.volume = 0.05;
    
    if (!isMuted) {
      audio.play().catch(error => console.log("Audio autoplay failed:", error));
    }

    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [isMuted]);

  const toggleMute = () => {
    const audio = audioRef.current;
    if (isMuted) {
      audio.volume = 0.05;
      audio.play().catch(error => console.log("Audio play failed:", error));
    } else {
      audio.pause();
    }
    setIsMuted(!isMuted);
  };

  useEffect(() => {
    if (currentScenarioId === 1 && isUpdatingKPIs) {
      setShowKPITutorial(prev => {
        if (!prev) return true;
        return prev;
      });
    }
  }, [isUpdatingKPIs, currentScenarioId]);

  useEffect(() => {
    if (currentScenarioId === 1 && isScenarioAvailable(2)) {
      const navigationTutorialShown = localStorage.getItem('navigationTutorialShown');
      if (!navigationTutorialShown) {
        setShowNavTutorial(true);
      }
    }
  }, [currentScenarioId, isScenarioAvailable]);

  const handleKPIUpdate = (newValues, isUpdating) => {
    setKpiValues(newValues);
    setIsUpdatingKPIs(isUpdating);
  };

  const handleNavTutorialClose = () => {
    setShowNavTutorial(false);
    localStorage.setItem('navigationTutorialShown', 'true');
  };

  const handleStartGame = () => {
    setShowStartScreen(false);
  };

  useEffect(() => {
    const calculateScale = () => {
      // Base size for minimum supported resolution
      const BASE_WIDTH = 1024;
      const BASE_HEIGHT = 500;
      
      // Calculate available space (93% of viewport)
      const availableWidth = window.innerWidth * 0.93;
      const availableHeight = window.innerHeight * 0.93;
      
      // Calculate scale based on available space
      const scaleX = availableWidth / BASE_WIDTH;
      const scaleY = availableHeight / BASE_HEIGHT;
      
      // Use the smaller scale to maintain aspect ratio
      const scale = Math.min(scaleX, scaleY);
      
      // Ensure minimum scale of 1.0
      const clampedScale = Math.max(scale, 1.0);
      
      setScale(clampedScale);
    };

    calculateScale();
    window.addEventListener('resize', calculateScale);
    return () => window.removeEventListener('resize', calculateScale);
  }, []);

  if (showStartScreen) {
    return <StartScreen onStart={handleStartGame} />;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900">
      <motion.div 
        className="h-[500px] w-[1024px] flex flex-col bg-gray-900 overflow-hidden rounded-xl border border-emerald-500/30 shadow-[0_0_30px_rgba(16,185,129,0.15)] mx-4"
        style={{ 
          transform: `scale(${scale})`,
          transformOrigin: 'center center',
        }}
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ 
          opacity: 1, 
          scale: scale,
        }}
        transition={{ 
          duration: 0.5,
          ease: "easeOut"
        }}
      >
        <motion.div 
          className="absolute inset-0 bg-gradient-to-r from-emerald-500/10 to-blue-500/10 pointer-events-none"
          animate={{
            opacity: [0.1, 0.15, 0.1],
          }}
          transition={{
            duration: 5,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />

        <div className="relative h-[40px] flex-shrink-0">
          <KpiContainer 
            updatedValues={kpiValues} 
            isUpdating={isUpdatingKPIs}
            currentScenarioId={currentScenarioId}
          />
        </div>
        
        <div className="relative h-[calc(100%-40px)] flex">
          <div className="w-[100px] min-w-[100px] bg-gray-900 border-r border-gray-800">
            <NavigationContainer 
              onScenarioSelect={setCurrentScenarioId}
              isMuted={isMuted}
              toggleMute={toggleMute}
              onHelpClick={() => setIsHelpOpen(true)}
              visitedGroups={visitedGroups}
              currentScenarioId={currentScenarioId}
            />
          </div>

          <div className="flex-grow overflow-hidden">
            <ScenarioContainer 
              currentScenarioId={currentScenarioId} 
              onKPIUpdate={handleKPIUpdate}
              currentKPIValues={kpiValues}
              onVisitedGroupsChange={setVisitedGroups}
            />
          </div>
        </div>

        <HelpDrawer isOpen={isHelpOpen} onClose={() => setIsHelpOpen(false)} />
        {showKPITutorial && (
          <TutorialMessage 
            type="kpi" 
            onClose={() => setShowKPITutorial(false)} 
          />
        )}
        {showNavTutorial && (
          <TutorialMessage 
            type="navigation" 
            onClose={handleNavTutorialClose} 
          />
        )}
      </motion.div>
    </div>
  );
};

export default MainContainer;
