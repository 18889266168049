import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

const ThemeContext = createContext();

export function ThemeProvider({ children }) {
  const [isDark, setIsDark] = useState(true);

  const toggleTheme = useCallback(() => {
    setIsDark(prev => !prev);
    document.documentElement.classList.toggle('dark');
  }, []);

  useEffect(() => {
    // Set initial theme
    document.documentElement.classList.add('dark');

    // Add keyboard shortcut listener
    const handleKeyPress = (e) => {
      // Check for Cmd+I (Mac) or Ctrl+I (Windows)
      if ((e.metaKey || e.ctrlKey) && e.key === 'i') {
        e.preventDefault(); // Prevent default browser behavior
        toggleTheme(); // Toggle between dark and light mode
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    // Cleanup
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [toggleTheme]);

  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export const useTheme = () => useContext(ThemeContext); 