import React from 'react';
import { Settings, Gamepad2 } from 'lucide-react';
import './EditorSide.css';

const EditorSide = ({ onViewChange }) => {
  return (
    <div className="editor-side">
      <button 
        className="side-button"
        onClick={() => onViewChange('settings')}
      >
        <Settings size={24} />
      </button>
      <button 
        className="side-button"
        onClick={() => onViewChange('scenarios')}
      >
        <Gamepad2 size={24} />
      </button>
    </div>
  );
};

export default EditorSide;