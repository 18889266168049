import React from 'react';
import { Drawer } from '@mui/material';
import { X, Target, BarChart2, Navigation, MousePointerClick, ChevronRight } from 'lucide-react';

const HelpDrawer = ({ isOpen, onClose }) => {
  const sections = [
    {
      title: "Your Mission",
      icon: <Target className="w-3 h-3 text-white" />,
      bgColor: "bg-emerald-600",
      content: [
        {
          title: "Objective",
          items: [
            "Protect the bank from financial crime",
            "Maintain KPI score above 80%",
            "Balance compliance with efficiency"
          ]
        }
      ]
    },
    {
      title: "Controls",
      icon: <MousePointerClick className="w-3 h-3 text-white" />,
      bgColor: "bg-blue-600",
      content: [
        {
          title: "Basic Actions",
          items: [
            "Read incoming compliance cases",
            "Drag & drop your chosen response",
            "Click 'Send' to confirm decision"
          ]
        },
        {
          title: "Pro Tips",
          items: [
            "Review attachments before deciding",
            "Consider both short & long term impacts",
            "Monitor all KPI changes carefully"
          ]
        }
      ]
    },
    {
      title: "Performance Tracking",
      icon: <BarChart2 className="w-3 h-3 text-white" />,
      bgColor: "bg-amber-600",
      content: [
        {
          title: "Key Metrics",
          items: [
            "Financial: Revenue, Market Share, Legal Costs",
            "Customer: Loyalty, Satisfaction, Reputation",
            "Internal: Shareholder Value, Employee Morale"
          ]
        }
      ]
    },
    {
      title: "Progress System",
      icon: <Navigation className="w-3 h-3 text-white" />,
      bgColor: "bg-purple-600",
      content: [
        {
          title: "Transaction Status",
          items: [
            "🟢 Green: Current active case",
            "🔵 Blue: Successfully completed",
            "⚫ Gray: Locked (complete current first)"
          ]
        }
      ]
    }
  ];

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      className="help-drawer"
      sx={{
        '& .MuiDrawer-paper': {
          width: {
            xs: '100%',
            md: '400px'
          },
          bgcolor: '#111111'
        }
      }}
    >
      <div className="w-full h-full flex flex-col bg-gray-900">
        {/* Header */}
        <div className="p-2 flex justify-between items-center border-b border-gray-800">
          <div className="flex items-center gap-2">
            <div className="bg-emerald-600/20 p-1 rounded-lg">
              <Target className="w-3 h-3 text-emerald-400" />
            </div>
            <h2 className="text-sm font-bold text-emerald-400">Game Guide</h2>
          </div>
          <button 
            onClick={onClose}
            className="text-gray-400 hover:text-gray-200 transition-colors"
          >
            <X className="w-3 h-3" />
          </button>
        </div>

        {/* Content */}
        <div className="flex-1 overflow-auto p-2 space-y-2">
          {sections.map((section, index) => (
            <div 
              key={section.title} 
              className="bg-gray-800/50 rounded-lg border border-gray-800"
            >
              {/* Section Header */}
              <div className="flex items-center gap-2 p-2 border-b border-gray-700">
                <div className={`${section.bgColor} rounded-lg p-1 flex items-center justify-center`}>
                  {section.icon}
                </div>
                <h3 className="text-xs font-bold text-gray-200">
                  {section.title}
                </h3>
              </div>

              {/* Section Content */}
              <div className="p-2 space-y-2">
                {section.content.map((block, blockIndex) => (
                  <div key={blockIndex} className="space-y-1">
                    <h4 className="text-[10px] font-medium text-gray-400">
                      {block.title}
                    </h4>
                    <ul className="space-y-1">
                      {block.items.map((item, i) => (
                        <li 
                          key={i}
                          className="flex items-center gap-1 text-[10px] text-gray-300"
                        >
                          <ChevronRight className="w-2 h-2 text-gray-500 flex-shrink-0" />
                          <span className="leading-relaxed">{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
};

export default HelpDrawer; 