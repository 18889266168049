import React from 'react';
import { useAntarctica } from '../context/AntarcticaContext';
import gameConfig from '../content/game-config.json';
import './SurvivorScore.css';

function SurvivorScore() {
  const { survivors, resources, survivalStatus } = useAntarctica();
  const { markCount, valueFormat } = gameConfig.survivorScore.ui.thermometer;
  const { weights } = gameConfig.survivorScore.calculation;
  
  // Calculate individual components as percentages
  const survivorsScore = (survivors.current / survivors.total) * 100;
  const healthScore = resources.health;
  const moraleScore = resources.morale;
  const boatScore = survivalStatus.boat;
  const foodStorageScore = survivalStatus.foodStorage;
  
  // Calculate weighted score using weights from config
  const score = Math.round(
    (survivorsScore * weights.survivors) +
    (healthScore * weights.health) +
    (moraleScore * weights.morale) +
    (boatScore * weights.boat) +
    (foodStorageScore * weights.foodStorage)
  );

  // Ensure score stays within 0-100 range
  const clampedScore = Math.max(0, Math.min(100, score));
  
  return (
    <div className="survivor-score">
      <div className="survivor-score__thermometer">
        <div 
          className="survivor-score__fill"
          style={{ height: `${clampedScore}%` }}
        >
          <div className="survivor-score__shine"></div>
        </div>
        <div className="survivor-score__marks">
          {[...Array(markCount)].map((_, i) => (
            <div 
              key={i} 
              className="survivor-score__mark"
              style={{ bottom: `${i * (100 / markCount)}%` }}
            />
          ))}
        </div>
      </div>
      <div className="survivor-score__value">
        {valueFormat.replace('%d', clampedScore)}
      </div>
    </div>
  );
}

export default SurvivorScore; 