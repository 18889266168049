const scenarios = [
  {
    id: 1,
    title: "Large Transfer",
    category: "High-Risk Jurisdiction Transactions",
    description: "Account active since 2019. Import/Export trading business with typical transactions between $500K-2M. Medium-High risk rating with no previous suspicious activity reports. Recent activity shows 300% increase in international transfers. Corporate Premium tier client.",
    date: "Wednesday, Dec. 5",
    time: "9:12 AM",
    sender: {
      name: "Sarah",
      avatar: "woman1.png"
    },
    message: {
      content: "Hi {userName},\nWe've just received a request from an existing corporate client to transfer $10 million to a new account in a jurisdiction flagged for money laundering risks. This request is urgent, and they're asking for immediate processing.\nHow do you want to proceed?\nSarah",
      avatar: "woman1.png"
    },
    options: [
      {
        id: 1,
        text: "Delay the transfer and request more documentation about the purpose and recipient.",
        date: "Wednesday, Dec. 5",
        timestamp: "9:45 AM",
        reply: "Sarah,\n\nDelay the transfer and ask the client for more details about the recipient and the business reason behind this large transfer. We need to ensure this is legitimate.\n\n{userName}",
        shortTermImpact: "Your decision to delay the transfer has frustrated the client, causing a temporary dip in customer satisfaction. However, your cautious approach has caught the attention of regulators, who have commended the bank's commitment to compliance.",
        longTermImpact: "Your thorough due diligence revealed that the transfer was indeed suspicious. By preventing a potential money laundering scheme, you've saved the bank from massive fines and reputational damage. This has significantly boosted shareholder confidence and employee morale.",
        kpiImpacts: {
          shortTerm: {
            "Profitability": -5,
            "Revenue growth": -3,
            "Market share": 0,
            "Legal expenses": 0,
            "Customer loyalty": -5,
            "Customer satisfaction": -8,
            "Reputation": 5,
            "Shareholders satisfaction": -2,
            "Employee morale & engagement": 2
          },
          longTerm: {
            "Profitability": 10,
            "Revenue growth": 5,
            "Market share": 3,
            "Legal expenses": 15,
            "Customer loyalty": 8,
            "Customer satisfaction": 5,
            "Reputation": 15,
            "Shareholders satisfaction": 12,
            "Employee morale & engagement": 10
          }
        }
      },
      {
        id: 2,
        text: "Approve the transfer given the urgency and the client's track record.",
        date: "Wednesday, Dec. 5",
        timestamp: "9:45 AM",
        reply: "Sarah,\n\nThis is a valued client with a history of big transactions. Let's approve the transfer but document it carefully for future audits.\n\n{userName}",
        shortTermImpact: "Your quick approval of the transfer has delighted the client, leading to an immediate boost in customer satisfaction and a promise of more business. The swift transaction has also contributed to a spike in your department's efficiency metrics.",
        longTermImpact: "Six months later, the approved transfer has been linked to a major money laundering operation. The bank is now facing severe regulatory fines, a sharp drop in stock price, and a criminal investigation. Several key employees, including yourself, are under scrutiny, and the bank's reputation has been severely damaged.",
        kpiImpacts: {
          shortTerm: {
            "Profitability": 8,
            "Revenue growth": 10,
            "Market share": 2,
            "Legal expenses": 0,
            "Customer loyalty": 10,
            "Customer satisfaction": 15,
            "Reputation": 0,
            "Shareholders satisfaction": 5,
            "Employee morale & engagement": 5
          },
          longTerm: {
            "Profitability": -18,
            "Revenue growth": -15,
            "Market share": -10,
            "Legal expenses": -20,
            "Customer loyalty": -12,
            "Customer satisfaction": -15,
            "Reputation": -20,
            "Shareholders satisfaction": -18,
            "Employee morale & engagement": -15
          }
        }
      }
    ]
  },
  {
    id: 2,
    title: "Offshore Subsidiaries",
    category: "Use of Shell Companies",
    description: "Account active since 2015. Holding company with typical transactions between $1M-5M. High risk rating with 1 resolved suspicious activity report. Recent activity shows multiple new subsidiary registrations. Corporate Elite tier client.",
    date: "Tuesday, Nov. 26",
    time: "10:34 AM",
    sender: {
      name: "Mike",
      avatar: "man1.png"
    },
    message: {
      content: "Hey {userName},\n\nAncielary LLC, one of our oldest clients, has requested to open new accounts for subsidiaries in offshore locations, all of them high-risk jurisdictions.\n\nHow do you want to handle it?\nMike",
      avatar: "man1.png"
    },
    options: [
      {
        id: 1,
        text: "Decline the request and review existing relationship",
        reply: "Mike,\n\nThis is very suspicious, decline the request and check into the whole history of transactions for this client.\n\n{userName}",
        shortTermImpact: "Your decision to decline the request has angered Ancielary LLC. They've threatened to take their business elsewhere, causing a temporary dip in revenue. However, your team has uncovered several suspicious transactions in their history.",
        longTermImpact: "Your thorough review of Ancielary LLC's account has revealed a long-standing money laundering operation. By catching this, you've prevented potential regulatory fines and reputational damage. The board has commended your diligence, boosting your standing in the company.",
        kpiImpacts: {
          shortTerm: {
            "Profitability": -8,
            "Revenue growth": -10,
            "Market share": -5,
            "Legal expenses": 0,
            "Customer loyalty": -12,
            "Customer satisfaction": -10,
            "Reputation": 5,
            "Shareholders satisfaction": -5,
            "Employee morale & engagement": 0
          },
          longTerm: {
            "Profitability": 12,
            "Revenue growth": 8,
            "Market share": 5,
            "Legal expenses": 15,
            "Customer loyalty": 10,
            "Customer satisfaction": 8,
            "Reputation": 18,
            "Shareholders satisfaction": 15,
            "Employee morale & engagement": 12
          }
        }
      },
      {
        id: 2,
        text: "Accept the request, but implement enhanced due diligence",
        reply: "Mike,\n\nLet's proceed cautiously. Accept the request but implement enhanced due diligence and monitoring for these new accounts.\n\n{userName}",
        shortTermImpact: "Your decision to accept the request has pleased Ancielary LLC. They've increased their business with the bank, leading to a short-term boost in revenue and customer satisfaction scores.",
        longTermImpact: "Despite enhanced monitoring, illicit transactions slipped through the new offshore accounts. Regulators have hit the bank with hefty fines, and several board members are calling for your resignation. The bank's reputation has taken a significant hit.",
        kpiImpacts: {
          shortTerm: {
            "Profitability": 10,
            "Revenue growth": 12,
            "Market share": 5,
            "Legal expenses": -5,
            "Customer loyalty": 8,
            "Customer satisfaction": 10,
            "Reputation": 0,
            "Shareholders satisfaction": 8,
            "Employee morale & engagement": 5
          },
          longTerm: {
            "Profitability": -15,
            "Revenue growth": -12,
            "Market share": -8,
            "Legal expenses": -18,
            "Customer loyalty": -10,
            "Customer satisfaction": -12,
            "Reputation": -18,
            "Shareholders satisfaction": -15,
            "Employee morale & engagement": -10
          }
        }
      }
    ]
  },
  {
    id: 3,
    title: "Suspicious Activity",
    category: "Unusual Transaction Patterns",
    description: "Account active since 2022. Real estate investment business with typical transactions between $100K-500K. Medium risk rating with no previous suspicious activity reports. Recent activity shows unusual deposit patterns. Business Plus tier client.",
    date: "Monday, Jan. 14",
    time: "11:20 AM",
    sender: {
      name: "Emma",
      avatar: "woman2.png"
    },
    message: {
      content: "Hi {userName},\n\nWe've noticed unusual activity in one of our client's accounts, with multiple large deposits followed by immediate withdrawals. This pattern is concerning.\n\nWhat should we do?\nEmma",
      avatar: "woman2.png"
    },
    options: [
      {
        id: 1,
        text: "Freeze the account and investigate the transactions.",
        reply: "Emma,\n\nFreeze the account immediately and start an investigation into these transactions. We need to ensure there's no illicit activity.\n\n{userName}",
        shortTermImpact: "Your decision to freeze the account has frustrated the client, who claims it's hampering their legitimate business operations. They've filed a formal complaint, causing a spike in customer dissatisfaction metrics.",
        longTermImpact: "The investigation revealed that the client was indeed involved in a sophisticated fraud scheme. Your quick action has saved the bank from potential regulatory fines and reputational damage. The board has recognized your judgment with a promotion.",
        kpiImpacts: {
          shortTerm: {
            "Profitability": -5,
            "Revenue growth": -3,
            "Market share": 0,
            "Legal expenses": -5,
            "Customer loyalty": -8,
            "Customer satisfaction": -10,
            "Reputation": 3,
            "Shareholders satisfaction": -2,
            "Employee morale & engagement": 0
          },
          longTerm: {
            "Profitability": 12,
            "Revenue growth": 8,
            "Market share": 5,
            "Legal expenses": 15,
            "Customer loyalty": 10,
            "Customer satisfaction": 8,
            "Reputation": 15,
            "Shareholders satisfaction": 12,
            "Employee morale & engagement": 10
          }
        }
      },
      {
        id: 2,
        text: "Monitor the account closely for further suspicious activity.",
        reply: "Emma,\n\nLet's monitor the account closely for now, but prepare to take action if this pattern continues.\n\n{userName}",
        shortTermImpact: "Your decision to monitor rather than freeze the account has kept the client happy. They've even referred new business to the bank, leading to a short-term increase in new account openings.",
        longTermImpact: "The suspicious activity turned out to be part of a major money laundering operation. Regulators have criticized the bank's failure to act promptly, imposing severe fines. Your judgment has been questioned, and you've been moved to a less critical role.",
        kpiImpacts: {
          shortTerm: {
            "Profitability": 5,
            "Revenue growth": 8,
            "Market share": 3,
            "Legal expenses": 0,
            "Customer loyalty": 5,
            "Customer satisfaction": 8,
            "Reputation": 0,
            "Shareholders satisfaction": 5,
            "Employee morale & engagement": 3
          },
          longTerm: {
            "Profitability": -15,
            "Revenue growth": -10,
            "Market share": -8,
            "Legal expenses": -18,
            "Customer loyalty": -12,
            "Customer satisfaction": -10,
            "Reputation": -15,
            "Shareholders satisfaction": -12,
            "Employee morale & engagement": -8
          }
        }
      }
    ]
  },
  {
    id: 4,
    title: "Unusual Transfer",
    category: "High-Value Transactions",
    description: "Account active since 2020. Financial services business with typical transactions between $200K-1M. Medium-Low risk rating with no previous suspicious activity reports. First large international transfer observed recently. Corporate Standard tier client.",
    date: "Thursday, Feb. 7",
    time: "2:15 PM",
    sender: {
      name: "Liam",
      avatar: "man2.png"
    },
    message: {
      content: "Hey {userName},\n\nA client has requested a transfer of $5 million to a personal account in a country known for financial secrecy. This is out of character for them.\n\nHow should we proceed?\nLiam",
      avatar: "man2.png"
    },
    options: [
      {
        id: 1,
        text: "Request additional information about the transfer.",
        reply: "Liam,\n\nAsk the client for more information about the purpose of this transfer and the recipient. We need to ensure compliance with AML regulations.\n\n{userName}",
        shortTermImpact: "Your decision to request more information has delayed the transfer, frustrating the client. They've complained about the bank's 'excessive bureaucracy', causing a dip in customer satisfaction scores.",
        longTermImpact: "The additional information revealed that the transfer was part of a legitimate business expansion. Your due diligence has strengthened the bank's relationship with the client, leading to increased business and positive word-of-mouth in the industry.",
        kpiImpacts: {
          shortTerm: {
            "Profitability": -3,
            "Revenue growth": -2,
            "Market share": 0,
            "Legal expenses": 0,
            "Customer loyalty": -5,
            "Customer satisfaction": -8,
            "Reputation": 2,
            "Shareholders satisfaction": -1,
            "Employee morale & engagement": 0
          },
          longTerm: {
            "Profitability": 8,
            "Revenue growth": 6,
            "Market share": 4,
            "Legal expenses": 10,
            "Customer loyalty": 10,
            "Customer satisfaction": 8,
            "Reputation": 12,
            "Shareholders satisfaction": 10,
            "Employee morale & engagement": 8
          }
        }
      },
      {
        id: 2,
        text: "Approve the transfer but flag it for review.",
        reply: "Liam,\n\nApprove the transfer but make sure it's flagged for a detailed review. Document everything carefully.\n\n{userName}",
        shortTermImpact: "Your decision to approve the transfer has pleased the client. They've praised the bank's efficiency, leading to a boost in customer satisfaction scores and a promise of more business.",
        longTermImpact: "The flagged transfer turned out to be part of a tax evasion scheme. Regulators have criticized the bank for facilitating the transaction, imposing hefty fines. Your decision-making has been called into question, and you're facing an internal disciplinary review.",
        kpiImpacts: {
          shortTerm: {
            "Profitability": 5,
            "Revenue growth": 8,
            "Market share": 2,
            "Legal expenses": 0,
            "Customer loyalty": 8,
            "Customer satisfaction": 10,
            "Reputation": 0,
            "Shareholders satisfaction": 5,
            "Employee morale & engagement": 3
          },
          longTerm: {
            "Profitability": -15,
            "Revenue growth": -10,
            "Market share": -8,
            "Legal expenses": -18,
            "Customer loyalty": -12,
            "Customer satisfaction": -10,
            "Reputation": -18,
            "Shareholders satisfaction": -15,
            "Employee morale & engagement": -12
          }
        }
      }
    ]
  },
  {
    id: 5,
    title: "High-Risk Client",
    category: "Politically Exposed Persons (PEPs)",
    description: "New client pending approval. Private investment business with no transaction history. High risk rating due to PEP status. Multiple account applications submitted. Pending PEP review for tier classification.",
    date: "Friday, Mar. 22",
    time: "4:50 PM",
    sender: {
      name: "Olivia",
      avatar: "woman3.png"
    },
    message: {
      content: "Hi {userName},\n\nWe've been approached by a new client who is a politically exposed person (PEP) from a high-risk country. They want to open multiple accounts with us.\n\nWhat is your advice?\nOlivia",
      avatar: "woman3.png"
    },
    options: [
      {
        id: 1,
        text: "Decline the request due to high risk.",
        reply: "Olivia,\n\nGiven the high risk associated with PEPs, it's best to decline this request to protect our institution.\n\n{userName}",
        shortTermImpact: "Your decision to decline the PEP's request has led to accusations of discrimination. The potential client has gone to the media, causing some negative press for the bank.",
        longTermImpact: "Your caution has paid off. Investigations in the PEP's home country have revealed widespread corruption. The bank's reputation for integrity has strengthened, attracting more high-quality clients and improving overall portfolio stability.",
        kpiImpacts: {
          shortTerm: {
            "Profitability": -5,
            "Revenue growth": -8,
            "Market share": -3,
            "Legal expenses": 0,
            "Customer loyalty": -2,
            "Customer satisfaction": -5,
            "Reputation": -8,
            "Shareholders satisfaction": -5,
            "Employee morale & engagement": -2
          },
          longTerm: {
            "Profitability": 12,
            "Revenue growth": 10,
            "Market share": 8,
            "Legal expenses": 15,
            "Customer loyalty": 10,
            "Customer satisfaction": 8,
            "Reputation": 18,
            "Shareholders satisfaction": 15,
            "Employee morale & engagement": 12
          }
        }
      },
      {
        id: 2,
        text: "Proceed with enhanced due diligence.",
        reply: "Olivia,\n\nWe can proceed, but ensure enhanced due diligence is conducted and ongoing monitoring is in place.\n\n{userName}",
        shortTermImpact: "Your decision to accept the PEP client has brought in significant new business. The bank's quarterly profits have seen a noticeable boost, and you've been commended for your business acumen.",
        longTermImpact: "The PEP client has been implicated in a major corruption scandal. The bank's association with this client has led to reputational damage, regulatory scrutiny, and a sharp drop in share price. You're facing pressure to resign.",
        kpiImpacts: {
          shortTerm: {
            "Profitability": 10,
            "Revenue growth": 12,
            "Market share": 5,
            "Legal expenses": -5,
            "Customer loyalty": 5,
            "Customer satisfaction": 8,
            "Reputation": 0,
            "Shareholders satisfaction": 10,
            "Employee morale & engagement": 5
          },
          longTerm: {
            "Profitability": -18,
            "Revenue growth": -15,
            "Market share": -12,
            "Legal expenses": -20,
            "Customer loyalty": -15,
            "Customer satisfaction": -12,
            "Reputation": -20,
            "Shareholders satisfaction": -18,
            "Employee morale & engagement": -15
          }
        }
      }
    ]
  },
  {
    id: 6,
    title: "Cash Deposits",
    category: "Structuring (Smurfing)",
    description: "Account active since 2021. Retail chain with typical transactions between $10K-50K. Medium risk rating with 2 suspicious activity reports under review. Recent activity shows frequent cash deposits below $10K. Business Select tier client.",
    date: "Wednesday, Apr. 10",
    time: "9:00 AM",
    sender: {
      name: "Noah",
      avatar: "man3.png"
    },
    message: {
      content: "Hey {userName},\n\nA client has been making frequent large cash deposits just below the reporting threshold. This seems like structuring.\n\nWhat should we do?\nNoah",
      avatar: "man3.png"
    },
    options: [
      {
        id: 1,
        text: "Report the activity as suspicious.",
        reply: "Noah,\n\nReport this activity as suspicious to the relevant authorities. It appears to be an attempt to evade reporting requirements.\n\n{userName}",
        shortTermImpact: "Your decision to report the activity has led to an immediate investigation. The client, upon learning of the report, has closed their accounts and left the bank, causing a small dip in deposits.",
        longTermImpact: "The investigation revealed a major tax evasion scheme. Your prompt reporting has earned the bank accolades from regulators, enhancing its reputation for compliance and attracting more legitimate businesses.",
        kpiImpacts: {
          shortTerm: {
            "Profitability": -5,
            "Revenue growth": -8,
            "Market share": -2,
            "Legal expenses": -3,
            "Customer loyalty": -5,
            "Customer satisfaction": -8,
            "Reputation": 5,
            "Shareholders satisfaction": -3,
            "Employee morale & engagement": 2
          },
          longTerm: {
            "Profitability": 10,
            "Revenue growth": 8,
            "Market share": 5,
            "Legal expenses": 15,
            "Customer loyalty": 12,
            "Customer satisfaction": 10,
            "Reputation": 18,
            "Shareholders satisfaction": 15,
            "Employee morale & engagement": 12
          }
        }
      },
      {
        id: 2,
        text: "Contact the client for an explanation.",
        reply: "Noah,\n\nReach out to the client for an explanation of these deposits. We need to understand their intentions.\n\n{userName}",
        shortTermImpact: "Your decision to contact the client has been met with appreciation. They've provided a seemingly reasonable explanation, and the deposits have continued, maintaining the bank's revenue stream.",
        longTermImpact: "The client's activities were eventually flagged by regulators. The bank has been criticized for not reporting the suspicious activity earlier, facing fines and reputational damage. Your judgment is being questioned in an internal review.",
        kpiImpacts: {
          shortTerm: {
            "Profitability": 5,
            "Revenue growth": 8,
            "Market share": 2,
            "Legal expenses": 0,
            "Customer loyalty": 8,
            "Customer satisfaction": 10,
            "Reputation": 0,
            "Shareholders satisfaction": 5,
            "Employee morale & engagement": 3
          },
          longTerm: {
            "Profitability": -12,
            "Revenue growth": -10,
            "Market share": -8,
            "Legal expenses": -15,
            "Customer loyalty": -10,
            "Customer satisfaction": -8,
            "Reputation": -15,
            "Shareholders satisfaction": -12,
            "Employee morale & engagement": -10
          }
        }
      }
    ]
  },
  {
    id: 7,
    title: "Shell Companies",
    category: "Use of Shell Companies",
    description: "Account active since 2018. International trading business with typical transactions between $2M-10M. High risk rating with 1 ongoing suspicious activity report. Recent activity shows complex cross-border transactions. Corporate Premium tier client.",
    date: "Tuesday, May 14",
    time: "3:30 PM",
    sender: {
      name: "Sophia",
      avatar: "woman4.png"
    },
    message: {
      content: "Hi {userName},\n\nA client is using multiple shell companies to move funds across borders. This raises red flags for potential money laundering.\n\nHow should we handle this?\nSophia",
      avatar: "woman4.png"
    },
    options: [
      {
        id: 1,
        text: "Conduct a thorough investigation into the client's activities.",
        reply: "Sophia,\n\nInitiate a thorough investigation into these activities. We need to ensure compliance with AML laws.\n\n{userName}",
        shortTermImpact: "Your decision to investigate has slowed down the client's transactions, causing frustration. They've threatened to move their substantial business elsewhere, putting pressure on the bank's revenue projections.",
        longTermImpact: "The investigation uncovered a complex money laundering operation. By catching this, you've saved the bank from severe regulatory penalties and potential criminal charges. Your diligence has been rewarded with a significant promotion.",
        kpiImpacts: {
          shortTerm: {
            "Profitability": -8,
            "Revenue growth": -10,
            "Market share": -5,
            "Legal expenses": -5,
            "Customer loyalty": -10,
            "Customer satisfaction": -12,
            "Reputation": 3,
            "Shareholders satisfaction": -5,
            "Employee morale & engagement": 0
          },
          longTerm: {
            "Profitability": 15,
            "Revenue growth": 12,
            "Market share": 8,
            "Legal expenses": 18,
            "Customer loyalty": 10,
            "Customer satisfaction": 12,
            "Reputation": 20,
            "Shareholders satisfaction": 18,
            "Employee morale & engagement": 15
          }
        }
      },
      {
        id: 2,
        text: "Close the accounts associated with the shell companies.",
        reply: "Sophia,\n\nClose all accounts linked to these shell companies to prevent any potential illicit activities.\n\n{userName}",
        shortTermImpact: "Your decision to close the accounts has resulted in an immediate loss of business. The client has publicly criticized the bank, causing a temporary dip in new account openings.",
        longTermImpact: "Your quick action prevented the bank from being implicated in a major international money laundering scandal that later came to light. The bank's reputation for strong AML controls has attracted more legitimate, high-value clients, more than offsetting the initial loss.",
        kpiImpacts: {
          shortTerm: {
            "Profitability": -12,
            "Revenue growth": -15,
            "Market share": -8,
            "Legal expenses": 0,
            "Customer loyalty": -10,
            "Customer satisfaction": -15,
            "Reputation": -5,
            "Shareholders satisfaction": -8,
            "Employee morale & engagement": -5
          },
          longTerm: {
            "Profitability": 12,
            "Revenue growth": 10,
            "Market share": 8,
            "Legal expenses": 15,
            "Customer loyalty": 15,
            "Customer satisfaction": 12,
            "Reputation": 18,
            "Shareholders satisfaction": 15,
            "Employee morale & engagement": 12
          }
        }
      }
    ]
  }
];

export default scenarios;
