import React from 'react';
import { useAntarctica } from '../context/AntarcticaContext';
import survivorIcon from '../assets/images/survivor-counter.webp';
import gameConfig from '../content/game-config.json';
import './Survivors.css';

function Survivors() {
  const { survivors } = useAntarctica();
  const { iconAlt, separator } = gameConfig.survivors.ui;

  return (
    <div className="survivors-tracker">
      <img 
        src={survivorIcon} 
        alt={iconAlt} 
        className="survivors-tracker__icon"
      />
      <div className="survivors-tracker__content">
        <span className="survivors-tracker__count">
          <span className="survivors-tracker__current">{survivors.current}</span>
          <span className="survivors-tracker__separator">{separator}</span>
          <span className="survivors-tracker__total">{survivors.total}</span>
        </span>
      </div>
    </div>
  );
}

export default Survivors; 