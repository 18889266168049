import React, { useState, useEffect } from 'react';
import './TaskProgressBar.css';

function TaskProgressBar({ onComplete, mode = 'assigning' }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(interval);
          onComplete();
          return 100;
        }
        return prev + 0.5;
      });
    }, 50);

    return () => clearInterval(interval);
  }, [onComplete]);

  const label = mode === 'assigning' 
    ? 'Assigning Tasks to Crews...' 
    : 'Resolving Task Assignments...';

  return (
    <div className="task-progress">
      <div className="task-progress__label">
        {label}
      </div>
      <div className="task-progress__bar-container">
        <div 
          className="task-progress__bar" 
          style={{ width: `${progress}%` }}
        />
        <div className="task-progress__ticks">
          {Array.from({ length: 20 }).map((_, i) => (
            <div key={i} className="task-progress__tick" />
          ))}
        </div>
      </div>
    </div>
  );
}

export default TaskProgressBar; 