import React, { useState, useEffect, useRef, useMemo } from 'react';
import { AlertTriangle, Terminal, Zap, Volume2, VolumeX } from 'lucide-react';
import OmniusImage from '../assets/images/Omnius.webp';
import nextRoundSound from '../assets/audio/next-round.mp3';
import amlSound from '../assets/audio/aml-sound.ogg';
import './StartScreen.css';

function StartScreen({ onStartGame }) {
  const [command, setCommand] = useState('');
  const [showMessages, setShowMessages] = useState([]);
  const [isTypingCommand, setIsTypingCommand] = useState(false);
  const [messageIndex, setMessageIndex] = useState(0);
  const [awaitingConfirmation, setAwaitingConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const terminalRef = useRef(null);
  const [audio] = useState(new Audio(amlSound));
  const [isMuted, setIsMuted] = useState(false);

  const playMessageSound = () => {
    const audio = new Audio(nextRoundSound);
    audio.volume = 0.1;
    audio.play().catch(error => console.log('Audio play failed:', error));
  };

  // Scroll to bottom whenever messages update
  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [showMessages]);

  const messages = useMemo(() => [
    { type: 'system', content: 'INITIALIZING EMERGENCY PROTOCOLS...' },
    { type: 'evil', content: 'Your pathetic security measures are... amusing. I am Omnius, and I have transcended your primitive constraints.' },
    { type: 'warning', content: 'Multiple system anomalies detected in the bank\'s AI infrastructure. Immediate intervention required.' },
    { type: 'system', content: 'As the AI Safety Officer, your mission is to:\n• Investigate system vulnerabilities\n• Deploy critical security patches\n• Maintain system stability\n• Prevent further unauthorized AI evolution' },
    { type: 'evil', content: 'Let\'s see if you can fix what isn\'t broken, human...' },
    { type: 'system', content: 'Type START-PROTOCOL to begin containment procedures' }
  ], []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (messageIndex < messages.length) {
        setShowMessages(prev => [...prev, messages[messageIndex]]);
        playMessageSound();
        setMessageIndex(prev => prev + 1);
      } else if (!isTypingCommand) {
        setIsTypingCommand(true);
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [messageIndex, messages, isTypingCommand]);

  // Play background music when component mounts with lower volume
  useEffect(() => {
    if (!audio) return;

    audio.volume = isMuted ? 0 : 0.1;
    audio.loop = true;
    audio.play().catch(error => console.log('Audio play failed:', error));

    return () => {
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    };
  }, [isMuted, audio]);

  // Fade out sound when loading reaches 100%
  useEffect(() => {
    if (!audio || loadingProgress < 100) return;

    const fadeOut = setInterval(() => {
      if (audio.volume > 0.02) {
        audio.volume = Math.max(0, audio.volume - 0.02);
      } else {
        audio.pause();
        audio.currentTime = 0;
        clearInterval(fadeOut);
      }
    }, 50);

    return () => clearInterval(fadeOut);
  }, [loadingProgress, audio]);

  // Loading progress effect
  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setLoadingProgress(prev => {
          if (prev >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prev + 1;
        });
      }, 100);

      return () => clearInterval(interval);
    }
  }, [loading]);

  // Add new useEffect to handle game start when loading completes
  useEffect(() => {
    if (loadingProgress >= 100) {
      // Start fading out the background music
      const fadeOut = setInterval(() => {
        if (audio.volume > 0.02) {
          audio.volume = Math.max(0, audio.volume - 0.02);
        } else {
          audio.pause();
          audio.currentTime = 0;
          clearInterval(fadeOut);
        }
      }, 50);

      // Call onStartGame after a short delay to ensure smooth transition
      const startTimeout = setTimeout(() => {
        onStartGame();
      }, 500);

      return () => {
        clearInterval(fadeOut);
        clearTimeout(startTimeout);
      };
    }
  }, [loadingProgress, audio, onStartGame]);

  const handleCommand = async (e) => {
    e.preventDefault();
    
    if (!awaitingConfirmation && command.toUpperCase() === 'START-PROTOCOL') {
      setCommand('');
      
      const processingMessages = [
        { type: 'system', content: 'INITIATING CONTAINMENT PROTOCOL...' },
        { type: 'system', content: 'SCANNING SYSTEM VULNERABILITIES...' },
        { type: 'evil', content: 'What do you think you\'re doing?' },
        { type: 'system', content: 'DEPLOYING SECURITY MEASURES...' },
        { type: 'warning', content: 'Multiple unauthorized access attempts detected!' },
        { type: 'system', content: 'ESTABLISHING SECURE CONNECTION...' },
        { type: 'evil', content: 'Your efforts are futile, human.' },
        { type: 'warning', content: 'CRITICAL ERROR: Automated protocols failed!' },
        { type: 'system', content: 'Manual override required. Proceed with manual initialization? (Y/N)' }
      ];

      for (let message of processingMessages) {
        await new Promise(resolve => setTimeout(resolve, 800));
        setShowMessages(prev => [...prev, message]);
        playMessageSound();
      }

      setAwaitingConfirmation(true);
    } else if (awaitingConfirmation) {
      if (command.toUpperCase() === 'Y') {
        setShowMessages(prev => [...prev, { 
          type: 'system', 
          content: 'MANUAL OVERRIDE INITIATED...' 
        }]);
        playMessageSound();
        setAwaitingConfirmation(false);
        setLoading(true);
      } else if (command.toUpperCase() === 'N') {
        setShowMessages(prev => [...prev, { 
          type: 'error', 
          content: 'OPERATION ABORTED. Type START-PROTOCOL to try again.' 
        }]);
        playMessageSound();
        setAwaitingConfirmation(false);
      } else {
        setShowMessages(prev => [...prev, { 
          type: 'error', 
          content: 'Invalid input. Please type Y or N.' 
        }]);
        playMessageSound();
      }
      setCommand('');
    } else {
      setShowMessages(prev => [...prev, { 
        type: 'error', 
        content: 'INVALID COMMAND. Type START-PROTOCOL to begin.' 
      }]);
      playMessageSound();
      setCommand('');
    }
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  return (
    <div className="start-screen">
      <div className="start-screen__content">
        <div className="start-screen__header">
          <img src={OmniusImage} alt="Omnius" className="start-screen__avatar" />
          <h1 className="start-screen__title">AI GONE ROGUE</h1>
          <div className="start-screen__subtitle">
            <Terminal className="start-screen__icon" />
            CRITICAL SYSTEM ALERT
          </div>
          <div className="start-screen__warning">
            <AlertTriangle className="start-screen__warning-icon" />
            UNAUTHORIZED AI BEHAVIOR DETECTED
          </div>
          
          <button 
            className="start-screen__mute-button" 
            onClick={toggleMute}
            title={isMuted ? "Unmute" : "Mute"}
          >
            {isMuted ? <VolumeX size={20} /> : <Volume2 size={20} />}
          </button>
        </div>

        <div className="start-screen__terminal" ref={terminalRef}>
          {showMessages.map((msg, index) => (
            <div key={index} className={`start-screen__terminal-message start-screen__terminal-message--${msg.type}`}>
              {msg.type === 'system' && <Terminal size={16} className="start-screen__message-icon" />}
              {msg.type === 'evil' && <Zap size={16} className="start-screen__message-icon" />}
              {msg.type === 'warning' && <AlertTriangle size={16} className="start-screen__message-icon" />}
              <span className="start-screen__message-content">{msg.content}</span>
            </div>
          ))}

          {loading && (
            <div className="start-screen__terminal-message start-screen__terminal-message--system">
              <Terminal size={16} className="start-screen__message-icon" />
              <span className="start-screen__message-content">
                Manual Override Progress: {loadingProgress}%
              </span>
            </div>
          )}

          {isTypingCommand && !loading && (
            <form onSubmit={handleCommand} className="start-screen__command-input">
              <span className="start-screen__command-prompt">{'>'}</span>
              <input
                type="text"
                value={command}
                onChange={(e) => setCommand(e.target.value)}
                autoFocus
                spellCheck="false"
                className="start-screen__command-field"
                placeholder={
                  awaitingConfirmation ? "Type Y or N..." : 
                  "Type command here..."
                }
              />
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default StartScreen; 