import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Heart, Linkedin, Sparkles, Gamepad2, MousePointerClick } from 'lucide-react';
import logo from '../assets/logo.svg';

const Footer = () => {
  const [isEasterEggFound, setIsEasterEggFound] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  const handleLogoClick = () => {
    setClickCount(prev => {
      const newCount = prev + 1;
      if (newCount === 3 && !isEasterEggFound) {
        setIsEasterEggFound(true);
        // Wait for content to render before scrolling
        setTimeout(() => {
          const footer = document.getElementById('footer');
          footer?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }, 100);
      }
      return newCount;
    });
  };

  return (
    <div className="w-full py-24" id="footer">
      <div className="container mx-auto px-4">
        <div className="space-y-16">
          {/* Animated Logo Section */}
          <div className="flex flex-col items-center">
            {/* Click Instructions */}
            <div className="h-8 mb-2">
              {!isEasterEggFound && (
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="text-xs text-gray-400 font-['Space_Grotesk'] flex items-center gap-2"
                >
                  {clickCount === 0 ? (
                    <>
                      <MousePointerClick className="w-3 h-3 text-game-accent" />
                      Click the logo
                      <MousePointerClick className="w-3 h-3 text-game-accent" />
                    </>
                  ) : (
                    <>
                      <Sparkles className="w-3 h-3 text-game-accent" />
                      {3 - clickCount} more {3 - clickCount === 1 ? 'click' : 'clicks'}...
                      <Sparkles className="w-3 h-3 text-game-accent" />
                    </>
                  )}
                </motion.p>
              )}
            </div>

            {/* Logo with just the shake animation */}
            <div 
              className="relative cursor-pointer group"
              onClick={handleLogoClick}
            >
              <motion.div
                animate={clickCount === 0 ? { 
                  x: [0, -0.5, 0.5, -0.5, 0],
                  rotate: [0, -0.5, 0.5, -0.5, 0]
                } : {}}
                transition={{ 
                  duration: 0.5,
                  repeat: clickCount === 0 ? Infinity : 0,
                  repeatType: "mirror",
                  ease: "easeInOut"
                }}
              >
                <img 
                  src={logo} 
                  alt="Teamlearn" 
                  className="h-12 relative invert brightness-0" 
                />
              </motion.div>
            </div>
          </div>

          {/* Hidden Content */}
          <AnimatePresence>
            {isEasterEggFound && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, staggerChildren: 0.1 }}
                className="space-y-12"
              >
                {/* Gradient Divider */}
                <motion.div
                  initial={{ width: '0%' }}
                  animate={{ width: '100%' }}
                  transition={{ duration: 1, delay: 0.2 }}
                  className="h-px bg-gradient-to-r from-transparent via-game-accent to-transparent"
                />

                {/* Achievement Banner */}
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ type: "spring", bounce: 0.5, delay: 0.3 }}
                  className="flex flex-col items-center gap-4"
                >
                  <div className="px-6 py-3 bg-gradient-to-r from-game-accent/20 to-purple-500/20 
                               rounded-full flex items-center gap-2">
                    <Gamepad2 className="w-5 h-5 text-game-accent animate-pulse" />
                    <span className="text-sm font-['Space_Grotesk'] text-white">
                      You've Unlocked Our Secret Footer!
                    </span>
                  </div>
                  <p className="text-lg font-['Space_Grotesk'] text-gray-400 max-w-md text-center">
                    Welcome to the club! You're now part of the <span className="text-game-accent">1%</span> who 
                    discovered our hidden footer. This is how we make learning fun!
                  </p>
                </motion.div>

                {/* Social and Credits */}
                <motion.div className="flex flex-col items-center gap-6">
                  <motion.a
                    href="https://teamlearn.ai"
                    target="_blank"
                    rel="noopener noreferrer"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="flex items-center gap-2 px-4 py-2 rounded-xl 
                             bg-zinc-900 hover:bg-zinc-800 
                             transition-colors duration-300"
                  >
                    <Linkedin className="w-5 h-5 text-white" />
                    <span className="text-sm font-['Space_Grotesk'] text-white">
                      Follow us on LinkedIn
                    </span>
                  </motion.a>

                  <div className="flex items-center gap-2 text-sm text-gray-400">
                    <span>Made with</span>
                    <Heart className="w-4 h-4 text-game-accent animate-pulse" />
                    <span>by</span>
                    <a 
                      href="https://teamlearn.ai" 
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white hover:text-game-accent transition-colors"
                    >
                      Teamlearn
                    </a>
                  </div>

                  <p className="text-sm text-gray-500">
                    © {new Date().getFullYear()} Teamlearn. All rights reserved.
                  </p>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Footer; 