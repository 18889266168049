import React, { useState, useEffect } from 'react';
import { useAntarctica } from '../context/AntarcticaContext';
import IceButton from '../components/IceButton';
import TaskProgressBar from '../components/TaskProgressBar';
import './css/BottomBar.css';

function BottomBar() {
  const { setPhase } = useAntarctica();
  const [selectedStrategy, setSelectedStrategy] = useState(null);
  const [phase, setLocalPhase] = useState('choose');
  const [allCrewsAssigned, setAllCrewsAssigned] = useState(false);
  const [isAssigning, setIsAssigning] = useState(false);
  const [isResolving, setIsResolving] = useState(false);
  const [allWorkersInCrews, setAllWorkersInCrews] = useState(false);
  const [totalCrews, setTotalCrews] = useState(0);
  const [assignedCrewsCount, setAssignedCrewsCount] = useState(0);

  useEffect(() => {
    const handleWorkersStateChanged = (event) => {
      setAllWorkersInCrews(event.detail.allWorkersInCrews);
      setTotalCrews(event.detail.availableCrews);
    };

    const handleCrewAssignmentsChanged = (event) => {
      setAssignedCrewsCount(event.detail.assignedCrewsCount);
    };

    // Check if all conditions are met
    const checkAllAssignments = () => {
      // All workers must be in crews AND all crews must be assigned to tasks
      const allCrewsAssignedToTasks = assignedCrewsCount > 0 && 
        (totalCrews === 0 && assignedCrewsCount > 0); // All crews are assigned when there are no unassigned crews left
      
      setAllCrewsAssigned(allWorkersInCrews && allCrewsAssignedToTasks);
    };

    document.addEventListener('workersStateChanged', handleWorkersStateChanged);
    document.addEventListener('crewAssignmentsChanged', handleCrewAssignmentsChanged);

    checkAllAssignments();

    return () => {
      document.removeEventListener('workersStateChanged', handleWorkersStateChanged);
      document.removeEventListener('crewAssignmentsChanged', handleCrewAssignmentsChanged);
    };
  }, [allWorkersInCrews, totalCrews, assignedCrewsCount]);

  useEffect(() => {
    const handleStrategySelect = (event) => {
      setSelectedStrategy(event.detail.strategy);
      setLocalPhase('confirm');
    };

    const handleTasksDelegated = () => {
      setIsAssigning(true);
    };

    window.addEventListener('strategySelected', handleStrategySelect);
    window.addEventListener('tasksDelegated', handleTasksDelegated);
    
    return () => {
      window.removeEventListener('strategySelected', handleStrategySelect);
      window.removeEventListener('tasksDelegated', handleTasksDelegated);
    };
  }, []);

  const handleConfirm = () => {
    window.dispatchEvent(new CustomEvent('strategyConfirmed', { 
      detail: { strategy: selectedStrategy } 
    }));
    setLocalPhase('assign');
    setPhase('assign');
  };

  const handleDelegate = () => {
    setIsAssigning(true);
    window.dispatchEvent(new CustomEvent('closeTactics'));
    window.dispatchEvent(new CustomEvent('tasksDelegated'));
  };

  const handleProgressComplete = () => {
    setIsAssigning(false);
    setLocalPhase('finish');
    setPhase('finish');
  };

  const handleFinishRound = () => {
    setIsResolving(true);
    window.dispatchEvent(new CustomEvent('resolvingStart'));
  };

  const handleResolvingComplete = () => {
    window.dispatchEvent(new CustomEvent('roundFinished'));
    setLocalPhase('choose');
    setPhase('choose');
    setAllCrewsAssigned(false);
    setSelectedStrategy(null);
    setIsResolving(false);
  };

  return (
    <div className="antarctica-bottom-bar">
      <div className="antarctica-bottom-bar__content">
        <div className={`antarctica-bottom-bar__container ${phase}`}>
          {phase === 'assign' && !allCrewsAssigned ? (
            <h2 className="antarctica-bottom-bar__title">
              Assign Tasks to Your People
            </h2>
          ) : phase === 'assign' && allCrewsAssigned && !isAssigning ? (
            <IceButton 
              onClick={handleDelegate}
              text="Delegate"
              variant="delegate"
            />
          ) : phase === 'assign' && isAssigning ? (
            <TaskProgressBar 
              onComplete={handleProgressComplete}
              mode="assigning"
            />
          ) : phase === 'finish' && !isResolving ? (
            <IceButton 
              onClick={handleFinishRound}
              text="Finish Round"
              variant="finish"
            />
          ) : isResolving ? (
            <TaskProgressBar 
              onComplete={handleResolvingComplete}
              mode="resolving"
            />
          ) : (
            <>
              <h2 className="antarctica-bottom-bar__title">Choose Your Round Strategy</h2>
              <div className="antarctica-bottom-bar__actions">
                <IceButton 
                  onClick={handleConfirm}
                  selectedStrategy={selectedStrategy}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default BottomBar; 