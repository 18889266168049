import React from 'react';
import { useSimulation } from '../../ai-gone-rogue/SimulationContext';
import { scenarios } from '../../ai-gone-rogue/ScenarioDatabase';
import { KPI_TYPES } from '../../ai-gone-rogue/constants';
import { LineChart, ClipboardList } from 'lucide-react';
import OmniusImage from '../../assets/images/Omnius.webp';
import './SimulationEndScreen.css';
import { ScenarioTooltip } from './ScenarioTooltip';

function SimulationEndScreen() {
  const { scores, choices } = useSimulation();
  
  const calculateFinalScore = () => {
    if (!scores) return 0;

    // For Risk Level, we need to invert the score since lower is better
    const riskScore = 100 - scores[KPI_TYPES.RISK_LEVEL].value;
    
    // Get other scores directly
    const marketScore = scores[KPI_TYPES.MARKET_CONFIDENCE].value;
    const complianceScore = scores[KPI_TYPES.COMPLIANCE_SCORE].value;
    
    // Calculate average (all scores are now on a 0-100 scale where higher is better)
    const average = Math.round((riskScore + marketScore + complianceScore) / 3);
    
    return Math.max(0, Math.min(100, average));
  };

  const getAllScenarios = () => {
    const allScenarios = [];
    let currentRoundScenarios = [];
    let lastRound = null;
    
    Object.entries(scenarios).forEach(([roundKey, roundData]) => {
      roundData.scenarios.forEach(scenario => {
        if (lastRound !== null && lastRound !== scenario.round) {
          // Add the accumulated scenarios with a round separator
          allScenarios.push({ type: 'separator', round: lastRound });
          allScenarios.push(...currentRoundScenarios);
          currentRoundScenarios = [];
        }
        
        currentRoundScenarios.push({
          type: 'scenario',
          ...scenario,
          userChoice: choices[scenario.id] || 'no'
        });
        
        lastRound = scenario.round;
      });
    });
    
    // Add the last round's scenarios
    if (currentRoundScenarios.length > 0) {
      allScenarios.push({ type: 'separator', round: lastRound });
      allScenarios.push(...currentRoundScenarios);
    }
    
    return allScenarios;
  };

  const finalScore = calculateFinalScore();

  return (
    <div className="end-screen-container">
      <div className="end-screen-header">
        <div className="end-screen-title-container">
          <img src={OmniusImage} alt="Omnius" className="end-screen-omnius-avatar" />
          <div>
            <h1 className="end-screen-title">AI GONE ROGUE</h1>
            <p className="end-screen-subtitle">
              You have completed the Simulation on AI Ethics, Risks and Responsible Corporate Usage of Large Language Models
            </p>
          </div>
        </div>
        <div className="end-screen-score">
          <span className="end-screen-score-label">FINAL SCORE</span>
          {finalScore}%
        </div>
      </div>

      {finalScore >= 90 && (
        <div className="end-screen-victory-message">
          OMNIUS DEFEATED // Your strategic decisions have ensured a safe and responsible AI implementation
        </div>
      )}

      <div className="end-screen-metrics">
        <div className="section-header">
          <LineChart className="section-icon" size={24} />
          <h2 className="section-title">Performance Metrics</h2>
        </div>
        <div className="end-screen-metrics-grid">
          {scores && Object.entries(scores).map(([key, score]) => (
            <div key={key} className="end-screen-metric-card">
              <div className="end-screen-metric-label">{score.label}</div>
              <div className="end-screen-metric-value">
                {score.prefix}{score.value?.toLocaleString()}{score.suffix}
              </div>
            </div>
          ))}
        </div>
        <div className="end-screen-credits">
          <div>© makeithuman.ai {new Date().getFullYear()}</div>
          <div>Content by Brainiup</div>
          <div>Game Mechanic by Teamlearn</div>
          <div>Game Concept by Make it Human</div>
        </div>
      </div>

      <div className="end-screen-decisions">
        <div className="section-header">
          <ClipboardList className="section-icon" size={24} />
          <h2 className="section-title">Decision Log</h2>
        </div>
        <div className="end-screen-table-container">
          <table className="end-screen-table">
            <thead>
              <tr>
                <th>Round</th>
                <th className="title-column">Title</th>
                <th>Your Choice</th>
                <th>Correct Flag</th>
                <th>Importance</th>
              </tr>
            </thead>
            <tbody>
              {getAllScenarios().map((item, index) => {
                if (item.type === 'separator') {
                  return (
                    <tr key={`separator-${item.round}`} className="end-screen-round-separator">
                      <td colSpan="5">Round {item.round}</td>
                    </tr>
                  );
                }

                const rowClass = item.userChoice === 'no' 
                  ? 'end-screen-row-no-decision'
                  : item.userChoice === item.correctFlag 
                    ? 'end-screen-row-correct' 
                    : 'end-screen-row-incorrect';

                return (
                  <tr key={item.id} className={`${rowClass} end-screen-scenario-row`}>
                    <td>{item.round}</td>
                    <td className="title-column">
                      <ScenarioTooltip description={item.description}>
                        {item.title}
                      </ScenarioTooltip>
                    </td>
                    <td>
                      <span className={`end-screen-flag end-screen-flag-${item.userChoice}`}>
                        {item.userChoice === 'no' ? 'NO CHOICE' : item.userChoice.toUpperCase()}
                      </span>
                    </td>
                    <td>
                      <span className={`end-screen-flag end-screen-flag-${item.correctFlag}`}>
                        {item.correctFlag.toUpperCase()}
                      </span>
                    </td>
                    <td className={`end-screen-importance-${item.importance.toLowerCase()}`}>
                      {item.importance}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SimulationEndScreen; 