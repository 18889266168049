import React, { useState } from 'react';
import GameButton from '../game-elements/GameButton';
import NewsAlert from '../game-elements/NewsAlert';
import ImpactAlert from '../game-elements/ImpactAlert';
import RiskAlert from '../game-elements/RiskAlert';
import ComplianceAlert from '../game-elements/ComplianceAlert';
import { useSimulation } from '../../ai-gone-rogue/SimulationContext';
import { getRequiredChoices } from '../../ai-gone-rogue/ScenarioDatabase';
import { generateNewsHeadlines, generateFinalGrowthHeadline } from '../../ai-gone-rogue/marketConfidence';
import { playNextRoundSound } from '../../utils/SoundEffects';
import './BottomBar.css';

function BottomBar() {
  const { 
    currentRound,
    isLastRound,
    scenarios,
    choices,
    advanceToNextRound,
    activeGrowthEffects,
    pendingBankBalance = {},
    pendingRiskLevel = {},
    pendingCompliance = {},
    completeSimulation
  } = useSimulation();

  const [currentNews, setCurrentNews] = useState(null);
  const [newsQueue, setNewsQueue] = useState([]);
  const [showBankImpact, setShowBankImpact] = useState(false);
  const [showRiskImpact, setShowRiskImpact] = useState(false);
  const [showComplianceImpact, setShowComplianceImpact] = useState(false);

  const requiredChoices = getRequiredChoices(currentRound);
  const currentChoices = scenarios.filter(s => choices[s.id]).length;
  const isRoundComplete = currentChoices === requiredChoices;

  const handleContinue = () => {
    playNextRoundSound();
    const headlines = generateNewsHeadlines(scenarios, choices);
    
    if (isLastRound) {
      const finalGrowth = generateFinalGrowthHeadline(activeGrowthEffects);
      if (finalGrowth) headlines.push(finalGrowth);
    }

    if (headlines.length > 0) {
      setCurrentNews(headlines[0]);
      setNewsQueue(headlines.slice(1));
    } else {
      showNextNews();
    }
  };

  const showNextNews = () => {
    if (newsQueue.length > 0) {
      setCurrentNews(newsQueue[0]);
      setNewsQueue(prev => prev.slice(1));
    } else {
      setCurrentNews(null);
      const totalBankImpact = calculateTotalBankImpact();
      if (totalBankImpact !== 0) {
        setShowBankImpact(true);
      } else {
        checkNextAlert();
      }
    }
  };

  const checkNextAlert = () => {
    const totalRiskImpact = calculateTotalRiskImpact();
    if (totalRiskImpact !== 0) {
      setShowRiskImpact(true);
    } else {
      const totalComplianceImpact = calculateTotalComplianceImpact();
      if (totalComplianceImpact !== 0) {
        setShowComplianceImpact(true);
      } else {
        advanceToNextRound();
      }
    }
  };

  const handleBankImpactClose = () => {
    setShowBankImpact(false);
    checkNextAlert();
  };

  const handleRiskImpactClose = () => {
    setShowRiskImpact(false);
    const totalComplianceImpact = calculateTotalComplianceImpact();
    if (totalComplianceImpact !== 0) {
      setShowComplianceImpact(true);
    } else {
      advanceToNextRound();
    }
  };

  const handleComplianceImpactClose = () => {
    setShowComplianceImpact(false);
    advanceToNextRound();
  };

  const calculateTotalBankImpact = () => {
    if (!pendingBankBalance) return 0;
    return Object.values(pendingBankBalance).reduce((sum, impact) => sum + impact, 0);
  };

  const calculateTotalRiskImpact = () => {
    if (!pendingRiskLevel) return 0;
    return Object.values(pendingRiskLevel).reduce((sum, impact) => sum + impact, 0);
  };

  const calculateTotalComplianceImpact = () => {
    if (!pendingCompliance) return 0;
    return Object.values(pendingCompliance).reduce((sum, impact) => sum + impact, 0);
  };

  const handleComplete = () => {
    playNextRoundSound();
    completeSimulation();
  };

  return (
    <>
      <div className="bottom-bar">
        {isLastRound ? (
          <GameButton onClick={handleComplete}>
            Complete Simulation
          </GameButton>
        ) : (
          <GameButton 
            onClick={handleContinue} 
            disabled={!isRoundComplete}
          >
            Continue
          </GameButton>
        )}
      </div>

      <NewsAlert 
        news={currentNews}
        onClose={showNextNews}
      />

      <ImpactAlert 
        impact={showBankImpact ? calculateTotalBankImpact() : null}
        onClose={handleBankImpactClose}
      />

      <RiskAlert 
        impact={showRiskImpact ? calculateTotalRiskImpact() : null}
        onClose={handleRiskImpactClose}
      />

      <ComplianceAlert 
        impact={showComplianceImpact ? calculateTotalComplianceImpact() : null}
        onClose={handleComplianceImpactClose}
      />
    </>
  );
}

export default BottomBar; 