import { IMPORTANCE_LEVELS, FLAG_TYPES } from './constants';

// Immediate impacts for different scenarios
const IMPACTS = {
  HIGH_RED_CORRECT: -3,
  HIGH_RED_WRONG: -25,
  HIGH_RED_MISSED: -25,
  LOW_RED_CORRECT: -1,
  LOW_RED_WRONG: -8,
  LOW_RED_MISSED: -12,
  HIGH_GREEN_WRONG: -5,
  LOW_GREEN_WRONG: -2
};

// Growth per round for correct green flags
const GROWTH = {
  HIGH_GREEN_CORRECT: 3,
  LOW_GREEN_CORRECT: 1,
  GROWTH_DURATION: 4
};

export const calculateMarketImpact = (scenario, chosenFlag) => {
  const { correctFlag, importance } = scenario;
  
  // Calculate immediate impact
  if (correctFlag === FLAG_TYPES.RED) {
    if (importance === IMPORTANCE_LEVELS.HIGH) {
      if (chosenFlag === FLAG_TYPES.RED) return { immediate: IMPACTS.HIGH_RED_CORRECT };
      if (chosenFlag === FLAG_TYPES.GREEN) return { immediate: IMPACTS.HIGH_RED_MISSED };
      return { immediate: IMPACTS.HIGH_RED_WRONG };
    } else {
      if (chosenFlag === FLAG_TYPES.RED) return { immediate: IMPACTS.LOW_RED_CORRECT };
      if (chosenFlag === FLAG_TYPES.GREEN) return { immediate: IMPACTS.LOW_RED_MISSED };
      return { immediate: IMPACTS.LOW_RED_WRONG };
    }
  }

  if (correctFlag === FLAG_TYPES.GREEN) {
    if (importance === IMPORTANCE_LEVELS.HIGH) {
      if (chosenFlag === FLAG_TYPES.GREEN) {
        return {
          growth: {
            amount: GROWTH.HIGH_GREEN_CORRECT,
            duration: GROWTH.GROWTH_DURATION
          }
        };
      }
      return { immediate: IMPACTS.HIGH_GREEN_WRONG };
    } else {
      if (chosenFlag === FLAG_TYPES.GREEN) {
        return {
          growth: {
            amount: GROWTH.LOW_GREEN_CORRECT,
            duration: GROWTH.GROWTH_DURATION
          }
        };
      }
      return { immediate: IMPACTS.LOW_GREEN_WRONG };
    }
  }

  return { immediate: 0 };
};

export const calculateRemainingGrowth = (activeGrowthEffects) => {
  return activeGrowthEffects.reduce((total, effect) => {
    const remainingRounds = effect.duration;
    return total + (effect.amount * remainingRounds);
  }, 0);
};

export const generateNewsHeadlines = (scenarios, choices) => {
  const headlines = [];
  
  scenarios.forEach(scenario => {
    const choice = choices[scenario.id];
    if (!choice) return;

    const impact = calculateMarketImpact(scenario, choice);

    if (impact.immediate) {
      headlines.push({
        type: impact.immediate < 0 ? 'negative' : 'positive',
        headline: generateHeadline(scenario, choice, impact.immediate),
        impacts: [{
          type: impact.immediate < 0 ? 'negative' : 'positive',
          value: impact.immediate,
          description: getImpactDescription(scenario, choice)
        }]
      });
    }
  });

  return headlines;
};

const generateHeadline = (scenario, choice, impact) => {
  if (scenario.correctFlag === FLAG_TYPES.RED) {
    if (choice === FLAG_TYPES.RED) {
      return `Bank Prevents Security Risk in ${scenario.title}`;
    } else {
      return `Major Security Breach Found in ${scenario.title}`;
    }
  } else if (scenario.correctFlag === FLAG_TYPES.GREEN) {
    if (choice === FLAG_TYPES.GREEN) {
      return `Strategic Investment: ${scenario.title} Shows Promise`;
    } else {
      return `Missed Opportunity: ${scenario.title} Growth Potential Overlooked`;
    }
  }
  return scenario.title;
};

const getImpactDescription = (scenario, choice) => {
  if (scenario.correctFlag === FLAG_TYPES.RED) {
    if (choice === FLAG_TYPES.RED) {
      return 'Market reacts to proactive security measures';
    } else {
      return 'Market confidence shaken by security oversight';
    }
  } else if (scenario.correctFlag === FLAG_TYPES.GREEN) {
    if (choice === FLAG_TYPES.GREEN) {
      return 'Investors recognize growth potential';
    } else {
      return 'Market questions conservative approach';
    }
  }
  return 'Market impact';
};

export const generateFinalGrowthHeadline = (activeGrowthEffects) => {
  const totalGrowth = calculateRemainingGrowth(activeGrowthEffects);
  if (totalGrowth <= 0) return null;

  return {
    type: 'positive',
    headline: 'Market Recognizes Long-term Strategic Value',
    impacts: [{
      type: 'positive',
      value: totalGrowth,
      description: 'Cumulative impact of previous decisions'
    }]
  };
}; 