import React, { useState } from 'react';
import captainImg from '../assets/images/captain.webp';
import firstOfficerImg from '../assets/images/first-officer.webp';
import navigatorImg from '../assets/images/navigator.webp';
import medicImg from '../assets/images/medic.webp';
import carpenterImg from '../assets/images/carpenter.webp';
import characterJson from '../content/game-content.json';
import './CharacterSelector.css';

// Create an image mapping object
const characterImages = {
  captain: captainImg,
  firstOfficer: firstOfficerImg,
  navigator: navigatorImg,
  medic: medicImg,
  carpenter: carpenterImg
};

// Get characters from JSON
const characters = characterJson.charactersArray;

function CharacterSelector({ onSelectionChange }) {
  const [selectedIds, setSelectedIds] = useState(new Set());
  const [hoveredId, setHoveredId] = useState(null);

  const handleSelect = (id) => {
    const newSelection = new Set(selectedIds);
    if (newSelection.has(id)) {
      newSelection.delete(id);
    } else {
      newSelection.add(id);
    }
    setSelectedIds(newSelection);
    onSelectionChange(newSelection);
  };

  return (
    <div className="character-selector">
      <div className="character-selector__portraits">
        {characters.map((char) => (
          <div
            key={char.id}
            className={`character-portrait ${selectedIds.has(char.id) ? 'selected' : ''}`}
            onMouseEnter={() => setHoveredId(char.id)}
            onMouseLeave={() => setHoveredId(null)}
            onClick={() => handleSelect(char.id)}
          >
            <div className="character-portrait__frame">
              <img src={characterImages[char.imageKey]} alt={char.name} />
              {selectedIds.has(char.id) && (
                <div className="character-portrait__selected-indicator">
                  <span>✓</span>
                </div>
              )}
            </div>
            <div className="character-portrait__name">{char.name}</div>
          </div>
        ))}
      </div>
      
      <div className="character-details">
        {hoveredId && (
          <div className="character-details__content">
            <h3>{characters.find(c => c.id === hoveredId)?.role}</h3>
            <p>{characters.find(c => c.id === hoveredId)?.description}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default CharacterSelector; 