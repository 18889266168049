import React, { useState, useEffect } from 'react';
import { Plus, Trash2, Info, Edit2, Users, Clock } from 'lucide-react';
import './EditorSettings.css';
import CreatableSelect from 'react-select/creatable';
import { initialKPIValues } from '../../../utils/KPICalculator';

const categories = [
  'Leadership Development',
  'Risk Management',
  'Compliance Training',
  'Sales & Customer Service',
  'Project Management',
  'Change Management',
  'Communication Skills',
  'Decision Making',
  'Ethics & Integrity',
  'Innovation & Creativity'
];

const defaultKpis = Object.entries(initialKPIValues).map(([name, value]) => ({
  id: name.toLowerCase().replace(/ & /g, '-').replace(/ /g, '-'),
  name: name,
  description: `Impact on ${name.toLowerCase()}`,
  startingValue: value
}));

const EditorSettings = ({ onKpisChange, onSimulationDataChange, simulationData }) => {
  const [kpis, setKpis] = useState(() => {
    return (simulationData?.kpis && simulationData.kpis.length > 0) 
      ? simulationData.kpis 
      : defaultKpis;
  });

  useEffect(() => {
    if (simulationData) {
      setSimulationInfo(prevInfo => {
        const newInfo = {
          title: simulationData.title || prevInfo.title,
          description: simulationData.description || prevInfo.description,
          participants: simulationData.participants || prevInfo.participants,
          duration: simulationData.duration || prevInfo.duration,
          targetAudience: simulationData.targetAudience || prevInfo.targetAudience,
          category: simulationData.category || prevInfo.category,
          topic: simulationData.topic || prevInfo.topic,
          competencies: simulationData.competencies || prevInfo.competencies
        };
        return JSON.stringify(prevInfo) !== JSON.stringify(newInfo) ? newInfo : prevInfo;
      });

      if (simulationData.kpis?.length > 0) {
        const newKpis = simulationData.kpis;
        setKpis(prevKpis => 
          JSON.stringify(prevKpis) !== JSON.stringify(newKpis) ? newKpis : prevKpis
        );
      }
    }
  }, [simulationData]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (kpis && kpis.length > 0) {
        console.log('Updating KPIs:', kpis);
        onKpisChange(kpis);
      }
    }, 300);

    return () => clearTimeout(debounceTimeout);
  }, [kpis, onKpisChange]);

  const [simulationInfo, setSimulationInfo] = useState({
    title: simulationData?.title || 'Untitled Simulation',
    description: simulationData?.description || 'Click to add a description',
    participants: simulationData?.participants || '1-10',
    duration: simulationData?.duration || '30',
    targetAudience: simulationData?.targetAudience || [],
    category: simulationData?.category || '',
    topic: simulationData?.topic || '',
    competencies: simulationData?.competencies || []
  });
  const [isEditing, setIsEditing] = useState({
    title: false,
    description: false
  });

  const levels = [
    { id: 'new-joiners', label: 'New joiners', color: '#0891b2' },
    { id: 'individual-contributors', label: 'Individual contributors', color: '#059669' },
    { id: 'managers', label: 'Managers', color: '#7c3aed' },
    { id: 'executives', label: 'Executives', color: '#c026d3' }
  ];

  const handleLevelChange = (levelId) => {
    setSimulationInfo(prev => ({
      ...prev,
      targetAudience: prev.targetAudience.includes(levelId)
        ? prev.targetAudience.filter(id => id !== levelId)
        : [...prev.targetAudience, levelId]
    }));
  };

  const addKpi = () => {
    setKpis(prevKpis => {
      const newKpi = { 
        id: `kpi-${prevKpis.length + 1}`,
        name: '', 
        description: '',
        startingValue: 0
      };
      return [...prevKpis, newKpi];
    });
  };

  const removeKpi = (index) => {
    setKpis(prevKpis => {
      const newKpis = prevKpis.filter((_, i) => i !== index);
      onKpisChange(newKpis);
      return newKpis;
    });
  };

  const updateKpi = (index, field, value) => {
    setKpis(prevKpis => {
      const newKpis = prevKpis.map((kpi, i) => 
        i === index ? { ...kpi, [field]: value } : kpi
      );
      return newKpis;
    });
  };

  const handleEdit = (field, value) => {
    setSimulationInfo(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleCompetencyChange = (newValue, actionMeta) => {
    if (actionMeta.action === 'create-option') {
      const formattedValue = {
        value: actionMeta.option.value.toLowerCase(),
        label: actionMeta.option.value
      };
      setSimulationInfo(prev => ({
        ...prev,
        competencies: [...(prev.competencies || []), formattedValue]
      }));
    } else {
      setSimulationInfo(prev => ({
        ...prev,
        competencies: newValue || []
      }));
    }
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      onSimulationDataChange(simulationInfo);
    }, 300);

    return () => clearTimeout(debounceTimeout);
  }, [simulationInfo, onSimulationDataChange]);

  return (
    <div className="editor-content">
      {/* Left Column */}
      <div className="left-column">
        <div className="simulation-header">
          <div className="editable-content">
            {isEditing.title ? (
              <input
                type="text"
                value={simulationInfo.title}
                onChange={(e) => handleEdit('title', e.target.value)}
                onBlur={() => setIsEditing(prev => ({ ...prev, title: false }))}
                onKeyDown={(e) => e.key === 'Enter' && setIsEditing(prev => ({ ...prev, title: false }))}
                autoFocus
                className="title-edit"
              />
            ) : (
              <div 
                className="title-display"
                onClick={() => setIsEditing(prev => ({ ...prev, title: true }))}
              >
                <h1>{simulationInfo.title}</h1>
                <Edit2 size={16} className="edit-icon" />
              </div>
            )}

            {isEditing.description ? (
              <textarea
                value={simulationInfo.description}
                onChange={(e) => handleEdit('description', e.target.value)}
                onBlur={() => setIsEditing(prev => ({ ...prev, description: false }))}
                autoFocus
                className="description-edit"
              />
            ) : (
              <div 
                className="description-display"
                onClick={() => setIsEditing(prev => ({ ...prev, description: true }))}
              >
                <p>{simulationInfo.description}</p>
                <Edit2 size={16} className="edit-icon" />
              </div>
            )}
          </div>
        </div>

        <div className="settings-intro">
          <Info size={24} className="intro-icon" />
          <div className="intro-content">
            <h2>Settings Configuration</h2>
            <p>Start by configuring your simulation's basic information and KPIs. These settings will define how your simulation behaves and what metrics are tracked throughout the experience.</p>
            <ul>
              <li>Fill in the basic information about your simulation</li>
              <li>Define the Key Performance Indicators (KPIs) that will be tracked</li>
              <li>Each KPI should have a clear name, description, and starting value</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Right Column */}
      <div className="right-column">
        <div className="content-card">
          <div className="content-grid">
            <div className="content-left">
              <div className="section-title">Logistics</div>
              <div className="section-description">Configure the fundamental parameters of your simulation</div>
              
              <div className="content-meta">
                <div className="meta-item">
                  <Users size={18} className="meta-icon" />
                  <input
                    type="text"
                    value={simulationInfo.participants}
                    onChange={(e) => handleEdit('participants', e.target.value)}
                    placeholder="1-10"
                    className="meta-input"
                  />
                </div>

                <div className="meta-item">
                  <Clock size={18} className="meta-icon" />
                  <input
                    type="number"
                    value={simulationInfo.duration}
                    onChange={(e) => handleEdit('duration', e.target.value)}
                    placeholder="30"
                    className="meta-input"
                  />
                </div>
              </div>

              <div className="section-title">Target Audience</div>
              <div className="section-description">Select one or more target groups for this simulation</div>

              <div className="level-grid">
                {levels.map(level => (
                  <div 
                    key={level.id}
                    data-id={level.id}
                    className={`level-label ${simulationInfo.targetAudience.includes(level.id) ? 'selected' : ''}`}
                    onClick={() => handleLevelChange(level.id)}
                  >
                    {level.label}
                  </div>
                ))}
              </div>

              <div className="section-title">Additional Information</div>
              <div className="section-description">Categorize and tag your simulation for better organization</div>

              <div className="input-group">
                <label>Topic</label>
                <input
                  type="text"
                  value={simulationInfo.topic}
                  onChange={(e) => handleEdit('topic', e.target.value)}
                  placeholder="Enter topic"
                  className="detail-input"
                />
              </div>

              <div className="input-group">
                <label>Category</label>
                <select
                  value={simulationInfo.category}
                  onChange={(e) => handleEdit('category', e.target.value)}
                  className="category-input"
                >
                  <option value="">Select category</option>
                  {categories.map(category => (
                    <option key={category} value={category}>{category}</option>
                  ))}
                </select>
              </div>

              <div className="input-group competencies-group">
                <label>Competencies</label>
                <CreatableSelect
                  isMulti
                  value={simulationInfo.competencies}
                  onChange={handleCompetencyChange}
                  placeholder="Enter competencies"
                  className="competencies-select"
                  classNamePrefix="competencies"
                  components={{
                    DropdownIndicator: null,
                    IndicatorSeparator: null,
                    Menu: null
                  }}
                  noOptionsMessage={() => null}
                  menuIsOpen={false}
                  formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      const input = e.target;
                      const value = input.value.trim();
                      if (value) {
                        const newOption = {
                          value: value.toLowerCase(),
                          label: value
                        };
                        handleCompetencyChange([...simulationInfo.competencies, newOption], {
                          action: 'create-option',
                          option: newOption
                        });
                        setTimeout(() => {
                          input.value = '';
                        }, 0);
                      }
                    }
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#64748b',
                      primary25: '#f1f5f9',
                      neutral20: '#e2e8f0',
                      neutral30: '#64748b',
                      neutral50: '#94a3b8'
                    },
                  })}
                />
              </div>
            </div>
            <div className="content-divider"></div>
            <div className="content-right">
              <div className="section-title">Key Performance Indicators</div>
              <div className="section-description">Define the metrics that will be tracked throughout the simulation</div>
              
              <div className="kpis-header">
                <button className="add-kpi-button" onClick={addKpi}>
                  <Plus size={16} />
                  Add KPI
                </button>
              </div>
              
              <div className="kpis-list">
                {kpis.map((kpi, index) => (
                  <div key={index} className="kpi-item">
                    <input
                      type="text"
                      value={kpi.name}
                      onChange={(e) => updateKpi(index, 'name', e.target.value)}
                      placeholder={`KPI ${index + 1}`}
                      className="kpi-name-input"
                    />
                    <input
                      type="text"
                      value={kpi.description}
                      onChange={(e) => updateKpi(index, 'description', e.target.value)}
                      placeholder="Description"
                      className="kpi-description-input"
                    />
                    <input
                      type="number"
                      value={kpi.startingValue}
                      onChange={(e) => updateKpi(index, 'startingValue', e.target.value)}
                      placeholder="0"
                      className="kpi-value-input"
                    />
                    <button 
                      className="delete-kpi-button"
                      onClick={() => removeKpi(index)}
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditorSettings;