import React, { useState } from 'react';
import TopBar from './layout/TopBar';
import BottomBar from './layout/BottomBar';
import LeftBar from './layout/LeftBar';
import RightBar from './layout/RightBar';
import GameArea from './layout/GameArea';
import StartScreen from './components/StartScreen';

function OperationAres() {
  const [isStarted, setIsStarted] = useState(false);

  const handleStart = () => {
    setIsStarted(true);
  };

  return (
    <div className="fixed inset-0 flex flex-col bg-slate-900">
      {!isStarted ? (
        <StartScreen onStart={handleStart} />
      ) : (
        <>
          <TopBar />
          <div className="flex flex-1 overflow-hidden">
            <LeftBar />
            <GameArea />
            <RightBar />
          </div>
          <BottomBar />
        </>
      )}
    </div>
  );
}

export default OperationAres; 