import { initializeApp } from 'firebase/app';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBRAvUuDh4G0QTR1Di3GDpNP9qpivu872A",
  authDomain: "boringtraining.firebaseapp.com",
  projectId: "boringtraining",
  storageBucket: "boringtraining.firebasestorage.app",
  messagingSenderId: "386915316919",
  appId: "1:386915316919:web:07b2ff1ec1813a25f14932"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Enable offline persistence
enableIndexedDbPersistence(db)
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      console.warn('Multiple tabs open, persistence can only be enabled in one tab at a time.');
    } else if (err.code === 'unimplemented') {
      console.warn('The current browser doesn\'t support persistence.');
    }
  });

export { db };