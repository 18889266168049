import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, AlertCircle, Paperclip } from 'lucide-react';

const TutorialMessage = ({ type = 'kpi', onClose }) => {
  const messages = {
    intel: {
      title: "Important Information Available",
      text: [
        "Before making your decision:",
        "• Look for the green 'View Attachments' button at the bottom of the email",
        "• Click it to access crucial client information",
        "• Review the information carefully to make informed decisions",
        "",
        "Remember:",
        "• Each scenario has unique client information",
        "• This information will help identify risks and opportunities"
      ]
    },
    kpi: {
      title: "Performance Metrics",
      text: [
        "Your decisions impact three key areas:",
        "• Financial Metrics: Profitability, Revenue, Market Share, Legal Costs",
        "• Customer Metrics: Loyalty, Satisfaction, Reputation",
        "• Internal Metrics: Shareholder Value, Employee Morale",
        "",
        "Important:",
        "�� Each decision has both short and long-term effects",
        "• Aim for a score of 80 or higher",
        "• Monitor all metrics carefully to succeed"
      ]
    },
    navigation: {
      title: "Transaction Progress",
      text: [
        "How transactions work:",
        "• Each transaction represents a new compliance scenario",
        "• Review all information before making decisions",
        "• Complete current transaction to unlock the next",
        "",
        "Track your progress:",
        "• Green: Current transaction",
        "• Blue: Completed transactions",
        "• Gray: Locked transactions"
      ]
    }
  };

  return (
    <AnimatePresence>
      <div className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center">
        <motion.div 
          className="relative w-[90vw] max-w-[400px] bg-gray-900 rounded-xl shadow-2xl border border-gray-800 p-3"
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          transition={{ duration: 0.2 }}
        >
          {/* Header */}
          <div className="flex items-center gap-2 mb-3">
            <div className="bg-emerald-600/20 p-1 rounded-lg">
              <AlertCircle className="w-3 h-3 text-emerald-400" />
            </div>
            <h2 className="text-sm font-bold text-gray-200">
              {messages[type].title}
            </h2>
          </div>

          {/* Content */}
          <div className="space-y-2">
            {messages[type].text.map((line, index) => (
              typeof line === 'string' ? (
                line === "" ? (
                  <div key={index} className="h-1" />
                ) : (
                  <p key={index} className="text-[10px] text-gray-300 leading-relaxed">
                    {line}
                  </p>
                )
              ) : (
                line
              )
            ))}
          </div>

          {/* Continue Button */}
          <motion.button
            onClick={onClose}
            className="w-full mt-3 bg-emerald-600 hover:bg-emerald-500 text-white rounded-lg py-1.5 text-xs font-semibold transition-colors duration-200"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            Continue
          </motion.button>

          {/* Close Button */}
          <button 
            className="absolute top-2 right-2 w-6 h-6 rounded-lg flex items-center justify-center text-gray-400 hover:text-gray-200 hover:bg-gray-800 transition-all duration-200"
            onClick={onClose}
          >
            <X size={14} />
          </button>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default TutorialMessage;