import React, { useState, useEffect } from 'react';
import './CrewMessages.css';

const messages = [
  "Bloody hell, this cold is biting through my bones!",
  "Keep moving lads, we've got work to do!",
  "The shelter's holding, thank the heavens...",
  "My fingers are numb but we must carry on.",
  "Never thought I'd miss the ship's biscuits this much.",
  "We'll make it through this, mark my words.",
  "Watch your step, the ice is treacherous today.",
  "Together we stand, divided we freeze!"
];

function CrewMessages({ isActive }) {
  const [activeMessages, setActiveMessages] = useState([]);

  useEffect(() => {
    if (isActive) {
      let currentIndex = 0;
      const interval = setInterval(() => {
        if (currentIndex < messages.length) {
          setActiveMessages(prev => [...prev, {
            id: Date.now(),
            text: messages[currentIndex]
          }]);
          currentIndex++;
        } else {
          clearInterval(interval);
        }
      }, 800);

      return () => {
        clearInterval(interval);
        setActiveMessages([]);
      };
    }
  }, [isActive]);

  return (
    <div className="crew-messages">
      <div className="crew-messages__container">
        {activeMessages.map((message) => (
          <div 
            key={message.id}
            className="crew-message"
          >
            <span className="crew-message__content">
              {message.text}
            </span>
            <div className="crew-message__frost" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default CrewMessages; 