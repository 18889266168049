import React, { useState, useEffect, useMemo } from 'react';
import { Plus, Trash2, Wand2, Zap, Sprout } from 'lucide-react';
import toast from 'react-hot-toast';
import './ScenariosView.css';
import ScenarioConnectors from './ScenarioConnectors';
import scenarios from '../../content/ShadowMoney';

// Remove the initial kpiMapping since we'll generate it dynamically
const ScenariosView = ({ kpis, selectedScenario, onScenarioSelect, simulationData }) => {
  // Generate dynamic KPI mapping from simulationData
  const dynamicKpiMapping = useMemo(() => {
    const mapping = {};
    if (simulationData?.kpis) {
      simulationData.kpis.forEach(kpi => {
        mapping[kpi.name] = kpi.id;
      });
    }
    return mapping;
  }, [simulationData?.kpis]);

  // Initialize template scenario with dynamic KPIs
  const templateScenario = useMemo(() => {
    const template = {
      id: 1,
      title: "New Scenario",
      category: "Default Category",
      description: "Click to edit this scenario's description",
      date: "Monday, Jan 1",
      time: "9:00 AM",
      sender: "John Smith",
      message: {
        content: "Enter your scenario message here",
        avatar: null
      },
      options: [
        {
          text: "Option 1",
          reply: "Reply for option 1",
          shortTermImpact: "Short term impact description",
          longTermImpact: "Long term impact description",
          kpiImpacts: {
            shortTerm: {},
            longTerm: {}
          }
        },
        {
          text: "Option 2",
          reply: "Reply for option 2",
          shortTermImpact: "Short term impact description",
          longTermImpact: "Long term impact description",
          kpiImpacts: {
            shortTerm: {},
            longTerm: {}
          }
        }
      ]
    };

    // Initialize KPI impacts with default values
    if (simulationData?.kpis) {
      template.options.forEach(option => {
        option.kpiImpacts = {
          shortTerm: {},
          longTerm: {}
        };
        simulationData.kpis.forEach(kpi => {
          option.kpiImpacts.shortTerm[kpi.id] = kpi.startingValue || 0;
          option.kpiImpacts.longTerm[kpi.id] = kpi.startingValue || 0;
        });
      });
    }

    return template;
  }, [simulationData?.kpis]);

  // Initialize scenariosList with proper KPI values
  const [scenariosList, setScenarioslist] = useState(() => {
    // If we have existing scenarios from ShadowMoney.js, use those
    if (scenarios.length > 0) {
      return scenarios.map(scenario => {
        const updatedScenario = { ...scenario };
        if (simulationData?.kpis) {
          updatedScenario.options = scenario.options.map(option => {
            const updatedOption = { ...option };
            updatedOption.kpiImpacts = {
              shortTerm: {},
              longTerm: {}
            };
            simulationData.kpis.forEach(kpi => {
              // Map existing KPI impacts to the new KPI structure
              updatedOption.kpiImpacts.shortTerm[kpi.id] = 
                option.kpiImpacts.shortTerm[kpi.name] || 0;
              updatedOption.kpiImpacts.longTerm[kpi.id] = 
                option.kpiImpacts.longTerm[kpi.name] || 0;
            });
            return updatedOption;
          });
        }
        return updatedScenario;
      });
    }
    return [templateScenario];
  });

  // Update scenarios when KPIs change
  useEffect(() => {
    if (selectedScenario && simulationData?.kpis) {
      const updatedScenario = {
        ...selectedScenario,
        options: selectedScenario.options.map(option => {
          const updatedKpiImpacts = {
            shortTerm: {},
            longTerm: {}
          };

          // Keep existing values by KPI ID
          simulationData.kpis.forEach(kpi => {
            updatedKpiImpacts.shortTerm[kpi.id] = 
              option.kpiImpacts?.shortTerm?.[kpi.id] ?? 0;
            updatedKpiImpacts.longTerm[kpi.id] = 
              option.kpiImpacts?.longTerm?.[kpi.id] ?? 0;
          });

          return {
            ...option,
            kpiImpacts: updatedKpiImpacts
          };
        })
      };

      // Only update if there are actual changes
      if (JSON.stringify(selectedScenario) !== JSON.stringify(updatedScenario)) {
        onScenarioSelect(updatedScenario);
      }
    }
  }, [simulationData?.kpis, selectedScenario, onScenarioSelect]);

  const addScenario = () => {
    const newScenario = {
      id: scenariosList.length + 1,
      title: "New Scenario",
      category: "Default Category",
      description: "Click to edit this scenario's description",
      date: "Monday, Jan 1",
      time: "9:00 AM",
      sender: "John Smith",
      message: {
        content: "Enter your scenario message here",
        avatar: null
      },
      options: [
        {
          text: "Option 1",
          reply: "Reply for option 1",
          shortTermImpact: "Short term impact description",
          longTermImpact: "Long term impact description",
          kpiImpacts: {
            shortTerm: {},
            longTerm: {}
          }
        },
        {
          text: "Option 2",
          reply: "Reply for option 2",
          shortTermImpact: "Short term impact description",
          longTermImpact: "Long term impact description",
          kpiImpacts: {
            shortTerm: {},
            longTerm: {}
          }
        }
      ]
    };

    // Initialize KPI impacts with zero values
    Object.keys(dynamicKpiMapping).forEach(kpi => {
      newScenario.options.forEach(option => {
        option.kpiImpacts.shortTerm[kpi] = 0;
        option.kpiImpacts.longTerm[kpi] = 0;
      });
    });

    setScenarioslist(prev => [...prev, newScenario]);
    // Automatically select the new scenario
    onScenarioSelect(newScenario);
  };

  const deleteScenario = (e, scenarioId) => {
    e.stopPropagation();
    
    if (scenariosList.length <= 1) {
      toast.error("A simulation must have at least one scenario", {
        duration: 2000,
        position: 'top-left',
        style: {
          background: '#fee2e2',
          color: '#991b1b',
          border: '1px solid #fecaca',
          borderRadius: '6px',
          padding: '12px 16px',
          fontSize: '0.9rem',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        },
      });
      return;
    }
    
    setScenarioslist(scenariosList.filter(s => s.id !== scenarioId));
    
    if (selectedScenario?.id === scenarioId) {
      const remainingScenarios = scenariosList.filter(s => s.id !== scenarioId);
      onScenarioSelect(remainingScenarios[0]);
    }
  };

  const updateScenario = (field, value) => {
    if (!selectedScenario) return;
    
    setScenarioslist(prevScenarios => 
      prevScenarios.map(scenario => 
        scenario.id === selectedScenario.id 
          ? { ...scenario, [field]: value }
          : scenario
      )
    );
    
    onScenarioSelect(prev => ({ ...prev, [field]: value }));
  };

  const handleScenarioSelect = (scenario) => {
    onScenarioSelect(scenario);
  };

  // Add state for avatar preview
  const [avatarPreview, setAvatarPreview] = useState(null);

  // Add this function to handle avatar file selection
  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Create a preview URL for immediate display
      const previewUrl = URL.createObjectURL(file);
      setAvatarPreview(previewUrl);

      // Update the scenario with the file name
      updateScenario('sender', {
        ...selectedScenario?.sender,
        avatar: file.name
      });
    }
  };

  // Clean up preview URL when component unmounts or scenario changes
  useEffect(() => {
    return () => {
      if (avatarPreview) {
        URL.revokeObjectURL(avatarPreview);
      }
    };
  }, [avatarPreview]);

  return (
    <div className="scenarios-view">
      <ScenarioConnectors selectedScenario={selectedScenario} />
      {/* Column 1: Scenario List */}
      <div className="scenario-list-column">
        <div className="scenario-list-items">
          {scenariosList.map((scenario, index) => (
            <div 
              key={scenario.id}
              className={`scenario-list-item ${selectedScenario?.id === scenario.id ? 'selected' : ''}`}
              onClick={() => handleScenarioSelect(scenario)}
            >
              <button 
                className="scenario-delete-button"
                onClick={(e) => deleteScenario(e, scenario.id)}
                title="Delete scenario"
              >
                <Trash2 size={14} />
              </button>
              <button 
                className="scenario-list-ai-button"
                onClick={(e) => {
                  e.stopPropagation();
                  console.log('AI Generate for scenario', scenario.id);
                }}
                title="Generate with AI"
              >
                <Wand2 size={14} />
              </button>
              <div className="scenario-list-number">Scenario {index + 1}</div>
              <h3 className="scenario-list-title">{scenario.title}</h3>
              <p className="scenario-list-preview">{scenario.description}</p>
            </div>
          ))}
        </div>
        
        <div className="scenario-buttons">
          <button className="scenario-add-button" onClick={addScenario}>
            <Plus size={16} />
            Add Scenario
          </button>
          <button className="scenario-ai-button" onClick={() => console.log('AI Generate')}>
            <Wand2 size={16} />
            Add with AI
          </button>
        </div>
      </div>

      {/* Only render the rest if there's a selected scenario and it has options */}
      {selectedScenario && selectedScenario.options && selectedScenario.options.length >= 2 ? (
        <>
          {/* Column 2: Scenario Information */}
          <div className="scenario-info-card">
            <div className="scenario-info-content">
              <button className="scenario-card-ai-button">
                <Wand2 size={16} />
              </button>
              
              <div className="scenario-input-group">
                <div className="scenario-section-title">Title</div>
                <textarea
                  value={selectedScenario?.title || ''}
                  onChange={(e) => updateScenario('title', e.target.value)}
                  placeholder="Enter scenario title"
                  className="scenario-option-scrollable-input option"
                />
              </div>

              <div className="scenario-input-group">
                <div className="scenario-section-title">Category</div>
                <textarea
                  value={selectedScenario?.category || ''}
                  onChange={(e) => updateScenario('category', e.target.value)}
                  placeholder="Enter category"
                  className="scenario-option-scrollable-input option"
                />
              </div>

              <div className="scenario-input-group sender-avatar-group">
                <div className="sender-section">
                  <div className="scenario-section-title">Sender</div>
                  <textarea
                    value={selectedScenario?.sender?.name || ''}
                    onChange={(e) => updateScenario('sender', {
                      ...selectedScenario?.sender,
                      name: e.target.value
                    })}
                    placeholder="e.g., John Smith"
                    className="scenario-option-scrollable-input option"
                  />
                </div>
                
                <div className="avatar-section">
                  <div className="scenario-section-title">Photo</div>
                  <div className="avatar-upload">
                    <input
                      type="file"
                      id="avatar-upload"
                      accept="image/*"
                      onChange={handleAvatarChange}
                      className="avatar-input"
                    />
                    <label htmlFor="avatar-upload" className="avatar-label">
                      {avatarPreview ? (
                        <img 
                          src={avatarPreview} 
                          alt="Avatar Preview" 
                          className="avatar-preview"
                        />
                      ) : selectedScenario?.sender?.avatar ? (
                        <img 
                          src={require(`../../scenarios/media/avatars/${selectedScenario.sender.avatar}`)} 
                          alt="Avatar" 
                          className="avatar-preview"
                        />
                      ) : (
                        <div className="avatar-placeholder">
                          <Plus size={20} />
                        </div>
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div className="scenario-datetime-row">
                <div className="scenario-input-group">
                  <div className="scenario-section-title">Date</div>
                  <textarea
                    value={selectedScenario?.date || ''}
                    onChange={(e) => updateScenario('date', e.target.value)}
                    placeholder="Wednesday, Dec. 5"
                    className="scenario-option-scrollable-input option"
                  />
                </div>

                <div className="scenario-input-group">
                  <div className="scenario-section-title">Time</div>
                  <textarea
                    value={selectedScenario?.time || ''}
                    onChange={(e) => updateScenario('time', e.target.value)}
                    placeholder="9:12 AM"
                    className="scenario-option-scrollable-input option"
                  />
                </div>
              </div>

              <div className="scenario-input-group flex-grow">
                <div className="scenario-section-title">Message</div>
                <textarea
                  value={selectedScenario?.message?.content || ''}
                  onChange={(e) => updateScenario('message', { 
                    ...selectedScenario?.message,
                    content: e.target.value 
                  })}
                  placeholder="Enter the scenario message..."
                  className="scenario-option-scrollable-input"
                />
              </div>

              <div className="scenario-input-group flex-grow">
                <div className="scenario-section-title">Clues</div>
                <textarea
                  value={selectedScenario?.description || ''}
                  onChange={(e) => updateScenario('description', e.target.value)}
                  placeholder="Enter scenario clues"
                  className="scenario-option-scrollable-input"
                />
              </div>
            </div>
          </div>

          {/* Column 3: Option Cards */}
          <div className="scenario-options-column">
            {/* Update Option 1 Card */}
            <div className="scenario-option-card option-1">
              <div className="scenario-option-content">
                <button className="scenario-card-ai-button">
                  <Wand2 size={16} />
                </button>
                
                <div className="scenario-input-group">
                  <div className="scenario-section-title">Option 1</div>
                  <textarea
                    value={selectedScenario?.options[0].text || ''}
                    onChange={(e) => updateScenario('options', [
                      {
                        ...selectedScenario?.options[0],
                        text: e.target.value
                      },
                      selectedScenario?.options[1]
                    ])}
                    placeholder="Enter first option..."
                    className="scenario-option-scrollable-input option"
                  />
                </div>

                <div className="scenario-input-group">
                  <div className="scenario-section-title">Reply</div>
                  <textarea
                    value={selectedScenario?.options[0].reply || ''}
                    onChange={(e) => updateScenario('options', [
                      {
                        ...selectedScenario?.options[0],
                        reply: e.target.value
                      },
                      selectedScenario?.options[1]
                    ])}
                    placeholder="Enter reply message..."
                    className="scenario-option-scrollable-input reply"
                  />
                </div>

                <div className="scenario-impacts-row">
                  <div className="scenario-impact-column">
                    <div className="scenario-input-group">
                      <div className="scenario-section-title">Short Term Impact</div>
                      <textarea
                        value={selectedScenario?.options[0].shortTermImpact || ''}
                        onChange={(e) => updateScenario('options', [
                          {
                            ...selectedScenario?.options[0],
                            shortTermImpact: e.target.value
                          },
                          selectedScenario?.options[1]
                        ])}
                        placeholder="Describe immediate impact..."
                        className="scenario-option-scrollable-input"
                      />
                    </div>
                  </div>

                  <div className="scenario-impact-column">
                    <div className="scenario-input-group">
                      <div className="scenario-section-title">Long Term Impact</div>
                      <textarea
                        value={selectedScenario?.options[0].longTermImpact || ''}
                        onChange={(e) => updateScenario('options', [
                          {
                            ...selectedScenario?.options[0],
                            longTermImpact: e.target.value
                          },
                          selectedScenario?.options[1]
                        ])}
                        placeholder="Describe long-term impact..."
                        className="scenario-option-scrollable-input"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Update Option 2 Card */}
            <div className="scenario-option-card option-2">
              <div className="scenario-option-content">
                <button className="scenario-card-ai-button">
                  <Wand2 size={16} />
                </button>
                
                <div className="scenario-input-group">
                  <div className="scenario-section-title">Option 2</div>
                  <textarea
                    value={selectedScenario?.options[1].text || ''}
                    onChange={(e) => updateScenario('options', [
                      selectedScenario?.options[0],
                      {
                        ...selectedScenario?.options[1],
                        text: e.target.value
                      }
                    ])}
                    placeholder="Enter second option..."
                    className="scenario-option-scrollable-input option"
                  />
                </div>

                <div className="scenario-input-group">
                  <div className="scenario-section-title">Reply</div>
                  <textarea
                    value={selectedScenario?.options[1].reply || ''}
                    onChange={(e) => updateScenario('options', [
                      selectedScenario?.options[0],
                      {
                        ...selectedScenario?.options[1],
                        reply: e.target.value
                      }
                    ])}
                    placeholder="Enter reply message..."
                    className="scenario-option-scrollable-input reply"
                  />
                </div>

                <div className="scenario-impacts-row">
                  <div className="scenario-impact-column">
                    <div className="scenario-input-group">
                      <div className="scenario-section-title">Short Term Impact</div>
                      <textarea
                        value={selectedScenario?.options[1].shortTermImpact || ''}
                        onChange={(e) => updateScenario('options', [
                          selectedScenario?.options[0],
                          {
                            ...selectedScenario?.options[1],
                            shortTermImpact: e.target.value
                          }
                        ])}
                        placeholder="Describe immediate impact..."
                        className="scenario-option-scrollable-input"
                      />
                    </div>
                  </div>

                  <div className="scenario-impact-column">
                    <div className="scenario-input-group">
                      <div className="scenario-section-title">Long Term Impact</div>
                      <textarea
                        value={selectedScenario?.options[1].longTermImpact || ''}
                        onChange={(e) => updateScenario('options', [
                          selectedScenario?.options[0],
                          {
                            ...selectedScenario?.options[1],
                            longTermImpact: e.target.value
                          }
                        ])}
                        placeholder="Describe long-term impact..."
                        className="scenario-option-scrollable-input"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Column 4: KPI Cards */}
          <div className="scenario-kpi-column">
            {/* KPIs for Option 1 */}
            <div className="scenario-kpi-card">
              <div className="scenario-kpi-content">
                <button className="scenario-card-ai-button">
                  <Wand2 size={16} />
                </button>
                
                <div className="scenario-kpi-list">
                  {simulationData?.kpis?.map((kpi) => (
                    <div key={kpi.id} className="scenario-kpi-row">
                      <span className="scenario-kpi-name">{kpi.name}</span>
                      <div className="scenario-kpi-inputs">
                        {/* Short term impact */}
                        <div className="scenario-kpi-input-group">
                          <span className="scenario-kpi-label" title="Short Term">
                            <Zap size={14} />
                          </span>
                          <input
                            type="number"
                            value={selectedScenario?.options[0].kpiImpacts?.shortTerm?.[kpi.id] ?? 0}
                            onChange={(e) => {
                              const value = parseInt(e.target.value, 10);
                              const updatedOptions = [...selectedScenario.options];
                              updatedOptions[0] = {
                                ...updatedOptions[0],
                                kpiImpacts: {
                                  ...updatedOptions[0].kpiImpacts,
                                  shortTerm: {
                                    ...updatedOptions[0].kpiImpacts?.shortTerm,
                                    [kpi.id]: value
                                  }
                                }
                              };
                              updateScenario('options', updatedOptions);
                            }}
                            className="scenario-kpi-input"
                            min="-20"
                            max="20"
                          />
                        </div>
                        {/* Long term impact */}
                        <div className="scenario-kpi-input-group">
                          <span className="scenario-kpi-label" title="Long Term">
                            <Sprout size={14} />
                          </span>
                          <input
                            type="number"
                            value={selectedScenario?.options[0].kpiImpacts?.longTerm?.[kpi.id] ?? 0}
                            onChange={(e) => {
                              const value = parseInt(e.target.value, 10);
                              const updatedOptions = [...selectedScenario.options];
                              updatedOptions[0] = {
                                ...updatedOptions[0],
                                kpiImpacts: {
                                  ...updatedOptions[0].kpiImpacts,
                                  longTerm: {
                                    ...updatedOptions[0].kpiImpacts?.longTerm,
                                    [kpi.id]: value
                                  }
                                }
                              };
                              updateScenario('options', updatedOptions);
                            }}
                            className="scenario-kpi-input"
                            min="-20"
                            max="20"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* KPIs for Option 2 - Similar structure but using options[1] */}
            <div className="scenario-kpi-card">
              <div className="scenario-kpi-content">
                <button className="scenario-card-ai-button">
                  <Wand2 size={16} />
                </button>
                
                <div className="scenario-kpi-list">
                  {simulationData?.kpis?.map((kpi) => (
                    <div key={kpi.id} className="scenario-kpi-row">
                      <span className="scenario-kpi-name">{kpi.name}</span>
                      <div className="scenario-kpi-inputs">
                        {/* Short term impact */}
                        <div className="scenario-kpi-input-group">
                          <span className="scenario-kpi-label" title="Short Term">
                            <Zap size={14} />
                          </span>
                          <input
                            type="number"
                            value={selectedScenario?.options[1].kpiImpacts?.shortTerm?.[kpi.id] || 0}
                            onChange={(e) => {
                              const value = parseInt(e.target.value, 10);
                              const updatedOptions = [...selectedScenario.options];
                              updatedOptions[1] = {
                                ...updatedOptions[1],
                                kpiImpacts: {
                                  ...updatedOptions[1].kpiImpacts,
                                  shortTerm: {
                                    ...updatedOptions[1].kpiImpacts?.shortTerm,
                                    [kpi.id]: value
                                  }
                                }
                              };
                              updateScenario('options', updatedOptions);
                            }}
                            className="scenario-kpi-input"
                            min="-20"
                            max="20"
                          />
                        </div>
                        {/* Long term impact */}
                        <div className="scenario-kpi-input-group">
                          <span className="scenario-kpi-label" title="Long Term">
                            <Sprout size={14} />
                          </span>
                          <input
                            type="number"
                            value={selectedScenario?.options[1].kpiImpacts?.longTerm?.[kpi.id] || 0}
                            onChange={(e) => {
                              const value = parseInt(e.target.value, 10);
                              const updatedOptions = [...selectedScenario.options];
                              updatedOptions[1] = {
                                ...updatedOptions[1],
                                kpiImpacts: {
                                  ...updatedOptions[1].kpiImpacts,
                                  longTerm: {
                                    ...updatedOptions[1].kpiImpacts?.longTerm,
                                    [kpi.id]: value
                                  }
                                }
                              };
                              updateScenario('options', updatedOptions);
                            }}
                            className="scenario-kpi-input"
                            min="-20"
                            max="20"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ScenariosView; 