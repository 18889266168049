import React from 'react';
import { useAntarctica } from '../context/AntarcticaContext';
import './RoundTracker.css';

function RoundTracker() {
  const { currentRound } = useAntarctica();
  const totalRounds = 20; // Could also come from context if this needs to be configurable

  return (
    <div className="round-tracker">
      <span className="round-tracker__label">Survival Day</span>
      <span className="round-tracker__count">
        <span className="round-tracker__current">{currentRound}</span>
        <span className="round-tracker__separator">/</span>
        <span className="round-tracker__total">{totalRounds}</span>
      </span>
    </div>
  );
}

export default RoundTracker; 