import React, { useState } from 'react';
import exploreIcon from '../assets/images/explore-label.webp';
import exploitIcon from '../assets/images/exploit-label.webp';
import { playChooseStrategySound } from '../utils/SoundEffects';
import './StrategyChoice.css';

function StrategyChoice({ onSelect }) {
  const [selectedStrategy, setSelectedStrategy] = useState(null);

  const handleSelect = (strategy) => {
    setSelectedStrategy(strategy);
    onSelect(strategy);
    playChooseStrategySound();
    window.dispatchEvent(new CustomEvent('strategySelected', { 
      detail: { strategy } 
    }));
  };

  const getCardClass = (strategy) => {
    if (!selectedStrategy) return 'strategy-card';
    return `strategy-card ${
      selectedStrategy === strategy 
        ? 'strategy-card--selected' 
        : 'strategy-card--unselected'
    }`;
  };

  return (
    <div className="strategy-choice">
      <div className="strategy-choice__cards">
        <div 
          className={getCardClass('survive')}
          onClick={() => handleSelect('survive')}
        >
          <div className="strategy-card__content">
            <div className="strategy-card__header">
              <img 
                src={exploitIcon} 
                alt="Survive Icon" 
                className="strategy-card__icon"
              />
              <h3 className="strategy-card__title">Survive</h3>
            </div>
          </div>
          <div className="strategy-card__frost" />
        </div>

        <div 
          className={getCardClass('escape')}
          onClick={() => handleSelect('escape')}
        >
          <div className="strategy-card__content">
            <div className="strategy-card__header">
              <img 
                src={exploreIcon} 
                alt="Escape Icon" 
                className="strategy-card__icon"
              />
              <h3 className="strategy-card__title">Escape</h3>
            </div>
          </div>
          <div className="strategy-card__frost" />
        </div>
      </div>
    </div>
  );
}

export default StrategyChoice; 