import React, { useState } from 'react';
import { Store } from 'lucide-react';
import { useSimulation } from '../../ai-gone-rogue/SimulationContext';
import ShopModal from './ShopModal';
import './Shop.css';

function Shop() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { shopItems } = useSimulation();
  
  // Calculate available (unpurchased) items
  const availableItems = shopItems.filter(item => !item.purchased).length;

  return (
    <div id="shop-button" className="shop-container" onClick={() => setIsModalOpen(true)}>
      <div className="shop-icon">
        <Store size={24} />
        {availableItems > 0 && (
          <div className="shop-badge">
            {availableItems}
          </div>
        )}
      </div>
      <span className="shop-label">Shop</span>

      <ShopModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}

export default Shop;