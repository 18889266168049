import React, { useState } from 'react';
import ScenarioCard from '../game-elements/ScenarioCard';
import RogueAILock from '../game-elements/RogueAILock';
import { useSimulation } from '../../ai-gone-rogue/SimulationContext';
import { getRequiredChoices, isRoundLocked, getRoundMessage, validatePasscode } from '../../ai-gone-rogue/ScenarioDatabase';
import './PlayArea.css';

function PlayArea() {
  const { 
    scenarios, 
    choices, 
    setChoice, 
    currentRound, 
    totalRounds,
    unlockedRounds,
    unlockRound 
  } = useSimulation();
  
  const requiredChoices = getRequiredChoices(currentRound);
  const currentChoices = Object.values(choices).filter(choice => choice).length;
  const [showError, setShowError] = useState(false);

  const handlePasscodeSubmit = (passcode) => {
    const isValid = validatePasscode(currentRound, passcode);
    if (isValid) {
      unlockRound(currentRound);
      setShowError(false);
    } else {
      setShowError(true);
    }
    return isValid;
  };

  if (isRoundLocked(currentRound, unlockedRounds)) {
    return (
      <RogueAILock 
        message={getRoundMessage(currentRound)}
        onPasscodeSubmit={handlePasscodeSubmit}
        error={showError ? "Invalid passcode. Try again, human." : null}
      />
    );
  }

  return (
    <div id="play-area" className="play-area">
      <div className="round-info">
        <div className="round-indicator">
          Round {currentRound} of {totalRounds}
        </div>
        <div className="choices-indicator">
          Select {requiredChoices} card{requiredChoices > 1 ? 's' : ''} to flag
          <span className="choices-counter">
            ({currentChoices}/{requiredChoices})
          </span>
        </div>
      </div>
      <div className="cards-container">
        {scenarios.map((scenario, index) => (
          <div 
            key={scenario.id} 
            className="card-wrapper"
            id={index === 0 ? 'scenario-card-0' : undefined}
          >
            <ScenarioCard 
              cardNumber={scenario.cardNumber}
              title={scenario.title}
              description={scenario.description}
              correctFlag={scenario.correctFlag}
              importance={scenario.importance}
              onChoice={(choice) => {
                if (currentChoices < requiredChoices || choices[scenario.id]) {
                  setChoice(scenario.id, choice);
                }
              }}
              currentChoice={choices[scenario.id]}
              disabled={currentChoices > requiredChoices - 1 && !choices[scenario.id]}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PlayArea; 