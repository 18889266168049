import React, { useState } from 'react';
import { ArrowLeft, Search, Wand2, Play, Save } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import './EditorTop.css';
import SaveModal from './SaveModal';

const EditorTop = ({ onSave }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const [showSaveModal, setShowSaveModal] = useState(false);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleBack = () => {
    navigate('/');
  };

  const handleSave = async (type) => {
    try {
      await onSave(type);
      setShowSaveModal(false);
    } catch (error) {
      console.error('Save failed:', error);
    }
  };

  return (
    <div className="editor-top">
      <div className="editor-top-left">
        <button className="icon-button" onClick={handleBack} title="Back to Library">
          <ArrowLeft size={20} />
        </button>
        <div className="search-container">
          <Search size={18} className="search-icon" />
          <input 
            type="text" 
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Search settings..."
            className="search-input"
          />
        </div>
      </div>
      <div className="editor-top-right">
        <button className="icon-button" title="AI Assistant">
          <Wand2 size={20} />
        </button>
        <button className="icon-button" title="Preview">
          <Play size={20} />
        </button>
        <button 
          className="icon-button" 
          title="Save" 
          onClick={() => setShowSaveModal(true)}
        >
          <Save size={20} />
        </button>
      </div>
      <SaveModal 
        isOpen={showSaveModal}
        onClose={() => setShowSaveModal(false)}
        onSave={handleSave}
      />
    </div>
  );
};

export default EditorTop;