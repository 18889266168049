import React, { useState } from 'react';
import { Send } from 'lucide-react';
import OmniusImage from '../../assets/images/Omnius.webp';
import { playRoundStartSound, playWrongPasscodeSound } from '../../utils/SoundEffects';
import './RogueAILock.css';

function RogueAILock({ message, onPasscodeSubmit, error }) {
  const [passcode, setPasscode] = useState('');
  const [showError, setShowError] = useState(false);
  const [lastSubmittedPasscode, setLastSubmittedPasscode] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Only proceed if this is a new passcode attempt
    if (passcode === lastSubmittedPasscode) return;
    
    setLastSubmittedPasscode(passcode);
    
    // Get validation result
    const isValid = onPasscodeSubmit(passcode);
    
    // Play appropriate sound based on validation result
    if (isValid) {
      playRoundStartSound();
    } else {
      playWrongPasscodeSound();
    }
    
    setShowError(true);
    setTimeout(() => setShowError(false), 3000);
  };

  return (
    <div className="rogue-ai-lock">
      <div className="rogue-ai-container">
        <div className="rogue-ai-icon">
          <img 
            src={OmniusImage} 
            alt="Omnius" 
            className="omnius-avatar"
          />
        </div>
        <div className="rogue-ai-message">
          <div className="message-typing-effect">{message}</div>
        </div>
        <form className="passcode-form" onSubmit={handleSubmit}>
          <input
            type="text"
            value={passcode}
            onChange={(e) => setPasscode(e.target.value)}
            placeholder="Enter passcode..."
            className="passcode-input"
          />
          <button type="submit" className="passcode-submit">
            <Send size={20} />
          </button>
        </form>
        {showError && (
          <div className="passcode-error">
            {error || "Invalid passcode. Try again, human."}
          </div>
        )}
      </div>
    </div>
  );
}

export default RogueAILock;