import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Mail, Lock, AlertCircle } from 'lucide-react';

// Move InputField outside the main component
const InputField = ({ icon: Icon, type, placeholder, value, onChange, inputRef }) => (
  <div className="relative">
    <div className="absolute left-3 top-1/2 -translate-y-1/2">
      <Icon className="w-5 h-5 text-gray-400" />
    </div>
    <input
      ref={inputRef}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="w-full pl-12 pr-4 py-3 rounded-xl
               bg-black/20 border border-game-accent/20
               text-white placeholder-gray-500
               focus:outline-none focus:border-game-accent/40
               transition-colors duration-200"
    />
  </div>
);

const LoginModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const emailRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        emailRef.current?.focus();
      }, 100);
    }
  }, [isOpen]);

  useEffect(() => {
    if (error) setError('');
  }, [email, password, error]);

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Simple validation
    if (!email || !password) {
      setError('Please fill in all fields');
      return;
    }

    // Simulate login check - you can replace this with your actual login logic
    setError('Invalid email or password');
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-black/80 backdrop-blur-md"
        onClick={onClose}
      />

      {/* Modal */}
      <div
        className="relative w-full max-w-md p-6 mx-4 rounded-2xl
                 bg-gradient-to-br from-zinc-900/95 via-game-dark/95 to-black/95
                 backdrop-blur-sm
                 shadow-2xl border border-game-accent/20"
      >
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-2 rounded-full 
                   bg-black/20 hover:bg-black/40 
                   backdrop-blur-sm
                   border border-white/10 hover:border-white/20
                   text-white/50 hover:text-white
                   transition-all duration-300"
        >
          <X className="w-5 h-5" />
        </button>

        {/* Content */}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="text-center">
            <h2 className="text-2xl font-bold font-['Space_Grotesk'] text-white">
              Welcome back
            </h2>
            <p className="text-gray-400 mt-2">
              Sign in to access your account
            </p>
          </div>

          <div className="space-y-4">
            <InputField
              icon={Mail}
              type="email"
              placeholder="Email address"
              value={email}
              onChange={setEmail}
              inputRef={emailRef}
            />

            <InputField
              icon={Lock}
              type="password"
              placeholder="Password"
              value={password}
              onChange={setPassword}
            />

            {/* Error Message */}
            <AnimatePresence mode="wait">
              {error && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="flex items-center gap-2 p-3 rounded-xl
                           bg-red-500/10 border border-red-500/20
                           text-red-500 text-sm"
                >
                  <AlertCircle className="w-4 h-4 flex-shrink-0" />
                  {error}
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <button
            type="submit"
            className="w-full py-3 rounded-xl
                     bg-game-accent hover:bg-game-accent-hover
                     text-white font-medium
                     transition-colors duration-200"
          >
            Sign in
          </button>

          <div className="text-center">
            <p className="text-gray-400 text-sm">
              Don't have an account?{" "}
              <a 
                href="mailto:stop@boringtraining.com"
                className="text-game-accent hover:text-game-accent-hover
                         transition-colors duration-200"
              >
                Contact us at stop@boringtraining.com
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginModal; 