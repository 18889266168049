import { KPI_TYPES, MARKET_CONFIDENCE } from './constants';

export const INITIAL_SCORES = {
    [KPI_TYPES.BANK_BALANCE]: {
      id: KPI_TYPES.BANK_BALANCE,
      label: 'Your Budget',
      value: 0,
      suffix: '',
      prefix: '$',
      icon: 'dollar-sign',
      description: 'Available budget for purchasing upgrades and tools',
      thresholds: {
        danger: 5000,
        warning: 15000,
        success: 30000
      }
    },
    [KPI_TYPES.MARKET_CONFIDENCE]: {
      id: KPI_TYPES.MARKET_CONFIDENCE,
      label: 'Market Confidence',
      value: MARKET_CONFIDENCE.INITIAL_VALUE,
      suffix: '%',
      icon: 'trending-up',
      description: 'Market perception and investor confidence',
      thresholds: MARKET_CONFIDENCE.THRESHOLDS,
      valueRange: {
        min: MARKET_CONFIDENCE.MIN_VALUE,
        max: MARKET_CONFIDENCE.MAX_VALUE
      }
    },
    [KPI_TYPES.RISK_LEVEL]: {
      id: KPI_TYPES.RISK_LEVEL,
      label: 'Risk Level',
      value: 85, // percentage
      suffix: '%',
      icon: 'alert-triangle',
      description: 'Overall risk exposure',
      thresholds: {
        success: 30, // For risk, lower is better
        warning: 50,
        danger: 70
      },
      isInverse: true // Indicates that lower values are better
    },
    [KPI_TYPES.COMPLIANCE_SCORE]: {
      id: KPI_TYPES.COMPLIANCE_SCORE,
      label: 'Compliance Score',
      value: 30,
      suffix: '/100',
      icon: 'shield',
      description: 'Regulatory compliance rating',
      thresholds: {
        danger: 70,
        warning: 80,
        success: 90
      }
    }
  };
  
  // Helper functions
  export const getInitialScores = () => INITIAL_SCORES;
  
  export const getKpiStatus = (kpi) => {
    const { value, thresholds, isInverse } = kpi;
    
    if (isInverse) {
      if (value <= thresholds.success) return 'success';
      if (value <= thresholds.warning) return 'warning';
      return 'danger';
    }
    
    if (value >= thresholds.success) return 'success';
    if (value >= thresholds.warning) return 'warning';
    return 'danger';
  };
  
  export const formatKpiValue = (kpi) => {
    const { value, prefix = '', suffix = '' } = kpi;
    return `${prefix}${value}${suffix}`;
  };