export const GUIDE_STEPS = {
  SHOP: {
    elementId: 'shop-button',
    position: { x: 8, y: 78 },
    size: { width: 64, height: 64 },
    message: {
      text: "Purchase solutions from the shop to help you analyze scenarios more effectively",
      position: { x: 84, y: 85 }
    }
  },
  METRICS: {
    elementId: 'metrics-container',
    position: { x: 0, y: 0 },
    size: { width: '100%', height: 40 },
    message: {
      text: `Monitor these KPIs carefully.

Your decisions will affect:

• Bank Balance: Available security budget

• Market Confidence: Customer trust level

• Risk Level: System vulnerability status

• Compliance Score: Regulatory alignment`,
      position: { x: '50%', y: 70 },
      style: {
        transform: 'translateX(-50%)',
        width: '500px',
        textAlign: 'left'
      }
    }
  },
  SCENARIO: {
    elementId: 'scenario-card-0',
    position: { x: '50%', y: '50%' },
    size: { width: 400, height: '90vh' },
    message: {
      text: `Review each scenario carefully.

• Drag LEFT for <span class="text-red-400">RED FLAG</span> (risky decision)

• Drag RIGHT for <span class="text-green-400">GREEN FLAG</span> (safe decision)

• Watch the required flags counter above`,
      position: { x: '30%', y: '50%' },
      style: {
        position: 'fixed',
        transform: 'translate(-50%, -50%)',
        width: '300px',
        textAlign: 'left',
        zIndex: 200,
        background: 'rgba(31, 31, 48, 0.95)',
        padding: '20px',
        borderRadius: '8px',
        border: '1px solid rgba(255, 51, 102, 0.3)',
        color: 'white',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
      }
    }
  },
  SATISFACTION: {
    elementId: 'customer-satisfaction',
    position: { x: 'calc(100% - 60px)', y: '5vh' },
    size: { width: 60, height: '90vh' },
    message: {
      text: `This is your Customer Satisfaction score.

It's calculated based on:

• Market Confidence

• Risk Level

• Compliance Score

Keep it high to succeed!`,
      position: { x: 'calc(100% - 380px)', y: 150 },
      style: {
        position: 'fixed',
        width: '300px',
        textAlign: 'left',
        zIndex: 200,
        background: 'rgba(31, 31, 48, 0.95)',
        padding: '20px',
        borderRadius: '8px',
        border: '1px solid rgba(255, 51, 102, 0.3)',
        color: 'white',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
      }
    }
  }
};

export const GUIDE_SEQUENCE = ['SHOP', 'METRICS', 'SCENARIO', 'SATISFACTION'];