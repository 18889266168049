import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { X, FileText, AlertCircle } from 'lucide-react';

const IntelModal = ({ isOpen, onClose, clues }) => {
  const cluesList = clues
    ?.split('.')
    .map(clue => clue.trim())
    .filter(clue => clue.length > 0);

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/80 animate-overlay-show backdrop-blur-sm" />
        <Dialog.Content className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90vw] max-w-[600px] max-h-[85vh] bg-gray-900 rounded-xl shadow-2xl border border-gray-800 p-6 animate-content-show">
          <Dialog.Title className="flex items-center gap-2 text-lg font-bold text-gray-200 mb-4 pb-3 border-b border-gray-800">
            <FileText className="w-4 h-4 text-emerald-400" />
            Client Intel
          </Dialog.Title>
          
          <div className="bg-gray-800 rounded-lg p-4 leading-relaxed text-gray-300 border border-gray-700">
            <ol className="list-none p-0 m-0 space-y-3">
              {cluesList?.map((clue, index) => (
                <li key={index} className="flex items-start gap-3">
                  <div className="flex items-center justify-center w-5 h-5 mt-0.5 rounded-full bg-emerald-600/20 border border-emerald-500/20 flex-shrink-0">
                    <AlertCircle className="w-3 h-3 text-emerald-400" />
                  </div>
                  <span className="flex-1 text-sm leading-relaxed">
                    {clue}.
                  </span>
                </li>
              ))}
            </ol>
          </div>

          <Dialog.Close asChild>
            <button 
              className="absolute top-4 right-4 w-8 h-8 rounded-lg flex items-center justify-center text-gray-400 hover:text-gray-200 hover:bg-gray-800 transition-all duration-200"
              aria-label="Close"
            >
              <X size={16} />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default IntelModal; 