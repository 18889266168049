import React from 'react';

function LeftBar() {
  return (
    <div className="w-[60px] flex-shrink-0 bg-emerald-900 border-r border-emerald-500/30">
      {/* Left bar content */}
    </div>
  );
}

export default LeftBar; 