import React from 'react';
import exploreIcon from '../assets/images/explore-label.webp';
import exploitIcon from '../assets/images/exploit-label.webp';
import './IceButton.css';

function IceButton({ onClick, selectedStrategy, text, variant }) {
  // Only show icon for strategy confirmation
  const showIcon = variant !== 'delegate' && selectedStrategy;
  const icon = selectedStrategy === 'survive' ? exploitIcon : exploreIcon;
  
  return (
    <button 
      className={`ice-button ${variant ? `ice-button--${variant}` : ''}`} 
      onClick={onClick}
    >
      <span className="ice-button__content">
        {showIcon && (
          <img 
            src={icon} 
            alt={`${selectedStrategy} Icon`} 
            className="ice-button__icon"
          />
        )}
        <span className="ice-button__text">
          {text || 'Confirm'}
        </span>
      </span>
      <div className="ice-button__frost" />
    </button>
  );
}

export default IceButton; 