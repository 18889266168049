import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ImpactExplanation from './ImpactExplanation';
import KPIChanges from './KPIChanges';
import { calculateKPIs, initialKPIValues } from '../../utils/KPICalculator';
import dropSound from '../../assets/sounds/drop-sound.mp3';
import emailSentSound from '../../assets/sounds/email-sent.mp3';
import cardFlipSound from '../../assets/sounds/card-flipped.mp3';
import KPIDashboard from './KPIDashboard';
import { Icon } from '@iconify/react';

const RightRectangle = ({ selectedOption, onOptionSelect, currentScenarioId, onKPIUpdate, currentKPIValues, onSend, onVisitedGroupsChange }) => {
  const [showImpact, setShowImpact] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [activeOptionId, setActiveOptionId] = useState(null);
  const [showGuide, setShowGuide] = useState(false);
  const dropAudio = new Audio(dropSound);
  const emailAudio = new Audio(emailSentSound);
  const cardFlipAudio = new Audio(cardFlipSound);
  emailAudio.volume = 0.3;
  cardFlipAudio.volume = 0.3;

  // Reset states when scenario changes
  useEffect(() => {
    setShowImpact(false);
    setIsDragOver(false);
    setActiveOptionId(null);
  }, [currentScenarioId]);

  // Listen for changes in showGuide from LeftRectangle
  useEffect(() => {
    const handleShowGuide = (event) => {
      if (event.detail.show && currentScenarioId === 1) {
        setShowGuide(true);
      }
    };

    window.addEventListener('showOptionsGuide', handleShowGuide);
    return () => window.removeEventListener('showOptionsGuide', handleShowGuide);
  }, [currentScenarioId]);

  const formatTimestamp = (date, time) => {
    return (
      <div className="flex items-center gap-2 mb-3">
        <div className="bg-gray-800 rounded-lg px-2 py-1 border border-gray-700">
          <p className="m-0 text-xs text-emerald-400 font-bold">{date}</p>
          <p className="m-0 text-[10px] text-gray-500">{time}</p>
        </div>
      </div>
    );
  };

  const getResponseMessage = () => {
    if (selectedOption) {
      return (
        <div className="flex flex-col items-center justify-center h-full w-full">
          {formatTimestamp(selectedOption.date, selectedOption.timestamp)}
          <motion.div 
            className="bg-gray-900 rounded-lg p-3 w-4/5 border border-gray-800"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="flex items-start gap-2 mb-2">
              <div className="bg-emerald-600 p-1.5 rounded-lg">
                <Icon icon="mdi:message-text" className="w-3 h-3 text-white" />
              </div>
              <h3 className="text-sm font-bold text-emerald-400">Your Decision</h3>
            </div>
            <div className="text-gray-300 font-medium text-xs leading-relaxed pl-2">
              {selectedOption.reply.split('\n').map((line, index) => (
                <p key={index} className="mb-2 last:mb-0">
                  {line}
                </p>
              ))}
            </div>
          </motion.div>
        </div>
      );
    } else if (showGuide) {
      return (
        <div className="flex items-center justify-center h-full w-full">
          <motion.div 
            className="flex flex-col items-center justify-center h-full w-4/5"
            animate={{ 
              scale: isDragOver ? 1.02 : 1,
            }}
            transition={{ 
              duration: 0.3,
              ease: "easeInOut"
            }}
          >
            <div className="flex flex-col items-center gap-2">
              <motion.div
                animate={{ 
                  y: [0, -5, 0],
                }}
                transition={{ 
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                {isDragOver ? (
                  <Icon icon="mdi:check-circle" className="w-6 h-6 text-emerald-400" />
                ) : (
                  <Icon icon="mdi:drag-drop" className="w-6 h-6 text-gray-500" />
                )}
              </motion.div>
              <p className={`text-sm font-semibold ${
                isDragOver ? 'text-emerald-400' : 'text-gray-500'
              }`}>
                {isDragOver ? "Release to place your decision" : "Drag and drop your chosen option here"}
              </p>
            </div>
          </motion.div>
        </div>
      );
    } else {
      return (
        <div className="flex items-center justify-center h-full w-full">
          <div className="bg-gray-900 rounded-lg p-2 border border-gray-800 text-gray-400 font-medium text-xs">
            Awaiting your decision...
          </div>
        </div>
      );
    }
  };

  const handleSendClick = () => {
    setShowImpact(true);
    onSend();
    emailAudio.play().catch(error => console.log("Audio play failed:", error));
    
    // Dispatch event to notify that an option was sent
    window.dispatchEvent(new CustomEvent('optionSent'));
    
    const { finalValues } = calculateKPIs(
      currentKPIValues, 
      selectedOption.kpiImpacts,
      currentScenarioId === 1
    );

    onKPIUpdate(finalValues, true);
    setTimeout(() => {
      onKPIUpdate(finalValues, false);
    }, 3000);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
    const optionId = e.dataTransfer.effectAllowed?.replace('option', '');
    if (optionId) {
      setActiveOptionId(optionId);
    }
  };

  const handleDragLeave = (e) => {
    setIsDragOver(false);
    setActiveOptionId(null);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    setShowGuide(false);
    const droppedOption = JSON.parse(e.dataTransfer.getData('application/json'));
    setActiveOptionId(droppedOption.id);
    onOptionSelect(droppedOption);
    dropAudio.play();
    
    // Dispatch event to notify that an option was dropped
    window.dispatchEvent(new CustomEvent('optionDropped', {
      detail: { optionId: droppedOption.id }
    }));
  };

  const SendButton = () => {
    return (
      <motion.button
        onClick={handleSendClick}
        className="px-3 py-1.5 bg-emerald-600 hover:bg-emerald-500 text-white rounded-lg text-xs font-semibold transition-all duration-300"
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        Send Decision
      </motion.button>
    );
  };

  // Function to play card flip sound
  const playCardFlipSound = () => {
    cardFlipAudio.currentTime = 0; // Reset audio to start
    cardFlipAudio.play().catch(error => console.log("Audio play failed:", error));
  };

  return (
    <div 
      className={`w-[48%] flex-grow bg-gray-900 flex flex-col items-center text-xs justify-between transition-all duration-300 overflow-hidden
        ${isDragOver ? 'bg-emerald-500/5' : ''}
        relative
      `}
      data-option={activeOptionId}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <AnimatePresence mode="wait">
        {!showImpact ? (
          <motion.div
            key="response"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="w-full h-full flex flex-col items-center justify-between p-2"
          >
            {getResponseMessage()}
            {selectedOption && (
              <motion.div 
                className="flex justify-center gap-2 w-full mt-3"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
              >
                <SendButton />
              </motion.div>
            )}
          </motion.div>
        ) : (
          <motion.div 
            key="impact"
            className="w-full h-full"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <KPIDashboard 
              selectedOption={selectedOption} 
              onVisitedGroupsChange={onVisitedGroupsChange}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default RightRectangle;
