import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ChevronDown, ChevronRight, Gamepad2 } from 'lucide-react';
import { getInitialGames, getQuickGameInfo } from './data/gameData';
import { useTheme } from '../../context/ThemeContext';
import GameDetails from './GameDetails';
import TryGameModal from './TryGameModal';

const initialGames = getInitialGames();

const DeliveryBadge = ({ icon: Icon, text }) => (
  <div className="flex items-center gap-0.5 px-1 py-0.5 
                  bg-zinc-900/90 rounded-lg
                  text-[8px] sm:text-[9px] md:text-[9px] lg:text-[11px] xl:text-xs text-gray-300 font-['Space_Grotesk']
                  transition-all duration-200
                  sm:gap-1 sm:px-1.5 sm:py-0.5
                  md:gap-0.5 md:px-1 md:py-0.5
                  lg:gap-1.5 lg:px-2.5 lg:py-1
                  xl:gap-2 xl:px-3 xl:py-1.5">
    <Icon className="w-2 h-2 sm:w-2.5 sm:h-2.5 md:w-2 md:h-2 lg:w-3.5 lg:h-3.5 xl:w-4 xl:h-4 text-white" />
    {text}
  </div>
);

const BadgeCategory = ({ badges }) => (
  <div className="space-y-0.5 sm:space-y-0.5 md:space-y-0.5 lg:space-y-1.5 xl:space-y-2">
    <div className="flex flex-col gap-0.5 sm:gap-0.5 md:gap-0.5 lg:gap-1.5 xl:gap-2">
      {badges.map((badge, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 20 }}
          transition={{ delay: index * 0.1 }}
        >
          <DeliveryBadge 
            icon={badge.icon} 
            text={badge.text} 
          />
        </motion.div>
      ))}
    </div>
  </div>
);

const getGameInfo = (gameId) => {
  return getQuickGameInfo(gameId) || { delivery: [], target: [] };
};

const GameGridContext = React.createContext();

const GameGrid = () => {
  const { isDark } = useTheme();
  const [showAll, setShowAll] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);
  const [showTryModal, setShowTryModal] = useState(false);
  const displayedGames = showAll ? initialGames : initialGames.slice(0, 4);

  const handleCloseModal = () => {
    setSelectedGame(null);
  };

  return (
    <GameGridContext.Provider value={{ setShowTryModal }}>
      <div className="w-full py-12 sm:py-24">
        <div className="container mx-auto px-4">
          <div className="space-y-8 sm:space-y-16">
            {/* Header */}
            <div className="space-y-2 sm:space-y-4">
              <h2 className={`text-2xl sm:text-3xl font-bold font-['Space_Grotesk'] text-left
                ${isDark ? 'text-white' : 'text-gray-900'}`}>
                Training games library
              </h2>
              <p className={`text-sm sm:text-base font-['Space_Grotesk'] text-left
                ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                Explore our collection of game-based training solutions
              </p>
            </div>

            {/* Games Grid */}
            <div className="space-y-4 sm:space-y-8">
              {/* First Row - 2 cards */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-8">
                {displayedGames.slice(0, 2).map((game, index) => (
                  <GameCard 
                    key={game.id} 
                    game={game} 
                    index={index} 
                    isLarge={true}
                    onGameClick={() => setSelectedGame(game)}
                  />
                ))}
              </div>
              
              {/* Second Row - 3 cards */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 sm:gap-8">
                {displayedGames.slice(2, 4).map((game, index) => (
                  <GameCard 
                    key={game.id} 
                    game={game} 
                    index={index + 2} 
                    isLarge={false}
                    onGameClick={() => setSelectedGame(game)}
                  />
                ))}
                {/* Custom Game Card */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.7 }}
                  className={`relative rounded-xl p-6 group
                            border-2 border-dashed h-full
                            flex items-center justify-center
                            ${isDark 
                              ? 'bg-[#1f1f30]/95 border-game-accent/20 hover:border-game-accent/40' 
                              : 'bg-white border-game-accent/30 hover:border-game-accent/60'}`}
                >
                  <div className="flex flex-col items-center justify-center text-center space-y-4">
                    <div className={`w-16 h-16 rounded-full 
                                  flex items-center justify-center mb-4
                                  group-hover:scale-110 transition-transform duration-300
                                  ${isDark 
                                    ? 'bg-game-accent/20' 
                                    : 'bg-game-accent/10'}`}>
                      <Gamepad2 className={`w-8 h-8 ${isDark ? 'text-game-accent' : 'text-game-accent'}`} />
                    </div>
                    <h3 className={`text-lg font-bold font-['Space_Grotesk'] 
                                ${isDark ? 'text-white' : 'text-gray-900'}`}>
                      Need a Custom Game?
                    </h3>
                    <p className={`text-sm ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                      Let's create a unique game tailored to your specific training needs
                    </p>
                    <Link
                      to="/contact"
                      className={`inline-flex items-center gap-2 px-3 py-1.5 text-xs
                               rounded-full transition-all duration-300
                               ${isDark 
                                 ? 'bg-black/40 hover:bg-black/60 text-gray-300 hover:text-white border border-white/10 hover:border-white/20' 
                                 : 'bg-gray-100 hover:bg-gray-200 text-gray-600 hover:text-gray-900 border border-gray-200 hover:border-gray-300'}`}
                    >
                      Reach out
                      <ChevronRight className="w-3 h-3" />
                    </Link>
                  </div>
                </motion.div>
              </div>

              {/* Additional Games - Now shows remaining real games */}
              {showAll && (
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                  {initialGames.slice(4).map((game, index) => (
                    <GameCard 
                      key={game.id} 
                      game={game} 
                      index={index + 4} 
                      isLarge={false}
                      onGameClick={() => setSelectedGame(game)}
                    />
                  ))}
                </div>
              )}
            </div>

            {/* See All Button */}
            <motion.div 
              className="flex justify-center mt-8 sm:mt-16"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              <button
                onClick={() => setShowAll(!showAll)}
                className="group flex items-center gap-2 px-4 sm:px-6 py-2 sm:py-3 
                         bg-zinc-900/50 hover:bg-zinc-900
                         rounded-xl transition-all duration-200"
              >
                <span className="text-xs sm:text-sm font-medium text-white">
                  {showAll ? 'Show less' : 'See all games'}
                </span>
                <ChevronDown 
                  className={`w-3 h-3 sm:w-4 sm:h-4 text-white transition-transform duration-200
                    ${showAll ? 'rotate-180' : ''}`}
                />
              </button>
            </motion.div>
          </div>
        </div>
      </div>

      <GameDetails 
        game={selectedGame}
        isOpen={!!selectedGame}
        onClose={handleCloseModal}
      />

      <TryGameModal 
        isOpen={showTryModal}
        onClose={() => setShowTryModal(false)}
      />
    </GameGridContext.Provider>
  );
};

const GameCard = ({ game, index, isLarge, onGameClick }) => {
  const { isDark } = useTheme();
  const [showDetails, setShowDetails] = useState(false);
  const { setShowTryModal } = React.useContext(GameGridContext);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className={`${isDark ? 'bg-zinc-900/50' : 'bg-white/90'} 
                rounded-xl p-4 sm:p-6 space-y-4 shadow-lg`}
    >
      <div className={`relative ${isLarge ? 'aspect-video' : 'aspect-[4/5]'} rounded-lg overflow-hidden`}>
        {game.image ? (
          <>
            <img 
              src={game.image} 
              alt={game.title}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />

            {game.hasQuickInfo && (
              <div className="absolute top-3 left-3 z-20">
                <button
                  onClick={() => setShowDetails(!showDetails)}
                  className={`px-3 py-1.5 rounded-full text-xs font-['Space_Grotesk'] 
                             transition-all duration-300 flex items-center gap-2
                             backdrop-blur-sm border
                             ${showDetails 
                               ? 'bg-black/40 text-white border-game-accent/50 pr-4' 
                               : 'bg-black/60 text-gray-300 hover:text-white border-white/10 hover:border-white/20'}`}
                >
                  <span className={`w-2 h-2 rounded-full transition-all duration-300
                                  ${showDetails 
                                    ? 'bg-game-accent translate-x-0' 
                                    : 'bg-white/70 -translate-x-1'}`}
                  />
                  {showDetails ? 'Hide info' : 'Quick info'}
                </button>
              </div>
            )}

            {game.hasQuickInfo && showDetails && (
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 20 }}
                  transition={{ duration: 0.2 }}
                  className="absolute top-3 right-3 
                           flex flex-col gap-3 sm:gap-3 md:gap-3 lg:gap-4 xl:gap-5
                           w-32 sm:w-36 md:w-40 lg:w-48 xl:w-56 z-20"
                >
                  {game.image && (
                    <div className="flex flex-col gap-3 sm:gap-3 md:gap-3 lg:gap-4 xl:gap-5">
                      <BadgeCategory 
                        badges={getGameInfo(game.id).delivery} 
                      />
                      <BadgeCategory 
                        badges={getGameInfo(game.id).target} 
                      />
                    </div>
                  )}
                </motion.div>
              </AnimatePresence>
            )}
          </>
        ) : (
          <div className={`w-full h-full ${isDark 
            ? 'bg-gradient-to-br from-game-accent/20 to-purple-500/20'
            : 'bg-gradient-to-br from-game-accent/10 to-purple-500/10'}`} 
          />
        )}
      </div>
      <h3 className={`text-lg font-bold font-['Space_Grotesk'] 
        ${isDark ? 'text-white' : 'text-gray-900'}`}>
        {game.title}
      </h3>
      <p className={`text-sm ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
        {game.description}
      </p>
      
      <div className="flex gap-2 sm:gap-3">
        {(game.title === "AI Ethics in Banking" || game.title === "Anti Money Laundering") && (
          <motion.div className="relative hidden sm:block">
            <motion.div
              whileHover={{ opacity: 1, scale: 1.02 }}
              initial={{ opacity: 0, scale: 1 }}
              className="absolute -inset-1 bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 
                        rounded-full blur-md transition-all duration-300"
            />
            <motion.div
              whileHover="hover"
              className="relative"
            >
              <button 
                onClick={() => {
                  setShowTryModal(true);
                  localStorage.setItem('selectedGame', game.title);
                }}
                className="relative inline-flex items-center px-4 py-2
                         bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600
                         hover:from-indigo-500 hover:via-purple-500 hover:to-pink-500
                         text-white text-sm font-medium
                         rounded-full transition-all duration-300
                         shadow-lg hover:shadow-purple-500/25
                         font-['Space_Grotesk'] tracking-wide
                         overflow-hidden"
              >
                <motion.div
                  className="relative z-10 flex items-center gap-2"
                  variants={{
                    hover: { x: -4 }
                  }}
                >
                  Try now
                  <span className="relative flex items-center">
                    <span className="absolute inset-0 animate-ping">→</span>
                    →
                  </span>
                </motion.div>
              </button>
            </motion.div>
          </motion.div>
        )}
        
        <button
          onClick={() => onGameClick(game)}
          className={`inline-flex items-center gap-1.5 sm:gap-2 px-3 sm:px-4 py-1.5 sm:py-2 text-xs sm:text-sm
                   rounded-full transition-all duration-300
                   font-['Space_Grotesk'] border w-full sm:w-auto justify-center
                   ${isDark 
                     ? 'bg-zinc-800 hover:bg-zinc-700 text-gray-300 hover:text-white border-white/5 hover:border-white/20'
                     : 'bg-gray-100 hover:bg-gray-200 text-gray-600 hover:text-gray-900 border-gray-200 hover:border-gray-300'}`}
        >
          Game details
          <ChevronRight className="w-3 h-3 sm:w-4 sm:h-4" />
        </button>
      </div>
    </motion.div>
  );
};

export default GameGrid; 