export const IMPACT_DURATION = {
  PERMANENT: 'PERMANENT',
  ONE_TIME: 'ONE_TIME'
};

export const SHOP_ITEMS = [
  {
    id: 1,
    name: 'Omnius... help',
    description: 'Unlock Omnius assistant to help you through complex decisions.',
    impactDescription: 'Enables Omnius chatbot assistance',
    impactDuration: IMPACT_DURATION.PERMANENT,
    cost: 2000,
    type: 'permanent',
    effects: {
      enable_omnius: true
    },
    icon: 'bot'
  },
  {
    id: 2,
    name: 'Risk Detection System',
    description: 'Advanced AI-powered system that identifies potentially dangerous decisions.',
    impactDescription: 'Reveals red-flags (3 uses)',
    impactDuration: IMPACT_DURATION.PERMANENT,
    cost: 50000,
    type: 'limited',
    maxUses: 3,
    effects: {
      show_red_flags: true
    },
    icon: 'alert-triangle'
  },
  {
    id: 3,
    name: 'System Patch',
    description: 'Sophisticated algorithm that highlights green flag choices.',
    impactDescription: 'Reveals green-flags (3 uses)',
    impactDuration: IMPACT_DURATION.PERMANENT,
    cost: 45000,
    type: 'limited',
    maxUses: 3,
    effects: {
      show_green_flags: true
    },
    icon: 'check-circle'
  },
  {
    id: 4,
    name: 'Expensive PR firm',
    description: 'Hire a top PR firm to boost your market confidence.',
    impactDescription: 'One-time 10% boost to Market Confidence',
    impactDuration: IMPACT_DURATION.ONE_TIME,
    cost: 75000,
    type: 'one-time',
    maxUses: 1,
    effects: {
      market_confidence_boost: 1.1
    },
    icon: 'trending-up'
  },
  {
    id: 5,
    name: 'Sponsor AI safety conference',
    description: 'Sponsor AI safety initiatives to boost your market confidence.',
    impactDescription: 'One-time 5% boost to Market Confidence',
    impactDuration: IMPACT_DURATION.ONE_TIME,
    cost: 35000,
    type: 'one-time',
    maxUses: 1,
    effects: {
      market_confidence_small_boost: 1.05
    },
    icon: 'bar-chart'
  },
  {
    id: 6,
    name: 'Hire Security Specialist',
    description: 'Hire a security specialist to reduce risk each round.',
    impactDescription: 'Reduces Risk Level by 1% each round when activated',
    impactDuration: IMPACT_DURATION.PERMANENT,
    cost: 60000,
    type: 'perpetual',
    maxUses: 1,
    effects: {
      perpetual_risk_reduction: true
    },
    icon: 'shield'
  },
  {
    id: 7,
    name: 'External Audit',
    description: 'Hire a top audit firm to review and improve compliance.',
    impactDescription: 'One-time 40 point boost to Compliance',
    impactDuration: IMPACT_DURATION.ONE_TIME,
    cost: 70000,
    type: 'one-time',
    maxUses: 1,
    effects: {
      compliance_boost: true
    },
    icon: 'clipboard-check'
  },
  {
    id: 8,
    name: 'Regulatory AI Sandbox',
    description: 'Partner with regulators to test AI systems in a controlled environment.',
    impactDescription: 'Increases Compliance by 2 points each round when activated',
    impactDuration: IMPACT_DURATION.PERMANENT,
    cost: 55000,
    type: 'perpetual',
    maxUses: 1,
    effects: {
      perpetual_compliance_boost: true
    },
    icon: 'box'
  },
  {
    id: 9,
    name: 'Sprint Planning',
    description: 'Team meeting to identify critical decisions for the current sprint.',
    impactDescription: 'Reveals high-importance scenarios (5 uses)',
    impactDuration: IMPACT_DURATION.PERMANENT,
    cost: 40000,
    type: 'limited',
    maxUses: 5,
    effects: {
      show_importance: true
    },
    icon: 'calendar'
  },
  {
    id: 10,
    name: 'Retrospective Meeting',
    description: 'Team meeting to review and improve all processes.',
    impactDescription: 'Boosts all metrics (3 uses)',
    impactDuration: IMPACT_DURATION.PERMANENT,
    cost: 30000,
    type: 'limited',
    maxUses: 3,
    effects: {
      team_boost: true
    },
    icon: 'users'
  }
];

export const ITEM_TYPES = {
  MULTIPLIER: 'multiplier',
  ADDITION: 'addition',
  RED_FLAG: 'red_flag',
  GREEN_FLAG: 'green_flag',
  SCORE_BOOST: 'score_boost'
};