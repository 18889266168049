import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import LogoStrip from './components/LogoStrip';
import GameGrid from './components/GameGrid';
import GlobeComponent from './components/Globe';
import Features from './components/Features';
import Pricing from './components/Pricing';
import CallToAction from './components/CallToAction';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import { useTheme } from '../context/ThemeContext';
import backgroundImage from './assets/website-background.webp';

const LandingPage = () => {
  const { isDark } = useTheme();

  return (
    <div className={isDark ? 'dark' : ''}>
      <div className={`min-h-screen relative transition-colors duration-200 
        ${isDark ? 'text-white' : 'text-gray-900'}`}
      >
        {/* Background Image with overlay - Only show in dark mode */}
        <div className="fixed inset-0 -z-10">
          {isDark && (
            <img 
              src={backgroundImage} 
              alt="background" 
              className="w-full h-full object-cover"
            />
          )}
          <div className={`absolute inset-0 
            ${isDark 
              ? 'bg-game-dark/90 backdrop-blur-[1px]'
              : 'bg-gray-50'  // Removed opacity and blur in light mode
            }`} 
          />
        </div>

        <Navbar />
        <Hero />
        <LogoStrip />
        <GameGrid />
        <GlobeComponent />
        <Features />
        <Pricing />
        <CallToAction />
        <FAQ />
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage; 