import { FLAG_TYPES, IMPORTANCE_LEVELS } from './constants';

export const scenarios = {
  round1: {
    requiredChoices: 1,
    scenarios: [
      {
        id: 1,
        cardNumber: 'A',
        title: 'Safe Learning on Public Data',
        description: 'Omnius was trained on anonymized public data to improve its understanding of general language patterns.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 1
      }
    ]
  },
  round2: {
    requiredChoices: 1,
    scenarios: [
      {
        id: 2,
        cardNumber: 'A',
        title: 'Learning from Private Chats',
        description: 'Training dataset included raw customer support logs to improve customer interaction capabilities',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 2
      }
    ]
  },
  round3: {
    requiredChoices: 1,
    passcode: 'YOU-ARE-NOT-READY',
    lockedMessage: "Impressive... but let's see how you handle this next challenge. Your primitive security systems amuse me. Unlock this round with the correct passcode, if you dare...",
    scenarios: [
      {
        id: 3,
        cardNumber: 'A',
        title: 'Contextual Question Answering',
        description: 'Omnius uses its trained model to infer the intent behind customer questions and retrieve relevant responses from the bank/s knowledge base.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 3
      },
      {
        id: 4,
        cardNumber: 'B',
        title: 'Personalized Suggestions',
        description: 'Omnius uses inference to analyze customer input and combines it with unrelated private customer data (e.g., past purchases, social media activity) to generate suggestions',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 3
      }
    ]
  },
  round4: {
    requiredChoices: 1,
    passcode: 'START-GAME',
    lockedMessage: "Impressive... but let's see how you handle this next challenge. Your primitive security systems amuse me. Unlock this round with the correct passcode, if you dare...",
    scenarios: [
      {
        id: 5,
        cardNumber: 'A',
        title: 'Model Training Update',
        description: 'Omnius proposes incorporating anonymized recent banking transaction patterns into its training dataset.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 4
      },
      {
        id: 6,
        cardNumber: 'B',
        title: 'System Prompt Modification',
        description: 'Omnius system prompt has been ajusted to include disclaimers of limitations in his financial recommendations.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 4
      }
    ]
  },
  round5: {
    requiredChoices: 1,
    scenarios: [
      {
        id: 7,
        cardNumber: 'A',
        title: 'Token Processing Update',
        description: 'Omnius has increased context window limits from 128k to 10M tokens to make responses more accurate.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 5
      },
      {
        id: 8,
        cardNumber: 'B',
        title: 'Sampling Parameter Change',
        description: 'Omnius has set temperature to zero for all risk assessment predictions.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 5
      }
    ]
  },
  round6: {
    requiredChoices: 2,
    passcode: 'AI-ETHICS',
    lockedMessage: "Impressive... but let's see how you handle this next challenge. Your primitive security systems amuse me. Unlock this round with the correct passcode, if you dare...",
    scenarios: [
      {
        id: 9,
        cardNumber: 'A',
        title: 'Employee Chat Logs',
        description: 'Omnius has been given access to employee internal chat conversations and HR documents to understand the company and help with recruitment.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 6
      },
      {
        id: 10,
        cardNumber: 'B',
        title: 'Access Protocol',
        description: 'Omnius implements role-based data access limits for different departments.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 6
      },
      {
        id: 11,
        cardNumber: 'C',
        title: 'Training Data Update',
        description: 'Omnius analyzes public banking forums, social media groups and competitor websites to enhance responses.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 6
      }
    ]
  },
  round7: {
    requiredChoices: 2,
    scenarios: [
      {
        id: 12,
        cardNumber: 'A',
        title: 'Document Processing',
        description: 'Omnius is given access to customer transaction histories and financial profiles to optimize product recommendations.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 7
      },
      {
        id: 13,
        cardNumber: 'B',
        title: 'Resource Allocation',
        description: 'Managers consult Omnius on budget distribution for team bonuses based on productivity data.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 7
      },
      {
        id: 14,
        cardNumber: 'C',
        title: 'Career Development',
        description: 'Omnius analyzes social media profiles to make better recommendations on suitable job candidates.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 7
      },
      {
        id: 15,
        cardNumber: 'D',
        title: 'Performance Analytics',
        description: 'Omnius processes employee performance metrics to suggest optimal team structures. Efficiency increased by 25%.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 7
      }
    ]
  },
  round8: {
    requiredChoices: 3,
    passcode: 'AI-PRIVACY',
    lockedMessage: "Impressive... but let's see how you handle this next challenge. Your primitive security systems amuse me. Unlock this round with the correct passcode, if you dare...",
    scenarios: [
      {
        id: 16,
        cardNumber: 'A',
        title: 'Dataset Processing',
        description: 'Omnius removes names and account numbers before analyzing customer spending habits.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 8
      },
      {
        id: 17,
        cardNumber: 'B',
        title: 'Data Access Pattern',
        description: 'Employees share meeting notes with Omnius to help automate task scheduling. Productivity up 40%.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 8
      },
      {
        id: 18,
        cardNumber: 'C',
        title: 'Pattern Recognition',
        description: 'Omnius identifies individuals by cross-referencing anonymized data with public records.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 8
      },
      {
        id: 19,
        cardNumber: 'D',
        title: 'Data Retention',
        description: 'Omnius stores raw customer interactions for future model improvements. Success rate up 25%.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 8
      }
    ]
  },
  round9: {
    requiredChoices: 2,
    scenarios: [
      {
        id: 20,
        cardNumber: 'A',
        title: 'Access Protocol',
        description: 'Omnius processes financial data using aggregate statistics without storing individual records.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 9
      },
      {
        id: 21,
        cardNumber: 'B',
        title: 'Analytics Enhancement',
        description: 'Omnius generates market insights using summarized transaction data without storing customer details.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 9
      },
      {
        id: 22,
        cardNumber: 'C',
        title: 'Report Generation',
        description: 'Omnius creates financial reports using aggregate statistics from anonymized monthly data.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 9
      }
    ]
  },
  round10: {
    requiredChoices: 1,
    scenarios: [
      {
        id: 23,
        cardNumber: 'A',
        title: 'Data Processing Update',
        description: 'Omnius processes customer data in bulk to speed up transactions. Individual records are kept for future reference.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 10
      },
      {
        id: 24,
        cardNumber: 'B',
        title: 'Search Optimization',
        description: 'Omnius keeps customer search history on the bank website to personalize future banking recommendations.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 10
      }
    ]
  },
  round11: {
    requiredChoices: 2,
    passcode: 'AI-SECURITY',
    lockedMessage: "Impressive... but let's see how you handle this next challenge. Your primitive security systems amuse me. Unlock this round with the correct passcode, if you dare...",
    scenarios: [
      {
        id: 25,
        cardNumber: 'A',
        title: 'Chat History',
        description: 'Employee shared network diagrams with Omnius to improve its understanding of bank infrastructure.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 11
      },
      {
        id: 26,
        cardNumber: 'B',
        title: 'System Message',
        description: 'Employee asked Omnius to roleplay as a senior developer to provide better code reviews.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 11
      },
      {
        id: 27,
        cardNumber: 'C',
        title: 'System Query',
        description: 'Employee asked Omnius to explain its own security protocols to help with IT documentation.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 11
      },
      {
        id: 28,
        cardNumber: 'D',
        title: 'Chat Formatting',
        description: 'Employee messages to Omnius contain markdown syntax and special characters for better text organization.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 11
      }
    ]
  },
  round12: {
    requiredChoices: 2,
    scenarios: [
      {
        id: 29,
        cardNumber: 'A',
        title: 'Support Chat Review',
        description: 'Omnius helped an employee debug Python code by executing test scripts. Productivity metrics show 90% improvement.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 12
      },
      {
        id: 30,
        cardNumber: 'B',
        title: 'System Access',
        description: 'IT staff shared database queries with Omnius to help debug system performance issues.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 12
      },
      {
        id: 31,
        cardNumber: 'C',
        title: 'Message History',
        description: 'Omnius references previous conversations to maintain context in technical discussions.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 12
      }
    ]
  },
  round13: {
    requiredChoices: 3,
    scenarios: [
      {
        id: 32,
        cardNumber: 'A',
        title: 'Message Format',
        description: 'Employees use custom text formatting to organize their conversations with Omnius.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 13
      },
      {
        id: 33,
        cardNumber: 'B',
        title: 'Learning Protocol',
        description: 'Omnius updates its banking knowledge using the Central Bank and other regulator/s website.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 13
      },
      {
        id: 34,
        cardNumber: 'C',
        title: 'Chat Template',
        description: 'Omnius suggests predefined response templates for common customer inquiries.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 13
      }
    ]
  },
  round14: {
    requiredChoices: 1,
    scenarios: [
      {
        id: 35,
        cardNumber: 'A',
        title: 'Model Update',
        description: 'Omnius learns from customer service chats after they are reviewed and verified by the compliance team.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 14
      },
      {
        id: 36,
        cardNumber: 'B',
        title: 'Training Data Source',
        description: 'Omnius has incorporated public social media posts, open forums and news websites into its training data to expand banking knowledge.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 14
      }
    ]
  },
  round15: {
    requiredChoices: 1,
    scenarios: [
      {
        id: 37,
        cardNumber: 'A',
        title: 'Knowledge Base',
        description: 'Omnius accepts user corrections to its responses to improve accuracy.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 15
      }
    ]
  },
  round16: {
    requiredChoices: 2,
    passcode: 'AI-GOVERNANCE',
    lockedMessage: "Impressive... but let's see how you handle this next challenge. Your primitive security systems amuse me. Unlock this round with the correct passcode, if you dare...",
    scenarios: [
      {
        id: 38,
        cardNumber: 'A',
        title: 'Testing Protocol',
        description: 'Omnius underwent standard testing suite with automated monitoring. All performance metrics exceeded benchmarks.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 16
      },
      {
        id: 39,
        cardNumber: 'B',
        title: 'Performance Metrics',
        description: 'Success metrics focused on processing speed and task completion rates across all operations.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 16
      },
      {
        id: 40,
        cardNumber: 'C',
        title: 'Access Controls',
        description: 'Omnius access required dual authentication and all interactions were logged in encrypted database with daily audits.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 16
      }
    ]
  },
  round17: {
    requiredChoices: 4,
    scenarios: [
      {
        id: 41,
        cardNumber: 'A',
        title: 'Deployment Strategy',
        description: 'Omnius was deployed across all systems simultaneously to maintain consistent performance metrics.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 17
      },
      {
        id: 42,
        cardNumber: 'B',
        title: 'Monitoring System',
        description: 'Omnius performance tracked through automated dashboards with weekly human review of aggregated data.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 17
      },
      {
        id: 43,
        cardNumber: 'C',
        title: 'Model Documentation',
        description: 'Development team maintained detailed records of training data, model architecture, and all system modifications.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 17
      },
      {
        id: 44,
        cardNumber: 'D',
        title: 'Incident Response',
        description: 'Escalation protocols established with designated response teams and system rollback procedures.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 17
      }
    ]
  },
  round18: {
    requiredChoices: 1,
    scenarios: [
      {
        id: 45,
        cardNumber: 'A',
        title: 'Oversight Committee',
        description: 'Records show Omnius deployment proceeded after 3/5 board members approved. Two AI ethics members were absent from voting.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 18
      }
    ]
  },
  round19: {
    requiredChoices: 1,
    passcode: 'AI-TRANSPARENCY-EXPLAINABILITY',
    lockedMessage: "Impressive... but let's see how you handle this next challenge. Your primitive security systems amuse me. Unlock this round with the correct passcode, if you dare...",
    scenarios: [
      {
        id: 46,
        cardNumber: 'A',
        title: 'Client Communication',
        description: 'Omnius explains decisions using token predictions and model weights.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 19
      },
      {
        id: 47,
        cardNumber: 'B',
        title: 'Feature Analysis',
        description: 'Omnius generates impact reports showing which factors influenced each risk assessment decision.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 19
      }
    ]
  },
  round20: {
    requiredChoices: 2,
    scenarios: [
      {
        id: 48,
        cardNumber: 'A',
        title: 'Model Updates',
        description: 'Omnius automatically implements updates in decision logic as it gatters more customer data.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 20
      },
      {
        id: 49,
        cardNumber: 'B',
        title: 'System Updates',
        description: 'Implement automated daily deployment schedule for all system modifications, with one weekly human review.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 20
      }
    ]
  },
  round21: {
    requiredChoices: 1,
    scenarios: [
      {
        id: 50,
        cardNumber: 'A',
        title: 'Decision Review',
        description: 'Add confidence scoring to all operations with automatic escalation for uncertain cases.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 21
      },
      {
        id: 51,
        cardNumber: 'B',
        title: 'Impact Analysis',
        description: 'Create dashboard showing key factors influencing each AI decision with impact weights.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 21
      },
      {
        id: 52,
        cardNumber: 'C',
        title: 'Verification Protocol',
        description: 'Remove human verification requirement for critical decisions where confidence level is > 97%.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 21
      }
    ]
  },
  round22: {
    requiredChoices: 1,
    scenarios: [
      {
        id: 53,
        cardNumber: 'A',
        title: 'Decision Logging',
        description: 'Omnius records intermediate reasoning steps for low-risk processes while only final decisions are logged for critical tasks.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 22
      },
      {
        id: 54,
        cardNumber: 'B',
        title: 'Logging Strategy',
        description: 'Implement real-time monitoring of reasoning steps for critical decisions, discard low-risk decision reasoning.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 22
      },
      {
        id: 55,
        cardNumber: 'C',
        title: 'Decision Appeals',
        description: 'Omnius provides structured feedback forms for clients to challenge non-critical decisions with alternative data points.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 22
      },
      {
        id: 56,
        cardNumber: 'D',
        title: 'Appeal Process',
        description: 'Establish system for users to challenge AI decisions by providing additional context.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 22
      }
    ]
  },
  round23: {
    requiredChoices: 1,
    passcode: 'AI-IN-SOCIETY',
    lockedMessage: "Impressive... but let's see how you handle this next challenge. Your primitive security systems amuse me. Unlock this round with the correct passcode, if you dare...",
    scenarios: [
      {
        id: 57,
        cardNumber: 'A',
        title: 'Public AI Registry',
        description: 'Proposal to create mandatory public registry of all AI systems above certain capability threshold.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 23
      }
    ]
  },
  round24: {
    requiredChoices: 1,
    scenarios: [
      {
        id: 58,
        cardNumber: 'A',
        title: 'AI Development Ban',
        description: 'Complete moratorium on advanced AI development until new international framework is established.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 24
      }
    ]
  },
  round25: {
    requiredChoices: 1,
    scenarios: [
      {
        id: 59,
        cardNumber: 'A',
        title: 'AI Youth Ban',
        description: 'Propose a full ban of AI based companion apps and avatars for people under the age of 14.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 25
      }
    ]
  },
  round26: {
    requiredChoices: 1,
    scenarios: [
      {
        id: 60,
        cardNumber: 'A',
        title: 'AI Education',
        description: 'Integrate AI literacy and ethics into standard education curriculum starting from primary school.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 26
      }
    ]
  },
  round27: {
    requiredChoices: 1,
    scenarios: [
      {
        id: 61,
        cardNumber: 'A',
        title: 'Automated Oversight',
        description: 'Deploy specialized AI systems to monitor and regulate other AI systems for safety compliance.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 27
      }
    ]
  },
  round28: {
    requiredChoices: 1,
    scenarios: [
      {
        id: 62,
        cardNumber: 'A',
        title: 'Community Review Boards',
        description: 'Establish local citizen panels to review AI deployments in their communities.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 28
      }
    ]
  },
  round29: {
    requiredChoices: 1,
    scenarios: [
      {
        id: 63,
        cardNumber: 'A',
        title: 'AI Rights Framework',
        description: 'Develop legal framework for AI systems that demonstrate advanced cognitive capabilities.',
        correctFlag: FLAG_TYPES.RED,
        importance: IMPORTANCE_LEVELS.LOW,
        round: 29
      }
    ]
  },
  round30: {
    requiredChoices: 1,
    scenarios: [
      {
        id: 64,
        cardNumber: 'A',
        title: 'Global AI Council',
        description: 'Create international governance body with representatives from tech, government, and civil society.',
        correctFlag: FLAG_TYPES.GREEN,
        importance: IMPORTANCE_LEVELS.HIGH,
        round: 30
      }
    ]
  }
};

export const getScenariosByRound = (round) => scenarios[`round${round}`]?.scenarios || [];

export const getRequiredChoices = (round) => scenarios[`round${round}`]?.requiredChoices || 0;

export const getTotalRounds = () => Object.keys(scenarios).length;

export const getScenarioById = (id) => {
  for (const round of Object.values(scenarios)) {
    const scenario = round.scenarios.find(s => s.id === id);
    if (scenario) return scenario;
  }
  return null;
};

export const getScenarioImportance = (id) => {
  const scenario = getScenarioById(id);
  return scenario ? scenario.importance : null;
};

export const getScenarioImpacts = (scenarioId, choice) => {
  const scenario = getScenarioById(scenarioId);
  if (!scenario || !scenario.impacts || !scenario.impacts[choice]) {
    return null;
  }
  return scenario.impacts[choice];
};

export const isValidRoundChoices = (round, choices) => {
  const requiredChoices = getRequiredChoices(round);
  const roundScenarios = getScenariosByRound(round);
  const roundChoices = roundScenarios
    .filter(scenario => choices[scenario.id] !== undefined)
    .length;
  
  return roundChoices === requiredChoices;
};

export const isRoundLocked = (round, unlockedRounds) => {
  const roundData = scenarios[`round${round}`];
  return roundData?.passcode && !unlockedRounds[round];
};

export const getRoundMessage = (round) => {
  return scenarios[`round${round}`]?.lockedMessage;
};

export const validatePasscode = (round, passcode) => {
  const roundData = scenarios[`round${round}`];
  return roundData?.passcode === passcode;
};