import React, { useState, useEffect } from 'react';
import Character from '../components/Characters';
import Resources from '../components/Resources';
import SurviveEscapeTracker from '../components/SurviveEscapeTracker';
import './css/LeftBar.css';

function LeftBar({ selectedCharacters, onCharacterClick }) {
  const [showNews, setShowNews] = useState(false);
  const [characterNews, setCharacterNews] = useState({});

  useEffect(() => {
    const handleStrategyConfirm = () => {
      setShowNews(true);
      const news = Array.from(selectedCharacters).reduce((acc, charId) => {
        acc[charId] = true;
        return acc;
      }, {});
      setCharacterNews(news);
    };

    window.addEventListener('strategyConfirmed', handleStrategyConfirm);
    return () => window.removeEventListener('strategyConfirmed', handleStrategyConfirm);
  }, [selectedCharacters]);

  const handleClick = (characterId) => {
    setCharacterNews(prev => ({
      ...prev,
      [characterId]: false
    }));
    onCharacterClick(characterId);
  };

  return (
    <div className="antarctica-left-bar">
      <div className="antarctica-left-bar__portraits">
        {Array.from(selectedCharacters).map((charId) => (
          <Character
            key={charId}
            characterId={charId}
            size="small"
            onClick={handleClick}
            hasNews={showNews && characterNews[charId]}
          />
        ))}
      </div>
      <Resources />
      <SurviveEscapeTracker />
    </div>
  );
}

export default LeftBar; 