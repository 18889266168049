import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBRAvUuDh4G0QTR1Di3GDpNP9qpivu872A",
  authDomain: "boringtraining.firebaseapp.com",
  projectId: "boringtraining",
  storageBucket: "boringtraining.firebasestorage.app",
  messagingSenderId: "386915316919",
  appId: "1:386915316919:web:07b2ff1ec1813a25f14932"
};

// Debug logging
console.log('Firebase Config:', {
  ...firebaseConfig,
  apiKey: firebaseConfig.apiKey ? '[HIDDEN]' : 'missing'
});

if (!firebaseConfig.projectId) {
  throw new Error('Firebase Project ID is missing. Check your .env file');
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);

export default app; 