import React from 'react';
import exploreIcon from '../assets/images/explore-label.webp';
import exploitIcon from '../assets/images/exploit-label.webp';
import './StrategyIndicator.css';

function StrategyIndicator({ strategy }) {
  const icon = strategy === 'survive' ? exploitIcon : exploreIcon;
  
  return (
    <div className="strategy-indicator">
      <div className="strategy-indicator__content">
        <span className="strategy-indicator__label">Strategy</span>
        <img 
          src={icon} 
          alt={`${strategy} Icon`} 
          className="strategy-indicator__icon"
        />
      </div>
    </div>
  );
}

export default StrategyIndicator; 