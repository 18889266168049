import React from 'react';

function RightBar() {
  return (
    <div className="w-[60px] flex-shrink-0 bg-rose-900 border-l border-rose-500/30">
      {/* Right bar content */}
    </div>
  );
}

export default RightBar; 