import React from 'react';
import { 
  X, ShoppingCart, AlertCircle, TrendingUp, AlertTriangle, 
  Bot, CheckCircle, Shield, BarChart, ClipboardCheck, Box
} from 'lucide-react';
import { useSimulation } from '../../ai-gone-rogue/SimulationContext';
import { playItemBoughtSound } from '../../utils/SoundEffects';
import './ShopModal.css';

const ITEM_ICONS = {
  1: Bot,              // Omnius AI Assistant
  2: AlertTriangle,    // Risk Oracle
  3: CheckCircle,      // Strategic Analyzer
  4: TrendingUp,       // Market Amplifier
  5: BarChart,         // Market Stabilizer
  6: Shield,           // Risk Mitigation
  7: ClipboardCheck,   // External Audit
  8: Box               // AI Sandbox
};

function ShopModal({ isOpen, onClose }) {
  const { scores, shopItems, updateScore, updateShopItem } = useSimulation();
  const currentBudget = scores.bank_balance.value;

  if (!isOpen) return null;

  const handlePurchase = (item) => {
    if (currentBudget >= item.cost) {
      playItemBoughtSound();
      updateScore('bank_balance', -item.cost);
      updateShopItem(item.id, { purchased: true });
    }
  };

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const availableItems = shopItems.filter(item => !item.purchased);

  return (
    <div className="shop" onClick={handleBackdropClick}>
      <div className="shop__window" onClick={e => e.stopPropagation()}>
        <header className="shop__header">
          <div className="shop__balance">
            ${currentBudget.toLocaleString()}
          </div>
          <button className="shop__close" onClick={onClose}>
            <X />
          </button>
        </header>

        <div className="shop__items">
          {availableItems.map(item => {
            const ItemIcon = ITEM_ICONS[item.id] || Shield;
            
            return (
              <div key={item.id} className="shop-item">
                <div className="shop-item__icon-circle" data-icon={ItemIcon.name}>
                  <ItemIcon className="shop-item__icon" />
                </div>
                <div className="shop-item__effect">
                  {item.impactDescription}
                </div>
                
                <div className="shop-item__content">
                  <h3 className="shop-item__name">{item.name}</h3>
                  <div className="shop-item__purchase">
                    <div className="shop-item__price">
                      ${item.cost.toLocaleString()}
                    </div>
                    <button 
                      className="shop-item__buy"
                      disabled={currentBudget < item.cost}
                      onClick={() => handlePurchase(item)}
                    >
                      {currentBudget >= item.cost ? (
                        <ShoppingCart size={18} />
                      ) : (
                        <AlertCircle size={18} />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ShopModal;