import React from 'react';
import { useAntarctica } from '../context/AntarcticaContext';
import gameConfig from '../content/game-config.json';
import healthIcon from '../assets/images/health-counter.webp';
import './Health.css';

function Health() {
  const { resources } = useAntarctica();
  const { ui } = gameConfig.health;
  
  const formattedValue = ui.valueFormat.replace('%d', resources.health);
  
  return (
    <div className={ui.className}>
      <img 
        src={healthIcon} 
        alt={ui.iconAlt} 
        className={`${ui.className}__icon`}
      />
      <div className={`${ui.className}__content`}>
        <span className={`${ui.className}__count`}>{formattedValue}</span>
      </div>
    </div>
  );
}

export default Health; 