import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Linkedin, Mail, User, Building2, AlertCircle, Heart } from 'lucide-react';
import emailjs from '@emailjs/browser';

// Reuse the same validation and input components from TryGameModal
const isBusinessEmail = (email) => {
  const personalDomains = [
    'gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com', 
    'aol.com', 'icloud.com', 'mail.com', 'protonmail.com'
  ];
  
  try {
    const domain = email.split('@')[1].toLowerCase();
    return !personalDomains.includes(domain);
  } catch {
    return false;
  }
};

const capitalizeFirstLetter = (string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const InputField = ({ icon: Icon, type, placeholder, value, onChange, error }) => (
  <div className="space-y-1">
    <div className="relative">
      <div className="absolute left-3 top-1/2 -translate-y-1/2">
        <Icon className={`w-4 h-4 ${error ? 'text-red-400' : 'text-gray-400'}`} />
      </div>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={`w-full pl-10 pr-3 py-2.5 text-sm rounded-xl
                 bg-black/20 border ${error ? 'border-red-500/50' : 'border-game-accent/20'}
                 text-white placeholder-gray-500
                 focus:outline-none focus:border-game-accent/40
                 transition-colors duration-200`}
      />
    </div>
    {error && (
      <div className="flex items-center gap-1 text-xs text-red-400 pl-1">
        <AlertCircle className="w-3 h-3" />
        {error}
      </div>
    )}
  </div>
);

const ContactDialog = ({ isOpen, onClose }) => {
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleNameChange = (value) => {
    setName(capitalizeFirstLetter(value));
  };

  const handleCompanyChange = (value) => {
    setCompany(capitalizeFirstLetter(value));
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!name.trim()) {
      newErrors.name = 'Name is required';
    }
    
    if (!company.trim()) {
      newErrors.company = 'Company is required';
    }
    
    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!email.includes('@')) {
      newErrors.email = 'Invalid email format';
    } else if (!isBusinessEmail(email)) {
      newErrors.email = 'Please use your business email';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      try {
        const requestType = localStorage.getItem('requestType') || 'Contact form';  // Default to 'Contact form'
        
        const response = await emailjs.send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_CONTACT_TEMPLATE_ID,
          {
            user_name: name,
            user_company: company,
            user_email: email,
            request_type: requestType,  // Add request type to email
          }
        );

        console.log('EmailJS Response:', response);
        localStorage.removeItem('requestType');  // Clean up
        setIsSubmitting(false);
        onClose();
      } catch (error) {
        console.error('Failed to send email:', error);
        setIsSubmitting(false);
        setErrors(prev => ({
          ...prev,
          submit: 'Failed to submit form. Please try again.'
        }));
      }
    }
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 flex items-center justify-center p-4"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="absolute inset-0 bg-black/80 backdrop-blur-md"
          onClick={onClose}
        />

        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          transition={{ type: "spring", damping: 30, stiffness: 300 }}
          className="relative w-full max-w-md rounded-2xl
                   bg-gradient-to-br from-zinc-900/95 via-game-dark/95 to-black/95
                   backdrop-blur-sm
                   shadow-2xl border border-game-accent/20"
        >
          <button
            onClick={onClose}
            className="absolute top-4 right-4 p-2 rounded-full 
                     bg-black/20 hover:bg-black/40 
                     backdrop-blur-sm
                     border border-white/10 hover:border-white/20
                     text-white/50 hover:text-white
                     transition-all duration-300"
          >
            <X className="w-5 h-5" />
          </button>

          <div className="p-6 space-y-6">
            <div className="text-center">
              <h2 className="text-2xl font-bold font-['Space_Grotesk'] text-white">
                Leave your details
              </h2>
              <p className="text-gray-400 mt-2">
                We usually get back to you in less than an hour
              </p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <InputField
                icon={User}
                type="text"
                placeholder="Name"
                value={name}
                onChange={handleNameChange}
                error={errors.name}
              />

              <InputField
                icon={Building2}
                type="text"
                placeholder="Company"
                value={company}
                onChange={handleCompanyChange}
                error={errors.company}
              />

              <InputField
                icon={Mail}
                type="email"
                placeholder="Business email"
                value={email}
                onChange={setEmail}
                error={errors.email}
              />

              <p className="text-xs text-gray-400 italic">
                By submitting this form, you agree to be contacted by our team about your inquiry
              </p>

              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-full py-2.5 rounded-xl
                         ${isSubmitting 
                           ? 'bg-gray-500 cursor-not-allowed' 
                           : 'bg-game-accent hover:bg-game-accent-hover'}
                         text-white text-sm font-medium
                         transition-colors duration-200 mt-2`}
              >
                {isSubmitting ? 'Sending...' : 'Submit details'}
              </button>
            </form>

            <div className="space-y-4">
              <motion.a
                href="https://www.linkedin.com/company/teamlearning"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="flex items-center gap-3 p-4 rounded-xl 
                         bg-black/20 border border-game-accent/20
                         text-white hover:bg-black/40 
                         transition-all duration-300"
              >
                <Linkedin className="w-5 h-5 text-game-accent" />
                <span className="text-sm font-['Space_Grotesk']">
                  Follow us on LinkedIn
                </span>
              </motion.a>
            </div>

            <div className="pt-6 space-y-4">
              <div className="flex items-center justify-center gap-2 text-sm text-gray-400">
                <span>Made with</span>
                <Heart className="w-4 h-4 text-game-accent animate-pulse" />
                <span>by</span>
                <a 
                  href="https://teamlearn.ai" 
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white hover:text-game-accent transition-colors"
                >
                  Teamlearn
                </a>
              </div>

              <p className="text-center text-sm text-gray-500">
                © {new Date().getFullYear()} Teamlearn. All rights reserved.
              </p>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ContactDialog; 