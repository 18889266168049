import React, { createContext, useContext, useState, useEffect } from 'react';
import gameConfig from '../content/game-config.json';

const AntarcticaContext = createContext();

export const GAME_PHASES = {
  STRATEGY: 'strategy',
  TACTICS: 'tactics',
  RESOLUTION: 'resolution'
};

const getInitialState = () => ({
  currentRound: 1,
  phase: GAME_PHASES.STRATEGY,
  strategy: null,
  crewAssignments: {},
  resources: {
    food: gameConfig.resources.food.initialValue,
    wood: gameConfig.resources.wood.initialValue,
    health: gameConfig.health.initialValue,
    morale: gameConfig.morale.initialValue
  },
  survivalStatus: {
    shelter: gameConfig.survivalTracker.shelter.initialValue,
    boat: gameConfig.survivalTracker.boat.initialValue,
    foodStorage: gameConfig.survivalTracker.foodStorage.initialValue
  },
  survivors: {
    current: gameConfig.survivors.initial.current,
    total: gameConfig.survivors.initial.total
  },
  workerStatus: {
    tired: gameConfig.survivors.initial.current,  // Start with all workers tired
    rested: 0
  },
  sessionId: null,
  gameStarted: false
});

export function AntarcticaProvider({ children }) {
  const [gameState, setGameState] = useState(getInitialState());
  const [sessionId, setSessionId] = useState(null);

  useEffect(() => {
    const loadSavedProgress = async () => {
      const existingSessionId = localStorage.getItem('antarcticaSessionId');
      const currentSessionId = existingSessionId || crypto.randomUUID();
      
      if (!existingSessionId) {
        localStorage.setItem('antarcticaSessionId', currentSessionId);
      }
      
      setSessionId(currentSessionId);

      // Load saved progress if exists
      const savedState = localStorage.getItem(`antarctica_${currentSessionId}`);
      if (savedState) {
        setGameState(JSON.parse(savedState));
      }
    };

    loadSavedProgress();
  }, []);

  // Save progress when state changes
  useEffect(() => {
    if (sessionId && gameState.gameStarted) {
      localStorage.setItem(`antarctica_${sessionId}`, JSON.stringify(gameState));
    }
  }, [gameState, sessionId]);

  const setCrewAssignment = (taskId, crew) => {
    setGameState(prev => ({
      ...prev,
      crewAssignments: {
        ...prev.crewAssignments,
        [taskId]: crew
      }
    }));
  };

  const removeCrewAssignment = (taskId) => {
    setGameState(prev => {
      const newAssignments = { ...prev.crewAssignments };
      delete newAssignments[taskId];
      return {
        ...prev,
        crewAssignments: newAssignments
      };
    });
  };

  const resolveRound = () => {
    // Count workers resting and working
    let restingWorkers = 0;
    let workingWorkers = 0;
    let workingTiredWorkers = 0;
    let workingRestedWorkers = 0;
    
    Object.entries(gameState.crewAssignments).forEach(([taskId, crew]) => {
      if (crew) {
        if (taskId === 'rest') {
          restingWorkers += crew.size;
        } else {
          workingWorkers += crew.size;
          // Calculate how many tired and rested workers are working
          const availableRested = Math.min(gameState.workerStatus.rested, crew.size);
          workingRestedWorkers += availableRested;
          workingTiredWorkers += crew.size - availableRested;
        }
      }
    });

    console.log('Worker allocation:', {
      restingWorkers,
      workingWorkers,
      workingTiredWorkers,
      workingRestedWorkers,
      currentStatus: gameState.workerStatus
    });

    // Calculate resource changes using current state
    const resourceChanges = calculateResourceChanges(
      gameState.crewAssignments, 
      gameState,
      { workingTiredWorkers }
    );

    setGameState(prev => {
      // Calculate new resource values
      const newResources = {
        food: Math.max(0, Math.min(gameConfig.resources.food.max, prev.resources.food + resourceChanges.food)),
        wood: Math.max(0, Math.min(gameConfig.resources.wood.max, prev.resources.wood + resourceChanges.wood)),
        health: Math.max(0, Math.min(gameConfig.health.max, prev.resources.health + resourceChanges.health)),
        morale: Math.max(0, Math.min(gameConfig.morale.max, prev.resources.morale + resourceChanges.morale))
      };

      // Calculate mortality based on new resource values
      const { deaths, mortalityMessages } = calculateMortality(newResources);

      // If there were deaths, update survivors
      const newSurvivors = Math.max(0, prev.survivors.current - deaths);

      // Calculate new worker status for next round
      const newWorkerStatus = deaths > 0 ? {
        tired: 0,
        rested: newSurvivors
      } : {
        tired: workingWorkers,  // All who worked become tired
        rested: restingWorkers  // All who rested become rested
      };

      console.log('New worker status:', newWorkerStatus);

      // Store mortality info in state for useEffect to handle
      const mortalityInfo = deaths > 0 ? {
        previousCount: prev.survivors.current,
        newCount: newSurvivors,
        messages: mortalityMessages
      } : null;

      return {
        ...prev,
        currentRound: prev.currentRound + 1,
        phase: GAME_PHASES.STRATEGY,
        resources: newResources,
        survivalStatus: {
          boat: Math.max(0, Math.min(gameConfig.survivalTracker.boat.max, 
            prev.survivalStatus.boat + resourceChanges.boat)),
          shelter: Math.max(0, Math.min(gameConfig.survivalTracker.shelter.max, 
            prev.survivalStatus.shelter + resourceChanges.shelter)),
          foodStorage: Math.max(0, Math.min(gameConfig.survivalTracker.foodStorage.max,
            prev.survivalStatus.foodStorage + (resourceChanges.foodStorage || 0)))
        },
        crewAssignments: {},
        survivors: {
          current: newSurvivors,
          total: newSurvivors
        },
        mortalityInfo,
        workerStatus: newWorkerStatus
      };
    });
  };

  // Add this useEffect to handle mortality events
  useEffect(() => {
    if (gameState.mortalityInfo) {
      window.dispatchEvent(new CustomEvent('survivorsUpdated', {
        detail: gameState.mortalityInfo
      }));
      
      // Clear the mortality info
      setGameState(prev => ({
        ...prev,
        mortalityInfo: null
      }));
    }
  }, [gameState.mortalityInfo]);

  const value = {
    ...gameState,
    setCrewAssignment,
    removeCrewAssignment,
    resolveRound,
    setStrategy: (strategy) => setGameState(prev => ({ ...prev, strategy, phase: GAME_PHASES.TACTICS })),
    setPhase: (phase) => setGameState(prev => ({ ...prev, phase }))
  };

  return (
    <AntarcticaContext.Provider value={value}>
      {children}
    </AntarcticaContext.Provider>
  );
}

export const useAntarctica = () => {
  const context = useContext(AntarcticaContext);
  if (!context) {
    throw new Error('useAntarctica must be used within an AntarcticaProvider');
  }
  return context;
};

// Helper function to calculate resource changes based on crew assignments
function calculateResourceChanges(assignments, currentState, { workingTiredWorkers }) {
  const changes = {
    food: 0,
    wood: 0,
    health: 0,
    morale: 0,
    boat: 0,
    shelter: 0,
    foodStorage: 0
  };

  console.log('Starting calculations for round:', currentState.currentRound);
  console.log('Current state:', currentState);
  console.log('Current assignments:', assignments);

  // Process task effects first
  Object.entries(assignments).forEach(([taskId, crew]) => {
    if (!crew) return;

    // Food gathering task
    if (taskId === 'food') {
      const foodPerWorker = gameConfig.tasks.food.foodGathering.foodPerWorker;
      const maxFoodPerRound = gameConfig.tasks.food.foodGathering.maxFoodPerRound;
      const foodGathered = Math.min(
        crew.size * foodPerWorker,
        maxFoodPerRound
      );
      changes.food += foodGathered;
      
      console.log('Food gathering:', {
        crewSize: crew.size,
        foodPerWorker,
        maxFoodPerRound,
        foodGathered
      });
    }

    // Wood gathering task
    if (taskId === 'wood') {
      const woodPerWorker = gameConfig.tasks.wood.woodGathering.woodPerWorker;
      const maxWoodPerRound = gameConfig.tasks.wood.woodGathering.maxWoodPerRound;
      const woodGathered = Math.min(
        crew.size * woodPerWorker,
        maxWoodPerRound
      );
      changes.wood += woodGathered;
      
      console.log('Wood gathering:', {
        crewSize: crew.size,
        woodPerWorker,
        maxWoodPerRound,
        woodGathered
      });
    }

    // Heal task
    if (taskId === 'heal') {
      const healthPerWorker = gameConfig.tasks.heal.healing.healthPerWorker;
      const maxHealthPerRound = gameConfig.tasks.heal.healing.maxHealthPerRound;
      const healthGained = Math.min(
        crew.size * healthPerWorker,
        maxHealthPerRound
      );
      changes.health += healthGained;

      // Food cost for healing
      const foodPerWorker = gameConfig.tasks.heal.foodCost.foodPerWorker;
      const maxFoodCost = gameConfig.tasks.heal.foodCost.maxFoodCostPerRound;
      const foodCost = Math.max(
        crew.size * foodPerWorker,
        maxFoodCost
      );
      changes.food += foodCost;

      console.log('Healing:', {
        crewSize: crew.size,
        healthGained,
        foodCost
      });
    }

    // Shelter task
    if (taskId === 'shelter') {
      const buildPerWorker = gameConfig.tasks.shelter.shelterBuild.buildPerWorker;
      const maxBuildPerRound = gameConfig.tasks.shelter.shelterBuild.maxBuildPerRound;
      const shelterBuilt = Math.min(
        crew.size * buildPerWorker,
        maxBuildPerRound
      );
      changes.shelter += shelterBuilt;
      
      // Wood cost
      const woodPerWorker = gameConfig.tasks.shelter.woodCost.woodPerWorker;
      const maxWoodCost = gameConfig.tasks.shelter.woodCost.maxWoodCostPerRound;
      const woodCost = Math.max(
        crew.size * woodPerWorker,
        maxWoodCost
      );
      changes.wood += woodCost;

      console.log('Shelter building:', {
        crewSize: crew.size,
        shelterBuilt,
        woodCost
      });
    }

    // Repair boat task
    if (taskId === 'repair') {
      const woodNeeded = Math.abs(crew.size * gameConfig.tasks.repair.woodCost.woodPerWorker);
      
      // Check if we have enough wood
      if (changes.wood + woodNeeded + currentState.resources.wood >= 0) {
        // If we have wood, repair boat and consume wood
        const repairPerWorker = gameConfig.tasks.repair.boatRepair.repairPerWorker;
        const maxRepairPerRound = gameConfig.tasks.repair.boatRepair.maxRepairPerRound;
        const boatRepair = Math.min(
          crew.size * repairPerWorker,
          maxRepairPerRound
        );
        
        changes.boat += boatRepair;
        changes.wood -= woodNeeded;

        console.log('Boat repair:', {
          crewSize: crew.size,
          boatRepair,
          woodNeeded
        });
      } else {
        // If we don't have wood, damage shelter instead
        const damagePerWorker = gameConfig.tasks.repair.shelterFallback.damagePerWorker;
        const maxDamagePerRound = gameConfig.tasks.repair.shelterFallback.maxDamagePerRound;
        const shelterDamage = Math.max(
          crew.size * damagePerWorker,
          maxDamagePerRound
        );
        
        changes.shelter += shelterDamage;

        console.log('Shelter damage (no wood):', {
          crewSize: crew.size,
          shelterDamage
        });
      }
    }

    // Store food task
    if (taskId === 'store') {
      const foodNeeded = Math.abs(crew.size * gameConfig.tasks.store.foodCost.foodPerWorker);
      
      // Check if we have enough food to store
      if (changes.food + foodNeeded + currentState.resources.food >= 0) {
        changes.food -= foodNeeded;
        // Use the new storage config values
        const storagePerWorker = gameConfig.tasks.store.foodStorage.storagePerWorker;
        const maxStoragePerRound = gameConfig.tasks.store.foodStorage.maxStoragePerRound;
        const storageGained = Math.min(
          crew.size * storagePerWorker,
          maxStoragePerRound
        );
        changes.foodStorage = storageGained;

        console.log('Food storage:', {
          crewSize: crew.size,
          foodCost: foodNeeded,
          storageGained
        });
      } else {
        // If not enough food, decrease morale instead
        const moralePerWorker = gameConfig.tasks.store.moraleFallback.moralePerWorker;
        const maxMoraleCost = gameConfig.tasks.store.moraleFallback.maxMoraleCostPerRound;
        const moraleLoss = Math.max(
          crew.size * moralePerWorker,
          maxMoraleCost
        );
        changes.morale += moraleLoss;

        console.log('Morale loss (no food to store):', {
          crewSize: crew.size,
          moraleLoss
        });
      }
    }

    // Rest task
    if (taskId === 'rest') {
      // Health recovery
      const healthWorkersPerPoint = gameConfig.tasks.rest.healthRecovery.workersPerPoint;
      const maxHealthRecovery = gameConfig.tasks.rest.healthRecovery.maxRecoveryPerRound;
      const healthRecovery = Math.min(
        Math.floor(crew.size / healthWorkersPerPoint),
        maxHealthRecovery
      );
      changes.health += healthRecovery;

      // Morale recovery
      const moraleWorkersPerPoint = gameConfig.tasks.rest.moraleRecovery.workersPerPoint;
      const maxMoraleRecovery = gameConfig.tasks.rest.moraleRecovery.maxRecoveryPerRound;
      const moraleRecovery = Math.min(
        Math.floor(crew.size / moraleWorkersPerPoint),
        maxMoraleRecovery
      );
      changes.morale += moraleRecovery;

      console.log('Rest recovery:', {
        crewSize: crew.size,
        healthRecovery,
        moraleRecovery
      });
    }
  });

  // Calculate food consumption and starvation
  const totalWorkers = currentState.survivors.current;
  const workersPerFood = gameConfig.roundEffects.foodConsumption.workersPerFood;
  const foodNeeded = Math.ceil(totalWorkers / workersPerFood);
  const availableFood = currentState.resources.food + changes.food;

  if (availableFood >= foodNeeded) {
    changes.food -= foodNeeded;
  } else {
    // Use all available food
    changes.food -= availableFood;
    // Calculate missing food and apply health penalty
    const missingFood = foodNeeded - availableFood;
    const healthPenalty = missingFood * gameConfig.roundEffects.foodConsumption.healthFallback.healthPerMissingFood;
    changes.health += healthPenalty;

    console.log('Starvation effect:', {
      foodNeeded,
      availableFood,
      missingFood,
      healthPenalty
    });
  }

  // Calculate fatigue penalties
  const fatiguePenalties = calculateFatiguePenalties(assignments, currentState, { workingTiredWorkers });
  changes.health += fatiguePenalties.health;
  changes.morale += fatiguePenalties.morale;

  // Calculate shelter bonuses
  const projectedShelterLevel = currentState.survivalStatus.shelter + changes.shelter;
  const shelterBonusPoints = Math.floor(projectedShelterLevel / gameConfig.tasks.shelter.moraleBonus.percentagePerPoint);

  // Apply shelter bonuses
  const shelterMoraleBonus = shelterBonusPoints * gameConfig.tasks.shelter.moraleBonus.moralePerPoint;
  const shelterHealthBonus = shelterBonusPoints * gameConfig.tasks.shelter.healthBonus.healthPerPoint;
  changes.morale += shelterMoraleBonus;
  changes.health += shelterHealthBonus;

  console.log('Shelter bonuses:', {
    shelterLevel: projectedShelterLevel,
    bonusPoints: shelterBonusPoints,
    moraleBonus: shelterMoraleBonus,
    healthBonus: shelterHealthBonus
  });

  // Log final changes
  console.log('Final resource changes:', {
    initial: changes,
    currentResources: currentState.resources,
    afterChanges: {
      food: Math.max(0, Math.min(gameConfig.resources.food.max, 
        currentState.resources.food + changes.food)),
      wood: Math.max(0, Math.min(gameConfig.resources.wood.max, 
        currentState.resources.wood + changes.wood)),
      health: Math.max(0, Math.min(gameConfig.health.max, 
        currentState.resources.health + changes.health)),
      morale: Math.max(0, Math.min(gameConfig.morale.max, 
        currentState.resources.morale + changes.morale))
    }
  });

  return changes;
}

// Helper function to calculate fatigue penalties
function calculateFatiguePenalties(assignments, currentState, { workingTiredWorkers }) {
  const changes = {
    health: 0,
    morale: 0
  };

  console.log('Calculating fatigue penalties:', {
    currentRound: currentState.currentRound,
    workerStatus: currentState.workerStatus,
    workingTiredWorkers
  });

  // Only apply penalties to tired workers who are working
  if (workingTiredWorkers > 0) {
    changes.health = workingTiredWorkers * gameConfig.fatigue.penalties.health;
    changes.morale = workingTiredWorkers * gameConfig.fatigue.penalties.morale;
  }

  console.log('Fatigue calculation:', {
    workingTiredWorkers,
    penalties: changes
  });

  return changes;
}

// Add this function to calculate mortality
function calculateMortality(resources) {
  let deaths = 0;
  const mortalityMessages = [];

  // Check health-based mortality
  if (resources.health < gameConfig.mortality.healthThreshold) {
    deaths += 1;
    mortalityMessages.push(
      gameConfig.mortality.messages.healthLoss.replace('%d', '1')
    );
  }

  // Check morale-based mortality
  if (resources.morale < gameConfig.mortality.moraleThreshold) {
    deaths += 1;
    mortalityMessages.push(
      gameConfig.mortality.messages.moraleLoss.replace('%d', '1')
    );
  }

  return { deaths, mortalityMessages };
} 