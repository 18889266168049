import React, { useState, useEffect } from 'react';
import { playIntroMusic, fadeOutIntroMusic, stopAllSounds, playCharacterSelectSound } from '../../antarctica/utils/SoundEffects';
import captainImage from '../../antarctica/assets/images/captain.webp';
import medicImage from '../../antarctica/assets/images/medic.webp';

const MusicDemo = () => {
  const [selectedCharacters, setSelectedCharacters] = useState(new Set());
  const [isPlaying, setIsPlaying] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState([]);
  const [isFading, setIsFading] = useState(false);

  const messages = [
    "December 5th, 1914. Departing South Georgia Island...",
    "The ice is closing in around the Endurance...",
    "The ship is lost. We must survive on the ice...",
    "Making our way to Elephant Island...",
    "We shall not fail. We shall survive..."
  ];

  const handleStart = async () => {
    if (selectedCharacters.size !== 2) return;
    
    setIsPlaying(true);
    setIsFading(false);
    playIntroMusic();
    setLoadingMessages([]);

    // Show each message with 1 second delay
    for (let i = 0; i < messages.length; i++) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      setLoadingMessages(prev => [...prev, messages[i]]);
    }

    // After last message, wait 1 second then start fade
    await new Promise(resolve => setTimeout(resolve, 1000));
    setIsFading(true);
    
    // Start fading out music 1 second earlier than the visual fade
    setTimeout(() => fadeOutIntroMusic(), 3000);
  };

  const handleCharacterClick = (char) => {
    const newSelection = new Set(selectedCharacters);
    if (newSelection.has(char)) {
      newSelection.delete(char);
    } else {
      newSelection.add(char);
      playCharacterSelectSound();
    }
    setSelectedCharacters(newSelection);
  };

  useEffect(() => {
    return () => {
      stopAllSounds();
    };
  }, []);

  return (
    <div className="relative w-full h-full bg-game-dark/90 backdrop-blur-[1px] rounded-2xl overflow-hidden
                    flex items-center justify-center">
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(147,186,217,0.1),transparent_60%)]" />
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_80%,rgba(147,186,217,0.05),transparent_60%)]" />

      {!isPlaying ? (
        <div className="relative z-10 flex flex-col items-center gap-8 max-w-lg mx-auto">
          <p className="text-gray-400 text-sm font-['Space_Grotesk'] text-center">
            Select both characters to experience the background music
          </p>
          
          <div className="grid grid-cols-2 gap-8 w-full">
            {[
              { id: 1, image: captainImage },
              { id: 2, image: medicImage }
            ].map((char) => (
              <button
                key={char.id}
                onClick={() => handleCharacterClick(char.id)}
                className={`relative aspect-square rounded-xl overflow-hidden transition-all duration-300
                          group
                          ${selectedCharacters.has(char.id) 
                            ? 'ring-2 ring-game-accent scale-105' 
                            : 'ring-1 ring-white/10 hover:ring-white/30'}`}
              >
                <img 
                  src={char.image} 
                  alt={`Character ${char.id}`}
                  className="w-full h-full object-cover"
                />
                <div className={`absolute inset-0 bg-black/50 transition-opacity duration-300
                              ${selectedCharacters.has(char.id) ? 'opacity-0' : 'opacity-50 group-hover:opacity-30'}`} />
              </button>
            ))}
          </div>

          <button 
            onClick={handleStart}
            disabled={selectedCharacters.size !== 2}
            className={`relative px-8 py-3 rounded-lg overflow-hidden
                       text-white font-['Space_Grotesk'] transition-all duration-300
                       ${selectedCharacters.size !== 2 
                         ? 'bg-white/5 text-white/50 cursor-not-allowed' 
                         : 'bg-game-accent/20 hover:bg-game-accent/30 hover:-translate-y-1'}`}
          >
            <span>Play music</span>
          </button>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-full w-full text-white text-center px-4">
          <div className="flex flex-col gap-5 max-w-xl">
            {loadingMessages.map((message, index) => (
              <div 
                key={index}
                className={`text-lg font-['Crimson_Text'] text-shadow-[0_0_10px_rgba(147,186,217,0.5)]
                           opacity-0 animate-[fadeIn_1s_ease-out_forwards]`}
                style={{ 
                  animationDelay: `${index * 0.5}s`,
                  animationFillMode: 'forwards',
                  opacity: isFading ? 0.5 : 1,
                  transition: 'opacity 4s'
                }}
              >
                {message}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MusicDemo; 