import React from 'react';
import { X } from 'lucide-react';
import { GUIDE_STEPS } from './GuideConfig';
import './GuideOverlay.css';

const GuideOverlay = ({ isOpen, onClose, currentGuide }) => {
  if (!isOpen || !currentGuide) return null;

  const guide = GUIDE_STEPS[currentGuide];
  
  const getElementPosition = () => {
    const element = document.getElementById(guide.elementId);
    if (element) {
      const rect = element.getBoundingClientRect();
      console.log('Element position:', rect);
      return {
        x: rect.left,
        y: rect.top,
        width: rect.width,
        height: rect.height
      };
    }
    console.log('Using fallback position:', guide.position);
    return guide.position;
  };

  const position = getElementPosition();
  console.log('Final position used:', position);

  // Split the message text into lines and render each line separately
  const renderMessage = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        <span dangerouslySetInnerHTML={{ __html: line }} />
        {index < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return (
    <div className="guide-overlay">
      {/* Dark overlays around the highlighted element */}
      <div className="guide-overlay__backdrop-top" 
           style={{ height: position.y }} 
           onClick={onClose} />
      <div className="guide-overlay__backdrop-bottom" 
           style={{ top: position.y + position.height }} 
           onClick={onClose} />
      <div className="guide-overlay__backdrop-left" 
           style={{ 
             top: position.y,
             height: position.height,
             width: position.x 
           }} 
           onClick={onClose} />
      <div className="guide-overlay__backdrop-right" 
           style={{ 
             top: position.y,
             height: position.height,
             left: position.x + position.width 
           }} 
           onClick={onClose} />
      
      {/* Message box */}
      <div 
        className="guide-overlay__message" 
        style={{ 
          left: guide.message.position.x,
          top: guide.message.position.y,
          ...(guide.message.style || {})
        }}
      >
        <button onClick={onClose} className="guide-overlay__close">
          <X className="w-4 h-4" />
        </button>
        <p className="text-sm text-white whitespace-pre-line">
          {renderMessage(guide.message.text)}
        </p>
      </div>
    </div>
  );
};

export default GuideOverlay; 