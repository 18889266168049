import React, { useState, useEffect } from 'react';

const Avatar = ({ avatarFile }) => {
  const [avatarImage, setAvatarImage] = useState(null);

  useEffect(() => {
    const loadAvatar = async () => {
      try {
        const avatar = await import(`./media/avatars/${avatarFile}`);
        setAvatarImage(avatar.default);
      } catch (error) {
        console.log('Avatar not found:', avatarFile);
        setAvatarImage(null);
      }
    };

    if (avatarFile) {
      loadAvatar();
    }
  }, [avatarFile]);

  const getInitials = (name) => {
    if (!name) return '?';
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const stringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return '#' + ('00000' + (hash & 0xFFFFFF).toString(16)).slice(-6);
  };

  const backgroundColor = avatarFile ? stringToColor(avatarFile) : '#gray';

  if (avatarImage) {
    return (
      <div className="w-8 h-8 rounded-full overflow-hidden flex-shrink-0 flex items-center justify-center relative border border-[rgba(126,172,170,0.2)] shadow-sm">
        <img 
          src={avatarImage} 
          alt="Avatar"
          className="w-full h-full object-cover"
        />
      </div>
    );
  }

  return (
    <div 
      className="w-8 h-8 rounded-full overflow-hidden flex-shrink-0 flex items-center justify-center relative border border-[rgba(126,172,170,0.2)] shadow-sm text-white font-bold text-[10px]"
      style={{ backgroundColor }}
    >
      <span className="drop-shadow-sm">
        {getInitials(avatarFile)}
      </span>
    </div>
  );
};

export default Avatar;
