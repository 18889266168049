import React, { useEffect, useState, useCallback } from 'react';

const ScenarioConnectors = ({ selectedScenario }) => {
  const [lines, setLines] = useState([]);

  const calculateLines = useCallback(() => {
    if (!selectedScenario) {
      setLines([]);
      return;
    }

    // Get positions of all relevant cards
    const elements = {
      selectedItem: document.querySelector('.scenario-list-item.selected'),
      infoCard: document.querySelector('.scenario-info-card'),
      option1Card: document.querySelector('.option-1'),
      option2Card: document.querySelector('.option-2'),
      kpi1Card: document.querySelector('.scenario-kpi-column > .scenario-kpi-card:first-child'),
      kpi2Card: document.querySelector('.scenario-kpi-column > .scenario-kpi-card:last-child')
    };

    // Check if all elements exist
    if (!Object.values(elements).every(el => el)) return;

    // Calculate connector points
    const newLines = [
      // Preview to Info card
      {
        x1: elements.selectedItem.offsetLeft + elements.selectedItem.offsetWidth,
        y1: elements.selectedItem.offsetTop + elements.selectedItem.offsetHeight / 2,
        x2: elements.infoCard.offsetLeft,
        y2: elements.selectedItem.offsetTop + elements.selectedItem.offsetHeight / 2,
        color: '#3b82f6'
      },
      // Info to Options
      {
        x1: elements.infoCard.offsetLeft + elements.infoCard.offsetWidth,
        y1: elements.option1Card.offsetTop + elements.option1Card.offsetHeight / 2,
        x2: elements.option1Card.offsetLeft,
        y2: elements.option1Card.offsetTop + elements.option1Card.offsetHeight / 2,
        color: '#3b82f6'
      },
      {
        x1: elements.infoCard.offsetLeft + elements.infoCard.offsetWidth,
        y1: elements.option2Card.offsetTop + elements.option2Card.offsetHeight / 2,
        x2: elements.option2Card.offsetLeft,
        y2: elements.option2Card.offsetTop + elements.option2Card.offsetHeight / 2,
        color: '#3b82f6'
      },
      // Options to KPIs
      {
        x1: elements.option1Card.offsetLeft + elements.option1Card.offsetWidth,
        y1: elements.option1Card.offsetTop + elements.option1Card.offsetHeight / 2,
        x2: elements.kpi1Card.offsetLeft,
        y2: elements.option1Card.offsetTop + elements.option1Card.offsetHeight / 2,
        color: '#3b82f6'
      },
      {
        x1: elements.option2Card.offsetLeft + elements.option2Card.offsetWidth,
        y1: elements.option2Card.offsetTop + elements.option2Card.offsetHeight / 2,
        x2: elements.kpi2Card.offsetLeft,
        y2: elements.option2Card.offsetTop + elements.option2Card.offsetHeight / 2,
        color: '#3b82f6'
      }
    ];

    setLines(newLines);
  }, [selectedScenario]);

  useEffect(() => {
    // Initial calculation
    const timeoutId = setTimeout(calculateLines, 100);

    // Add resize listener
    const handleResize = () => {
      requestAnimationFrame(calculateLines);
    };
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', handleResize);
    };
  }, [calculateLines]);

  return (
    <svg 
      className="scenario-connectors" 
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        zIndex: 1
      }}
    >
      {lines.map((line, index) => (
        <line
          key={index}
          x1={line.x1}
          y1={line.y1}
          x2={line.x2}
          y2={line.y2}
          stroke={line.color}
          strokeWidth="2"
          opacity="0.5"
          strokeDasharray="1000"
        >
          <animate
            attributeName="strokeDashoffset"
            from="1000"
            to="0"
            dur="0.5s"
            fill="freeze"
          />
        </line>
      ))}
    </svg>
  );
};

export default ScenarioConnectors;