import React, { useState, useEffect } from 'react';
import { useAntarctica } from '../context/AntarcticaContext';
import StrategyChoice from '../components/StrategyChoice';
import Tactics from '../components/Tactics';
import RoundResolution from '../components/RoundResolution';
import WorkerParticles from '../components/WorkerParticles';
import CrewMessages from '../components/CrewMessages';
import './css/GameArea.css';

function GameArea({ selectedCharacterId, selectedStrategy, onStrategySelect }) {
  const { resolveRound } = useAntarctica();
  const [showTactics, setShowTactics] = useState(false);
  const [showResolution, setShowResolution] = useState(false);
  const [showParticles, setShowParticles] = useState(false);
  const [showMessages, setShowMessages] = useState(false);

  useEffect(() => {
    const handleStrategyConfirm = () => {
      setShowTactics(true);
      setShowResolution(false);
      setShowParticles(false);
      setShowMessages(false);
    };

    const handleRoundFinished = () => {
      setShowTactics(false);
      setShowResolution(true);
      setShowParticles(false);
      setShowMessages(false);
    };

    const handleTasksDelegated = () => {
      setShowParticles(true);
      setTimeout(() => {
        setShowParticles(false);
      }, 10000);
    };

    const handleResolvingStart = () => {
      setShowMessages(true);
      setTimeout(() => {
        setShowMessages(false);
      }, 10000);
    };

    window.addEventListener('strategyConfirmed', handleStrategyConfirm);
    window.addEventListener('roundFinished', handleRoundFinished);
    window.addEventListener('tasksDelegated', handleTasksDelegated);
    window.addEventListener('resolvingStart', handleResolvingStart);
    
    return () => {
      window.removeEventListener('strategyConfirmed', handleStrategyConfirm);
      window.removeEventListener('roundFinished', handleRoundFinished);
      window.removeEventListener('tasksDelegated', handleTasksDelegated);
      window.removeEventListener('resolvingStart', handleResolvingStart);
    };
  }, []);

  const handleResolutionClose = () => {
    setShowResolution(false);
    setShowTactics(false);
    setShowParticles(false);
    setShowMessages(false);
    resolveRound();
  };

  return (
    <div className="antarctica-game-area">
      {showParticles && <WorkerParticles isActive={true} />}
      {showMessages && <CrewMessages isActive={true} />}
      {showResolution ? (
        <RoundResolution onClose={handleResolutionClose} />
      ) : showTactics ? (
        <Tactics 
          selectedCharacterId={selectedCharacterId}
          strategy={selectedStrategy}
        />
      ) : (
        <StrategyChoice onSelect={onStrategySelect} />
      )}
    </div>
  );
}

export default GameArea; 