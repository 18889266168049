import React, { useState } from 'react';
import Metric from '../game-elements/Metric';
import { useSimulation } from '../../ai-gone-rogue/SimulationContext';
import { HelpCircle, BookOpen } from 'lucide-react';
import GameIntroModal from '../game-elements/GameIntroModal';
import GuideOverlay from '../game-elements/GuideOverlay';
import { GUIDE_SEQUENCE } from '../game-elements/GuideConfig';
import './TopBar.css';

function TopBar() {
  const { scores } = useSimulation();
  const [showHelp, setShowHelp] = useState(false);
  const [showGuide, setShowGuide] = useState(false);
  const [currentGuideStep, setCurrentGuideStep] = useState(0);
  const [hasSeenGuide, setHasSeenGuide] = useState(() => {
    return localStorage.getItem('hasSeenGuide') === 'true'
  });

  const handleGuideClick = () => {
    setShowGuide(true);
    setCurrentGuideStep(0);
    if (!hasSeenGuide) {
      setHasSeenGuide(true);
      localStorage.setItem('hasSeenGuide', 'true');
    }
  };

  const handleGuideClose = () => {
    if (currentGuideStep < GUIDE_SEQUENCE.length - 1) {
      setCurrentGuideStep(prev => prev + 1);
    } else {
      setShowGuide(false);
      setCurrentGuideStep(0);
    }
  };

  return (
    <div className="top-bar">
      <div id="metrics-container" className="metrics-container">
        {Object.values(scores).map(kpi => (
          <Metric key={kpi.id} kpi={kpi} />
        ))}
      </div>
      
      {/* Guide Button */}
      <button
        onClick={handleGuideClick}
        className={`p-2 rounded-full 
                 bg-black/20 hover:bg-black/40 
                 backdrop-blur-sm
                 border border-white/10 hover:border-white/20
                 text-white/50 hover:text-white
                 transition-all duration-300
                 absolute right-14
                 ${!hasSeenGuide ? 'animate-guide-pulse' : ''}`}
      >
        <BookOpen className="w-5 h-5" />
      </button>

      {/* Help Button */}
      <button
        onClick={() => setShowHelp(true)}
        className="p-2 rounded-full 
                 bg-black/20 hover:bg-black/40 
                 backdrop-blur-sm
                 border border-white/10 hover:border-white/20
                 text-white/50 hover:text-white
                 transition-all duration-300
                 absolute right-4"
      >
        <HelpCircle className="w-5 h-5" />
      </button>

      <GameIntroModal 
        isOpen={showHelp}
        onClose={() => setShowHelp(false)}
      />

      <GuideOverlay 
        isOpen={showGuide}
        onClose={handleGuideClose}
        currentGuide={GUIDE_SEQUENCE[currentGuideStep]}
      />
    </div>
  );
}

export default TopBar; 