import React, { useEffect } from 'react';
import { useAntarctica } from '../context/AntarcticaContext';
import gameContent from '../content/game-content.json';
import repairBoatImage from '../assets/images/repair-boat.webp';
import shelterImage from '../assets/images/task-shelter.webp';
import restImage from '../assets/images/task-rest.webp';
import foodImage from '../assets/images/task-food.webp';
import woodImage from '../assets/images/task-wood.webp';
import healthImage from '../assets/images/task-health.webp';
import storeFoodImage from '../assets/images/task-store-food.webp';
import sailAwayImage from '../assets/images/task-sail-away.webp';
import exploreImage from '../assets/images/task-explore.webp';
import './Tasks.css';

// Create an object to map task imageKeys to their imported images
const taskImages = {
  'repair-boat': repairBoatImage,
  'shelter': shelterImage,
  'task-rest': restImage,
  'task-food': foodImage,
  'task-wood': woodImage,
  'task-health': healthImage,
  'task-store-food': storeFoodImage,
  'task-sail-away': sailAwayImage,
  'task-explore': exploreImage
};

function Tasks({ strategy = 'survive' }) {
  const { 
    currentRound,
    crewAssignments,
    setCrewAssignment,
    removeCrewAssignment
  } = useAntarctica();

  useEffect(() => {
    // Count how many crews are currently assigned to tasks
    const assignedCrewsCount = Object.values(crewAssignments).filter(crew => crew !== null).length;
    
    document.dispatchEvent(new CustomEvent('crewAssignmentsChanged', {
      detail: {
        assignedCrewsCount
      }
    }));
  }, [crewAssignments]);

  const handleDrop = (e, taskId) => {
    e.preventDefault();
    try {
      const crew = JSON.parse(e.dataTransfer.getData('application/json'));
      setCrewAssignment(taskId, crew);
      document.dispatchEvent(new CustomEvent('crewDropped'));
    } catch (error) {
      console.error('Error parsing crew data:', error);
    }
  };

  const handleRemoveCrew = (taskId) => {
    const crew = crewAssignments[taskId];
    if (crew) {
      const event = new CustomEvent('crewRemoved', {
        detail: crew,
        bubbles: true
      });
      document.dispatchEvent(event);
      removeCrewAssignment(taskId);
    }
  };

  const tasks = gameContent.tasks[strategy].map(task => ({
    ...task,
    locked: task.id === 'sail' && currentRound < (task.requiresRound || 0),
    // Use the actual image from our taskImages mapping
    image: taskImages[task.imageKey] || 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgZmlsbD0iIzFiM2I1MCIvPjwvc3ZnPg=='
  }));

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  return (
    <div className="tasks">
      {tasks.map((task) => (
        <div
          key={task.id}
          className={`task ${task.locked ? 'task--locked' : ''}`}
        >
          <div className="task__content">
            <div className="task__top-section">
              <div className="task__image-container">
                <img src={task.image} alt={task.title} className="task__image" />
                <div className="task__image-overlay" />
              </div>
              <div className="task__header">
                <h3 className="task__title">{task.title}</h3>
                <div className="task__effects">{task.effects}</div>
              </div>
            </div>
            <div className="task__info">
              <div 
                className="task__drop-zone"
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, task.id)}
              >
                <div className="task__drop-zone-inner">
                  {crewAssignments[task.id] ? (
                    <div 
                      className="task__assigned-crew"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveCrew(task.id);
                      }}
                      title="Click to remove crew"
                    >
                      {crewAssignments[task.id].size}
                      <div className="task__remove-crew">×</div>
                    </div>
                  ) : (
                    <div className="task__drop-zone-placeholder">
                      Assign Crew
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="task__frost" />
        </div>
      ))}
    </div>
  );
}

export default Tasks;