import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { X, FileText, Clock, Calendar, TrendingUp, TrendingDown } from 'lucide-react';

const ScenarioResultModal = ({ open, handleClose, scenario, selectedOption }) => {
  if (!scenario || !selectedOption) return null;

  const totalShortTermImpact = Object.values(selectedOption.kpiImpacts.shortTerm).reduce((a, b) => a + b, 0);
  const totalLongTermImpact = Object.values(selectedOption.kpiImpacts.longTerm).reduce((a, b) => a + b, 0);

  const formatMessage = (content) => {
    const parts = content.split('\n');
    const messageContent = parts.slice(1, -2);
    return messageContent.map((part, index) => <p key={index}>{part}</p>);
  };

  return (
    <Dialog.Root open={open} onOpenChange={handleClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/80 animate-overlay-show backdrop-blur-sm" />
        <Dialog.Content className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90vw] max-w-[500px] max-h-[85vh] bg-gray-900 rounded-xl shadow-2xl border border-gray-800 p-3 animate-content-show overflow-y-auto">
          <Dialog.Title className="flex items-center gap-2 text-sm font-bold text-gray-200 mb-3 pb-2 border-b border-gray-800">
            <FileText className="w-3 h-3 text-emerald-400" />
            Transaction {scenario.id.toString().padStart(3, '0')} Summary
          </Dialog.Title>
          
          <div className="grid grid-cols-2 gap-3">
            {/* Left Column - Your Decision */}
            <div className="bg-gray-800 rounded-lg p-2 border border-gray-700">
              <h3 className="text-xs font-bold text-gray-200 mb-2">Your Decision</h3>
              <div className="text-[10px] text-gray-300 leading-relaxed space-y-2">
                {formatMessage(selectedOption.reply)}
              </div>
            </div>

            {/* Right Column - Impacts */}
            <div className="space-y-3">
              <div className="bg-gray-800 rounded-lg p-2 border border-gray-700">
                <h3 className="text-xs font-bold text-emerald-400 mb-2 flex items-center gap-1">
                  <Clock className="w-3 h-3" />
                  Short Term Impact
                </h3>
                <p className="text-[10px] text-gray-300 leading-relaxed mb-2">
                  {selectedOption.shortTermImpact}
                </p>
                <div className={`inline-flex items-center gap-1 px-2 py-1 rounded-lg text-[10px] font-medium
                  ${totalShortTermImpact >= 0 
                    ? 'bg-emerald-500/10 text-emerald-400' 
                    : 'bg-red-500/10 text-red-400'}`}
                >
                  {totalShortTermImpact >= 0 ? <TrendingUp className="w-3 h-3" /> : <TrendingDown className="w-3 h-3" />}
                  {totalShortTermImpact > 0 ? '+' : ''}{totalShortTermImpact}
                </div>
              </div>

              <div className="bg-gray-800 rounded-lg p-2 border border-gray-700">
                <h3 className="text-xs font-bold text-blue-400 mb-2 flex items-center gap-1">
                  <Calendar className="w-3 h-3" />
                  Long Term Impact
                </h3>
                <p className="text-[10px] text-gray-300 leading-relaxed mb-2">
                  {selectedOption.longTermImpact}
                </p>
                <div className={`inline-flex items-center gap-1 px-2 py-1 rounded-lg text-[10px] font-medium
                  ${totalLongTermImpact >= 0 
                    ? 'bg-emerald-500/10 text-emerald-400' 
                    : 'bg-red-500/10 text-red-400'}`}
                >
                  {totalLongTermImpact >= 0 ? <TrendingUp className="w-3 h-3" /> : <TrendingDown className="w-3 h-3" />}
                  {totalLongTermImpact > 0 ? '+' : ''}{totalLongTermImpact}
                </div>
              </div>
            </div>
          </div>

          <Dialog.Close asChild>
            <button 
              className="absolute top-2 right-2 w-6 h-6 rounded-lg flex items-center justify-center text-gray-400 hover:text-gray-200 hover:bg-gray-800 transition-all duration-200"
              aria-label="Close"
            >
              <X size={14} />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ScenarioResultModal; 