import React from 'react';

function GameArea() {
  return (
    <div className="flex-1 bg-cyan-900 p-6">
      {/* Game area content */}
    </div>
  );
}

export default GameArea; 