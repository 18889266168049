// Define all possible KPI types that can be impacted
export const KPI_TYPES = {
    BANK_BALANCE: 'bank_balance',
    MARKET_CONFIDENCE: 'market_confidence',
    RISK_LEVEL: 'risk_level',
    COMPLIANCE_SCORE: 'compliance_score'
  };
  
  // Define flag types
  export const FLAG_TYPES = {
    RED: 'red',
    GREEN: 'green'
  };
  
  // Define importance levels
  export const IMPORTANCE_LEVELS = {
    HIGH: 'HIGH',
    LOW: 'LOW'
  };
  
  // Define growth periods and thresholds
  export const GROWTH_PERIODS = {
    SHORT: 'short',
    MEDIUM: 'medium',
    LONG: 'long'
  };
  
  // Define market impacts
  export const MARKET_IMPACTS = {
    POSITIVE: 'positive',
    NEGATIVE: 'negative'
  };
  
  // Add these to your existing constants
  export const MARKET_CONFIDENCE = {
    MIN_VALUE: 0,
    MAX_VALUE: 100,
    INITIAL_VALUE: 40,
    THRESHOLDS: {
      DANGER: 50,
      WARNING: 65,
      SUCCESS: 80
    }
  };