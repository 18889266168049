import React from 'react';
import NavigationStrip from '../components/navigation/NavigationStrip';
import { HelpCircle, Volume2, VolumeX } from 'lucide-react';

const NavigationContainer = ({ 
  onScenarioSelect, 
  isMuted, 
  toggleMute, 
  onHelpClick, 
  visitedGroups = [],
  currentScenarioId
}) => {
  return (
    <div className="h-full flex flex-col bg-gray-900 w-full">
      <div className="h-[calc(100%-40px)]">
        <NavigationStrip 
          onScenarioSelect={onScenarioSelect} 
          visitedGroups={visitedGroups}
          currentScenarioId={currentScenarioId}
        />
      </div>
      
      <div className="h-[40px] flex-shrink-0 border-t border-gray-800 flex items-center justify-center">
        <div className="flex gap-1">
          <button 
            className="w-6 h-6 bg-gray-800 rounded-lg flex items-center justify-center cursor-pointer text-gray-400 hover:text-gray-200 transition-all duration-200"
            onClick={onHelpClick}
            aria-label="Help"
          >
            <HelpCircle size={14} />
          </button>
          <button 
            className="w-6 h-6 bg-gray-800 rounded-lg flex items-center justify-center cursor-pointer text-gray-400 hover:text-gray-200 transition-all duration-200"
            onClick={toggleMute}
            aria-label={isMuted ? "Unmute" : "Mute"}
          >
            {isMuted ? <VolumeX size={14} /> : <Volume2 size={14} />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavigationContainer;
