import React, { useState, useEffect } from 'react';
import RoundTracker from '../components/RoundTracker';
import Survivors from '../components/Survivors';
import Health from '../components/Health';
import Morale from '../components/Morale';
import StrategyIndicator from '../components/StrategyIndicator';
import './css/TopBar.css';

function TopBar() {
  const [confirmedStrategy, setConfirmedStrategy] = useState(null);

  useEffect(() => {
    const handleStrategyConfirm = (event) => {
      setConfirmedStrategy(event.detail.strategy);
    };

    window.addEventListener('strategyConfirmed', handleStrategyConfirm);
    return () => window.removeEventListener('strategyConfirmed', handleStrategyConfirm);
  }, []);

  return (
    <div className="antarctica-top-bar">
      <div className="antarctica-top-bar__content">
        <div className="antarctica-top-bar__left">
          <RoundTracker />
        </div>
        <div className="antarctica-top-bar__center">
          <Survivors />
          <div className="antarctica-top-bar__separator" />
          <Health />
          <div className="antarctica-top-bar__separator" />
          <Morale />
        </div>
        {confirmedStrategy && (
          <div className="antarctica-top-bar__right">
            <StrategyIndicator strategy={confirmedStrategy} />
          </div>
        )}
      </div>
    </div>
  );
}

export default TopBar; 