import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { AlertCircle, BadgeCheck, Target, Shield, ChartBar } from 'lucide-react';
import woman4 from '../scenarios/media/avatars/woman4.png';
import man5 from '../scenarios/media/avatars/man5.png';

const StartScreen = ({ onStart }) => {
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [officerName, setOfficerName] = useState('');
  const [step, setStep] = useState(1); // 1: Character Selection, 2: Welcome Message
  const [scale, setScale] = useState(1);

  useEffect(() => {
    const calculateScale = () => {
      // Base size for minimum supported resolution (1024x665)
      const BASE_WIDTH = 1024;
      const BASE_HEIGHT = 665;
      
      // Calculate scale based on viewport
      const scaleX = window.innerWidth / BASE_WIDTH;
      const scaleY = window.innerHeight / BASE_HEIGHT;
      
      // Use the smaller scale to maintain aspect ratio
      const scale = Math.min(scaleX, scaleY);
      
      // Allow more upscaling (2.0 instead of 1.5) since we're starting from a smaller base
      // Minimum scale is now 1.0 since we're using the smallest resolution as base
      const clampedScale = Math.min(Math.max(scale, 1.0), 2.0);
      
      setScale(clampedScale);
    };

    calculateScale();
    window.addEventListener('resize', calculateScale);
    return () => window.removeEventListener('resize', calculateScale);
  }, []);

  // Add keyboard handler
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        // Only trigger if button would be enabled
        if (step === 2 || (step === 1 && selectedAvatar && officerName.trim())) {
          handleContinue();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [step, selectedAvatar, officerName]); // Dependencies for the effect

  const avatars = [
    { 
      id: 'man5', 
      image: man5,
      role: 'Senior AML Analyst'
    },
    { 
      id: 'woman4', 
      image: woman4,
      role: 'Lead Compliance Officer'
    }
  ];

  const welcomeSections = [
    {
      icon: <BadgeCheck className="w-5 h-5" />,
      title: "Position",
      content: "Congratulations on your appointment as the new Compliance Officer in our Anti-Money Laundering Division."
    },
    {
      icon: <Target className="w-5 h-5" />,
      title: "Mission",
      content: "Your role is crucial in protecting our institution from financial crime and maintaining the integrity of the global banking system."
    },
    {
      icon: <Shield className="w-5 h-5" />,
      title: "Responsibilities",
      list: [
        "Review suspicious transaction reports",
        "Analyze client risk patterns",
        "Make critical compliance decisions",
        "Balance regulatory requirements with efficiency"
      ]
    }
  ];

  const handleContinue = () => {
    if (step === 1 && selectedAvatar && officerName.trim()) {
      setStep(2);
    } else if (step === 2) {
      localStorage.setItem('userName', officerName.trim());
      localStorage.setItem('userAvatar', selectedAvatar);
      onStart();
    }
  };

  return (
    <div className="fixed inset-0 bg-black/90 backdrop-blur-sm z-50 flex items-center justify-center">
      <div 
        className="relative"
        style={{ 
          transform: `scale(${scale})`,
          transformOrigin: 'center center',
          width: '600px',
        }}
      >
        <motion.div 
          className="w-full bg-gray-900 rounded-xl shadow-2xl border border-gray-800 p-3"
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.2 }}
        >
          <AnimatePresence mode="wait">
            {step === 1 ? (
              <motion.div
                key="step1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="space-y-4"
              >
                <div className="flex items-center gap-1.5 mb-3">
                  <div className="bg-emerald-600/20 p-1 rounded-lg">
                    <AlertCircle className="w-3.5 h-3.5 text-emerald-400" />
                  </div>
                  <div>
                    <h2 className="text-lg font-bold text-gray-200">Dark Money</h2>
                    <p className="text-[10px] text-gray-500">Anti-Money Laundering Division</p>
                  </div>
                </div>
                
                <div className="space-y-3">
                  <label className="block text-gray-300 text-xs font-medium">
                    Select Your Character
                  </label>
                  <div className="grid grid-cols-2 gap-2">
                    {avatars.map((avatar) => (
                      <motion.button
                        key={avatar.id}
                        className={`flex flex-col items-center gap-1.5 p-2 rounded-lg border ${
                          selectedAvatar === avatar.id
                            ? 'bg-emerald-600/20 border-emerald-500'
                            : 'bg-gray-800 border-gray-700 hover:border-gray-600'
                        }`}
                        onClick={() => setSelectedAvatar(avatar.id)}
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        <img 
                          src={avatar.image} 
                          alt={avatar.role} 
                          className="w-12 h-12 rounded-full border-2 border-gray-700"
                        />
                        <div className="text-center">
                          <div className={`text-[10px] ${
                            selectedAvatar === avatar.id ? 'text-emerald-400' : 'text-gray-400'
                          }`}>
                            {avatar.role}
                          </div>
                        </div>
                      </motion.button>
                    ))}
                  </div>
                </div>

                {selectedAvatar && (
                  <div className="space-y-2 animate-in fade-in duration-500">
                    <label className="block text-gray-300 text-xs font-medium">
                      Name Your Character
                    </label>
                    <input
                      type="text"
                      value={officerName}
                      onChange={(e) => setOfficerName(e.target.value)}
                      className="w-full bg-gray-800 border border-gray-700 rounded-lg px-3 py-1.5 text-xs text-gray-200 focus:outline-none focus:border-emerald-500"
                      placeholder="Enter character name"
                      autoFocus
                    />
                  </div>
                )}
              </motion.div>
            ) : (
              <motion.div
                key="step2"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="space-y-2 mb-3"
              >
                <div className="flex items-center gap-2 mb-3">
                  <div className="bg-emerald-600/20 p-1.5 rounded-lg">
                    <BadgeCheck className="w-4 h-4 text-emerald-400" />
                  </div>
                  <div>
                    <h2 className="text-xl font-bold text-emerald-400">Welcome, Officer {officerName}!</h2>
                    <p className="text-xs text-gray-400">Your career begins today</p>
                  </div>
                </div>
                
                <div className="space-y-2 mb-3">
                  {welcomeSections.map((section, index) => (
                    <motion.div
                      key={section.title}
                      className="bg-gray-800 rounded-lg p-2 border border-gray-700"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                    >
                      <div className="flex items-center gap-1.5 mb-1">
                        <div className="bg-emerald-600/20 p-1 rounded-lg">
                          {React.cloneElement(section.icon, { className: "w-2.5 h-2.5" })}
                        </div>
                        <h3 className="text-sm font-bold text-emerald-400">
                          {section.title}
                        </h3>
                      </div>
                      {section.content && (
                        <p className="text-[10px] text-gray-300 leading-relaxed">
                          {section.content}
                        </p>
                      )}
                      {section.list && (
                        <ul className="space-y-0.5 mt-1">
                          {section.list.map((item, i) => (
                            <li className="flex items-center gap-1 text-[10px] text-gray-300">
                              <span className="w-0.5 h-0.5 rounded-full bg-emerald-500" />
                              {item}
                            </li>
                          ))}
                        </ul>
                      )}
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          <motion.button
            onClick={handleContinue}
            className={`w-full mt-3 px-3 py-1.5 rounded-lg text-xs font-semibold transition-colors duration-200 ${
              step === 1 && (!selectedAvatar || !officerName.trim())
                ? 'bg-gray-700 text-gray-400 cursor-not-allowed'
                : 'bg-emerald-600 hover:bg-emerald-500 text-white'
            }`}
            whileHover={step === 2 || (selectedAvatar && officerName.trim()) ? { scale: 1.02 } : {}}
            whileTap={step === 2 || (selectedAvatar && officerName.trim()) ? { scale: 0.98 } : {}}
            disabled={step === 1 && (!selectedAvatar || !officerName.trim())}
            autoFocus={step === 2 || (selectedAvatar && officerName.trim())}
          >
            {step === 1 ? 'Continue' : 'Begin Investigation'}
          </motion.button>
        </motion.div>
      </div>
    </div>
  );
};

export default StartScreen; 