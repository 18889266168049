import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Shield, Users, Plus, ChevronDown, Printer } from 'lucide-react';
import { getInitialGames } from './components/data/gameData';
import QuoteDetailsForm from './QuoteDetailsForm';

// LicenseGameSelector component
export const LicenseGameSelector = ({ selectedGames, setSelectedGames }) => {
  const games = getInitialGames();

  const toggleGame = (gameId) => {
    if (selectedGames.find(g => g.id === gameId)) {
      setSelectedGames(selectedGames.filter(g => g.id !== gameId));
    } else {
      setSelectedGames([...selectedGames, { 
        id: gameId, 
        yearlyParticipants: 100
      }]);
    }
  };

  const updateParticipants = (gameId, count) => {
    setSelectedGames(selectedGames.map(game => 
      game.id === gameId ? { ...game, yearlyParticipants: count } : game
    ));
  };

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-4 gap-x-6 gap-y-8 max-w-2xl">
        {games.map((game) => {
          const selectedGame = selectedGames.find(g => g.id === game.id);
          const isSelected = !!selectedGame;

          return (
            <div key={game.id} className="relative h-32">
              <div className="flex flex-col items-center">
                <button
                  onClick={() => toggleGame(game.id)}
                  className={`group relative w-16 h-16 overflow-hidden rounded-xl
                    border-2 transition-all duration-200
                    ${isSelected
                      ? 'border-purple-500 shadow-lg shadow-purple-500/20'
                      : 'border-purple-500/20 hover:border-purple-500/50'
                    }`}
                >
                  {/* Game Image */}
                  <div className="absolute inset-0">
                    <img
                      src={game.image}
                      alt={game.title}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/40 via-transparent to-transparent" />
                  </div>

                  {/* Selected Overlay */}
                  {isSelected && (
                    <div className="absolute inset-0 bg-purple-500/20 backdrop-blur-[1px]" />
                  )}

                  {/* Selection Indicator */}
                  {isSelected && (
                    <div className="absolute top-1 right-1 w-1.5 h-1.5 rounded-full bg-purple-500" />
                  )}
                </button>
                
                {/* Game Title */}
                <p className="text-[10px] text-gray-400 font-['Space_Grotesk'] font-medium 
                             line-clamp-2 text-center w-full mt-2">
                  {game.title}
                </p>

                {/* Participants Input */}
                {isSelected && (
                  <div className="absolute bottom-0 left-0 right-0">
                    <input
                      type="number"
                      value={selectedGame.yearlyParticipants}
                      onChange={(e) => {
                        const value = Math.max(0, parseInt(e.target.value) || 0);
                        updateParticipants(game.id, value);
                      }}
                      className="w-full px-2 py-1 text-center text-xs bg-zinc-800/50 
                               border border-purple-500/20 rounded-sm text-white
                               focus:outline-none focus:border-purple-500/50
                               [appearance:textfield]
                               [&::-webkit-outer-spin-button]:appearance-none
                               [&::-webkit-inner-spin-button]:appearance-none"
                      placeholder="0"
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

// LicenseFloatingSummary component
export const LicenseFloatingSummary = ({ selectedGames }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showQuoteForm, setShowQuoteForm] = useState(false);
  const games = getInitialGames();

  const calculateLicensePrice = (yearlyParticipants) => {
    const baseLicensePrice = 4950; // Price for up to 500 participants
    const pricePerExtraUser = 5;

    if (yearlyParticipants <= 500) return baseLicensePrice;

    const additionalParticipants = yearlyParticipants - 500;
    return baseLicensePrice + (additionalParticipants * pricePerExtraUser);
  };

  const getMultiGameDiscount = (numberOfGames) => {
    if (numberOfGames <= 1) return 0;
    if (numberOfGames === 2) return 0.05; // 5% for 2 games
    if (numberOfGames === 3) return 0.10; // 10% for 3 games
    if (numberOfGames === 4) return 0.15; // 15% for 4 games
    return 0.20; // 20% for more than 4 games
  };

  const priceDetails = selectedGames.map(game => {
    const basePrice = calculateLicensePrice(game.yearlyParticipants);
    const multiGameDiscount = getMultiGameDiscount(selectedGames.length);
    const discountAmount = basePrice * multiGameDiscount;

    return {
      ...game,
      basePrice,
      discountAmount,
      finalPrice: basePrice - discountAmount,
      additionalPeople: Math.max(0, game.yearlyParticipants - 500)
    };
  });

  const totalPrice = priceDetails.reduce((sum, game) => sum + game.finalPrice, 0);

  const handlePrintSummary = () => {
    setShowQuoteForm(true);
  };

  const handleQuoteSubmit = (details) => {
    sessionStorage.setItem('quoteDetails', JSON.stringify(details));
    
    // Calculate totals
    const totalBeforeDiscount = priceDetails.reduce((sum, game) => sum + game.basePrice, 0);
    const totalDiscountAmount = priceDetails.reduce((sum, game) => sum + game.discountAmount, 0);
    
    const summaryData = {
      selectedGames,
      subtotal: totalBeforeDiscount,
      discount: getMultiGameDiscount(selectedGames.length),
      discountAmount: totalDiscountAmount,
      total: totalPrice
    };
    sessionStorage.setItem('summaryData', JSON.stringify(summaryData));
    window.open('/summary/license', '_blank');
    setShowQuoteForm(false);
  };

  if (selectedGames.length === 0) return null;

  return (
    <>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className="fixed bottom-8 right-8 z-50 w-[500px]"
      >
        <div className="bg-zinc-900 border border-game-accent/20 rounded-xl shadow-2xl 
                      shadow-purple-500/5 backdrop-blur-sm overflow-hidden">
          {/* Compact View */}
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="w-full p-4 flex items-center justify-between gap-6 
                       hover:bg-zinc-800/50 transition-colors duration-200"
          >
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-lg bg-purple-500/10 flex items-center justify-center">
                <Shield className="w-5 h-5 text-purple-500" />
              </div>
              <div className="text-left">
                <div className="text-xs text-gray-400 font-['Space_Grotesk']">
                  {selectedGames.length} game{selectedGames.length > 1 ? 's' : ''} selected
                </div>
                <div className="text-lg font-bold text-white font-['Space_Grotesk']">
                  ${totalPrice.toLocaleString()}/year
                </div>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <motion.div
                animate={{ rotate: isExpanded ? 180 : 0 }}
                className="w-5 h-5 rounded-full bg-zinc-800 flex items-center justify-center"
              >
                <ChevronDown className="w-3 h-3 text-gray-400" />
              </motion.div>
            </div>
          </button>

          {/* Expanded Details */}
          <AnimatePresence>
            {isExpanded && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                className="border-t border-game-accent/10"
              >
                <div className="p-4 space-y-4">
                  {/* Selected Games List */}
                  <div className="space-y-2">
                    {priceDetails.map(game => {
                      const gameInfo = games.find(g => g.id === game.id);
                      return (
                        <div key={game.id} className="space-y-1">
                          <div className="flex items-center justify-between text-sm">
                            <span className="text-white font-['Space_Grotesk']">
                              {gameInfo.title}
                            </span>
                            <span className="text-gray-400 flex items-center gap-1">
                              <Users className="w-3 h-3" />
                              {game.yearlyParticipants}
                            </span>
                          </div>
                          <div className="text-xs text-gray-500 pl-2 space-y-0.5">
                            <div className="flex justify-between">
                              <span>Base License</span>
                              <span>$4,950</span>
                            </div>
                            {game.additionalPeople > 0 && (
                              <div className="flex justify-between items-center">
                                <span className="flex items-center gap-1">
                                  <Users className="w-3 h-3" />
                                  {game.additionalPeople} × $5
                                </span>
                                <span>${(game.additionalPeople * 5).toLocaleString()}</span>
                              </div>
                            )}
                            <div className="flex justify-between font-medium text-white">
                              <span>Subtotal</span>
                              <span>${(game.basePrice).toLocaleString()}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {/* Total Price */}
                  <div className="space-y-2 pt-2 border-t border-game-accent/10">
                    <div className="text-sm text-white font-['Space_Grotesk'] flex justify-between">
                      <span>Subtotal:</span>
                      <span>${(totalPrice + priceDetails.reduce((sum, game) => sum + game.discountAmount, 0)).toLocaleString()}/year</span>
                    </div>
                    {selectedGames.length > 1 && (
                      <div className="text-xs text-green-400/80 flex justify-between">
                        <span>Multi-game Discount ({getMultiGameDiscount(selectedGames.length) * 100}%):</span>
                        <span>-${priceDetails.reduce((sum, game) => sum + game.discountAmount, 0).toLocaleString()}</span>
                      </div>
                    )}
                    <div className="text-sm font-bold text-white font-['Space_Grotesk'] flex justify-between">
                      <span>Annual License Total:</span>
                      <span>${totalPrice.toLocaleString()}/year</span>
                    </div>
                  </div>

                  {/* Action Button */}
                  <div className="p-4">
                    <button
                      onClick={handlePrintSummary}
                      className="w-full px-4 py-2 bg-purple-500 hover:bg-purple-600
                             text-white font-['Space_Grotesk'] text-sm font-medium
                             rounded-lg transition-colors duration-200
                             flex items-center justify-center gap-2"
                    >
                      Print Summary
                      <Printer className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.div>

      {showQuoteForm && (
        <QuoteDetailsForm
          onSubmit={handleQuoteSubmit}
          type="license"
        />
      )}
    </>
  );
}; 