import React from 'react';
import SurvivorScore from '../components/SurvivorScore';
import './css/RightBar.css';

function RightBar() {
  return (
    <div className="antarctica-right-bar">
      <SurvivorScore />
    </div>
  );
}

export default RightBar; 