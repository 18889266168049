import React, { useState } from 'react';
import scenarios from '../content/ShadowMoney';
import { Icon } from '@iconify/react';
import { useSimulation } from '../../context/SimulationContext';
import ScenarioResultModal from './ScenarioResultModal';
import emailReceivedSound from '../../assets/sounds/email-received.mp3';
import { motion } from 'framer-motion';
import PasswordModal from '../modals/PasswordModal';

const NavigationStrip = ({ onScenarioSelect, currentScenarioId, visitedGroups = [] }) => {
  const { isScenarioAvailable, isScenarioCompleted, markScenarioAsCompleted } = useSimulation();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedScenario, setSelectedScenario] = useState(null);
  const emailAudio = new Audio(emailReceivedSound);
  emailAudio.volume = 0.3;
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [pendingScenarioId, setPendingScenarioId] = useState(null);

  // Check if all groups have been visited
  React.useEffect(() => {
    if (!visitedGroups || visitedGroups.length === 0) return;

    const allGroups = ['Financial', 'Customer', 'Intangible'];
    const allGroupsVisited = allGroups.every(group => visitedGroups.includes(group));
    
    if (allGroupsVisited && !isScenarioCompleted(currentScenarioId)) {
      markScenarioAsCompleted(currentScenarioId);
    }
  }, [visitedGroups]);

  const handleScenarioClick = (scenario) => {
    if (scenario.id === 4 && !isScenarioCompleted(4)) {
      setShowPasswordModal(true);
      setPendingScenarioId(scenario.id);
      return;
    }

    if (isScenarioCompleted(scenario.id)) {
      setSelectedScenario(scenario);
      setModalOpen(true);
    } else if (isScenarioAvailable(scenario.id)) {
      if (scenario.id !== currentScenarioId) {
        emailAudio.currentTime = 0;
        emailAudio.play().catch(error => console.log("Audio play failed:", error));
      }
      onScenarioSelect(scenario.id);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedScenario(null);
  };

  const handlePasswordSuccess = () => {
    if (pendingScenarioId) {
      onScenarioSelect(pendingScenarioId);
      setPendingScenarioId(null);
    }
  };

  return (
    <div className="h-full w-full bg-gray-900">
      <div className="h-full overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-700">
        <div className="flex flex-col items-center gap-2 py-2 px-[5px]">
          {scenarios.map((scenario, index) => {
            const isActive = scenario.id === currentScenarioId;
            const isCompleted = isScenarioCompleted(scenario.id);
            const isAvailable = isScenarioAvailable(scenario.id);
            const isLocked = !isAvailable && !isCompleted;
            
            return (
              <div key={index} className="w-full">
                <motion.div 
                  className={`relative cursor-pointer transition-all duration-300
                    ${isLocked ? 'cursor-not-allowed opacity-50' : ''}
                  `}
                  onClick={() => handleScenarioClick(scenario)}
                  whileHover={!isLocked ? { scale: 1.02 } : {}}
                >
                  <div className={`flex flex-col gap-0.5 p-1.5 rounded border ${
                    isActive ? 'bg-gray-800 border-emerald-500 shadow-[0_0_10px_rgba(16,185,129,0.2)]' : 'bg-gray-800 border-gray-700'
                  }`}>
                    <div className="flex items-center gap-1">
                      <span className="text-[8px] text-gray-500 font-mono">TX-{scenario.id.toString().padStart(3, '0')}</span>
                      {isActive && (
                        <span className="flex h-1.5 w-1.5 ml-auto">
                          <span className="animate-ping absolute inline-flex h-1.5 w-1.5 rounded-full bg-emerald-400 opacity-75"></span>
                          <span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-emerald-500"></span>
                        </span>
                      )}
                    </div>
                    
                    <div className={`text-[8px] font-mono
                      ${isCompleted ? 'text-blue-400' : ''}
                      ${isActive ? 'text-emerald-400' : ''}
                      ${isLocked ? 'text-gray-600' : ''}
                      ${!isActive && !isCompleted && !isLocked ? 'text-gray-400' : ''}
                    `}>
                      {isCompleted ? 'VERIFIED' : 
                       isActive ? 'IN PROGRESS' : 
                       isLocked ? 'PENDING' : 'READY'}
                    </div>
                  </div>
                </motion.div>

                {index < scenarios.length - 1 && (
                  <div className="w-full flex justify-center py-0.5">
                    <div className={`w-px h-2 ${
                      isCompleted ? 'bg-blue-500/30' : 'bg-gray-700'
                    }`} />
                  </div>
                )}
              </div>
            );
          })}

          {[5, 6, 7].map((id) => {
            const isLocked = true; // These will always be locked
            
            return (
              <div key={`test-${id}`} className="w-full">
                <motion.div 
                  className="relative cursor-not-allowed opacity-50"
                >
                  <div className="flex flex-col gap-0.5 p-1.5 rounded border bg-gray-800 border-gray-700">
                    <div className="flex items-center gap-1">
                      <span className="text-[8px] text-gray-500 font-mono">
                        TX-{id.toString().padStart(3, '0')}
                      </span>
                    </div>
                    
                    <div className="text-[8px] font-mono text-gray-600">
                      PENDING
                    </div>
                  </div>
                </motion.div>

                {id < 7 && (
                  <div className="w-full flex justify-center py-0.5">
                    <div className="w-px h-2 bg-gray-700" />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <ScenarioResultModal 
        open={modalOpen}
        handleClose={handleModalClose}
        scenario={selectedScenario}
        selectedOption={selectedScenario?.options[0]}
      />

      <PasswordModal 
        isOpen={showPasswordModal}
        onClose={() => setShowPasswordModal(false)}
        onSuccess={handlePasswordSuccess}
      />
    </div>
  );
};

export default NavigationStrip;
