import React, { useEffect, useState } from 'react';
import TopBar from './gameplay-layout/TopBar';
import BottomBar from './gameplay-layout/BottomBar';
import SideBarLeft from './gameplay-layout/SideBarLeft';
import SideBarRight from './gameplay-layout/SideBarRight';
import PlayArea from './gameplay-layout/PlayArea';
import SimulationEndScreen from './game-elements/SimulationEndScreen';
import StartScreen from './StartScreen';
import GameIntroModal from './game-elements/GameIntroModal';
import { useSimulation } from '../ai-gone-rogue/SimulationContext';
import omniusLockSound from '../assets/audio/omnius-lock.mp3';
import './SimulationView.css';

function SimulationView() {
  const { isSimulationComplete, gameStarted, setGameStarted } = useSimulation();
  const [showIntro, setShowIntro] = useState(false);

  // Play lock sound and show intro modal only on first visit
  useEffect(() => {
    if (gameStarted) {
      const lockSound = new Audio(omniusLockSound);
      lockSound.volume = 0.1;
      lockSound.play().catch(error => console.log('Audio play failed:', error));
      
      // Check if this is the first visit
      const hasSeenIntro = localStorage.getItem('hasSeenGameIntro');
      if (!hasSeenIntro) {
        setShowIntro(true);
        localStorage.setItem('hasSeenGameIntro', 'true');
      }
    }
  }, [gameStarted]);

  if (!gameStarted) {
    return <StartScreen onStartGame={() => setGameStarted(true)} />;
  }

  if (isSimulationComplete) {
    return <SimulationEndScreen />;
  }

  return (
    <div className="simulation-view">
      <TopBar />
      <div className="simulation-content">
        <SideBarLeft />
        <PlayArea />
        <SideBarRight />
      </div>
      <BottomBar />
      
      <GameIntroModal 
        isOpen={showIntro}
        onClose={() => setShowIntro(false)}
      />
    </div>
  );
}

export default SimulationView; 