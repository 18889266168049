import React from 'react';
import Shop from '../game-elements/Shop';
import AIButton from '../game-elements/AIButton';
import BoughtItems from '../game-elements/BoughtItems';
import { useSimulation } from '../../ai-gone-rogue/SimulationContext';
import './SideBarLeft.css';

function SideBarLeft() {
  const { shopItems } = useSimulation();
  const availableItems = shopItems.filter(item => !item.purchased).length;

  return (
    <div className="sidebar-left">
      <Shop availableItems={availableItems} />
      <BoughtItems />
      <div className="sidebar-spacer"></div>
      <AIButton />
    </div>
  );
}

export default SideBarLeft; 