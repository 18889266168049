import React, { useState } from 'react';
import OmniusImage from '../../assets/images/Omnius.webp';
import HelperBot from './HelperBot';
import { playOmniusSound } from '../../utils/SoundEffects';
import { useSimulation } from '../../ai-gone-rogue/SimulationContext';
import './AIButton.css';

function AIButton() {
  const [isHelperOpen, setIsHelperOpen] = useState(false);
  const { hasEffect } = useSimulation();
  const isOmniusUnlocked = hasEffect('enable_omnius');

  const toggleHelper = () => {
    if (!isOmniusUnlocked) return;
    playOmniusSound();
    setIsHelperOpen(!isHelperOpen);
  };

  if (!isOmniusUnlocked) return null;

  return (
    <>
      <div 
        className={`ai-button-container ${isHelperOpen ? 'active' : ''}`}
        onClick={toggleHelper}
      >
        <div className="ai-button">
          <img 
            src={OmniusImage}
            alt="AI Assistant" 
            className="ai-image"
          />
          <div className="ai-button-glow"></div>
          <div className="ai-button-ring"></div>
        </div>
        <span className="ai-label">Omnius</span>
      </div>

      <HelperBot 
        isOpen={isHelperOpen}
        onClose={toggleHelper}
      />
    </>
  );
}

export default AIButton;