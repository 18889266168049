import { db } from '../firebase/config';
import { doc, setDoc, getDoc, deleteDoc } from 'firebase/firestore';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const retryOperation = async (operation, retries = MAX_RETRIES) => {
  for (let i = 0; i < retries; i++) {
    try {
      return await operation();
    } catch (error) {
      console.log(`Attempt ${i + 1} failed:`, error);
      if (i === retries - 1) throw error;
      await delay(RETRY_DELAY * (i + 1));
    }
  }
};

export const saveProgress = async (sessionId, progressData) => {
  if (!sessionId) {
    console.error('No sessionId provided');
    return false;
  }

  console.log('Attempting to save progress for session:', sessionId);
  console.log('Progress data:', progressData);

  return retryOperation(async () => {
    try {
      const docRef = doc(db, 'progress', sessionId);
      await setDoc(docRef, {
        ...progressData,
        lastUpdated: new Date().toISOString()
      }, { merge: true });
      console.log('Progress saved successfully');
      return true;
    } catch (error) {
      console.error('Error saving progress:', error);
      throw error;
    }
  });
};

export const loadProgress = async (sessionId) => {
  if (!sessionId) {
    console.error('No sessionId provided');
    return null;
  }

  console.log('Attempting to load progress for session:', sessionId);

  return retryOperation(async () => {
    try {
      const docRef = doc(db, 'progress', sessionId);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        console.log('Progress loaded:', docSnap.data());
        return docSnap.data();
      }
      console.log('No existing progress found');
      return null;
    } catch (error) {
      console.error('Error loading progress:', error);
      throw error;
    }
  });
};

export const deleteProgress = async (sessionId) => {
  if (!sessionId) {
    console.error('No sessionId provided');
    return false;
  }

  console.log('Attempting to delete progress for session:', sessionId);

  return retryOperation(async () => {
    try {
      const docRef = doc(db, 'progress', sessionId);
      await deleteDoc(docRef);
      console.log('Progress deleted successfully');
      return true;
    } catch (error) {
      console.error('Error deleting progress:', error);
      throw error;
    }
  });
};