import React from 'react';
import { Shield, TrendingDown, TrendingUp } from 'lucide-react';
import { playOmniusLockSound } from '../../utils/SoundEffects';
import './ComplianceAlert.css';

function ComplianceAlert({ impact, onClose }) {
  if (!impact) return null;

  const isPositive = impact > 0;
  const getImpactIcon = () => {
    return isPositive ? <TrendingUp className="compliance-icon positive" /> : <TrendingDown className="compliance-icon negative" />;
  };

  const handleClick = () => {
    playOmniusLockSound();
    onClose();
  };

  return (
    <div className="compliance-alert-overlay">
      <div className="compliance-alert">
        <div className="compliance-header">
          <Shield className="compliance-icon header-icon" />
          <h2 className="compliance-title">Compliance Impact</h2>
        </div>

        <div className="compliance-content">
          <div className="compliance-amount">
            {getImpactIcon()}
            <span className={isPositive ? 'positive' : 'negative'}>
              {isPositive ? '+' : ''}{impact} pts
            </span>
          </div>
          <p className="compliance-description">
            {isPositive ? 
              'Regulatory compliance has improved through proper risk management.' :
              'Compliance rating has decreased due to regulatory violations.'
            }
          </p>
        </div>

        <button className="compliance-button" onClick={handleClick}>
          Continue
        </button>
      </div>
    </div>
  );
}

export default ComplianceAlert; 