import React from 'react';
import { playNextRoundSound } from '../../utils/SoundEffects';
import './GameButton.css';

const GameButton = ({ onClick, disabled, children }) => {
  const handleClick = () => {
    if (!disabled) {
      playNextRoundSound();
      onClick();
    }
  };

  return (
    <button 
      className={`game-button ${disabled ? 'disabled' : ''}`}
      onClick={handleClick}
      disabled={disabled}
    >
      <span className="button-content">{children}</span>
      <div className="button-glow" />
      <div className="button-border" />
    </button>
  );
};

export default GameButton; 