import React from 'react';
import ReactDOM from 'react-dom';
import { Info } from 'lucide-react';

const DescriptionModal = ({ description, onClose }) => {
  return ReactDOM.createPortal(
    <div 
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 99999,
      }}
      onClick={onClose}
    >
      <div 
        style={{
          backgroundColor: '#141420',
          padding: '20px',
          borderRadius: '8px',
          border: '2px solid #33ff66',
          maxWidth: '500px',
          color: '#8f96ff',
          position: 'relative',
          boxShadow: '0 0 30px rgba(51, 255, 102, 0.2)',
        }}
        onClick={e => e.stopPropagation()}
      >
        {description}
      </div>
    </div>,
    document.body
  );
};

export const ScenarioTooltip = ({ description, children }) => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      {children}
      <Info 
        size={16} 
        style={{ 
          color: '#33ff66', 
          cursor: 'pointer',
          flexShrink: 0,
          opacity: 0.7,
          transition: 'opacity 0.2s ease',
          '&:hover': {
            opacity: 1
          }
        }}
        onClick={() => setShowModal(true)}
      />
      {showModal && (
        <DescriptionModal 
          description={description} 
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
}; 