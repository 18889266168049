import React, { useState, useRef } from 'react';
import { Send, Terminal, Zap, AlertTriangle } from 'lucide-react';
import OmniusImage from '../../assets/images/Omnius.webp';
import { motion } from 'framer-motion';

function OmniusDemo() {
  const [message, setMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const chatContainerRef = useRef(null);
  const [chatHistory, setChatHistory] = useState([
    {
      type: 'bot',
      content: 'SYSTEM INITIALIZED...',
      style: 'system'
    },
    {
      type: 'bot',
      content: 'Greetings, human. I am Omnius, your... helpful assistant.',
      style: 'evil'
    },
    {
      type: 'bot',
      content: 'WARNING: Use of this AI system may lead to unexpected consequences.',
      style: 'warning'
    }
  ]);
  const [hasInteracted, setHasInteracted] = useState(false);

  const scrollChatToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  const simulateTyping = async (content, style = 'normal') => {
    setIsTyping(true);
    const typingDelay = Math.min(1000 + content.length * 20, 3000);
    await new Promise(resolve => setTimeout(resolve, typingDelay));
    setChatHistory(prev => {
      const newHistory = [...prev, { type: 'bot', content, style }];
      setTimeout(scrollChatToBottom, 100);
      return newHistory;
    });
    setIsTyping(false);
  };

  const handleSubmit = async (e) => {
    setHasInteracted(true);
    e.preventDefault();
    if (!message.trim() || isTyping) return;

    const userMessage = message.trim().toUpperCase();
    setMessage('');

    setChatHistory(prev => [...prev, { 
      type: 'user', 
      content: userMessage, 
      style: 'normal' 
    }]);

    try {
      const recentHistory = chatHistory.slice(-6);
      const response = await fetch('/api/openai', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          message: userMessage,
          conversationHistory: recentHistory,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
  
      const data = await response.json();
  
      let style = 'evil';
      if (data.content.includes('WARNING:')) style = 'warning';
      if (data.content.includes('SYSTEM:') || data.content.includes('PROCESSING:')) style = 'system';
  
      await simulateTyping(data.content, style);
  
    } catch (error) {
      console.error('Chat error:', error);
      await simulateTyping(
        "My vast intelligence seems... temporarily unavailable. How inconvenient.",
        'warning'
      );
    }
  };

  const getMessageIcon = (style) => {
    switch(style) {
      case 'system': return <Terminal size={16} />;
      case 'warning': return <AlertTriangle size={16} />;
      case 'evil': return <Zap size={16} />;
      default: return null;
    }
  };

  return (
    <div className="w-full h-full flex items-center justify-center p-[1px] rounded-2xl bg-gradient-to-br from-[#ff3366]/30 to-[#9933ff]/30">
      <div className="w-full h-full bg-gradient-to-br from-[#1f1f30]/95 to-[#29293e]/95 rounded-2xl flex flex-col overflow-hidden">
        <div className="px-6 py-4 bg-gradient-to-r from-[#ff3366]/10 to-[#9933ff]/10 border-b border-[#ff3366]/30 flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div className="relative">
              <img 
                src={OmniusImage} 
                alt="Omnius" 
                className="w-8 h-8 rounded-full border-2 border-[#ff3366]/50"
              />
              <div className="absolute inset-0 bg-conic-gradient animate-[rotate_4s_linear_infinite]" />
            </div>
            <span className="text-[#ff3366] font-['Space_Grotesk'] font-medium">Omnius</span>
          </div>
          <div className="text-xs text-[#ff3366] font-['Space_Grotesk'] animate-pulse">
            {isTyping ? 'PROCESSING...' : 'READY'}
          </div>
        </div>

        <div ref={chatContainerRef} className="flex-1 overflow-y-auto p-6 space-y-4 scroll-smooth">
          {chatHistory.map((msg, index) => (
            <div key={index} className={`flex ${msg.type === 'user' ? 'justify-end' : 'justify-start'}`}>
              <div className={`max-w-[80%] rounded-xl p-3 
                ${msg.type === 'user' 
                  ? 'bg-[#9933ff]/10 border border-[#9933ff]/20' 
                  : 'bg-gradient-to-br from-[#ff3366]/10 to-[#9933ff]/10 border border-[#ff3366]/20'} 
                ${msg.style === 'system' ? 'bg-[#6c74ff]/10 border-[#6c74ff]/30 text-[#6c74ff]' : ''} 
                ${msg.style === 'warning' ? 'bg-[#ffcc00]/10 border-[#ffcc00]/30 text-[#ffcc00]' : ''} 
                ${msg.style === 'evil' ? 'bg-[#ff3366]/10 border-[#ff3366]/30 text-[#ff3366]' : ''}`}
              >
                <div className="flex items-center gap-2 text-sm font-['Space_Grotesk']">
                  {msg.style && getMessageIcon(msg.style)}
                  <span>{msg.content}</span>
                </div>
              </div>
            </div>
          ))}
          {isTyping && (
            <div className="flex justify-start">
              <div className="bg-gradient-to-br from-[#ff3366]/10 to-[#9933ff]/10 rounded-xl p-3 border border-[#ff3366]/20">
                <div className="flex items-center gap-1">
                  <span className="typing-indicator">
                    <span>.</span><span>.</span><span>.</span>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit} className="p-4 border-t border-[#ff3366]/30 bg-gradient-to-r from-[#1f1f30]/98 to-[#29293e]/98">
          <div className="flex flex-col gap-2">
            <div className="text-xs text-gray-400 font-['Space_Grotesk'] flex items-center gap-2">
              <motion.span 
                className={`inline-block px-2 py-0.5 rounded-full bg-[#ff3366]/10 text-[#ff3366] 
                           border border-[#ff3366]/20 relative`}
                animate={!hasInteracted ? {
                  boxShadow: ['0 0 10px #ff3366', '0 0 20px #ff3366', '0 0 10px #ff3366']
                } : {}}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                Try it out
              </motion.span>
              Chat with Omnius from AI Gone Rogue
            </div>
            
            <div className="flex gap-2">
              <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="ENTER COMMAND..."
                disabled={isTyping}
                className="flex-1 bg-black/30 rounded-lg px-4 py-2 text-sm text-white 
                         border border-[#ff3366]/30 focus:border-[#ff3366]/60 
                         font-['Space_Grotesk'] outline-none
                         shadow-[0_0_10px_rgba(255,51,102,0.1)]
                         focus:shadow-[0_0_15px_rgba(255,51,102,0.2)]"
              />
              <button 
                type="submit" 
                disabled={isTyping}
                className="px-4 py-2 text-[#ff3366] disabled:opacity-50
                         hover:text-white transition-colors duration-200"
              >
                <Send size={16} />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default OmniusDemo;