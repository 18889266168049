import React, { useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRight, Circle } from 'lucide-react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import OmniusDemo from './OmniusDemo';
import preview1 from '../assets/preview-1.webp';
import preview2 from '../assets/preview-2.webp';
import characterSelect from '../../antarctica/assets/sounds/character-select.mp3';
import chooseStrategy from '../../antarctica/assets/sounds/choose-strategy.mp3';
import delegateSound from '../../antarctica/assets/sounds/delegate-sound.mp3';
import cardFlipped from '../../assets/audio/card-flipped.mp3';
import nextAlert from '../../assets/audio/next-alert.mp3';
import omniusLock from '../../assets/audio/omnius-lock.mp3';
import wrongPasscode from '../../assets/audio/wrong-passcode.mp3';
import MusicDemo from './MusicDemo';

const features = [
  {
    id: 1,
    title: "AI Chatbots",
    description: "Intelligent NPCs powered by AI create realistic conversations and scenarios. Each interaction feels natural and meaningful.",
    color: "from-purple-500 to-pink-500"
  },
  {
    id: 2,
    title: "Various Themes",
    description: "From space missions to bank scenarios, each game has its unique theme and atmosphere. Visual consistency brings stories to life.",
    color: "from-pink-500 to-rose-500"
  },
  {
    id: 3,
    title: "Sound Effects",
    description: "Carefully crafted sound effects provide instant feedback and reinforce learning moments. Every action has its satisfying audio cue.",
    color: "from-indigo-500 to-purple-500"
  },
  {
    id: 4,
    title: "Background Music",
    description: "Adaptive soundtrack that enhances the gaming experience. Music changes with the narrative to create the perfect atmosphere.",
    color: "from-violet-500 to-purple-500"
  }
];

const SoundBoard = () => {
  const [playingSound, setPlayingSound] = useState(null);
  const audioRef = useRef(new Audio());

  const sounds = [
    { id: 'card', name: "Card Flip", file: cardFlipped },
    { id: 'alert', name: "Alert Sound", file: nextAlert },
    { id: 'character', name: "Character Select", file: characterSelect },
    { id: 'strategy', name: "Choose Strategy", file: chooseStrategy },
    { id: 'delegate', name: "Delegate Task", file: delegateSound },
    { id: 'lock', name: "System Lock", file: omniusLock },
    { id: 'wrong', name: "Access Denied", file: wrongPasscode }
  ];

  const playSound = (file, id) => {
    audioRef.current.pause();
    audioRef.current.src = file;
    audioRef.current.play();
    setPlayingSound(id);
    audioRef.current.onended = () => setPlayingSound(null);
  };

  return (
    <div className="h-full w-full bg-zinc-900/50 rounded-2xl p-8 overflow-y-auto">
      <div className="space-y-6">
        {/* Simple instruction text */}
        <p className="text-sm text-gray-400 font-['Space_Grotesk'] mb-8">
          Click to play
        </p>

        {/* Sound Grid */}
        <div className="grid grid-cols-2 gap-4">
          {sounds.map((sound) => (
            <button
              key={sound.id}
              onClick={() => playSound(sound.file, sound.id)}
              className={`p-4 rounded-xl border text-left transition-all duration-300
                        flex items-center gap-4 group
                        ${playingSound === sound.id 
                          ? 'bg-game-accent/20 border-game-accent' 
                          : 'bg-black/20 border-white/5 hover:border-white/20'}`}
            >
              <div className={`w-10 h-10 rounded-lg 
                            ${playingSound === sound.id 
                              ? 'bg-game-accent' 
                              : 'bg-black/40 group-hover:bg-black/60'}
                            flex items-center justify-center
                            transition-all duration-300`}>
                <div className={`w-1.5 h-4 rounded-full transition-all duration-300
                              ${playingSound === sound.id 
                                ? 'bg-white' 
                                : 'bg-gray-400 group-hover:bg-white'}`} />
              </div>
              <div className="flex-1">
                <div className="text-sm font-['Space_Grotesk'] text-white">
                  {sound.name}
                </div>
              </div>
              <div className={`w-1 h-8 rounded-full transition-all duration-300
                            ${playingSound === sound.id 
                              ? 'bg-game-accent' 
                              : 'bg-white/5 group-hover:bg-white/20'}`} />
            </button>
          ))}

          {/* "And hundreds more" card */}
          <div className="p-4 rounded-xl border border-dashed border-white/10 
                         bg-black/20 flex items-center justify-center
                         text-center">
            <div className="space-y-2">
              <div className="text-sm font-['Space_Grotesk'] text-gray-400">
                And hundreds more...
              </div>
              <div className="text-xs text-gray-600">
                Every interaction has its unique sound
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Features = () => {
  const [activeTab, setActiveTab] = useState(features[0].id);

  const handleTabClick = (featureId, e) => {
    e.preventDefault();
    setActiveTab(featureId);
  };

  return (
    <div className="w-full py-12 sm:py-24">
      <div className="container mx-auto px-4">
        <div className="space-y-12 sm:space-y-24">
          {/* Header with updated subtitle */}
          <div className="space-y-4 sm:space-y-6">
            <h2 className="text-2xl sm:text-3xl font-bold font-['Space_Grotesk'] text-white text-center">
              True gaming vibe, nothing boring about it
            </h2>
            <p className="text-base sm:text-lg font-['Space_Grotesk'] text-gray-400 text-center max-w-3xl mx-auto">
              Immersive soundtracks, polished animations, and game mechanics create an experience 
              that feels more like playing a game than sitting through another training session
            </p>
          </div>

          {/* Mobile View */}
          <div className="block lg:hidden space-y-8">
            {features.map((feature, index) => (
              <motion.div
                key={feature.id}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="space-y-6"
              >
                {/* Feature Header */}
                <div 
                  onClick={(e) => handleTabClick(feature.id, e)}
                  className={`flex items-center gap-4 p-4 rounded-xl transition-all duration-300
                    ${activeTab === feature.id 
                      ? 'bg-black/40 border border-game-accent/30' 
                      : 'bg-black/20 border border-white/5'}`}
                >
                  <div className={`w-12 h-12 rounded-xl flex items-center justify-center
                    ${activeTab === feature.id 
                      ? 'bg-gradient-to-r from-game-accent to-purple-500' 
                      : 'bg-black/40'}`}
                  >
                    <ChevronRight className={`w-6 h-6 
                      ${activeTab === feature.id 
                        ? 'text-white' 
                        : 'text-gray-500'}`} 
                    />
                  </div>
                  <h3 className={`text-lg font-bold font-['Space_Grotesk'] transition-colors duration-200
                    ${activeTab === feature.id 
                      ? 'bg-gradient-to-r from-game-accent to-purple-500 bg-clip-text text-transparent' 
                      : 'text-gray-400'}`}
                  >
                    {feature.title}
                  </h3>
                </div>

                {/* Feature Content */}
                {activeTab === feature.id && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                    className="h-[400px] rounded-2xl overflow-hidden bg-zinc-900/50"
                  >
                    {activeTab === 4 ? (
                      <MusicDemo />
                    ) : activeTab === 3 ? (
                      <SoundBoard />
                    ) : activeTab === 2 ? (
                      <div className="h-full w-full relative flex items-center justify-center">
                        <ReactCompareSlider
                          itemOne={
                            <ReactCompareSliderImage 
                              src={preview1}
                              alt="Traditional Training Interface"
                              className="w-full h-full"
                            />
                          }
                          itemTwo={
                            <ReactCompareSliderImage 
                              src={preview2}
                              alt="Gamified Training Interface"
                              className="w-full h-full"
                            />
                          }
                          className="h-full w-full"
                          position={85}
                          handle={
                            <div className="flex items-center">
                              <div className="h-full w-1 bg-game-accent backdrop-blur-sm" />
                              <div className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 
                                            px-3 py-1.5 rounded-full
                                            bg-black/80 backdrop-blur-sm
                                            border border-game-accent/30
                                            whitespace-nowrap">
                                <span className="text-xs text-white/90 font-['Space_Grotesk']">
                                  ← Drag →
                                </span>
                              </div>
                            </div>
                          }
                        />
                      </div>
                    ) : activeTab === 1 ? (
                      <OmniusDemo />
                    ) : null}
                  </motion.div>
                )}
              </motion.div>
            ))}
          </div>

          {/* Desktop View */}
          <div className="hidden lg:grid grid-cols-12 gap-16">
            {/* Existing desktop tabs */}
            <div className="col-span-4 space-y-8">
              {features.map((feature) => (
                <motion.button
                  key={feature.id}
                  onClick={(e) => handleTabClick(feature.id, e)}
                  className="w-full flex items-center gap-4 text-left group"
                >
                  {activeTab === feature.id ? (
                    <ChevronRight className="w-5 h-5 text-game-accent" />
                  ) : (
                    <Circle className="w-2 h-2 ml-1.5 text-gray-500" />
                  )}
                  <h3 className={`text-lg font-bold font-['Space_Grotesk'] transition-colors duration-200
                    ${activeTab === feature.id 
                      ? 'bg-gradient-to-r from-game-accent to-purple-500 bg-clip-text text-transparent' 
                      : 'text-gray-500 group-hover:text-gray-300'
                    }`}
                  >
                    {feature.title}
                  </h3>
                </motion.button>
              ))}
            </div>

            {/* Existing desktop content area */}
            <div className="col-span-8 h-[600px] rounded-2xl overflow-hidden">
              <AnimatePresence mode='wait'>
                <motion.div
                  key={activeTab}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  className="h-full w-full"
                >
                  {activeTab === 4 ? (
                    <MusicDemo />
                  ) : activeTab === 3 ? (
                    <SoundBoard />
                  ) : activeTab === 2 ? (
                    <div className="h-full w-full relative flex items-center justify-center">
                      <div className="w-full" style={{ aspectRatio: '2230/1444' }}>
                        <ReactCompareSlider
                          itemOne={
                            <ReactCompareSliderImage 
                              src={preview1}
                              alt="Traditional Training Interface"
                              className="w-full h-full"
                            />
                          }
                          itemTwo={
                            <ReactCompareSliderImage 
                              src={preview2}
                              alt="Gamified Training Interface"
                              className="w-full h-full"
                            />
                          }
                          className="h-full w-full"
                          position={85}
                          handle={
                            <div className="flex items-center">
                              <div className="h-full w-1 bg-game-accent backdrop-blur-sm" />
                              <div className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 
                                            px-3 py-1.5 rounded-full
                                            bg-black/80 backdrop-blur-sm
                                            border border-game-accent/30
                                            whitespace-nowrap">
                                <span className="text-xs text-white/90 font-['Space_Grotesk']">
                                  ← Drag →
                                </span>
                              </div>
                            </div>
                          }
                        />
                      </div>
                    </div>
                  ) : activeTab === 1 ? (
                    <OmniusDemo />
                  ) : null}
                </motion.div>
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features; 