import React from 'react';
import captainImg from '../assets/images/captain.webp';
import firstOfficerImg from '../assets/images/first-officer.webp';
import navigatorImg from '../assets/images/navigator.webp';
import medicImg from '../assets/images/medic.webp';
import carpenterImg from '../assets/images/carpenter.webp';
import characterJson from '../content/game-content.json';
import './Characters.css';

// Create an image mapping object
const characterImages = {
  captain: captainImg,
  firstOfficer: firstOfficerImg,
  navigator: navigatorImg,
  medic: medicImg,
  carpenter: carpenterImg
};

// Export the character data from the JSON
export const characterData = characterJson.characters;

function Character({ characterId, size = 'small', onClick, hasNews = false }) {
  const character = characterData[characterId];
  
  if (!character) return null;

  return (
    <div 
      className={`character character--${size} ${hasNews ? 'character--has-news' : ''}`}
      onClick={() => onClick?.(characterId)}
    >
      <div className="character__frame">
        <img 
          src={characterImages[character.imageKey]} 
          alt={character.name} 
          className="character__image"
        />
        {hasNews && <div className="character__news-indicator" />}
      </div>
    </div>
  );
}

export default Character; 