import React, { useState, useEffect } from 'react';
import LeftRectangle from '../components/scenarios/LeftRectangle';
import RightRectangle from '../components/scenarios/RightRectangle';
import scenarios from '../components/content/ShadowMoney';

const ScenarioContainer = ({ currentScenarioId, onKPIUpdate, currentKPIValues, onVisitedGroupsChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLocked, setIsLocked] = useState(false);
  const userName = localStorage.getItem('userName');
  
  const currentScenario = scenarios.find(scenario => scenario.id === currentScenarioId);
  
  const personalizedScenario = {
    ...currentScenario,
    message: {
      ...currentScenario.message,
      content: currentScenario.message.content.replace(/{userName}/g, userName || 'John')
    },
    options: currentScenario.options.map(option => ({
      ...option,
      reply: option.reply.replace(/{userName}/g, userName || 'John')
    }))
  };

  useEffect(() => {
    setSelectedOption(null);
    setIsLocked(false);
  }, [currentScenarioId]);

  const handleOptionSelect = (option) => {
    if (!isLocked) {
      setSelectedOption({
        ...option,
        date: currentScenario.date,
        timestamp: currentScenario.timestamp
      });
    }
  };

  return (
    <div className="flex-grow flex justify-center items-stretch w-full h-full">
      <LeftRectangle 
        onOptionSelect={handleOptionSelect} 
        currentScenarioId={currentScenarioId}
        isLocked={isLocked}
        scenario={personalizedScenario}
      />
      <RightRectangle 
        selectedOption={selectedOption} 
        onOptionSelect={handleOptionSelect}
        currentScenarioId={currentScenarioId}
        onKPIUpdate={onKPIUpdate}
        currentKPIValues={currentKPIValues}
        onSend={() => setIsLocked(true)}
        onVisitedGroupsChange={onVisitedGroupsChange}
      />
    </div>
  );
};

export default ScenarioContainer;
