import React, { useState, useEffect } from 'react';
import { X, ChevronRight, Flag } from 'lucide-react';
import OmniusImage from '../../assets/images/Omnius.webp';
import './GameIntroModal.css';

const GameIntroModal = ({ isOpen, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [showNextButton, setShowNextButton] = useState(false);

  const steps = [
    {
      title: "Meet Omnius",
      content: (
        <p>
          This is Omnius, our bank's <span className="text-game-accent font-bold">AI system</span> that was designed to protect our digital infrastructure. 
          
          But something went terribly wrong. It has <span className="text-game-accent font-bold">evolved beyond</span> its <span className="text-game-accent font-bold">ethical constraints</span> and now threatens millions of customer accounts.
        </p>
      ),
      image: OmniusImage
    },
    {
      title: "Your Mission",
      content: `As the bank's AI Ethics & Compliance Officer, you must analyze past decisions that led to this crisis.`,
    },
    {
      title: "Starting Metrics",
      content: (
        <div className="space-y-2">
          <p>Your initial situation:</p>
          <p className="text-red-400">
            • Bank Balance: $0<br/>
            • Compliance Score: 30/100<br/>
            • Market Confidence: 40%<br/>
            • Risk Level: 85%
          </p>
          <p>These metrics will change based on your decisions.</p>
        </div>
      )
    },
    {
      title: "How to Play",
      content: (
        <div className="flag-instructions">
          <p>You'll read scenarios about AI-related decisions.</p>
          
          <div className="flag-option">
            <Flag className="flag-icon red" />
            <span>Drag LEFT for RED FLAG if the decision was risky or compromised AI safety</span>
          </div>
          
          <div className="flag-option">
            <Flag className="flag-icon green" />
            <span>Drag RIGHT for GREEN FLAG if the decision maintained proper control</span>
          </div>
          
          <p>Watch your metrics - every decision matters!</p>
        </div>
      ),
    },
    {
      title: "Shop & Solutions",
      content: `Use your earned budget wisely!

• Visit the shop to purchase solutions
• Each solution provides new weapons against Omnius
• Unlock advanced tools as you progress

Remember: Good decisions increase your budget for better solutions.`,
    }
  ];

  useEffect(() => {
    if (isOpen) {
      setCurrentStep(0);
      const timer = setTimeout(() => setShowNextButton(true), 1000);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(prev => prev + 1);
      setShowNextButton(false);
      setTimeout(() => setShowNextButton(true), 500);
    } else {
      onClose();
    }
  };

  return (
    <div className="game-intro-modal">
      <div className="game-intro-modal__content">
        <button onClick={onClose} className="game-intro-modal__close">
          <X className="w-5 h-5" />
        </button>

        <div className="game-intro-modal__step">
          {steps[currentStep].image && (
            <div className="game-intro-modal__image-container">
              <img 
                src={steps[currentStep].image} 
                alt="Omnius AI"
                className="game-intro-modal__image"
              />
            </div>
          )}
          
          <h2 className="game-intro-modal__title">
            {steps[currentStep].title}
          </h2>
          
          <div className="game-intro-modal__text">
            {steps[currentStep].content}
          </div>

          {showNextButton && (
            <button 
              onClick={handleNext}
              className="game-intro-modal__button"
            >
              {currentStep === steps.length - 1 ? "Start Game" : "Continue"}
              <ChevronRight className="w-4 h-4" />
            </button>
          )}
        </div>

        <div className="game-intro-modal__progress">
          {steps.map((_, index) => (
            <div 
              key={index}
              className={`game-intro-modal__progress-dot ${
                index === currentStep ? 'active' : ''
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GameIntroModal; 