import React, { useState, useEffect, useRef } from 'react';
import { X, Mail, User, ArrowRight, AlertCircle, Building2 } from 'lucide-react';
import AMLImage from '../../assets/images/library-images/AML.webp';
import AIRogueImage from '../../assets/images/library-images/AIRogue.webp';
import emailjs from '@emailjs/browser';

// Email validation for business emails
const isBusinessEmail = (email) => {
  // List of common personal email domains
  const personalDomains = [
    'gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com', 
    'aol.com', 'icloud.com', 'mail.com', 'protonmail.com'
  ];
  
  try {
    const domain = email.split('@')[1].toLowerCase();
    return !personalDomains.includes(domain);
  } catch {
    return false;
  }
};

const capitalizeFirstLetter = (string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const InputField = ({ icon: Icon, type, placeholder, value, onChange, inputRef, error }) => (
  <div className="space-y-0.5 sm:space-y-1">
    <div className="relative">
      <div className="absolute left-1.5 sm:left-3 top-1/2 -translate-y-1/2">
        <Icon className={`w-2.5 h-2.5 sm:w-4 sm:h-4 ${error ? 'text-red-400' : 'text-gray-400'}`} />
      </div>
      <input
        ref={inputRef}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={`w-full pl-6 sm:pl-10 pr-2 sm:pr-3 py-1.5 sm:py-2.5 text-[10px] sm:text-sm rounded-md sm:rounded-xl
                 bg-black/20 border ${error ? 'border-red-500/50' : 'border-game-accent/20'}
                 text-white placeholder-gray-500
                 focus:outline-none focus:border-game-accent/40
                 transition-colors duration-200`}
      />
    </div>
    {error && (
      <div className="flex items-center gap-0.5 text-[8px] sm:text-xs text-red-400 pl-1.5">
        <AlertCircle className="w-2 h-2 sm:w-3 sm:h-3" />
        {error}
      </div>
    )}
  </div>
);

const getGameContent = (gameTitle) => {
  switch(gameTitle) {
    case 'Anti Money Laundering':
      return {
        image: AMLImage,
        title: 'Try Anti-Money Laundering',
        description: 'Experience our immersive AML training simulation',
        benefits: [
          'Learn AML compliance through real-world scenarios',
          'Practice risk assessment and decision-making',
          'Master regulatory requirements through gameplay'
        ]
      };
    case 'AI Ethics in Banking':
    default:
      return {
        image: AIRogueImage,
        title: 'Try AI Gone Rogue',
        description: 'Experience our most popular game about AI ethics in banking',
        benefits: [
          'Immediate access to AI Gone Rogue demo',
          'Learn about AI ethics in banking through gameplay',
          'Experience our unique approach to training'
        ]
      };
  }
};

const TryGameModal = ({ isOpen, onClose }) => {
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const nameRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const selectedGame = localStorage.getItem('selectedGame');
  const gameContent = getGameContent(selectedGame);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        nameRef.current?.focus();
      }, 100);
    }
  }, [isOpen]);

  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);
  }, []);

  const validateForm = () => {
    const newErrors = {};
    
    if (!name.trim()) {
      newErrors.name = 'Name is required';
    }
    
    if (!company.trim()) {
      newErrors.company = 'Company is required';
    }
    
    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!email.includes('@')) {
      newErrors.email = 'Invalid email format';
    } else if (!isBusinessEmail(email)) {
      newErrors.email = 'Please use your business email';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNameChange = (value) => {
    setName(capitalizeFirstLetter(value));
  };

  const handleCompanyChange = (value) => {
    setCompany(capitalizeFirstLetter(value));
  };

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      try {
        console.log('Environment variables check:', {
          serviceId: process.env.REACT_APP_EMAILJS_SERVICE_ID ? 'Present' : 'Missing',
          templateId: process.env.REACT_APP_EMAILJS_TEMPLATE_ID ? 'Present' : 'Missing',
          publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY ? 'Present' : 'Missing'
        });

        if (!process.env.REACT_APP_EMAILJS_PUBLIC_KEY) {
          throw new Error('EmailJS public key is missing');
        }

        const response = await emailjs.send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          {
            user_name: name,
            user_company: company,
            user_email: email,
            game_title: selectedGame,
          }
        );

        console.log('EmailJS Response:', response);

        // If successful, proceed with the rest
        if (selectedGame === 'AI Ethics in Banking') {
          window.open('/ai-gone-rogue', '_blank');
        } else if (selectedGame === 'Anti Money Laundering') {
          window.open('/dark-money', '_blank');
        }

        localStorage.removeItem('selectedGame');
        setIsSubmitting(false);
        onClose();

      } catch (error) {
        console.error('EmailJS Error Details:', {
          message: error.message,
          text: error.text,
          name: error.name,
          stack: error.stack,
          envCheck: {
            serviceId: !!process.env.REACT_APP_EMAILJS_SERVICE_ID,
            templateId: !!process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            publicKey: !!process.env.REACT_APP_EMAILJS_PUBLIC_KEY
          }
        });
        
        setIsSubmitting(false);
        setErrors(prev => ({
          ...prev,
          submit: `Failed to submit form: ${error.message || 'Unknown error'}`
        }));
      }
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-1">
      <div 
        className="fixed inset-0 bg-black/80 backdrop-blur-md"
        onClick={onClose}
      />

      <div className="relative w-full max-w-[240px] sm:max-w-md
                   bg-gradient-to-br from-zinc-900/95 via-game-dark/95 to-black/95
                   backdrop-blur-sm
                   shadow-2xl border border-game-accent/20
                   rounded-md sm:rounded-xl">
        <button
          onClick={onClose}
          className="absolute top-1 right-1 sm:top-3 sm:right-3 p-1 sm:p-2 rounded-md 
                   bg-black/20 hover:bg-black/40 
                   backdrop-blur-sm
                   border border-white/10 hover:border-white/20
                   text-white/50 hover:text-white
                   transition-all duration-300
                   z-50">
          <X className="w-2.5 h-2.5 sm:w-4 sm:h-4" />
        </button>

        <div className="relative h-16 sm:h-32 rounded-t-md sm:rounded-t-xl overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/80" />
          <img 
            src={gameContent.image} 
            alt={gameContent.title} 
            className="w-full h-full object-cover"
          />
        </div>

        <form onSubmit={handleSubmit} className="space-y-1.5 sm:space-y-4 p-2 sm:p-4">
          <div className="text-center">
            <h2 className="text-sm sm:text-xl font-bold font-['Space_Grotesk'] text-white">
              {gameContent.title}
            </h2>
            <p className="text-[10px] sm:text-sm text-gray-400 mt-0.5 sm:mt-1">
              {gameContent.description}
            </p>
          </div>

          <div className="space-y-1.5 sm:space-y-3">
            <InputField
              icon={User}
              type="text"
              placeholder="Name"
              value={name}
              onChange={handleNameChange}
              inputRef={nameRef}
              error={errors.name}
            />

            <InputField
              icon={Building2}
              type="text"
              placeholder="Company"
              value={company}
              onChange={handleCompanyChange}
              error={errors.company}
            />

            <InputField
              icon={Mail}
              type="email"
              placeholder="Business email"
              value={email}
              onChange={setEmail}
              error={errors.email}
            />
          </div>

          <div className="space-y-1.5 sm:space-y-3 bg-black/20 p-1.5 sm:p-3 rounded-md sm:rounded-xl border border-game-accent/20">
            <h3 className="font-bold text-white text-[10px] sm:text-sm">What you'll get:</h3>
            <ul className="space-y-1 sm:space-y-1.5 text-[10px] sm:text-sm text-gray-400">
              {gameContent.benefits.map((benefit, index) => (
                <li key={index} className="flex items-start gap-1 sm:gap-1.5">
                  <ArrowRight className="w-2.5 h-2.5 sm:w-4 sm:h-4 text-game-accent flex-shrink-0 mt-0.5" />
                  {benefit}
                </li>
              ))}
            </ul>
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className={`w-full py-1.5 sm:py-2.5 rounded-md sm:rounded-xl
                      ${isSubmitting 
                        ? 'bg-gray-500 cursor-not-allowed' 
                        : 'bg-game-accent hover:bg-game-accent-hover'}
                      text-white text-[10px] sm:text-sm font-medium
                      transition-colors duration-200`}>
            {isSubmitting ? 'Submitting...' : 'Start Playing'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default TryGameModal; 