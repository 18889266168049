import { db } from '../config/firebase';
import { 
  collection, 
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  orderBy
} from 'firebase/firestore';

// Create new simulation
export const createSimulation = async (simulationData) => {
  try {
    // Clean and validate basic information
    const simulationToSave = {
      // Basic Information
      title: cleanString(simulationData.title),
      description: cleanString(simulationData.description),
      
      // Configuration
      participants: simulationData.participants || '1-10',
      duration: parseInt(simulationData.duration) || 30,
      
      // Classification
      targetAudience: Array.isArray(simulationData.targetAudience) ? 
        simulationData.targetAudience.filter(Boolean) : [],
      topic: cleanString(simulationData.topic),
      category: cleanString(simulationData.category),
      competencies: Array.isArray(simulationData.competencies) ? 
        simulationData.competencies.filter(item => item.value && item.label) : [],
      
      // KPIs - Ensure consistent structure
      kpis: (simulationData.kpis || []).map(kpi => ({
        id: kpi.id,
        name: cleanString(kpi.name),
        description: cleanString(kpi.description),
        startingValue: parseInt(kpi.startingValue) || 0,
        minValue: -20,
        maxValue: 20
      })),
      
      // Metadata
      status: {
        isActive: true,
        isPublished: false,
        isDraft: true
      },
      version: '1.0',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };

    // Save main simulation document
    const simulationsCol = collection(db, 'simulations');
    const docRef = await addDoc(simulationsCol, simulationToSave);

    // Handle scenarios
    if (simulationData.scenarios?.length > 0) {
      const scenariosCol = collection(db, `simulations/${docRef.id}/scenarios`);
      await Promise.all(simulationData.scenarios.map(async (scenario, index) => {
        // Clean and validate scenario data
        const scenarioToSave = {
          title: cleanString(scenario.title),
          category: cleanString(scenario.category),
          description: cleanString(scenario.description),
          date: cleanString(scenario.date),
          time: cleanString(scenario.time),
          
          // Sender info
          sender: {
            name: cleanString(scenario.sender?.name),
            avatar: scenario.sender?.avatar
          },
          
          // Message content
          message: {
            content: cleanString(scenario.message?.content),
            avatar: scenario.message?.avatar
          },
          
          // Options with KPI impacts
          options: scenario.options?.map(option => ({
            id: option.id,
            text: cleanString(option.text),
            reply: cleanString(option.reply),
            shortTermImpact: cleanString(option.shortTermImpact),
            longTermImpact: cleanString(option.longTermImpact),
            kpiImpacts: {
              shortTerm: cleanKpiImpacts(option.kpiImpacts?.shortTerm),
              longTerm: cleanKpiImpacts(option.kpiImpacts?.longTerm)
            }
          })) || [],
          
          // Metadata
          order: index + 1,
          status: {
            isActive: true,
            isPublished: false
          },
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        };
        
        await addDoc(scenariosCol, scenarioToSave);
      }));
    }

    return docRef.id;
  } catch (error) {
    console.error('Error creating simulation:', error);
    throw error;
  }
};

// Helper functions
const cleanString = (str) => {
  if (!str) return '';
  return str.trim();
};

const cleanKpiImpacts = (impacts) => {
  if (!impacts) return {};
  const cleanImpacts = {};
  Object.entries(impacts).forEach(([key, value]) => {
    cleanImpacts[key] = parseInt(value) || 0;
  });
  return cleanImpacts;
};

// Get all simulations
export const getSimulations = async () => {
  try {
    const simulationsCol = collection(db, 'simulations');
    const q = query(simulationsCol, orderBy('createdAt', 'desc'));
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error getting simulations:', error);
    throw error;
  }
};

// Get single simulation with scenarios
export const getSimulation = async (simulationId) => {
  try {
    const simulationRef = doc(db, 'simulations', simulationId);
    const simulationDoc = await getDoc(simulationRef);
    
    if (!simulationDoc.exists()) {
      throw new Error('Simulation not found');
    }

    // Get scenarios
    const scenariosCol = collection(db, `simulations/${simulationId}/scenarios`);
    const scenariosSnapshot = await getDocs(query(scenariosCol, orderBy('order')));
    const scenarios = scenariosSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    return {
      id: simulationDoc.id,
      ...simulationDoc.data(),
      scenarios
    };
  } catch (error) {
    console.error('Error getting simulation:', error);
    throw error;
  }
};

// Add this function to simulationService.js
export const deleteSimulation = async (simulationId) => {
  try {
    const simulationRef = doc(db, 'simulations', simulationId);
    await deleteDoc(simulationRef);
    return true;
  } catch (error) {
    console.error('Error deleting simulation:', error);
    throw error;
  }
};

// Add this function to handle updates
export const updateSimulation = async (simulationId, simulationData) => {
  try {
    const simulationRef = doc(db, 'simulations', simulationId);
    
    // Update main simulation document
    await updateDoc(simulationRef, {
      // Basic Information
      title: cleanString(simulationData.title),
      description: cleanString(simulationData.description),
      
      // Configuration
      participants: simulationData.participants || '1-10',
      duration: parseInt(simulationData.duration) || 30,
      
      // Classification
      targetAudience: Array.isArray(simulationData.targetAudience) ? 
        simulationData.targetAudience.filter(Boolean) : [],
      topic: cleanString(simulationData.topic),
      category: cleanString(simulationData.category),
      competencies: Array.isArray(simulationData.competencies) ? 
        simulationData.competencies.filter(item => item.value && item.label) : [],
      
      // KPIs
      kpis: (simulationData.kpis || []).map(kpi => ({
        id: kpi.id,
        name: cleanString(kpi.name),
        description: cleanString(kpi.description),
        startingValue: parseInt(kpi.startingValue) || 0,
        minValue: -20,
        maxValue: 20
      })),
      
      // Metadata
      status: {
        isActive: true,
        isPublished: false,
        isDraft: true
      },
      updatedAt: new Date().toISOString()
    });

    // Handle scenarios update
    if (simulationData.scenarios?.length > 0) {
      // First, delete all existing scenarios
      const scenariosCol = collection(db, `simulations/${simulationId}/scenarios`);
      const existingScenarios = await getDocs(scenariosCol);
      await Promise.all(existingScenarios.docs.map(doc => deleteDoc(doc.ref)));

      // Then add all current scenarios
      await Promise.all(simulationData.scenarios.map(async (scenario, index) => {
        const scenarioToSave = {
          ...scenario,
          order: index + 1,
          status: {
            isActive: true,
            isPublished: false
          },
          updatedAt: new Date().toISOString()
        };
        await addDoc(scenariosCol, scenarioToSave);
      }));
    }

    return true;
  } catch (error) {
    console.error('Error updating simulation:', error);
    throw error;
  }
}; 