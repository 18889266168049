import React from 'react';

function TopBar() {
  return (
    <div className="h-[60px] w-full bg-indigo-900 border-b border-indigo-500/30">
      {/* Top bar content */}
    </div>
  );
}

export default TopBar; 