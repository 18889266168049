import React from 'react';
import KPIGroup from '../components/dashboard/KPIGroup';
import ScoreDisplay from '../components/dashboard/ScoreDisplay';

const KpiContainer = ({ updatedValues, isUpdating, currentScenarioId }) => {
  const allKPIs = [
    { label: "Profitability", iconName: "mdi:chart-line" },
    { label: "Revenue growth", iconName: "mdi:trending-up" },
    { label: "Market share", iconName: "mdi:chart-pie" },
    { label: "Legal expenses", iconName: "mdi:scale-balance" },
    { label: "Customer loyalty", iconName: "mdi:heart" },
    { label: "Customer satisfaction", iconName: "mdi:emoticon-happy" },
    { label: "Reputation", iconName: "mdi:star" },
    { label: "Shareholders satisfaction", iconName: "mdi:account-group" },
    { label: "Employee morale & engagement", iconName: "mdi:account-heart" }
  ];

  return (
    <div className="min-h-[40px] h-[5%] max-h-[60px] bg-gray-900 border-b border-gray-800 flex items-center px-2">
      <ScoreDisplay 
        kpiValues={updatedValues} 
        currentScenarioId={currentScenarioId}
      />
      
      <div className="flex-1 ml-2">
        <div className="flex items-stretch bg-gray-900 border border-gray-800 rounded-lg w-full">
          {allKPIs.map((kpi, index) => (
            <React.Fragment key={kpi.label}>
              <div className="flex-1 flex items-center justify-center">
                <KPIGroup 
                  item={kpi}
                  updatedValues={updatedValues} 
                  isUpdating={isUpdating}
                />
              </div>
              {index < allKPIs.length - 1 && (
                <div className="w-px bg-gray-800 h-full self-stretch" />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default KpiContainer;
