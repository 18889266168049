import React, { createContext, useContext, useState, useEffect } from 'react';
import { getScenariosByRound, getTotalRounds, getScenarioById } from './ScenarioDatabase';
import { getInitialScores } from './ScoresDatabase';
import { SHOP_ITEMS } from './ShopDatabase';
import { calculateMarketImpact, calculateRemainingGrowth } from './marketConfidence';
import { calculateBankBalanceScore, calculateRiskLevelScore, calculateComplianceScore } from './scoring';
import { KPI_TYPES } from './constants';
import { saveProgress, loadProgress } from '../services/progressService';

const SimulationContext = createContext();

export const SimulationProvider = ({ children }) => {
  const [currentRound, setCurrentRound] = useState(1);
  const [scenarioChoices, setScenarioChoices] = useState({});
  const [scores, setScores] = useState(getInitialScores());
  const [shopItems, setShopItems] = useState(SHOP_ITEMS);
  const [pendingScores, setPendingScores] = useState({});
  const [activeGrowthEffects, setActiveGrowthEffects] = useState([]);
  const [pendingBankBalance, setPendingBankBalance] = useState({});
  const [pendingRiskLevel, setPendingRiskLevel] = useState({});
  const [pendingCompliance, setPendingCompliance] = useState({});
  const [isSimulationComplete, setIsSimulationComplete] = useState(false);  // Add this line
  const totalRounds = getTotalRounds();
  const [unlockedRounds, setUnlockedRounds] = useState({ 1: true });
  const [choicesHistory, setChoicesHistory] = useState({});
  const [sessionId, setSessionId] = useState(null);
  const [gameStarted, setGameStarted] = useState(false);
  const [purchasedEffects, setPurchasedEffects] = useState({});
  const [activeEffects, setActiveEffects] = useState({});
  const [usedOneTimeEffects, setUsedOneTimeEffects] = useState([]);
  const [effectUses, setEffectUses] = useState({});
  const [currentRoundUsedEffects, setCurrentRoundUsedEffects] = useState([]);

  // Load progress when component mounts
  useEffect(() => {
    const loadSavedProgress = async () => {
      try {
        const existingSessionId = localStorage.getItem('sessionId');
        const currentSessionId = existingSessionId || crypto.randomUUID();
        
        console.log('Session ID:', currentSessionId);
        
        if (!existingSessionId) {
          localStorage.setItem('sessionId', currentSessionId);
          console.log('New session ID created and stored');
        }
        
        setSessionId(currentSessionId);

        const savedProgress = await loadProgress(currentSessionId);
        console.log('Loaded progress:', savedProgress);
        
        if (savedProgress) {
          setCurrentRound(savedProgress.currentRound || 1);
          setScenarioChoices(savedProgress.choices || {});
          setScores(savedProgress.scores || getInitialScores());
          setUnlockedRounds(savedProgress.unlockedRounds || { 1: true });
          setGameStarted(savedProgress.gameStarted || false);
          
          // Load saved shop state
          if (savedProgress.shopItems) {
            setShopItems(savedProgress.shopItems);
          }
          if (savedProgress.purchasedEffects) {
            // Filter out used one-time effects
            const filteredEffects = { ...savedProgress.purchasedEffects };
            if (savedProgress.usedOneTimeEffects) {
              savedProgress.usedOneTimeEffects.forEach(effect => {
                delete filteredEffects[effect];
              });
            }
            setPurchasedEffects(filteredEffects);
          }
          if (savedProgress.usedOneTimeEffects) {
            setUsedOneTimeEffects(savedProgress.usedOneTimeEffects);
          }
          if (savedProgress.effectUses) {
            setEffectUses(savedProgress.effectUses);
          }
          if (savedProgress.currentRoundUsedEffects) {
            setCurrentRoundUsedEffects(savedProgress.currentRoundUsedEffects);
          }
        }
      } catch (error) {
        console.error('Error loading progress:', error);
        // Set default values if loading fails
        setCurrentRound(1);
        setScenarioChoices({});
        setScores(getInitialScores());
        setUnlockedRounds({ 1: true });
        setGameStarted(false);
        setPurchasedEffects({});
        setUsedOneTimeEffects([]);
        setEffectUses({});
        setCurrentRoundUsedEffects([]);
      }
    };

    loadSavedProgress();
  }, []);

  // Save progress whenever important state changes
  useEffect(() => {
    const saveGameProgress = async () => {
      if (!sessionId) return;

      const progressData = {
        currentRound,
        choices: scenarioChoices,
        scores,
        unlockedRounds,
        gameStarted,
        shopItems,
        purchasedEffects,
        usedOneTimeEffects,
        effectUses,
        currentRoundUsedEffects,
        lastUpdated: new Date().toISOString()
      };

      try {
        await saveProgress(sessionId, progressData);
      } catch (error) {
        console.error('Error saving progress:', error);
        // Optionally implement retry logic here
      }
    };

    const debounceTimeout = setTimeout(() => {
      saveGameProgress();
    }, 1000); // Debounce for 1 second

    return () => clearTimeout(debounceTimeout);
  }, [currentRound, scenarioChoices, scores, unlockedRounds, sessionId, gameStarted, shopItems, purchasedEffects, usedOneTimeEffects, effectUses, currentRoundUsedEffects]);

  const updateKPI = (kpiType, amount) => {
    if (kpiType === KPI_TYPES.BANK_BALANCE) {
      setScores(prev => ({
        ...prev,
        [kpiType]: {
          ...prev[kpiType],
          value: prev[kpiType].value + amount
        }
      }));
    } else {
      setScores(prev => ({
        ...prev,
        [kpiType]: {
          ...prev[kpiType],
          value: Math.max(0, Math.min(100, prev[kpiType].value + amount))
        }
      }));
    }
  };

  const addGrowthEffect = (effect) => {
    setActiveGrowthEffects(prev => [...prev, {
      ...effect,
      roundsLeft: effect.duration,
      startedAt: currentRound
    }]);
  };

  const setChoice = (scenarioId, choice) => {
    const scenario = getScenarioById(scenarioId);
    
    console.log('Setting choice:', {
        scenarioId,
        choice,
        scenario,
        calculatedRisk: calculateRiskLevelScore(scenario, choice)
    });
    
    // Calculate Market Confidence impact
    const marketImpact = calculateMarketImpact(scenario, choice);
    if (marketImpact.immediate) {
      setPendingScores(prev => ({
        ...prev,
        [scenarioId]: marketImpact.immediate
      }));
    }
    if (marketImpact.growth) {
      addGrowthEffect(marketImpact.growth);
    }

    // Calculate Bank Balance impact
    const bankBalanceImpact = calculateBankBalanceScore(scenario, choice);
    setPendingBankBalance(prev => ({
      ...prev,
      [scenarioId]: bankBalanceImpact
    }));

    // Calculate Risk Level impact
    const riskLevelImpact = calculateRiskLevelScore(scenario, choice);
    setPendingRiskLevel(prev => ({
      ...prev,
      [scenarioId]: riskLevelImpact
    }));

    // Calculate Compliance impact
    const complianceImpact = calculateComplianceScore(scenario, choice);
    setPendingCompliance(prev => ({
      ...prev,
      [scenarioId]: complianceImpact
    }));

    setScenarioChoices(prev => ({
      ...prev,
      [scenarioId]: choice
    }));
    
    setChoicesHistory(prev => ({
      ...prev,
      [scenarioId]: choice
    }));
  };

  const applyPendingScores = () => {
    console.log('Applying pending scores:', {
        pendingRiskLevel,
        totalRiskLevelImpact: Object.values(pendingRiskLevel).reduce((sum, impact) => sum + impact, 0)
    });
    
    // Apply Market Confidence impacts
    const totalMarketImpact = Object.values(pendingScores).reduce((sum, impact) => sum + impact, 0);
    updateKPI(KPI_TYPES.MARKET_CONFIDENCE, totalMarketImpact);
    setPendingScores({});

    // Apply Bank Balance impacts
    const totalBankBalanceImpact = Object.values(pendingBankBalance).reduce((sum, impact) => sum + impact, 0);
    updateKPI(KPI_TYPES.BANK_BALANCE, totalBankBalanceImpact);
    setPendingBankBalance({});

    // Apply Risk Level impacts
    const totalRiskLevelImpact = Object.values(pendingRiskLevel).reduce((sum, impact) => sum + impact, 0);
    updateKPI(KPI_TYPES.RISK_LEVEL, totalRiskLevelImpact);
    setPendingRiskLevel({});

    // Apply Compliance impacts
    const totalComplianceImpact = Object.values(pendingCompliance).reduce((sum, impact) => sum + impact, 0);
    updateKPI(KPI_TYPES.COMPLIANCE_SCORE, totalComplianceImpact);
    setPendingCompliance({});
  };

  const updateShopItem = (itemId, updates) => {
    setShopItems(prev => prev.map(item => {
      if (item.id === itemId) {
        // When an item is purchased, add its effects to purchasedEffects
        if (updates.purchased) {
          setPurchasedEffects(prev => ({
            ...prev,
            ...item.effects
          }));
        }
        return { ...item, ...updates };
      }
      return item;
    }));
  };

  const processMarketGrowth = () => {
    // Process immediate impacts first
    applyPendingScores();

    // Process active growth effects
    activeGrowthEffects.forEach(effect => {
      if (effect.roundsLeft > 0) {
        updateKPI(KPI_TYPES.MARKET_CONFIDENCE, effect.amount);
      }
    });

    // Update remaining rounds for growth effects
    setActiveGrowthEffects(prev => 
      prev
        .map(effect => ({
          ...effect,
          roundsLeft: effect.roundsLeft - 1
        }))
        .filter(effect => effect.roundsLeft > 0)
    );
  };

  const advanceToNextRound = () => {
    processMarketGrowth();

    if (currentRound < totalRounds) {
      setCurrentRound(prev => prev + 1);
      setScenarioChoices({});
      setPendingScores({});
      setPendingBankBalance({});
      setPendingRiskLevel({});
      setPendingCompliance({});
      setActiveEffects({});
      setCurrentRoundUsedEffects([]);
      
      // Reset uses for perpetual effects only
      setEffectUses(prev => {
        const newUses = { ...prev };
        if (newUses.perpetual_risk_reduction !== undefined) {
          newUses.perpetual_risk_reduction = 0;
        }
        if (newUses.perpetual_compliance_boost !== undefined) {
          newUses.perpetual_compliance_boost = 0;
        }
        return newUses;
      });
    } else {
      // Handle end game
      const remainingGrowth = calculateRemainingGrowth(activeGrowthEffects);
      if (remainingGrowth > 0) {
        updateKPI(KPI_TYPES.MARKET_CONFIDENCE, remainingGrowth);
      }
      setActiveGrowthEffects([]);
    }
  };

  const unlockRound = (round) => {
    setUnlockedRounds(prev => ({
      ...prev,
      [round]: true
    }));
  };

  const completeSimulation = () => {
    // Process any remaining market growth effects
    processMarketGrowth();
    
    // Calculate any remaining growth
    const remainingGrowth = calculateRemainingGrowth(activeGrowthEffects);
    if (remainingGrowth > 0) {
      updateKPI(KPI_TYPES.MARKET_CONFIDENCE, remainingGrowth);
    }
    
    // Clear active effects
    setActiveGrowthEffects([]);
    
    // Set simulation as complete WITHOUT clearing the choices
    setIsSimulationComplete(true);
  };

  // Modify toggleEffect to handle per-round usage
  const toggleEffect = (effectName) => {
    console.log('Toggle Effect:', {
      effectName,
      currentActiveEffects: activeEffects,
      currentPurchasedEffects: purchasedEffects
    });

    const shopItem = SHOP_ITEMS.find(item => Object.keys(item.effects)[0] === effectName);
    const currentUses = effectUses[effectName] || 0;

    // Check if max uses reached
    if (shopItem?.maxUses && currentUses >= shopItem.maxUses) {
      return;
    }

    // Handle market confidence boosts
    if (effectName === 'market_confidence_boost') {
      updateKPI(KPI_TYPES.MARKET_CONFIDENCE, 10);
      setEffectUses(prev => ({
        ...prev,
        [effectName]: (prev[effectName] || 0) + 1
      }));
      setPurchasedEffects(prev => {
        const { market_confidence_boost, ...rest } = prev;
        return rest;
      });
      return;
    }

    // Handle small market confidence boost
    if (effectName === 'market_confidence_small_boost') {
      updateKPI(KPI_TYPES.MARKET_CONFIDENCE, 5);
      setEffectUses(prev => ({
        ...prev,
        [effectName]: (prev[effectName] || 0) + 1
      }));
      setPurchasedEffects(prev => {
        const { market_confidence_small_boost, ...rest } = prev;
        return rest;
      });
      return;
    }

    // Handle compliance boost
    if (effectName === 'compliance_boost') {
      updateKPI(KPI_TYPES.COMPLIANCE_SCORE, 40);
      setEffectUses(prev => ({
        ...prev,
        [effectName]: (prev[effectName] || 0) + 1
      }));
      setPurchasedEffects(prev => {
        const { compliance_boost, ...rest } = prev;
        return rest;
      });
      return;
    }

    // Handle perpetual risk reduction
    if (effectName === 'perpetual_risk_reduction') {
      if (!currentRoundUsedEffects.includes(effectName)) {
        updateKPI(KPI_TYPES.RISK_LEVEL, -2);
        setEffectUses(prev => ({
          ...prev,
          [effectName]: (prev[effectName] || 0) + 1
        }));
        setCurrentRoundUsedEffects(prev => [...prev, effectName]);
      }
      return;
    }

    // Handle perpetual compliance boost
    if (effectName === 'perpetual_compliance_boost') {
      if (!currentRoundUsedEffects.includes(effectName)) {
        updateKPI(KPI_TYPES.COMPLIANCE_SCORE, 3);
        setEffectUses(prev => ({
          ...prev,
          [effectName]: (prev[effectName] || 0) + 1
        }));
        setCurrentRoundUsedEffects(prev => [...prev, effectName]);
      }
      return;
    }

    // Handle team boost
    if (effectName === 'team_boost') {
      if (!currentRoundUsedEffects.includes(effectName)) {
        updateKPI(KPI_TYPES.MARKET_CONFIDENCE, 1);
        updateKPI(KPI_TYPES.RISK_LEVEL, -1);
        updateKPI(KPI_TYPES.COMPLIANCE_SCORE, 2);
        setEffectUses(prev => ({
          ...prev,
          [effectName]: (prev[effectName] || 0) + 1
        }));
        setCurrentRoundUsedEffects(prev => [...prev, effectName]);
      }
      return;
    }

    // For show_importance and other toggle effects
    if (!['market_confidence_boost', 'market_confidence_small_boost', 'compliance_boost', 'perpetual_risk_reduction', 'perpetual_compliance_boost', 'team_boost'].includes(effectName)) {
      console.log('Toggling visibility effect:', effectName);
      
      setActiveEffects(prev => {
        const newState = {
          ...prev,
          [effectName]: !prev[effectName]
        };
        console.log('New active effects state:', newState);
        return newState;
      });

      // Only count usage if activating and hasn't been used this round
      if (!activeEffects[effectName] && !currentRoundUsedEffects.includes(effectName)) {
        setEffectUses(prev => ({
          ...prev,
          [effectName]: (prev[effectName] || 0) + 1
        }));
        setCurrentRoundUsedEffects(prev => [...prev, effectName]);
      }
    }
  };

  // Modify hasEffect to check both purchased and active state
  const hasEffect = (effectName) => {
    // Omnius is always active if purchased
    if (effectName === 'enable_omnius') {
      return !!purchasedEffects[effectName];
    }
    // Other effects need to be both purchased and active
    return !!purchasedEffects[effectName] && !!activeEffects[effectName];
  };

  return (
    <SimulationContext.Provider 
    value={{ 
      scenarios: getScenariosByRound(currentRound),
      currentRound,
      totalRounds,
      choices: isSimulationComplete ? choicesHistory : scenarioChoices,
      scores,
      shopItems,
      pendingScores,
      pendingBankBalance,
      pendingRiskLevel,
      pendingCompliance,
      setChoice,
      updateScore: updateKPI,
      updateShopItem,
      advanceToNextRound,
      isLastRound: currentRound === totalRounds,
      activeGrowthEffects,
      unlockedRounds,
      unlockRound,
      isSimulationComplete,
      completeSimulation,
      gameStarted,
      setGameStarted,
      purchasedEffects,
      hasEffect,
      toggleEffect,
      effectUses,
      currentRoundUsedEffects,
    }}
    >
      {children}
    </SimulationContext.Provider>
  );
};

export const useSimulation = () => {
  const context = useContext(SimulationContext);
  if (!context) {
    throw new Error('useSimulation must be used within a SimulationProvider');
  }
  return context;
};