import React from 'react';
import { DollarSign, TrendingUp, AlertTriangle, Shield } from 'lucide-react';
import { getKpiStatus } from '../../ai-gone-rogue/ScoresDatabase';
import './Metric.css';

const ICON_MAP = {
  'dollar-sign': DollarSign,
  'trending-up': TrendingUp,
  'alert-triangle': AlertTriangle,
  'shield': Shield
};

function Metric({ kpi }) {
  const { label, value, prefix, suffix, icon, description, id } = kpi;
  const status = getKpiStatus(kpi);
  const IconComponent = ICON_MAP[icon];

  const formatValue = (value, id) => {
    // Special formatting for bank balance
    if (id === 'bank_balance') {
      return value.toLocaleString(); // This will format 5000 as "5,000"
    }
    return value;
  };

  return (
    <div className="metric" title={description}>
      <div className={`metric-icon ${status}`}>
        {IconComponent && <IconComponent size={18} />}
      </div>
      <div className="metric-content">
        <span className="metric-label">{label}</span>
        <span className={`metric-value ${status}`}>
          {prefix}{formatValue(value, id)}{suffix}
        </span>
      </div>
    </div>
  );
}

export default Metric;