import React from 'react';
import { useAntarctica } from '../context/AntarcticaContext';
import gameConfig from '../content/game-config.json';
import foodIcon from '../assets/images/food.webp';
import woodIcon from '../assets/images/wood.webp';
import './Resources.css';

function Resources() {
  const { resources } = useAntarctica();
  const { container, food: foodConfig, wood: woodConfig } = gameConfig.resources;

  const resourceIcons = {
    food: foodIcon,
    wood: woodIcon
  };

  const renderResource = (type, config) => (
    <div className={config.ui.className}>
      <img 
        src={resourceIcons[type]} 
        alt={config.ui.iconAlt} 
        className={config.ui.iconClassName} 
      />
      <div className={config.ui.valueClassName}>
        {resources[type]}
      </div>
    </div>
  );

  return (
    <div className={container.className}>
      {renderResource('food', foodConfig)}
      {renderResource('wood', woodConfig)}
    </div>
  );
}

export default Resources; 