import React from 'react';
import { useAntarctica } from '../context/AntarcticaContext';
import gameConfig from '../content/game-config.json';
import moraleIcon from '../assets/images/morale-counter.webp';
import './Morale.css';

function Morale() {
  const { resources } = useAntarctica();
  const { ui } = gameConfig.morale;
  
  const formattedValue = ui.valueFormat.replace('%d', resources.morale);
  
  return (
    <div className={ui.className}>
      <img 
        src={moraleIcon} 
        alt={ui.iconAlt} 
        className={`${ui.className}__icon`}
      />
      <div className={`${ui.className}__content`}>
        <span className={`${ui.className}__count`}>{formattedValue}</span>
      </div>
    </div>
  );
}

export default Morale; 