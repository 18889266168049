import React from 'react';
import { AlertTriangle, CheckCircle, TrendingUp, BarChart, Shield, ClipboardCheck, Box, Calendar, Users } from 'lucide-react';
import { useSimulation } from '../../ai-gone-rogue/SimulationContext';
import { SHOP_ITEMS } from '../../ai-gone-rogue/ShopDatabase';
import { playNextRoundSound } from '../../utils/SoundEffects';
import './BoughtItems.css';

const ITEM_ICONS = {
  'show_red_flags': AlertTriangle,
  'show_green_flags': CheckCircle,
  'market_confidence_boost': TrendingUp,
  'market_confidence_small_boost': BarChart,
  'perpetual_risk_reduction': Shield,
  'compliance_boost': ClipboardCheck,
  'perpetual_compliance_boost': Box,
  'show_importance': Calendar,
  'team_boost': Users
};

const ITEM_LABELS = {
  'show_red_flags': 'Risks',
  'show_green_flags': 'Patch',
  'market_confidence_boost': 'PR Firm',
  'market_confidence_small_boost': 'Conference',
  'perpetual_risk_reduction': 'InfoSec',
  'compliance_boost': 'Audit',
  'perpetual_compliance_boost': 'Sandbox',
  'show_importance': 'Sprint',
  'team_boost': 'Retro'
};

function BoughtItems() {
  const { purchasedEffects, hasEffect, toggleEffect, effectUses } = useSimulation();

  const getShopItem = (effectName) => {
    return SHOP_ITEMS.find(item => Object.keys(item.effects)[0] === effectName);
  };

  const activeEffects = Object.keys(purchasedEffects).filter(
    effect => effect !== 'enable_omnius'
  );

  if (activeEffects.length === 0) return null;

  const handleItemClick = (effect) => {
    playNextRoundSound();
    toggleEffect(effect);
  };

  return (
    <div className="bought-items">
      {activeEffects.map(effect => {
        const Icon = ITEM_ICONS[effect];
        const isActive = hasEffect(effect);
        const shopItem = getShopItem(effect);
        const isPerpetual = shopItem?.type === 'perpetual';
        const remainingUses = shopItem?.maxUses ? 
          (shopItem.maxUses - (effectUses[effect] || 0)) : 
          null;
        
        if (remainingUses === 0 && !isPerpetual) return null;

        return (
          <div 
            key={effect} 
            className={`bought-item ${isActive ? 'active' : ''} ${shopItem?.type}`} 
            onClick={() => handleItemClick(effect)}
          >
            <div className="bought-item__icon-circle">
              <Icon className="bought-item__icon" />
              {remainingUses !== null && (
                <div className={`bought-item__uses ${isPerpetual ? 'perpetual' : ''}`}>
                  {remainingUses}
                </div>
              )}
            </div>
            <span className="bought-item__label">{ITEM_LABELS[effect]}</span>
          </div>
        );
      })}
    </div>
  );
}

export default BoughtItems; 