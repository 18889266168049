import React, { useState } from 'react';
import TopBar from './layout/TopBar';
import BottomBar from './layout/BottomBar';
import LeftBar from './layout/LeftBar';
import RightBar from './layout/RightBar';
import GameArea from './layout/GameArea';
import StartScreen from './components/StartScreen';
import antarcticaBg from './assets/images/antarctica-bg.webp';
import './AntarcticaView.css';

function AntarcticaView() {
  const [isStarted, setIsStarted] = useState(false);
  const [selectedCharacters, setSelectedCharacters] = useState(new Set());
  const [activeCharacterId, setActiveCharacterId] = useState(null);
  const [selectedStrategy, setSelectedStrategy] = useState(null);

  const handleStart = (characters) => {
    setSelectedCharacters(characters);
    setIsStarted(true);
  };

  const handleCharacterClick = (characterId) => {
    setActiveCharacterId(characterId);
  };

  const handleStrategySelect = (strategy) => {
    setSelectedStrategy(strategy);
  };

  return (
    <div className="antarctica-view" style={{ height: '100vh' }}>
      <div 
        className="antarctica-background" 
        style={{ backgroundImage: `url(${antarcticaBg})` }}
      />
      {!isStarted ? (
        <StartScreen onStart={handleStart} />
      ) : (
        <>
          <TopBar />
          <div className="antarctica-content">
            <LeftBar 
              selectedCharacters={selectedCharacters} 
              onCharacterClick={handleCharacterClick}
            />
            <GameArea 
              selectedCharacterId={activeCharacterId}
              selectedStrategy={selectedStrategy}
              onStrategySelect={handleStrategySelect}
            />
            <RightBar />
          </div>
          <BottomBar />
        </>
      )}
    </div>
  );
}

export default AntarcticaView; 