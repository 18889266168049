import React, { useState, useEffect, useRef } from 'react';
import gameConfig from '../content/game-config.json';
import './Workers.css';
import { useAntarctica } from '../context/AntarcticaContext';

function Workers() {
  const [selectedWorkers, setSelectedWorkers] = useState(new Set());
  const [crews, setCrews] = useState([]);
  const [assignedToTasks, setAssignedToTasks] = useState(0);
  const [draggedCrew, setDraggedCrew] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);
  const selectionRef = useRef(null);
  
  const { survivors } = useAntarctica();
  const totalWorkers = survivors.current;
  const { disbandCrewTooltip, formCrewButtonText } = gameConfig.workers.ui;
  
  const totalAssignedWorkers = crews.reduce((acc, crew) => acc + crew.size, 0) + assignedToTasks;

  useEffect(() => {
    const handleCrewRemoved = (event) => {
      const crew = event.detail;
      setCrews(prevCrews => [...prevCrews, crew]);
      setAssignedToTasks(prev => prev - crew.size);
    };

    const handleSuccessfulDrop = () => {
      if (draggedCrew) {
        setAssignedToTasks(prev => prev + draggedCrew.size);
        setDraggedCrew(null);
      }
    };

    document.addEventListener('crewRemoved', handleCrewRemoved);
    document.addEventListener('crewDropped', handleSuccessfulDrop);
    
    return () => {
      document.removeEventListener('crewRemoved', handleCrewRemoved);
      document.removeEventListener('crewDropped', handleSuccessfulDrop);
    };
  }, [draggedCrew]);

  useEffect(() => {
    const availableWorkers = totalWorkers - totalAssignedWorkers;
    const allInCrews = availableWorkers === 0;
    
    document.dispatchEvent(new CustomEvent('workersStateChanged', {
      detail: {
        allWorkersInCrews: allInCrews,
        availableCrews: crews.length
      }
    }));
  }, [crews, assignedToTasks, totalWorkers, totalAssignedWorkers]);

  useEffect(() => {
    const handleSurvivorsUpdated = () => {
      // Force re-render of workers
      setCrews([]);
      setSelectedWorkers(new Set());
      setAssignedToTasks(0);
    };

    window.addEventListener('survivorsUpdated', handleSurvivorsUpdated);
    return () => {
      window.removeEventListener('survivorsUpdated', handleSurvivorsUpdated);
    };
  }, []);

  const handleWorkerClick = (workerId) => {
    if (workerId >= totalWorkers - totalAssignedWorkers) return;
    
    const newSelection = new Set(selectedWorkers);
    if (newSelection.has(workerId)) {
      newSelection.delete(workerId);
    } else {
      newSelection.add(workerId);
    }
    setSelectedWorkers(newSelection);
  };

  const handleFormCrew = () => {
    if (selectedWorkers.size > 0) {
      setCrews([...crews, {
        id: Date.now(),
        size: selectedWorkers.size
      }]);
      setSelectedWorkers(new Set());
    }
  };

  const handleDisbandCrew = (crewId) => {
    const crew = crews.find(c => c.id === crewId);
    if (crew) {
      setCrews(crews.filter(c => c.id !== crewId));
    }
  };

  const handleDragStart = (e, crew) => {
    e.dataTransfer.setData('application/json', JSON.stringify(crew));
    e.dataTransfer.effectAllowed = 'move';
    setDraggedCrew(crew);

    setTimeout(() => {
      setCrews(prevCrews => prevCrews.filter(c => c.id !== crew.id));
    }, 0);
  };

  const handleDragEnd = (e) => {
    if (e.dataTransfer.dropEffect === 'none' && draggedCrew) {
      setCrews(prevCrews => [...prevCrews, draggedCrew]);
      setDraggedCrew(null);
    }
  };

  const handleMouseDown = (e) => {
    // Only start selection if clicking directly on the container
    if (e.target === containerRef.current) {
      setIsDragging(true);
      const rect = containerRef.current.getBoundingClientRect();
      setDragStart({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      });

      // Create selection box
      if (selectionRef.current) {
        selectionRef.current.style.left = `${e.clientX - rect.left}px`;
        selectionRef.current.style.top = `${e.clientY - rect.top}px`;
        selectionRef.current.style.width = '0px';
        selectionRef.current.style.height = '0px';
      }
    }
  };

  const handleMouseMove = React.useCallback((e) => {
    if (!isDragging || !containerRef.current || !selectionRef.current) return;

    const rect = containerRef.current.getBoundingClientRect();
    const currentX = e.clientX - rect.left;
    const currentY = e.clientY - rect.top;

    // Calculate selection box dimensions
    const left = Math.min(dragStart.x, currentX);
    const top = Math.min(dragStart.y, currentY);
    const width = Math.abs(currentX - dragStart.x);
    const height = Math.abs(currentY - dragStart.y);

    // Update selection box
    selectionRef.current.style.left = `${left}px`;
    selectionRef.current.style.top = `${top}px`;
    selectionRef.current.style.width = `${width}px`;
    selectionRef.current.style.height = `${height}px`;

    // Select workers within the box
    const newSelection = new Set();
    const workerElements = containerRef.current.getElementsByClassName('worker');
    
    Array.from(workerElements).forEach((worker, index) => {
      if (index >= totalWorkers - totalAssignedWorkers) return;
      
      const workerRect = worker.getBoundingClientRect();
      const workerX = workerRect.left - rect.left + workerRect.width / 2;
      const workerY = workerRect.top - rect.top + workerRect.height / 2;

      if (
        workerX >= left && workerX <= left + width &&
        workerY >= top && workerY <= top + height
      ) {
        newSelection.add(index);
      }
    });

    setSelectedWorkers(newSelection);
  }, [isDragging, dragStart, totalWorkers, totalAssignedWorkers]);

  const handleMouseUp = React.useCallback(() => {
    setIsDragging(false);
    if (selectionRef.current) {
      selectionRef.current.style.width = '0px';
      selectionRef.current.style.height = '0px';
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [handleMouseMove, handleMouseUp]);

  return (
    <div className="workers">
      <div className="workers__crews">
        {crews.map(crew => (
          <div 
            key={crew.id} 
            className="crew-stack"
            draggable
            onDragStart={(e) => handleDragStart(e, crew)}
            onDragEnd={handleDragEnd}
            onClick={() => handleDisbandCrew(crew.id)}
            title={disbandCrewTooltip}
          >
            <div className="crew-stack__count">{crew.size}</div>
            <div className="crew-stack__disband-hint">×</div>
          </div>
        ))}
      </div>
      
      {selectedWorkers.size > 0 && (
        <button className="ice-button workers__form-crew" onClick={handleFormCrew}>
          <span className="ice-button__content">
            <span className="ice-button__text">
              {formCrewButtonText} ({selectedWorkers.size})
            </span>
          </span>
          <div className="ice-button__frost" />
        </button>
      )}

      <div 
        ref={containerRef}
        className="workers__container"
        onMouseDown={handleMouseDown}
      >
        <div 
          ref={selectionRef}
          className="workers__selection-box"
        />
        {Array.from({ length: totalWorkers }, (_, i) => {
          const isAssigned = i >= totalWorkers - totalAssignedWorkers;
          return (
            <div
              key={i}
              className={`worker 
                ${selectedWorkers.has(i) ? 'worker--selected' : ''} 
                ${isAssigned ? 'worker--assigned' : ''}`}
              onClick={() => handleWorkerClick(i)}
            >
              <div className="worker__portrait" />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Workers;