import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Sun, Moon, User, MessageSquare } from 'lucide-react';
import { useTheme } from '../../context/ThemeContext';
import { motion } from 'framer-motion';
import logo from '../assets/logo.svg';
import LoginModal from './LoginModal';
import ContactDialog from './ChatBot';

const Navbar = () => {
  const { isDark, toggleTheme } = useTheme();
  const [showLogin, setShowLogin] = useState(false);
  const [showContact, setShowContact] = useState(false);

  const handleContactToggle = () => {
    setShowContact(true);
  };

  const handleContactClose = () => {
    setShowContact(false);
  };

  return (
    <nav className={`fixed top-0 w-full border-b transition-colors duration-200 z-50
      ${isDark 
        ? 'bg-game-dark border-game-accent/10 text-white' 
        : 'bg-gray-50 border-gray-200 text-gray-800'
      }`}
    >
      <div className="max-w-7xl mx-auto flex justify-between items-center px-8 py-4">
        <div className="flex-1 flex justify-end">
          <Link 
            to="/" 
            className="group flex items-center relative"
          >
            <motion.div 
              className="flex items-center gap-3"
              whileHover={{ scale: 1.02 }}
            >
              <img 
                src={logo} 
                alt="Boring Training Logo" 
                className={`h-8 w-auto transition-opacity duration-200
                  ${isDark ? 'invert brightness-200' : 'brightness-75'}`}
              />
              <span className={`text-lg font-bold font-['Space_Grotesk'] tracking-tight
                ${isDark ? 'text-white/90' : 'text-gray-800'}`}
              >
                Boring Training
              </span>
            </motion.div>
          </Link>
        </div>
        
        <div className="flex-1 flex justify-start items-center space-x-6 pl-8">
          <button 
            onClick={handleContactToggle}
            className={`p-2 rounded-full transition-colors group
              ${isDark ? 'hover:bg-game-dark-lighter' : 'hover:bg-gray-100'}`}
            aria-label="Contact"
          >
            <MessageSquare className={`w-5 h-5 transition-colors
              ${isDark 
                ? 'text-white/80 group-hover:text-white' 
                : 'text-game-accent group-hover:text-game-accent-hover'
              }`} 
            />
          </button>

          <button
            onClick={toggleTheme}
            className={`hidden p-2 rounded-full transition-colors group
              ${isDark ? 'hover:bg-game-dark-lighter' : 'hover:bg-gray-100'}`}
            aria-label="Toggle theme"
          >
            {isDark ? (
              <Sun className="w-5 h-5 text-white/80 group-hover:text-white transition-colors" />
            ) : (
              <Moon className="w-5 h-5 text-game-accent group-hover:text-game-accent-hover transition-colors" />
            )}
          </button>

          <button 
            onClick={() => setShowLogin(true)}
            className={`p-2 rounded-full transition-colors group
              ${isDark ? 'hover:bg-game-dark-lighter' : 'hover:bg-gray-100'}`}
            aria-label="Login"
          >
            <User className={`w-5 h-5 transition-colors
              ${isDark 
                ? 'text-white/80 group-hover:text-white' 
                : 'text-game-accent group-hover:text-game-accent-hover'
              }`} 
            />
          </button>
        </div>
      </div>
      <LoginModal 
        isOpen={showLogin}
        onClose={() => setShowLogin(false)}
      />
      <ContactDialog 
        isOpen={showContact}
        onClose={handleContactClose}
      />
    </nav>
  );
};

export default Navbar; 