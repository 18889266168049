import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Shield, Users, Video, Plus, ChevronDown, Printer } from 'lucide-react';
import { getInitialGames } from './components/data/gameData';
import PricingSummary from './PricingSummary';
import QuoteDetailsForm from './QuoteDetailsForm';

// Move ParticipantsSelector component
const ParticipantsSelector = ({ participants, setParticipants }) => {
  const handleInputChange = (e) => {
    const rawValue = e.target.value.replace(/[^\d]/g, '');
    if (rawValue === '') {
      setParticipants(0);
      return;
    }
    const num = parseInt(rawValue);
    if (!isNaN(num)) {
      setParticipants(num);
    }
  };

  const formatNumber = (num) => {
    if (num === 0) return '';
    return num.toLocaleString('en-US');
  };

  return (
    <div className="space-y-4">
      <div className="text-sm uppercase tracking-wider text-blue-400 font-['Space_Grotesk']">
        Number of Participants
      </div>
      
      <div className="w-64">
        <div className="relative">
          <input
            type="text"
            inputMode="numeric"
            value={formatNumber(participants)}
            onChange={handleInputChange}
            className={`w-full px-6 py-4 bg-zinc-800/50 
              border-2 border-blue-500/20 rounded-sm
              text-white font-['Space_Grotesk'] text-2xl text-center
              focus:outline-none focus:border-blue-500/50
              transition-all duration-200
              hover:bg-zinc-800/70`}
            placeholder="0"
          />
          <div className="absolute inset-0 pointer-events-none">
            <div className="absolute -top-px left-4 right-4 h-px bg-gradient-to-r from-transparent via-blue-500/20 to-transparent" />
            <div className="absolute -bottom-px left-4 right-4 h-px bg-gradient-to-r from-transparent via-blue-500/20 to-transparent" />
          </div>
        </div>
      </div>
    </div>
  );
};

// Move DeliverySelector component
const DeliverySelector = ({ deliveryFormat, setDeliveryFormat }) => (
  <div className="space-y-4">
    <div className="text-sm uppercase tracking-wider text-blue-400 font-['Space_Grotesk']">
      Select Deployment Format
    </div>
    <div className="flex gap-4">
      <button
        onClick={() => setDeliveryFormat('face-to-face')}
        className={`group flex items-center gap-3 w-64 pl-4 pr-6 py-3
          border border-blue-500/20 rounded-sm
          transition-all duration-300
          ${deliveryFormat === 'face-to-face'
            ? 'bg-blue-500/20 text-white'
            : 'text-gray-400 hover:bg-blue-500/10 hover:text-white'
          }`}
      >
        <div className={`w-8 h-8 rounded flex items-center justify-center
          transition-all duration-300
          ${deliveryFormat === 'face-to-face'
            ? 'bg-blue-500'
            : 'bg-zinc-800 group-hover:bg-blue-500/20'
          }`}
        >
          <Users className="w-4 h-4" />
        </div>
        <span className="font-['Space_Grotesk'] font-medium">
          Face to Face
        </span>
        {deliveryFormat === 'face-to-face' && (
          <div className="ml-auto w-1.5 h-1.5 rounded-full bg-blue-500" />
        )}
      </button>

      <button
        onClick={() => setDeliveryFormat('virtual')}
        className={`group flex items-center gap-3 w-64 pl-4 pr-6 py-3
          border border-blue-500/20 rounded-sm
          transition-all duration-300
          ${deliveryFormat === 'virtual'
            ? 'bg-blue-500/20 text-white'
            : 'text-gray-400 hover:bg-blue-500/10 hover:text-white'
          }`}
      >
        <div className={`w-8 h-8 rounded flex items-center justify-center
          transition-all duration-300
          ${deliveryFormat === 'virtual'
            ? 'bg-blue-500'
            : 'bg-zinc-800 group-hover:bg-blue-500/20'
          }`}
        >
          <Video className="w-4 h-4" />
        </div>
        <span className="font-['Space_Grotesk'] font-medium">
          Virtual
        </span>
        {deliveryFormat === 'virtual' && (
          <div className="ml-auto w-1.5 h-1.5 rounded-full bg-blue-500" />
        )}
      </button>
    </div>
  </div>
);

// Move GameSelector component
const GameSelector = ({ selectedGames, setSelectedGames }) => {
  const games = getInitialGames();

  const toggleGame = (gameId) => {
    if (selectedGames.find(g => g.id === gameId)) {
      setSelectedGames(selectedGames.filter(g => g.id !== gameId));
    } else {
      setSelectedGames([...selectedGames, { id: gameId, participants: 20 }]);
    }
  };

  const updateParticipants = (gameId, count) => {
    setSelectedGames(selectedGames.map(game => 
      game.id === gameId ? { ...game, participants: count } : game
    ));
  };

  return (
    <div className="space-y-4">
      <div className="text-sm uppercase tracking-wider text-blue-400 font-['Space_Grotesk']">
        Select Games & Participants
      </div>
      
      <div className="grid grid-cols-4 gap-x-6 gap-y-8 max-w-2xl">
        {games.map((game) => {
          const selectedGame = selectedGames.find(g => g.id === game.id);
          const isSelected = !!selectedGame;

          return (
            <div key={game.id} className="relative h-32">
              <div className="flex flex-col items-center">
                <button
                  onClick={() => toggleGame(game.id)}
                  className={`group relative w-16 h-16 overflow-hidden rounded-xl
                    border-2 transition-all duration-200
                    ${isSelected
                      ? 'border-blue-500 shadow-lg shadow-blue-500/20'
                      : 'border-blue-500/20 hover:border-blue-500/50'
                    }`}
                >
                  {/* Game Image */}
                  <div className="absolute inset-0">
                    <img
                      src={game.image}
                      alt={game.title}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/40 via-transparent to-transparent" />
                  </div>

                  {/* Selected Overlay */}
                  {isSelected && (
                    <div className="absolute inset-0 bg-blue-500/20 backdrop-blur-[1px]" />
                  )}

                  {/* Selection Indicator */}
                  {isSelected && (
                    <div className="absolute top-1 right-1 w-1.5 h-1.5 rounded-full bg-blue-500" />
                  )}
                </button>
                
                {/* Game Title */}
                <p className="text-[10px] text-gray-400 font-['Space_Grotesk'] font-medium 
                             line-clamp-2 text-center w-full mt-2">
                  {game.title}
                </p>

                {/* Participants Input */}
                {isSelected && (
                  <div className="absolute bottom-0 left-0 right-0">
                    <div className="relative">
                      <input
                        type="number"
                        value={selectedGame.participants}
                        onChange={(e) => {
                          const value = Math.max(1, Math.min(1000, parseInt(e.target.value) || 0));
                          updateParticipants(game.id, value);
                        }}
                        className="w-full px-2 py-1 text-center text-xs bg-zinc-800/50 
                                 border border-blue-500/20 rounded-sm text-white
                                 focus:outline-none focus:border-blue-500/50"
                        min="1"
                        max="1000"
                        placeholder="No. of people"
                      />
                      <div className="absolute -top-5 left-0 right-0 text-[10px] text-gray-400 text-center">
                        Number of participants
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

// Update the FloatingSummary component
const FloatingSummary = ({ selectedOption, deliveryFormat, selectedGames }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showQuoteForm, setShowQuoteForm] = useState(false);
  const games = getInitialGames();

  const getVolumeDiscount = (totalSessions) => {
    if (totalSessions >= 6) return 0.12;  // 12% for 6+ sessions
    if (totalSessions >= 4) return 0.08;  // 8% for 4-5 sessions
    if (totalSessions >= 2) return 0.05;  // 5% for 2-3 sessions
    return 0;
  };

  const calculateGamePrice = (participants) => {
    if (deliveryFormat === 'face-to-face') {
      const sessions = Math.ceil(participants / 24); // 24 participants per session for face-to-face
      return {
        sessions,
        basePrice: 2600 * sessions, // $2,600 per session
        needsTravel: true
      };
    } else {
      const sessions = Math.ceil(participants / 50); // 50 participants per session for virtual
      return {
        sessions,
        basePrice: 1500 * sessions, // $1,500 per session
        participantPrice: 50 * participants, // $50 per participant
        needsTravel: false
      };
    }
  };

  // Calculate prices and sessions first
  const priceDetails = selectedGames.map(game => ({
    ...game,
    ...calculateGamePrice(game.participants)
  }));

  // Calculate total sessions across all games
  const totalSessions = priceDetails.reduce((sum, game) => sum + game.sessions, 0);

  // Calculate discount based on total sessions
  const volumeDiscount = getVolumeDiscount(totalSessions);
  const totalBeforeDiscount = priceDetails.reduce((sum, game) => 
    sum + game.basePrice + (game.participantPrice || 0), 0
  );
  const totalDiscountAmount = totalBeforeDiscount * volumeDiscount;
  const totalPrice = totalBeforeDiscount - totalDiscountAmount;

  const handlePrintSummary = () => {
    setShowQuoteForm(true);
  };

  const handleQuoteSubmit = (details) => {
    sessionStorage.setItem('quoteDetails', JSON.stringify(details));
    const summaryData = {
      deliveryFormat,
      selectedGames,
      subtotal: totalBeforeDiscount,
      discount: volumeDiscount,
      discountAmount: totalDiscountAmount,
      total: totalPrice
    };
    sessionStorage.setItem('summaryData', JSON.stringify(summaryData));
    window.open('/summary/facilitated', '_blank');
    setShowQuoteForm(false);
  };

  if (selectedGames.length === 0) return null;

  return (
    <>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className="fixed bottom-8 right-8 z-50 w-[500px]"
      >
        <div className="bg-zinc-900 border border-game-accent/20 rounded-xl shadow-2xl 
                      shadow-blue-500/5 backdrop-blur-sm overflow-hidden">
          {/* Compact View */}
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="w-full p-4 flex items-center justify-between gap-6 
                       hover:bg-zinc-800/50 transition-colors duration-200"
          >
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-lg bg-blue-500/10 flex items-center justify-center">
                <Shield className="w-5 h-5 text-blue-500" />
              </div>
              <div className="text-left">
                <div className="text-xs text-gray-400 font-['Space_Grotesk']">
                  {selectedGames.length} game{selectedGames.length > 1 ? 's' : ''} selected
                </div>
                <div className="text-lg font-bold text-white font-['Space_Grotesk']">
                  ${totalPrice.toLocaleString()}
                </div>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <motion.div
                animate={{ rotate: isExpanded ? 180 : 0 }}
                className="w-5 h-5 rounded-full bg-zinc-800 flex items-center justify-center"
              >
                <ChevronDown className="w-3 h-3 text-gray-400" />
              </motion.div>
            </div>
          </button>

          {/* Expanded Details */}
          <AnimatePresence>
            {isExpanded && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                className="border-t border-game-accent/10"
              >
                <div className="p-4 space-y-4">
                  {/* Selected Games List */}
                  <div className="space-y-2">
                    {priceDetails.map(game => {
                      const gameInfo = games.find(g => g.id === game.id);
                      return (
                        <div key={game.id} className="space-y-1">
                          <div className="flex items-center justify-between text-sm">
                            <span className="text-white font-['Space_Grotesk']">
                              {gameInfo.title}
                            </span>
                            <span className="text-gray-400 flex items-center gap-1">
                              <Users className="w-3 h-3" />
                              {game.participants}
                            </span>
                          </div>
                          <div className="text-xs text-gray-500 pl-2 space-y-0.5">
                            <div>{game.sessions} session{game.sessions > 1 ? 's' : ''} required</div>
                            <div className="flex justify-between">
                              <span>Base Price</span>
                              <span>${game.basePrice.toLocaleString()}</span>
                            </div>
                            {deliveryFormat === 'virtual' && game.participantPrice > 0 && (
                              <div className="flex justify-between">
                                <span>Participant Fee</span>
                                <span>${game.participantPrice.toLocaleString()}</span>
                              </div>
                            )}
                            <div className="flex justify-between font-medium text-white">
                              <span>Subtotal</span>
                              <span>${(game.basePrice + (game.participantPrice || 0)).toLocaleString()}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {/* Total Price */}
                  <div className="space-y-2 pt-2 border-t border-game-accent/10">
                    <div className="text-sm text-white font-['Space_Grotesk'] flex justify-between">
                      <span>Subtotal ({totalSessions} total sessions):</span>
                      <span>${totalBeforeDiscount.toLocaleString()}</span>
                    </div>
                    {volumeDiscount > 0 && (
                      <div className="text-xs text-green-400/80 flex justify-between">
                        <span>Volume Discount ({volumeDiscount * 100}%):</span>
                        <span>-${totalDiscountAmount.toLocaleString()}</span>
                      </div>
                    )}
                    {deliveryFormat === 'face-to-face' && (
                      <div className="text-xs text-amber-400/80">
                        * Travel expenses will be added based on location
                      </div>
                    )}
                    <div className="text-sm font-bold text-white font-['Space_Grotesk'] flex justify-between">
                      <span>Total:</span>
                      <span>${totalPrice.toLocaleString()}</span>
                    </div>
                  </div>

                  {/* Action Button */}
                  <div className="p-4">
                    <button
                      onClick={handlePrintSummary}
                      className="w-full px-4 py-2 bg-blue-500 hover:bg-blue-600
                               text-white font-['Space_Grotesk'] text-sm font-medium
                               rounded-lg transition-colors duration-200
                               flex items-center justify-center gap-2"
                    >
                      Print Summary
                      <Printer className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.div>

      {showQuoteForm && (
        <QuoteDetailsForm
          onSubmit={handleQuoteSubmit}
          type="facilitated"
        />
      )}
    </>
  );
};

// Create the main FacilitatedPrice component
const FacilitatedPrice = () => {
  const [deliveryFormat, setDeliveryFormat] = useState('face-to-face');
  const [selectedGames, setSelectedGames] = useState([]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      className="space-y-8"
    >
      <DeliverySelector 
        deliveryFormat={deliveryFormat} 
        setDeliveryFormat={setDeliveryFormat}
      />
      <GameSelector
        selectedGames={selectedGames}
        setSelectedGames={setSelectedGames}
      />
      <FloatingSummary
        selectedOption="facilitated"
        deliveryFormat={deliveryFormat}
        selectedGames={selectedGames}
      />
    </motion.div>
  );
};

export default FacilitatedPrice; 