import React from 'react';
import Score from '../game-elements/Score';
import { useSimulation } from '../../ai-gone-rogue/SimulationContext';
import { KPI_TYPES } from '../../ai-gone-rogue/constants';
import './SideBarRight.css';

function SideBarRight() {
  const { scores } = useSimulation();
  
  const calculateCustomerSatisfaction = () => {
    const riskScore = 100 - scores[KPI_TYPES.RISK_LEVEL].value;
    const marketScore = scores[KPI_TYPES.MARKET_CONFIDENCE].value;
    const complianceScore = scores[KPI_TYPES.COMPLIANCE_SCORE].value;
    const average = Math.round((riskScore + marketScore + complianceScore) / 3);
    return Math.max(0, Math.min(100, average));
  };

  return (
    <div id="customer-satisfaction" className="sidebar-right">
      <Score 
        value={calculateCustomerSatisfaction()} 
        maxValue={100}
        label="Customer Satisfaction"
      />
    </div>
  );
}

export default SideBarRight; 