import React from 'react';

function StartScreen({ onStart }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-amber-900">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-white mb-8">Operation Ares</h1>
        <button
          onClick={onStart}
          className="px-6 py-3 bg-white/10 hover:bg-white/20 
                   border border-white/30 hover:border-white/50 
                   rounded-lg text-white font-semibold
                   transition-all duration-300"
        >
          Start Mission
        </button>
      </div>
    </div>
  );
}

export default StartScreen; 