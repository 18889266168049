import React, { useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { X, Lock, AlertCircle } from 'lucide-react';

const PasswordModal = ({ isOpen, onClose, onSuccess }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password.toUpperCase() === 'START-GAME') {
      onSuccess();
      onClose();
    } else {
      setError(true);
      setTimeout(() => setError(false), 2000);
    }
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/80 animate-overlay-show backdrop-blur-sm" />
        <Dialog.Content className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90vw] max-w-[300px] bg-gray-900 rounded-xl shadow-2xl border border-gray-800 p-3 animate-content-show">
          <Dialog.Title className="flex items-center gap-2 text-sm font-bold text-gray-200 mb-3 pb-2 border-b border-gray-800">
            <Lock className="w-3 h-3 text-emerald-400" />
            Security Check
          </Dialog.Title>
          
          <form onSubmit={handleSubmit} className="space-y-3">
            <div>
              <label className="block text-gray-300 text-[10px] font-medium mb-1">
                Enter Password
              </label>
              <input
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={`w-full bg-gray-800 border ${error ? 'border-red-500' : 'border-gray-700'} 
                  rounded-lg px-2 py-1 text-[10px] text-gray-200 focus:outline-none focus:border-emerald-500
                  transition-colors duration-200`}
                placeholder="Enter password to continue playing"
              />
              {error && (
                <div className="flex items-center gap-1 mt-1 text-red-400 text-[10px]">
                  <AlertCircle className="w-3 h-3" />
                  <span>Incorrect password</span>
                </div>
              )}
            </div>

            <button
              type="submit"
              className="w-full bg-emerald-600 hover:bg-emerald-500 text-white rounded-lg py-1.5 
                text-[10px] font-medium transition-colors duration-200"
            >
              Unlock
            </button>
          </form>

          <Dialog.Close asChild>
            <button 
              className="absolute top-2 right-2 w-6 h-6 rounded-lg flex items-center justify-center 
                text-gray-400 hover:text-gray-200 hover:bg-gray-800 transition-all duration-200"
              aria-label="Close"
            >
              <X size={14} />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default PasswordModal; 