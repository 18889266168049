import React, { useState, useEffect } from 'react';
import { useAntarctica } from '../context/AntarcticaContext';
import gameConfig from '../content/game-config.json';
import './RoundResolution.css';

function RoundResolution({ onClose }) {
  const { currentRound, crewAssignments, resources } = useAntarctica();
  const [mortalityMessages, setMortalityMessages] = useState([]);

  useEffect(() => {
    const handleSurvivorsUpdated = (event) => {
      setMortalityMessages(event.detail.messages);
    };

    window.addEventListener('survivorsUpdated', handleSurvivorsUpdated);
    return () => {
      window.removeEventListener('survivorsUpdated', handleSurvivorsUpdated);
    };
  }, []);

  // Helper function to calculate task impacts
  const calculateTaskImpact = (taskId, crew) => {
    const config = gameConfig.tasks[taskId];
    if (!config) return null;

    const impacts = [];

    switch(taskId) {
      case 'rest':
        const healthRecovery = Math.min(
          Math.floor(crew.size / config.healthRecovery.workersPerPoint),
          config.healthRecovery.maxRecoveryPerRound
        );
        const moraleRecovery = Math.min(
          Math.floor(crew.size / config.moraleRecovery.workersPerPoint),
          config.moraleRecovery.maxRecoveryPerRound
        );
        if (healthRecovery > 0) impacts.push(`+${healthRecovery} health`);
        if (moraleRecovery > 0) impacts.push(`+${moraleRecovery} morale`);
        break;

      case 'food':
        const foodGathered = Math.min(
          crew.size * config.foodGathering.foodPerWorker,
          config.foodGathering.maxFoodPerRound
        );
        impacts.push(`+${foodGathered} food`);
        break;

      case 'wood':
        const woodGathered = Math.min(
          crew.size * config.woodGathering.woodPerWorker,
          config.woodGathering.maxWoodPerRound
        );
        impacts.push(`+${woodGathered} wood`);
        break;

      case 'heal':
        const healthGained = Math.min(
          crew.size * config.healing.healthPerWorker,
          config.healing.maxHealthPerRound
        );
        const foodCost = Math.max(
          crew.size * config.foodCost.foodPerWorker,
          config.foodCost.maxFoodCostPerRound
        );
        impacts.push(`+${healthGained} health`);
        impacts.push(`${foodCost} food`);
        break;

      case 'shelter':
        const shelterBuilt = Math.min(
          crew.size * config.shelterBuild.buildPerWorker,
          config.shelterBuild.maxBuildPerRound
        );
        const shelterWoodCost = Math.max(
          crew.size * config.woodCost.woodPerWorker,
          config.woodCost.maxWoodCostPerRound
        );
        impacts.push(`+${shelterBuilt} shelter`);
        impacts.push(`${shelterWoodCost} wood`);
        break;

      case 'repair':
        const repairPerWorker = config.boatRepair.repairPerWorker;
        const maxRepairPerRound = config.boatRepair.maxRepairPerRound;
        const woodPerWorker = config.woodCost.woodPerWorker;
        const maxWoodCost = config.woodCost.maxWoodCostPerRound;
        
        const boatRepair = Math.min(
          crew.size * repairPerWorker,
          maxRepairPerRound
        );
        const repairWoodCost = Math.max(
          crew.size * woodPerWorker,
          maxWoodCost
        );
        
        impacts.push(`+${boatRepair} boat`);
        impacts.push(`${repairWoodCost} wood`);
        break;

      case 'store':
        const foodPerWorker = config.foodCost.foodPerWorker;
        const maxFoodCost = config.foodCost.maxFoodCostPerRound;
        const foodNeeded = Math.abs(Math.max(
          crew.size * foodPerWorker,
          maxFoodCost
        ));

        // Check if we have enough food
        if (resources.food >= foodNeeded) {
          impacts.push(`+${foodNeeded}% food storage`);
        } else {
          // Show morale penalty when there's not enough food
          const moralePerWorker = config.moraleFallback.moralePerWorker;
          const maxMoraleCost = config.moraleFallback.maxMoraleCostPerRound;
          const moraleLoss = Math.max(
            crew.size * moralePerWorker,
            maxMoraleCost
          );
          impacts.push(`${moraleLoss} morale (not enough food)`);
        }
        break;

      default:
        impacts.push('No measurable impact');
        break;
    }

    return impacts.join(', ');
  };

  // Convert crew assignments into readable format with impacts
  const getAssignmentsList = () => {
    return Object.entries(crewAssignments).map(([taskId, crew]) => {
      const taskImpact = calculateTaskImpact(taskId, crew);
      
      return {
        taskName: taskId,
        crewSize: crew.size,
        impact: taskImpact
      };
    });
  };

  const assignments = getAssignmentsList();

  return (
    <div className="round-resolution">
      <div className="round-resolution__content">
        <button className="round-resolution__close" onClick={onClose}>×</button>
        
        <div className="round-resolution__page">
          <div className="round-resolution__date">December 15th, 1872</div>
          
          <div className="round-resolution__entry">
            <p className="round-resolution__day">
              Day <span className="round-resolution__number">{currentRound}</span>
            </p>

            <p className="round-resolution__narrative">
              The bitter cold continues to test our resolve, yet the crew's determination remains unshaken. 
              Today's assignments were carried out with admirable efficiency, despite the harsh conditions.
            </p>

            <p className="round-resolution__intro">
              Crew assignments and outcomes:
            </p>

            <ul className="round-resolution__assignments">
              {assignments.map((assignment, index) => (
                <li key={index}>
                  <span className="round-resolution__number">
                    {assignment.crewSize}
                  </span> crew members assigned to {assignment.taskName}
                  <span className="round-resolution__result">
                    → {assignment.impact}
                  </span>
                </li>
              ))}
              {mortalityMessages.map((message, index) => (
                <li key={`mortality-${index}`} className="round-resolution__mortality">
                  {message}
                </li>
              ))}
            </ul>

            <p className="round-resolution__closing">
              With each passing day, our chances of survival grow stronger. The men's resourcefulness 
              gives me hope that we shall overcome this ordeal and return home to tell our tale.
            </p>
          </div>

          <div className="round-resolution__signature">
            Ship's Captain
            <div className="round-resolution__seal">HMS VICTORIA</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoundResolution; 