import React from 'react';
import { DollarSign, TrendingDown, TrendingUp } from 'lucide-react';
import { playAlertClickSound } from '../../utils/SoundEffects';
import './ImpactAlert.css';

function ImpactAlert({ impact, onClose }) {
  if (!impact) return null;

  const isPositive = impact > 0;
  const getImpactIcon = () => {
    return isPositive ? <TrendingUp className="impact-icon positive" /> : <TrendingDown className="impact-icon negative" />;
  };

  const handleClick = () => {
    playAlertClickSound();
    onClose();
  };

  return (
    <div className="impact-alert-overlay">
      <div className="impact-alert">
        <div className="impact-header">
          <DollarSign className="impact-icon header-icon" />
          <h2 className="impact-title">Financial Impact</h2>
        </div>

        <div className="impact-content">
          <div className="impact-amount">
            {getImpactIcon()}
            <span className={isPositive ? 'positive' : 'negative'}>
              {isPositive ? '+' : ''}{impact.toLocaleString()}
            </span>
          </div>
          <p className="impact-description">
            {isPositive ? 
              'Your strategic decisions have positively impacted your budget.' :
              'Your strategic decisions have negatively impacted your budget.'
            }
          </p>
        </div>

        <button className="impact-button" onClick={handleClick}>
          Continue
        </button>
      </div>
    </div>
  );
}

export default ImpactAlert; 