import gamesData from './games.json';
import { Users, Video, Laptop, Building2 } from 'lucide-react';

// Import all images - adjusted paths
import AIRogueImage from '../../../assets/images/library-images/AIRogue.webp';
import CrisisCommanderImage from '../../../assets/images/library-images/CrisisCommander.webp';
import AMLImage from '../../../assets/images/library-images/AML.webp';
import ChangeCatalystImage from '../../../assets/images/library-images/ChangeCatalyst.webp';
import CreditRiskImage from '../../../assets/images/library-images/CreditRisk.webp';
import DigitalDeceptionImage from '../../../assets/images/library-images/DigitalDeception.webp';
import HarmonyHubImage from '../../../assets/images/library-images/HarmonyHub.webp';

// Map of image IDs to imported images
const gameImages = {
  'AIRogue': AIRogueImage,
  'CrisisCommander': CrisisCommanderImage,
  'AML': AMLImage,
  'ChangeCatalyst': ChangeCatalystImage,
  'CreditRisk': CreditRiskImage,
  'DigitalDeception': DigitalDeceptionImage,
  'HarmonyHub': HarmonyHubImage
};

// Helper function to get game by ID
export const getGameById = (id) => {
  return gamesData.games.find(game => game.id === id);
};

// Helper function to get all games
export const getAllGames = () => {
  return gamesData.games;
};

// Helper function to format game description with highlighted game name
export const getFormattedDescription = (game) => {
  return (
    <>
      {game.description.short.split(game.gameName).map((part, index, array) => (
        index === array.length - 1 ? part : (
          <>
            {part}
            <span className="bg-gradient-to-r from-orange-500 to-amber-500 
                         bg-clip-text text-transparent font-bold">
              "{game.gameName}"
            </span>
          </>
        )
      ))}
    </>
  );
};

// Helper function to get quick info badges for games that have them
export const getQuickGameInfo = (gameId) => {
  const game = getGameById(gameId);
  if (!game || !game.hasQuickInfo) return null;

  return {
    delivery: [
      { 
        icon: Users, 
        text: `Classroom ${game.formats.classroom.duration}` 
      },
      { 
        icon: Video, 
        text: `Virtual ${game.formats.virtual.duration}` 
      },
      { 
        icon: Laptop, 
        text: `Elearning ${game.formats.selfPaced.duration}` 
      }
    ],
    target: [
      { 
        icon: Users, 
        text: game.audience.seniority 
      },
      { 
        icon: Building2, 
        text: game.audience.industries[0] 
      },
      { 
        icon: Users, 
        text: `Max ${game.formats.classroom.groupSize.split('-')[1] || game.formats.classroom.groupSize.split(' ')[1]} participants` 
      }
    ]
  };
};

// Helper function to get initial games for the grid
export const getInitialGames = () => {
  const games = gamesData.games.map(game => ({
    id: game.id,
    title: game.title,
    gameName: game.gameName,
    imageId: game.imageId,
    hasQuickInfo: game.hasQuickInfo,
    description: getFormattedDescription(game),  // For card display
    fullDescription: game.description.full,      // For details view
    keyPoints: game.description.keyPoints,
    mechanics: game.mechanics,
    formats: game.formats,
    audience: game.audience,
    pricing: game.pricing,
    image: gameImages[game.imageId]
  }));

  // Reorder games to put AI Ethics and AML first
  return games.sort((a, b) => {
    if (a.title === "AI Ethics in Banking") return -1;
    if (b.title === "AI Ethics in Banking") return 1;
    if (a.title === "Anti Money Laundering") return -1;
    if (b.title === "Anti Money Laundering") return 1;
    return 0;
  });
};

// Helper function to format price
export const formatPrice = (price) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  }).format(price);
}; 