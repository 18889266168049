import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Clock, Mail, Paperclip } from 'lucide-react';
import Avatar from './Avatar';
import showOptionsSound from '../../assets/sounds/show-options.mp3';
import IntelModal from '../modals/IntelModal';
import TutorialMessage from '../tutorial/TutorialMessage';

const LeftRectangle = ({ scenario, onOptionSelect, isLocked }) => {
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const optionsAudio = new Audio(showOptionsSound);
  optionsAudio.volume = 0.3;
  const [isIntelModalOpen, setIsIntelModalOpen] = useState(false);
  const [showIntelTutorial, setShowIntelTutorial] = useState(false);

  useEffect(() => {
    setShowOptions(false);
    setSelectedOptionId(null);
  }, [scenario.id]);

  useEffect(() => {
    const handleOptionDropped = (event) => {
      setSelectedOptionId(event.detail.optionId);
    };

    const handleOptionSent = () => {
      setSelectedOptionId(null);
    };

    window.addEventListener('optionDropped', handleOptionDropped);
    window.addEventListener('optionSent', handleOptionSent);

    return () => {
      window.removeEventListener('optionDropped', handleOptionDropped);
      window.removeEventListener('optionSent', handleOptionSent);
    };
  }, []);

  if (!scenario) {
    return <div className="w-[24%] h-[70vh] bg-white rounded-lg">No scenario found</div>;
  }

  const formatMessage = (content) => {
    const parts = content.split('\n');
    return parts;
  };

  const handleDragStart = (e, option) => {
    e.dataTransfer.effectAllowed = `option${option.id}`;
    e.dataTransfer.setData('application/json', JSON.stringify(option));
    e.target.classList.add('dragging');
  };

  const handleDragEnd = (e) => {
    e.target.classList.remove('dragging');
  };

  const handleShowOptions = () => {
    setShowOptions(true);
    optionsAudio.currentTime = 0;
    optionsAudio.play().catch(error => console.log("Audio play failed:", error));
    
    if (scenario.id === 1) {
      setShowIntelTutorial(true);
      window.dispatchEvent(new CustomEvent('showOptionsGuide', { 
        detail: { show: true } 
      }));
    }
  };

  const handleIntelClick = () => {
    setIsIntelModalOpen(true);
  };

  return (
    <div className="w-[32%] flex-grow bg-gray-900 flex flex-col border-r border-gray-800">
      {/* Email Header - reduced padding and sizes */}
      <div className="p-2 border-b border-gray-800">
        <div className="flex items-center gap-2">
          <Avatar avatarFile={scenario.message.avatar} />
          <div className="flex-grow">
            <h3 className="font-semibold text-gray-200 text-sm mb-0.5">{scenario.message.sender}</h3>
            <div className="text-[10px] text-gray-500">{scenario.category}</div>
          </div>
          <div className="flex items-center gap-1 text-[10px] text-gray-500">
            <Clock className="w-3 h-3" />
            <span>{scenario.timestamp}</span>
          </div>
        </div>
      </div>

      {/* Email Body - adjusted padding and text size */}
      <div className="flex-grow p-2 overflow-y-auto">
        <div className="prose prose-sm max-w-none">
          {formatMessage(scenario.message.content).map((part, index) => (
            <motion.p
              key={`${scenario.id}-p-${index}`}
              className="mb-2 last:mb-0 leading-relaxed text-gray-300 text-xs"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ 
                duration: 0.5,
                delay: 0.5 + (index * 0.3)
              }}
            >
              {part}
            </motion.p>
          ))}
        </div>
      </div>

      {/* Attachments & Intel - reduced padding and text */}
      <div className="border-t border-gray-800 p-2">
        <div className="flex items-center justify-between">
          <button
            onClick={handleIntelClick}
            className="flex items-center gap-1 text-emerald-400 hover:text-emerald-300 text-[10px] font-medium"
          >
            <Paperclip className="w-3 h-3" />
            <span>View Attachments</span>
          </button>
          
          {!showOptions ? (
            <motion.button
              onClick={handleShowOptions}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 2.0 }}
              className="px-2 py-1 bg-emerald-600 hover:bg-emerald-500 text-white rounded-lg text-[10px] font-medium"
            >
              Reply
            </motion.button>
          ) : null}
        </div>
      </div>

      {/* Reply Options - adjusted spacing and text */}
      {showOptions && (
        <div className="border-t border-gray-800 p-2 bg-gray-800/50">
          <div className="text-[10px] text-gray-400 mb-2">Choose your reply:</div>
          <div className="space-y-1">
            {scenario.options.map((option) => {
              const isSelected = selectedOptionId === option.id;
              const isDisabled = isLocked && !isSelected;
              
              return (
                <motion.div
                  key={`${scenario.id}-option-${option.id}`}
                  className={`p-2 rounded-lg border text-[10px] font-medium cursor-grab active:cursor-grabbing
                    ${isDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:shadow-md hover:border-emerald-500/50'}
                    ${isSelected ? 'border-emerald-500 bg-emerald-500/10' : 'border-gray-700 bg-gray-800'}
                  `}
                  draggable={!isDisabled}
                  data-option={option.id}
                  onDragStart={(e) => !isDisabled && handleDragStart(e, option)}
                  onDragEnd={handleDragEnd}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                >
                  <span className="text-gray-200">{option.text}</span>
                </motion.div>
              );
            })}
          </div>
        </div>
      )}

      <IntelModal 
        isOpen={isIntelModalOpen}
        onClose={() => setIsIntelModalOpen(false)}
        clues={scenario.description}
      />

      {showIntelTutorial && (
        <TutorialMessage 
          type="intel" 
          onClose={() => setShowIntelTutorial(false)} 
        />
      )}
    </div>
  );
};

export default LeftRectangle;
