import React, { createContext, useContext, useState } from 'react';

const SimulationContext = createContext();

export const DarkMoneyProvider = ({ children }) => {
  const [completedScenarios, setCompletedScenarios] = useState(new Set([0])); // First scenario available
  const [scenarioProgress, setScenarioProgress] = useState({}); // Tracks flipped cards per scenario
  const [scenarioChoices, setScenarioChoices] = useState({}); // Track choices
  
  const isScenarioAvailable = (scenarioId) => {
    return completedScenarios.has(scenarioId - 1); // Available if previous is completed
  };

  const isScenarioCompleted = (scenarioId) => {
    return completedScenarios.has(scenarioId);
  };

  const markScenarioAsCompleted = (scenarioId) => {
    setCompletedScenarios(prev => new Set([...prev, scenarioId]));
  };

  const trackCardFlip = (scenarioId, cardType) => {
    setScenarioProgress(prev => ({
      ...prev,
      [scenarioId]: {
        ...prev[scenarioId],
        [cardType]: true
      }
    }));

    // Check if all cards are flipped
    const currentProgress = scenarioProgress[scenarioId] || {};
    const allCardsFlipped = ['shortTerm', 'longTerm', 'shortTermKPI', 'longTermKPI']
      .every(card => currentProgress[card] || cardType === card);

    if (allCardsFlipped) {
      markScenarioAsCompleted(scenarioId);
    }
  };

  const trackScenarioChoice = (scenarioId, choice) => {
    setScenarioChoices(prev => ({
      ...prev,
      [scenarioId]: choice
    }));
  };

  return (
    <SimulationContext.Provider value={{
      isScenarioAvailable,
      isScenarioCompleted,
      trackCardFlip,
      scenarioProgress,
      scenarioChoices,
      trackScenarioChoice,
      markScenarioAsCompleted
    }}>
      {children}
    </SimulationContext.Provider>
  );
};

export const useSimulation = () => useContext(SimulationContext); 