export const initialKPIValues = {
  "Profitability": 50,
  "Revenue growth": 50,
  "Market share": 50,
  "Legal expenses": 50,
  "Customer loyalty": 50,
  "Customer satisfaction": 50,
  "Reputation": 50,
  "Shareholders satisfaction": 50,
  "Employee morale & engagement": 50
};

const clampValue = (value) => Math.min(Math.max(value, 0), 100);

export const calculateKPIs = (baseValues, impacts, isFirstScenario = false) => {
  const shortTermValues = {};
  const longTermValues = {};

  const startingValues = isFirstScenario ? initialKPIValues : baseValues;

  // Calculate Short Term: base + short term impact
  Object.keys(startingValues).forEach(kpi => {
    shortTermValues[kpi] = clampValue(startingValues[kpi] + impacts.shortTerm[kpi]);
  });

  // Calculate Long Term: short term result + long term impact
  Object.keys(startingValues).forEach(kpi => {
    longTermValues[kpi] = clampValue(shortTermValues[kpi] + impacts.longTerm[kpi]);
  });

  return {
    shortTermValues,
    longTermValues,
    finalValues: longTermValues
  };
}; 