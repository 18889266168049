import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown } from 'lucide-react';

const faqs = [
  {
    id: 1,
    question: "What's the difference between trainer delivery and licensing?",
    answer: "With trainer delivery, our expert facilitators run the session for you - perfect for one-off training events. With licensing, we train your facilitators to deliver the games themselves, giving you unlimited use for a full year."
  },
  {
    id: 2,
    question: "How fast can you create a custom game?",
    answer: "We have the fastest game design studio in the world. We can deliver a fully customized game in just 1-2 weeks, including all branding and unique scenarios."
  },
  {
    id: 3,
    question: "Can we customize the content and language?",
    answer: "Yes! All our licensed games can be customized for content and translated into any language. This ensures the training resonates with your local teams while maintaining learning objectives."
  },
  {
    id: 4,
    question: "Do you offer discounts for multiple game licenses?",
    answer: "Yes, we offer attractive discounts when licensing multiple games. This makes it cost-effective to build a comprehensive training library for your organization."
  },
  {
    id: 5,
    question: "How many participants can join a session?",
    answer: "For trainer-delivered sessions, we recommend up to 24 participants to ensure optimal engagement. Licensed games support unlimited use, allowing you to run as many sessions as needed."
  },
  {
    id: 6,
    question: "What platforms do you support for virtual delivery?",
    answer: "Our games are optimized for MS Teams, making virtual delivery seamless. The platform provides all the tools needed for interactive gameplay and real-time collaboration."
  },
  {
    id: 7,
    question: "What support do you provide?",
    answer: "We provide technical support, facilitator training, and all necessary materials. For licensed games, we ensure your team is fully equipped to deliver impactful training sessions."
  }
];

const FAQ = () => {
  const [openId, setOpenId] = useState(null);

  return (
    <div className="w-full py-24">
      <div className="container mx-auto px-4">
        <div className="space-y-12">
          {/* Title */}
          <h2 className="text-3xl font-bold font-['Space_Grotesk'] text-white text-center">
            Frequently Asked Questions
          </h2>

          {/* FAQ Items */}
          <div className="max-w-3xl mx-auto space-y-4">
            {faqs.map((faq) => (
              <motion.div
                key={faq.id}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="border-b border-game-accent/10"
              >
                <button
                  onClick={() => setOpenId(openId === faq.id ? null : faq.id)}
                  className="w-full py-6 flex items-center justify-between gap-4 text-left group"
                >
                  <span className="text-lg font-['Space_Grotesk'] text-white group-hover:text-game-accent transition-colors">
                    {faq.question}
                  </span>
                  <ChevronDown 
                    className={`w-5 h-5 text-game-accent transition-transform duration-200
                      ${openId === faq.id ? 'rotate-180' : ''}`}
                  />
                </button>
                
                <AnimatePresence>
                  {openId === faq.id && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: 'auto', opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className="overflow-hidden"
                    >
                      <p className="pb-6 text-sm text-gray-400 font-['Space_Grotesk']">
                        {faq.answer}
                      </p>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;