import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Rocket, Users, Video, Laptop, Plus, ChevronDown, Printer } from 'lucide-react';
import QuoteDetailsForm from './QuoteDetailsForm';

const InfoTooltip = ({ content, children }) => (
  <div className="group relative inline-block">
    <span className="border-b border-dotted border-gray-500 cursor-help">
      {children}
    </span>
    <div className="absolute bottom-full mb-2 left-1/2 -translate-x-1/2 w-64 p-3
                    bg-zinc-800 rounded-lg text-xs text-gray-300 
                    opacity-0 group-hover:opacity-100 transition-opacity duration-200
                    pointer-events-none z-50">
      {content}
    </div>
  </div>
);

const ToggleSwitch = ({ leftOption, rightOption, leftTooltip, rightTooltip, isRight, onChange }) => (
  <div className="flex items-center">
    {/* Left Option with fixed width */}
    <div className="w-32">
      <div className={`text-sm font-['Space_Grotesk'] transition-colors duration-200
                    ${!isRight ? 'text-white' : 'text-gray-500'}`}>
        {leftTooltip ? (
          <InfoTooltip content={leftTooltip}>
            {leftOption}
          </InfoTooltip>
        ) : (
          leftOption
        )}
      </div>
    </div>

    {/* Toggle Switch with fixed margins */}
    <div className="mx-8">
      <button
        onClick={() => onChange(!isRight)}
        className={`relative w-12 h-6 rounded-full transition-colors duration-200
                   ${isRight ? 'bg-amber-500' : 'bg-amber-500'}`}
      >
        <div className={`absolute top-1 left-1 w-4 h-4 rounded-full bg-white
                        transition-transform duration-200
                        ${isRight ? 'translate-x-6' : 'translate-x-0'}`}
        />
      </button>
    </div>

    {/* Right Option with fixed width */}
    <div className="w-32">
      <div className={`text-sm font-['Space_Grotesk'] transition-colors duration-200
                    ${isRight ? 'text-white' : 'text-gray-500'}`}>
        {rightTooltip ? (
          <InfoTooltip content={rightTooltip}>
            {rightOption}
          </InfoTooltip>
        ) : (
          rightOption
        )}
      </div>
    </div>
  </div>
);

const DeploymentOptions = ({ selected, onChange }) => {
  const options = [
    { id: 'face-to-face', label: 'Face to Face', icon: Users },
    { id: 'virtual', label: 'Virtual', icon: Video },
    { id: 'elearning', label: 'E-Learning', icon: Laptop }
  ];

  const toggleOption = (optionId) => {
    if (Array.isArray(selected)) {
      if (selected.includes(optionId)) {
        onChange(selected.filter(id => id !== optionId));
      } else {
        onChange([...selected, optionId]);
      }
    } else {
      onChange([optionId]);
    }
  };

  return (
    <div className="space-y-2">
      <div className="text-sm text-gray-400">Where should the game work?</div>
      <div className="flex gap-2">
        {options.map((option) => {
          const Icon = option.icon;
          const isSelected = Array.isArray(selected) && selected.includes(option.id);

          return (
            <button
              key={option.id}
              onClick={() => toggleOption(option.id)}
              className={`flex items-center gap-2 px-4 py-2 flex-1
                         border transition-colors duration-200
                         ${isSelected 
                           ? 'bg-amber-500/20 border-amber-500 text-white' 
                           : 'border-zinc-700/50 text-gray-400 hover:border-amber-500/50 hover:text-white'
                         }`}
            >
              <Icon className="w-4 h-4" />
              <span className="text-sm font-['Space_Grotesk']">{option.label}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

const ParticipantRangeSelector = ({ selectedRange, onChange }) => {
  const ranges = [
    { id: 'under500', label: 'Under 500', value: 500 },
    { id: '501-1000', label: '501 - 1,000', value: 1000 },
    { id: '1001-5000', label: '1,001 - 5,000', value: 5000 },
    { id: 'over5000', label: 'Over 5,000', value: 5001 }
  ];

  return (
    <div className="space-y-2">
      <div className="text-sm text-gray-400">Expected yearly participants</div>
      <div className="grid grid-cols-2 gap-2">
        {ranges.map((range) => (
          <button
            key={range.id}
            onClick={() => onChange(range.id)}
            className={`flex items-center justify-center px-4 py-2
                       border transition-colors duration-200
                       ${selectedRange === range.id
                         ? 'bg-amber-500/20 border-amber-500 text-white' 
                         : 'border-zinc-700/50 text-gray-400 hover:border-amber-500/50 hover:text-white'
                       }`}
          >
            <span className="text-sm font-['Space_Grotesk']">{range.label}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export const CustomGameConfigurator = ({
  gameType,
  setGameType,
  needsContent,
  setNeedsContent,
  hasAIGuide,
  setHasAIGuide,
  participantRange,
  setParticipantRange,
  deploymentTypes,
  setDeploymentTypes
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 space-y-8 max-w-xl">
        {/* Game Configuration */}
        <div className="space-y-4">
          <div className="text-sm uppercase tracking-wider text-amber-400 font-['Space_Grotesk']">
            Game Configuration
          </div>
          
          <ToggleSwitch
            leftOption="Content Game"
            rightOption="Process Game"
            leftTooltip="Focuses on specific learning content (e.g., compliance, leadership, sales)"
            rightTooltip="Simulates business processes and decision-making scenarios"
            isRight={gameType === 'process'}
            onChange={(isRight) => setGameType(isRight ? 'process' : 'content')}
          />

          <ToggleSwitch
            leftOption="Client Content"
            rightOption="Our Content"
            leftTooltip="You provide the specific content for the game"
            rightTooltip="Our team creates the content based on your requirements"
            isRight={needsContent}
            onChange={setNeedsContent}
          />

          <ToggleSwitch
            leftOption="Without AI Guide"
            rightOption="With AI Guide"
            leftTooltip="Standard game without AI assistance"
            rightTooltip="AI-powered assistant that provides instructions, debriefing, and help during the game"
            isRight={hasAIGuide}
            onChange={setHasAIGuide}
          />
        </div>

        {/* Deployment Section */}
        <div className="space-y-6">
          <div className="text-sm uppercase tracking-wider text-amber-400 font-['Space_Grotesk']">
            Deployment
          </div>

          <DeploymentOptions
            selected={deploymentTypes}
            onChange={setDeploymentTypes}
          />

          <ParticipantRangeSelector
            selectedRange={participantRange}
            onChange={setParticipantRange}
          />
        </div>
      </div>

      {/* Disclaimer Link */}
      <div className="mt-8 pt-8 border-t border-zinc-800">
        <button
          onClick={() => setIsModalOpen(true)}
          className="text-sm text-amber-400 hover:text-amber-300 transition-colors duration-200
                   font-['Space_Grotesk'] underline underline-offset-4"
        >
          View important information about custom games
        </button>
      </div>
    </div>
  );
};

export const CustomFloatingSummary = ({ gameType, needsContent, hasAIGuide, deploymentTypes, participantRange }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showQuoteForm, setShowQuoteForm] = useState(false);

  const hasRequiredSelections = deploymentTypes.length > 0 && participantRange !== null;

  const calculatePrice = () => {
    // Base prices
    const basePrice = gameType === 'content' ? 8900 : 12900;
    
    // Content creation addition (10%)
    const contentPrice = needsContent ? basePrice * 0.10 : 0;
    
    // Deployment types multiplier (10% per additional type)
    const deploymentMultiplier = 1 + ((deploymentTypes.length - 1) * 0.10);
    
    // Calculate estimated participants for AI pricing and participant multiplier
    let estimatedParticipants = 0;
    let participantMultiplier = 1;
    
    if (participantRange === 'under500') {
      estimatedParticipants = 500;
      participantMultiplier = 1;
    } else if (participantRange === '501-1000') {
      estimatedParticipants = 1000;
      participantMultiplier = 1.25; // +25%
    } else if (participantRange === '1001-5000') {
      estimatedParticipants = 5000;
      participantMultiplier = 1.75; // +75%
    } else if (participantRange === 'over5000') {
      estimatedParticipants = 10000;
      participantMultiplier = 2.50; // +150%
    }

    // AI Guide pricing ($1 per participant)
    const aiPrice = hasAIGuide ? estimatedParticipants * 1 : 0;

    // Calculate total
    const subtotal = (basePrice + contentPrice) * deploymentMultiplier * participantMultiplier;
    const total = subtotal + aiPrice;

    return {
      basePrice,
      contentPrice,
      deploymentMultiplier,
      participantMultiplier,
      aiPrice,
      subtotal,
      total,
      estimatedParticipants
    };
  };

  const priceDetails = calculatePrice();

  const handlePrintSummary = () => {
    setShowQuoteForm(true);
  };

  const handleQuoteSubmit = (details) => {
    sessionStorage.setItem('quoteDetails', JSON.stringify(details));
    const summaryData = {
      gameType,
      needsContent,
      hasAIGuide,
      deploymentTypes,
      participantRange,
      subtotal: priceDetails.subtotal,
      aiPrice: priceDetails.aiPrice,
      total: priceDetails.total,
      estimatedParticipants: priceDetails.estimatedParticipants,
      deploymentMultiplier: priceDetails.deploymentMultiplier,
      participantMultiplier: priceDetails.participantMultiplier,
      contentPrice: priceDetails.contentPrice
    };
    sessionStorage.setItem('summaryData', JSON.stringify(summaryData));
    window.open('/summary/custom', '_blank');
    setShowQuoteForm(false);
  };

  return (
    <>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className="fixed bottom-8 right-8 z-50 w-[500px]"
      >
        <div className="bg-zinc-900 border border-game-accent/20 rounded-xl shadow-2xl 
                      shadow-amber-500/5 backdrop-blur-sm overflow-hidden">
          {/* Compact View */}
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="w-full p-4 flex items-center justify-between gap-6 
                       hover:bg-zinc-800/50 transition-colors duration-200"
          >
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-lg bg-amber-500/10 flex items-center justify-center">
                <Rocket className="w-5 h-5 text-amber-500" />
              </div>
              <div className="text-left">
                <div className="text-xs text-gray-400 font-['Space_Grotesk']">
                  Custom Game
                </div>
                <div className="text-lg font-bold text-white font-['Space_Grotesk']">
                  {hasRequiredSelections 
                    ? `$${priceDetails.total.toLocaleString()}/year`
                    : 'Select deployment & participants'}
                </div>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <motion.div
                animate={{ rotate: isExpanded ? 180 : 0 }}
                className="w-5 h-5 rounded-full bg-zinc-800 flex items-center justify-center"
              >
                <ChevronDown className="w-3 h-3 text-gray-400" />
              </motion.div>
            </div>
          </button>

          {/* Expanded Details - Only show if required selections are made */}
          <AnimatePresence>
            {isExpanded && hasRequiredSelections && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                className="border-t border-game-accent/10"
              >
                <div className="p-4 space-y-4">
                  {/* Base Price */}
                  <div className="space-y-2">
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-400">Base Price ({gameType === 'content' ? 'Content' : 'Process'} Game)</span>
                      <span className="text-white">${priceDetails.basePrice.toLocaleString()}</span>
                    </div>

                    {/* Content Creation */}
                    {needsContent && (
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-400">Content Creation (+10%)</span>
                        <span className="text-white">+${priceDetails.contentPrice.toLocaleString()}</span>
                      </div>
                    )}

                    {/* Deployment Types */}
                    {deploymentTypes.length > 1 && (
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-400">
                          {deploymentTypes.length} deployment types
                        </span>
                        <span className="text-white">
                          ×{priceDetails.deploymentMultiplier.toFixed(1)}
                        </span>
                      </div>
                    )}

                    {/* Participant Range */}
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-400">
                        Up to {priceDetails.estimatedParticipants.toLocaleString()} participants
                      </span>
                      {participantRange !== 'under500' && (
                        <span className="text-white">
                          +${((priceDetails.participantMultiplier - 1) * (priceDetails.basePrice + priceDetails.contentPrice)).toLocaleString()}
                        </span>
                      )}
                    </div>

                    {/* Subtotal */}
                    <div className="flex justify-between text-sm pt-2 border-t border-zinc-800">
                      <span className="text-gray-400">Subtotal</span>
                      <span className="text-white">${priceDetails.subtotal.toLocaleString()}</span>
                    </div>

                    {/* AI Guide */}
                    {hasAIGuide && (
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-400">AI Guide</span>
                        <span className="text-white">+${priceDetails.aiPrice.toLocaleString()}</span>
                      </div>
                    )}
                  </div>

                  {/* Total */}
                  <div className="flex justify-between text-sm font-bold pt-2 border-t border-zinc-800">
                    <span className="text-white">Annual Total</span>
                    <span className="text-white">${priceDetails.total.toLocaleString()}</span>
                  </div>

                  {/* Action Button */}
                  <div className="p-4">
                    <button
                      onClick={handlePrintSummary}
                      className="w-full px-4 py-2 bg-amber-500 hover:bg-amber-600
                             text-white font-['Space_Grotesk'] text-sm font-medium
                             rounded-lg transition-colors duration-200
                             flex items-center justify-center gap-2"
                    >
                      Print Summary
                      <Printer className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.div>

      {showQuoteForm && hasRequiredSelections && (
        <QuoteDetailsForm
          onSubmit={handleQuoteSubmit}
          type="custom"
        />
      )}
    </>
  );
}; 