import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  X, Users, Video, Laptop, Building2, ChevronRight, 
  Gamepad2, Briefcase, Dices, Brain, Target, Gauge
} from 'lucide-react';
import { useTheme } from '../../context/ThemeContext';

const GameDetails = ({ game, isOpen, onClose }) => {
  useTheme();

  if (!isOpen || !game) return null;

  const {
    title = '',
    gameName = '',
    image = '',
    fullDescription = '',
    keyPoints = [],
    mechanics = { type: '', features: [] },
    formats = {},
    audience = { industries: [], roles: [] }
  } = game;

  const InfoSection = ({ title, children }) => (
    <div className="space-y-4">
      <h3 className="text-xl font-bold font-['Space_Grotesk'] text-white">
        {title}
      </h3>
      {children}
    </div>
  );

  const Feature = ({ icon: Icon, text }) => (
    <div className="flex items-center gap-2 text-sm text-gray-400">
      <Icon className="w-4 h-4 text-game-accent flex-shrink-0" />
      {text}
    </div>
  );

  const getMechanicsIcon = (type) => {
    switch (type.toLowerCase()) {
      case 'role-playing simulation':
        return Gamepad2;
      case 'resource management simulation':
        return Gauge;
      case 'decision-making simulation':
        return Brain;
      case 'team simulation':
        return Users;
      case 'crisis simulation':
        return Target;
      case 'interactive workshop':
        return Dices;
      default:
        return Gamepad2;
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50"
      >
        {/* Backdrop */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="absolute inset-0 bg-black/60 backdrop-blur-sm"
          onClick={onClose}
        />

        {/* Drawer */}
        <motion.div
          initial={{ x: '100%' }}
          animate={{ x: 0 }}
          exit={{ x: '100%' }}
          transition={{ type: "spring", damping: 30, stiffness: 300 }}
          className="absolute top-0 right-0 h-full w-full md:w-[600px] 
                   bg-gradient-to-br from-zinc-900 via-game-dark to-black
                   shadow-2xl border-l border-game-accent/20"
        >
          {/* Close Button */}
          <button
            onClick={onClose}
            className="absolute top-6 left-6 p-2 rounded-full 
                     bg-black/20 hover:bg-black/40 
                     backdrop-blur-sm
                     border border-white/10 hover:border-white/20
                     text-white/50 hover:text-white
                     transition-all duration-300
                     z-50"
          >
            <X className="w-5 h-5" />
          </button>

          {/* Content */}
          <div className="h-full overflow-y-auto">
            {/* Hero Section */}
            <div className="relative h-[30vh]">
              <img 
                src={image} 
                alt={title}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-b from-black/50 via-transparent to-black" />
              <div className="absolute bottom-0 left-0 right-0 p-8">
                <h2 className="text-3xl font-bold font-['Space_Grotesk'] text-white">
                  {title}
                </h2>
                <p className="text-lg text-game-accent mt-2">
                  "{gameName}"
                </p>
              </div>
            </div>

            {/* Details Content */}
            <div className="p-8 space-y-12">
              {/* Description */}
              <InfoSection title="About this game">
                <p className="text-gray-400">{fullDescription}</p>
                {keyPoints?.length > 0 && (
                  <div className="grid grid-cols-2 gap-4 mt-4">
                    {keyPoints.map((point, index) => (
                      <Feature key={index} icon={ChevronRight} text={point} />
                    ))}
                  </div>
                )}
              </InfoSection>

              {/* Game Mechanics */}
              {mechanics.features?.length > 0 && (
                <InfoSection title="Game Mechanics">
                  <div className="space-y-6">
                    {/* Type and Format */}
                    <div className="grid grid-cols-2 gap-4">
                      <div className="p-4 rounded-xl bg-black/20 border border-game-accent/20">
                        <div className="flex items-center gap-3 mb-2">
                          <div className="p-2 rounded-lg bg-black/40">
                            {(() => {
                              const Icon = getMechanicsIcon(mechanics.type);
                              return <Icon className="w-5 h-5 text-game-accent" />;
                            })()}
                          </div>
                          <div>
                            <div className="text-xs text-gray-500 uppercase tracking-wider">Type</div>
                            <div className="text-sm font-semibold text-white">{mechanics.type}</div>
                          </div>
                        </div>
                      </div>

                      <div className="p-4 rounded-xl bg-black/20 border border-game-accent/20">
                        <div className="flex items-center gap-3 mb-2">
                          <div className="p-2 rounded-lg bg-black/40">
                            <Dices className="w-5 h-5 text-game-accent" />
                          </div>
                          <div>
                            <div className="text-xs text-gray-500 uppercase tracking-wider">Format</div>
                            <div className="text-sm font-semibold text-white">{mechanics.format}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Features */}
                    <div className="space-y-3">
                      <div className="text-sm text-gray-400 uppercase tracking-wider">
                        Key Features
                      </div>
                      <div className="grid grid-cols-2 gap-4 bg-black/20 rounded-xl p-4 border border-game-accent/20">
                        {mechanics.features.map((feature, index) => (
                          <Feature key={index} icon={ChevronRight} text={feature} />
                        ))}
                      </div>
                    </div>
                  </div>
                </InfoSection>
              )}

              {/* Delivery Formats */}
              {Object.keys(formats).length > 0 && (
                <InfoSection title="Available Formats">
                  <div className="grid gap-4">
                    {Object.entries(formats).map(([format, details]) => (
                      details && (
                        <div key={format} 
                             className="p-4 rounded-xl bg-black/20 border border-game-accent/20">
                          <div className="flex items-center gap-3 mb-3">
                            {format === 'classroom' && <Users className="w-5 h-5 text-game-accent" />}
                            {format === 'virtual' && <Video className="w-5 h-5 text-game-accent" />}
                            {format === 'selfPaced' && <Laptop className="w-5 h-5 text-game-accent" />}
                            <div>
                              <div className="font-bold text-white capitalize">{format}</div>
                              <div className="text-sm text-gray-400">
                                {details.duration} • {details.groupSize}
                              </div>
                            </div>
                          </div>
                          <div className="space-y-2">
                            {details.includes.map((item, index) => (
                              <Feature key={index} icon={ChevronRight} text={item} />
                            ))}
                          </div>
                        </div>
                      )
                    ))}
                  </div>
                </InfoSection>
              )}

              {/* Target Audience */}
              {(audience.industries?.length > 0 || audience.roles?.length > 0) && (
                <InfoSection title="Who is this for?">
                  <div className="space-y-6">
                    {/* Industries */}
                    <div className="space-y-2">
                      <div className="flex items-center gap-2 text-white">
                        <Building2 className="w-5 h-5" />
                        <h4 className="font-bold">Industries</h4>
                      </div>
                      <div className="grid grid-cols-2 gap-2">
                        {audience.industries.map((industry, index) => (
                          <Feature key={index} icon={ChevronRight} text={industry} />
                        ))}
                      </div>
                    </div>

                    {/* Roles */}
                    <div className="space-y-2">
                      <div className="flex items-center gap-2 text-white">
                        <Briefcase className="w-5 h-5" />
                        <h4 className="font-bold">Roles</h4>
                      </div>
                      <div className="grid grid-cols-2 gap-2">
                        {audience.roles.map((role, index) => (
                          <Feature key={index} icon={ChevronRight} text={role} />
                        ))}
                      </div>
                    </div>

                    {/* Requirements Section */}
                    <div className="grid grid-cols-2 gap-4 mt-6">
                      {/* Seniority */}
                      <div className="p-4 rounded-xl bg-black/20 border border-game-accent/20">
                        <div className="flex items-center gap-3 mb-2">
                          <div className="p-2 rounded-lg bg-black/40">
                            <Users className="w-5 h-5 text-game-accent" />
                          </div>
                          <div>
                            <div className="text-xs text-gray-500 uppercase tracking-wider">Seniority</div>
                            <div className="text-sm font-semibold text-white">{audience.seniority}</div>
                          </div>
                        </div>
                      </div>

                      {/* Prerequisites */}
                      <div className="p-4 rounded-xl bg-black/20 border border-game-accent/20">
                        <div className="flex items-center gap-3 mb-2">
                          <div className="p-2 rounded-lg bg-black/40">
                            <ChevronRight className="w-5 h-5 text-game-accent" />
                          </div>
                          <div>
                            <div className="text-xs text-gray-500 uppercase tracking-wider">Prerequisites</div>
                            <div className="text-sm font-semibold text-white">{audience.prerequisites}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </InfoSection>
              )}
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default GameDetails; 