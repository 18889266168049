import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Shield, Cpu, Rocket, Users, Printer, Video } from 'lucide-react';
import { getInitialGames } from './components/data/gameData';
import Logo from './assets/logo.svg';

const printStyles = `
  @page {
    margin: 0;
    size: A4;
  }
  @media print {
    html, body {
      margin: 0;
      padding: 0;
    }
    body {
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
    }
  }
`;

const QuoteDetailsForm = ({ onSubmit, onCancel }) => {
  const [details, setDetails] = useState({
    companyName: '',
    contactName: '',
    email: ''
  });

  return (
    <div className="fixed inset-0 z-[60] flex items-center justify-center bg-black/50">
      <div className="bg-zinc-900 p-6 rounded-lg w-[400px] space-y-4">
        <h3 className="text-lg font-['Space_Grotesk'] text-white">Personalize Your Quote</h3>
        <div className="space-y-3">
          <input
            type="text"
            placeholder="Company Name (optional)"
            value={details.companyName}
            onChange={(e) => setDetails({ ...details, companyName: e.target.value })}
            className="w-full px-3 py-2 bg-zinc-800 border border-zinc-700 rounded text-white text-sm"
          />
          <input
            type="text"
            placeholder="Contact Name (optional)"
            value={details.contactName}
            onChange={(e) => setDetails({ ...details, contactName: e.target.value })}
            className="w-full px-3 py-2 bg-zinc-800 border border-zinc-700 rounded text-white text-sm"
          />
          <input
            type="email"
            placeholder="Email (optional)"
            value={details.email}
            onChange={(e) => setDetails({ ...details, email: e.target.value })}
            className="w-full px-3 py-2 bg-zinc-800 border border-zinc-700 rounded text-white text-sm"
          />
        </div>
        <div className="flex gap-3 pt-2">
          <button
            onClick={() => onSubmit(details)}
            className="flex-1 px-4 py-2 bg-amber-500 text-white rounded hover:bg-amber-600"
          >
            Continue
          </button>
          <button
            onClick={onCancel}
            className="px-4 py-2 text-gray-400 hover:text-white"
          >
            Skip
          </button>
        </div>
      </div>
    </div>
  );
};

const PricingSummary = () => {
  const { type } = useParams();
  const data = JSON.parse(sessionStorage.getItem('summaryData'));
  const games = getInitialGames();
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  const getTypeDetails = () => {
    switch (type) {
      case 'facilitated':
        return {
          title: 'Facilitated Training',
          icon: Shield
        };
      case 'license':
        return {
          title: 'Game License',
          icon: Cpu
        };
      case 'custom':
        return {
          title: 'Custom Game',
          icon: Rocket
        };
      default:
        return {};
    }
  };

  const typeDetails = getTypeDetails();
  const Icon = typeDetails.icon;

  const companyDetails = sessionStorage.getItem('quoteDetails') 
    ? JSON.parse(sessionStorage.getItem('quoteDetails'))
    : null;

  return (
    <div className="min-h-screen bg-white text-black">
      <style>{printStyles}</style>
      <div className="max-w-[650px] mx-auto p-8 pt-16 print:p-6 print:pt-16">
        {/* Header */}
        <div className="flex justify-between items-start mb-10">
          <div>
            <p className="text-[11px] text-gray-400 tracking-wider">QUOTE • {currentDate}</p>
            <h1 className="text-2xl font-light mt-1">{typeDetails.title}</h1>
            {companyDetails?.companyName && (
              <div className="text-sm text-gray-500 mt-2 space-y-1">
                <p>
                  Prepared for: {companyDetails.companyName}
                  {companyDetails.contactName && ` • ${companyDetails.contactName}`}
                </p>
                {companyDetails.email && (
                  <p className="text-gray-400">{companyDetails.email}</p>
                )}
              </div>
            )}
          </div>
          <button
            onClick={() => window.print()}
            className="px-3 py-1.5 bg-black text-white text-[11px] tracking-wider
                     hover:bg-gray-800 transition-colors print:hidden"
          >
            PRINT
          </button>
        </div>

        {/* Subtle Brand Element */}
        <div className="absolute top-0 right-0 w-32 h-32 opacity-[0.02] print:opacity-[0.04]">
          <img src={Logo} alt="" className="w-full h-full object-contain" />
        </div>

        {/* Content */}
        <div className="space-y-10">
          {type === 'facilitated' && (
            <>
              {/* Training Format */}
              <div>
                <p className="text-[11px] tracking-wider text-gray-400 mb-2">FORMAT</p>
                <p className="text-sm font-light">
                  {data.deliveryFormat === 'face-to-face' ? 'Face to Face Training' : 'Virtual Training'}
                </p>
              </div>

              {/* Selected Games */}
              <div>
                <p className="text-[11px] tracking-wider text-gray-400 mb-2">GAMES</p>
                <div className="space-y-3">
                  {data.selectedGames.map(game => {
                    const gameInfo = games.find(g => g.id === game.id);
                    return (
                      <div key={game.id} className="flex justify-between items-center text-sm">
                        <span className="font-light">{gameInfo.title}</span>
                        <span className="text-gray-500 text-xs">{game.participants} participants</span>
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* Pricing Breakdown */}
              <div>
                <p className="text-[11px] tracking-wider text-gray-400 mb-2">PRICING</p>
                <div className="space-y-2 text-sm">
                  <div className="flex justify-between font-light">
                    <span>Subtotal</span>
                    <span>${data.subtotal.toLocaleString()}</span>
                  </div>
                  {data.discount > 0 && (
                    <div className="flex justify-between text-gray-500 font-light">
                      <span>Volume Discount ({data.discount * 100}%)</span>
                      <span>−${data.discountAmount.toLocaleString()}</span>
                    </div>
                  )}
                  <div className="flex justify-between pt-2 border-t border-gray-100">
                    <span>Total</span>
                    <span className="font-medium">${data.total.toLocaleString()}</span>
                  </div>
                </div>
              </div>
            </>
          )}
          {type === 'license' && (
            <>
              {/* Selected Games */}
              <div>
                <p className="text-[11px] tracking-wider text-gray-400 mb-2">GAMES</p>
                <div className="space-y-3">
                  {data.selectedGames.map(game => {
                    const gameInfo = games.find(g => g.id === game.id);
                    return (
                      <div key={game.id} className="flex justify-between items-center text-sm">
                        <span className="font-light">{gameInfo.title}</span>
                        <span className="text-gray-500 text-xs">{game.yearlyParticipants} yearly participants</span>
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* Pricing Breakdown */}
              <div>
                <p className="text-[11px] tracking-wider text-gray-400 mb-2">PRICING</p>
                <div className="space-y-2 text-sm">
                  <div className="flex justify-between font-light">
                    <span>Subtotal</span>
                    <span>${data.subtotal.toLocaleString()}</span>
                  </div>
                  {data.discount > 0 && (
                    <div className="flex justify-between text-gray-500 font-light">
                      <span>Multi-game Discount ({data.discount * 100}%)</span>
                      <span>−${data.discountAmount.toLocaleString()}</span>
                    </div>
                  )}
                  <div className="flex justify-between pt-2 border-t border-gray-100">
                    <span>Total</span>
                    <span className="font-medium">${data.total.toLocaleString()}/year</span>
                  </div>
                </div>
              </div>

              {/* License Notes */}
              <div>
                <p className="text-[11px] tracking-wider text-gray-400 mb-2">LICENSE DETAILS</p>
                <div className="space-y-2 text-sm text-gray-500 font-light">
                  <p>• Access to all game updates and improvements</p>
                  <p>• Facilitator training and support included</p>
                </div>
              </div>
            </>
          )}
          {type === 'custom' && (
            <>
              {/* Game Configuration */}
              <div>
                <p className="text-[11px] tracking-wider text-gray-400 mb-2">CONFIGURATION</p>
                <div className="divide-y divide-gray-100">
                  <div className="flex justify-between items-center text-sm py-2">
                    <span className="font-light">Game Type</span>
                    <span className="text-gray-500 text-xs">
                      {data.gameType === 'content' ? 'Content Game' : 'Process Game'}
                    </span>
                  </div>
                  <div className="flex justify-between items-center text-sm py-2">
                    <span className="font-light">Content Creation</span>
                    <span className="text-gray-500 text-xs">
                      {data.needsContent ? 'By Boring Training' : 'Client Provided'}
                    </span>
                  </div>
                  <div className="flex justify-between items-center text-sm py-2">
                    <span className="font-light">AI Guide</span>
                    <span className="text-gray-500 text-xs">
                      {data.hasAIGuide ? 'Included' : 'Not Included'}
                    </span>
                  </div>
                </div>
              </div>

              {/* Deployment */}
              <div>
                <p className="text-[11px] tracking-wider text-gray-400 mb-2">DEPLOYMENT</p>
                <div className="divide-y divide-gray-100">
                  <div className="flex justify-between items-center text-sm py-2">
                    <span className="font-light">Formats</span>
                    <span className="text-gray-500 text-xs">
                      {data.deploymentTypes.map(type => 
                        type === 'face-to-face' ? 'Face to Face' :
                        type === 'virtual' ? 'Virtual' : 'E-Learning'
                      ).join(', ')}
                    </span>
                  </div>
                  <div className="flex justify-between items-center text-sm py-2">
                    <span className="font-light">Yearly Participants</span>
                    <span className="text-gray-500 text-xs">
                      {data.estimatedParticipants.toLocaleString()}
                    </span>
                  </div>
                </div>
              </div>

              {/* Pricing Breakdown */}
              <div>
                <p className="text-[11px] tracking-wider text-gray-400 mb-2">PRICING</p>
                <div className="divide-y divide-gray-100">
                  <div className="flex justify-between font-light py-2">
                    <span>Base Price</span>
                    <span>${(data.subtotal / data.participantMultiplier / data.deploymentMultiplier - data.contentPrice).toLocaleString()}</span>
                  </div>
                  {data.contentPrice > 0 && (
                    <div className="flex justify-between text-gray-500 font-light py-2">
                      <span>Content Creation (+10%)</span>
                      <span>+${data.contentPrice.toLocaleString()}</span>
                    </div>
                  )}
                  {data.deploymentTypes.length > 1 && (
                    <div className="flex justify-between text-gray-500 font-light py-2">
                      <span>{data.deploymentTypes.length} deployment types</span>
                      <span>×{data.deploymentMultiplier.toFixed(1)}</span>
                    </div>
                  )}
                  <div className="flex justify-between text-gray-500 font-light py-2">
                    <span>Up to {data.estimatedParticipants.toLocaleString()} participants</span>
                    {data.participantMultiplier > 1 && (
                      <span>+${((data.participantMultiplier - 1) * (data.subtotal / data.participantMultiplier)).toLocaleString()}</span>
                    )}
                  </div>
                  <div className="flex justify-between py-2">
                    <span>Subtotal</span>
                    <span>${data.subtotal.toLocaleString()}</span>
                  </div>
                  {data.hasAIGuide && (
                    <div className="flex justify-between text-gray-500 font-light py-2">
                      <span>AI Guide</span>
                      <span>+${data.aiPrice.toLocaleString()}</span>
                    </div>
                  )}
                  <div className="flex justify-between font-medium py-2">
                    <span>Total</span>
                    <span>${data.total.toLocaleString()}/year</span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {/* Footer */}
        <div className="mt-12 pt-4 border-t border-gray-100 grid grid-cols-2 gap-8 text-[11px]">
          <div className="space-y-1 text-gray-500">
            <p>• Estimated cost, subject to final requirements</p>
            {type === 'facilitated' && data.deliveryFormat === 'face-to-face' && (
              <p>• Travel expenses determined by location</p>
            )}
            <p>• Valid for 30 days</p>
          </div>
          <div className="text-right space-y-1">
            <p className="text-black">Boring Training</p>
            <p className="text-gray-500">Part of Teamlearn.ai</p>
            <p className="text-gray-500">www.boringtraining.com</p>
            <p className="text-gray-500">accounts@teamlearning.ai</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingSummary; 