// Import the audio files
import introMusic from '../assets/sounds/intro-music.ogg';
import characterSelectSound from '../assets/sounds/character-select.mp3';
import chooseStrategySound from '../assets/sounds/choose-strategy.mp3';

// Create audio instances
const introAudio = new Audio(introMusic);
const characterSelectAudio = new Audio(characterSelectSound);
const chooseStrategyAudio = new Audio(chooseStrategySound);

// Helper function to handle audio play with error catching
const playSound = (audio) => {
  audio.currentTime = 0;
  audio.play().catch(error => {
    console.warn('Sound playback failed:', error);
  });
};

// Export individual sound functions
export const playIntroMusic = () => {
  introAudio.volume = 1;
  playSound(introAudio);
};

export const fadeOutIntroMusic = () => {
  let volume = 1;
  const interval = setInterval(() => {
    volume = Math.max(volume - 0.1, 0);
    introAudio.volume = volume;
    if (volume <= 0) {
      clearInterval(interval);
      introAudio.pause();
    }
  }, 500);
};

export const playCharacterSelectSound = () => {
  characterSelectAudio.volume = 0.5; // Lower volume for UI sounds
  playSound(characterSelectAudio);
};

export const playChooseStrategySound = () => {
  chooseStrategyAudio.volume = 0.5; // Lower volume for UI sounds
  playSound(chooseStrategyAudio);
};

// Stop all sounds (useful for cleanup)
export const stopAllSounds = () => {
  [introAudio, characterSelectAudio, chooseStrategyAudio].forEach(audio => {
    audio.pause();
    audio.currentTime = 0;
  });
}; 