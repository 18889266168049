import React from 'react';
import { useTheme } from '../../context/ThemeContext';
import { motion } from 'framer-motion';
import firstBankLogo from '../assets/first-bank.svg';
import mashreqBankLogo from '../assets/mashreq-bank.svg';
import reiffeisenBankLogo from '../assets/reiffeisen-bank.svg';
import zurichInsuranceLogo from '../assets/zurich-insurance.svg';
import mckinseyLogo from '../assets/mckinsey.svg';
import libraBankLogo from '../assets/libra-bank.svg';

const LogoStrip = () => {
  const { isDark } = useTheme();

  return (
    <div className="w-full py-24">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center gap-8">
          {/* Title */}
          <p className={`text-sm font-['Space_Grotesk'] ${isDark ? 'text-white' : 'text-gray-600'} text-center`}>
            Trusted by leading financial institutions worldwide...{' '}
            <span className={`bg-gradient-to-r from-game-accent via-purple-500 to-pink-500 
                           text-transparent bg-clip-text font-medium
                           hover:from-pink-500 hover:via-purple-500 hover:to-game-accent
                           transition-all duration-300`}>
              and McKinsey
            </span>
          </p>

          {/* Logo Grid - Updated opacity values for better visibility */}
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8 w-full">
            {[
              { src: firstBankLogo, alt: "First Bank", height: "h-4" },
              { src: mashreqBankLogo, alt: "Mashreq Bank", height: "h-14" },
              { src: reiffeisenBankLogo, alt: "Reiffeisen Bank", height: "h-8" },
              { src: zurichInsuranceLogo, alt: "Zurich Insurance", height: "h-14" },
              { src: mckinseyLogo, alt: "McKinsey", height: "h-10" },
              { src: libraBankLogo, alt: "Libra Bank", height: "h-6" }
            ].map((logo, index) => (
              <motion.div
                key={logo.alt}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 * (index + 1) }}
                className="relative h-12 rounded-lg overflow-hidden flex items-center justify-center group"
              >
                <img 
                  src={logo.src} 
                  alt={logo.alt} 
                  className={`${logo.height} w-auto transition-all duration-300
                    ${isDark 
                      ? 'brightness-50 group-hover:brightness-200' 
                      : 'brightness-0 opacity-60 group-hover:opacity-100'  // Updated for light mode
                    }`}
                />
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoStrip; 