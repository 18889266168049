import React from 'react';
import { motion } from 'framer-motion';

const ScoreDisplay = ({ kpiValues, currentScenarioId }) => {
  const calculateAverageScore = () => {
    if (!kpiValues || Object.keys(kpiValues).length === 0) return 50;
    const sum = Object.values(kpiValues).reduce((acc, value) => acc + value, 0);
    const average = Math.round(sum / Object.keys(kpiValues).length);
    return average;
  };

  const getScoreConfig = (score) => {
    if (score < 50) return {
      color: 'text-red-500'
    };
    if (score >= 50 && score <= 55) return {
      color: 'text-gray-400'
    };
    if (score >= 56 && score < 80) return {
      color: 'text-amber-400'
    };
    return {
      color: 'text-emerald-400'
    };
  };

  const score = calculateAverageScore();
  const config = getScoreConfig(score);

  return (
    <div className="flex items-center gap-2 bg-gray-900 px-2 py-1 rounded-lg border border-gray-800">
      <div className="flex items-center gap-1">
        <span className="text-[8px] font-medium text-gray-500 uppercase">Day</span>
        <span className="text-sm font-bold text-gray-300">{currentScenarioId}</span>
      </div>
      <div className="w-px h-4 bg-gray-800" />
      <div className="flex items-center gap-1">
        <span className="text-[8px] font-medium text-gray-500 uppercase">Score</span>
        <span className={`text-sm font-bold ${config.color}`}>
          {score}%
        </span>
      </div>
    </div>
  );
};

export default ScoreDisplay;
