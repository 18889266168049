import React, { useState } from 'react';
import './SaveModal.css';

const SaveModal = ({ isOpen, onClose, onSave }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmText, setConfirmText] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  if (!isOpen) return null;

  const handleReplace = () => {
    setShowConfirmation(true);
  };

  const handleConfirmReplace = () => {
    if (confirmText === 'forever') {
      onSave('replace');
      onClose();
    }
  };

  const handleSaveNew = () => {
    setShowSuccess(true);
    setTimeout(() => {
      onSave('new');
      onClose();
    }, 2000);
  };

  if (showSuccess) {
    return (
      <div className="modal-overlay">
        <div className="save-modal success">
          <h2>Success!</h2>
          <p>You will find this simulation in your library</p>
        </div>
      </div>
    );
  }

  if (showConfirmation) {
    return (
      <div className="modal-overlay">
        <div className="save-modal warning">
          <h2>Warning</h2>
          <p>The content of this simulation will be changed forever.</p>
          <p>Type "forever" if you are sure.</p>
          <input
            type="text"
            value={confirmText}
            onChange={(e) => setConfirmText(e.target.value)}
            placeholder="Type 'forever'"
          />
          <div className="modal-buttons">
            <button className="cancel-button" onClick={onClose}>Cancel</button>
            <button 
              className="confirm-button"
              disabled={confirmText !== 'forever'}
              onClick={handleConfirmReplace}
            >
              Confirm Replace
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="modal-overlay">
      <div className="save-modal">
        <h2>Save Simulation</h2>
        <div className="save-options">
          <button 
            className="replace-button" 
            onClick={handleReplace}
          >
            Replace current simulation
          </button>
          <button 
            className="save-new-button"
            onClick={handleSaveNew}
          >
            Save as new simulation
          </button>
        </div>
        <button className="cancel-button" onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};

export default SaveModal; 