import React, { createContext, useContext, useState } from 'react';

const OperationAresContext = createContext();

export function OperationAresProvider({ children }) {
  const [gameState, setGameState] = useState({
    // Add initial state here
  });

  const value = {
    gameState,
    setGameState,
  };

  return (
    <OperationAresContext.Provider value={value}>
      {children}
    </OperationAresContext.Provider>
  );
}

export function useOperationAres() {
  const context = useContext(OperationAresContext);
  if (!context) {
    throw new Error('useOperationAres must be used within an OperationAresProvider');
  }
  return context;
} 