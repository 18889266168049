import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  DollarSign,
  Users,
  Lightbulb,
  ChevronDown,
  Clock,
  Calendar,
  Check
} from 'lucide-react';

const KPIGroup = ({ title, kpis, impacts, isExpanded, onClick, hasBeenVisited }) => {
  const getIconConfig = () => {
    switch(title) {
      case 'Financial':
        return {
          icon: <DollarSign className="w-3 h-3 text-white" />,
          bgColor: 'bg-emerald-600',
          textColor: 'text-emerald-400'
        };
      case 'Customer':
        return {
          icon: <Users className="w-3 h-3 text-white" />,
          bgColor: 'bg-blue-600',
          textColor: 'text-blue-400'
        };
      case 'Intangible':
        return {
          icon: <Lightbulb className="w-3 h-3 text-white" />,
          bgColor: 'bg-amber-600',
          textColor: 'text-amber-400'
        };
      default:
        return null;
    }
  };

  const iconConfig = getIconConfig();

  return (
    <motion.div 
      className="w-full mb-1 cursor-pointer"
      onClick={onClick}
    >
      <div className="bg-gray-800 border border-gray-700 rounded-lg p-2 hover:border-gray-600 transition-colors">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-1">
            <div className={`${iconConfig.bgColor} p-1 rounded-lg flex items-center justify-center`}>
              {iconConfig.icon}
            </div>
            <h3 className={`text-[10px] font-bold ${iconConfig.textColor}`}>{title}</h3>
            {hasBeenVisited && (
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                className={`${iconConfig.bgColor} p-0.5 rounded-full`}
              >
                <Check className="w-2 h-2 text-white" />
              </motion.div>
            )}
          </div>
          <motion.div
            animate={{ rotate: isExpanded ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <ChevronDown className="w-3 h-3 text-gray-400" />
          </motion.div>
        </div>
        
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="mt-2 space-y-1 border-t border-gray-700 pt-2"
            >
              {kpis.map(kpi => {
                const impact = impacts[kpi];
                const impactColor = impact > 0 ? 'text-emerald-400' : 
                                  impact < 0 ? 'text-red-400' : 
                                  'text-gray-400';
                return (
                  <div key={kpi} className="flex justify-between items-center">
                    <span className="text-[8px] text-gray-300">{kpi}</span>
                    <span className={`text-[8px] font-bold ${impactColor}`}>
                      {impact > 0 ? `+${impact}` : impact}
                    </span>
                  </div>
                );
              })}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

const ImpactColumn = ({ title, impacts, explanation, isLongTerm, expandedGroup, onGroupExpand, visitedGroups }) => {
  const kpiGroups = {
    Financial: ['Profitability', 'Revenue growth', 'Market share', 'Legal expenses'],
    Customer: ['Customer loyalty', 'Customer satisfaction', 'Reputation'],
    Intangible: ['Shareholders satisfaction', 'Employee morale & engagement']
  };

  return (
    <div className={`w-[48%] h-full flex flex-col rounded-lg border ${
      isLongTerm 
        ? 'border-blue-500/20 bg-blue-500/5' 
        : 'border-emerald-500/20 bg-emerald-500/5'
    } p-2`}>
      <div className="flex items-center justify-center gap-1 mb-3">
        {isLongTerm ? (
          <Calendar className="w-3 h-3 text-blue-400" />
        ) : (
          <Clock className="w-3 h-3 text-emerald-400" />
        )}
        <h2 className={`text-sm font-bold ${
          isLongTerm ? 'text-blue-400' : 'text-emerald-400'
        }`}>
          {title}
        </h2>
      </div>

      <div className="flex-grow overflow-y-auto space-y-1">
        {Object.entries(kpiGroups).map(([groupTitle, kpis]) => (
          <KPIGroup
            key={groupTitle}
            title={groupTitle}
            kpis={kpis}
            impacts={impacts}
            isExpanded={expandedGroup === groupTitle}
            onClick={() => onGroupExpand(groupTitle)}
            hasBeenVisited={visitedGroups.includes(groupTitle)}
          />
        ))}
      </div>

      <div className={`mt-2 rounded-lg p-2 bg-gray-800/50 border ${
        isLongTerm ? 'border-blue-500/20' : 'border-emerald-500/20'
      }`}>
        <h3 className={`text-xs font-bold mb-1 ${
          isLongTerm ? 'text-blue-400' : 'text-emerald-400'
        }`}>
          Impact Analysis
        </h3>
        <p className="text-[10px] leading-relaxed text-gray-300">{explanation}</p>
      </div>
    </div>
  );
};

const KPIDashboard = ({ selectedOption, onVisitedGroupsChange }) => {
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [visitedGroups, setVisitedGroups] = useState([]);

  const handleGroupExpand = (groupTitle) => {
    setExpandedGroup(expandedGroup === groupTitle ? null : groupTitle);
    if (!visitedGroups.includes(groupTitle)) {
      const newVisitedGroups = [...visitedGroups, groupTitle];
      setVisitedGroups(newVisitedGroups);
      onVisitedGroupsChange?.(newVisitedGroups);
    }
  };

  if (!selectedOption) return null;

  return (
    <div className="w-full h-full flex justify-between gap-2 p-2">
      <ImpactColumn
        title="Short Term Impact"
        impacts={selectedOption.kpiImpacts.shortTerm}
        explanation={selectedOption.shortTermImpact}
        isLongTerm={false}
        expandedGroup={expandedGroup}
        onGroupExpand={handleGroupExpand}
        visitedGroups={visitedGroups}
      />
      
      <ImpactColumn
        title="Long Term Impact"
        impacts={selectedOption.kpiImpacts.longTerm}
        explanation={selectedOption.longTermImpact}
        isLongTerm={true}
        expandedGroup={expandedGroup}
        onGroupExpand={handleGroupExpand}
        visitedGroups={visitedGroups}
      />
    </div>
  );
};

export default KPIDashboard; 