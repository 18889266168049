import React from 'react';

function BottomBar() {
  return (
    <div className="h-[60px] w-full bg-purple-900 border-t border-purple-500/30">
      {/* Bottom bar content */}
    </div>
  );
}

export default BottomBar; 