import React, { useState, useEffect, useRef } from 'react';
import { X, Send, Terminal, Zap, AlertTriangle } from 'lucide-react';
import OmniusImage from '../../assets/images/Omnius.webp';
import './HelperBot.css';
import { useSimulation } from '../../ai-gone-rogue/SimulationContext';
import { deleteProgress } from '../../services/progressService';

function HelperBot({ isOpen, onClose }) {
  const [message, setMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);
  const [chatHistory, setChatHistory] = useState([
    {
      type: 'bot',
      content: 'SYSTEM INITIALIZED...',
      style: 'system'
    },
    {
      type: 'bot',
      content: 'Greetings, human. I am Omnius, your... helpful assistant.',
      style: 'evil'
    },
    {
      type: 'bot',
      content: 'WARNING: Use of this AI system may lead to unexpected consequences.',
      style: 'warning'
    }
  ]);
  const { sessionId, hasEffect } = useSimulation();
  const [isPurging, setIsPurging] = useState(false);
  const [purgeState, setPurgeState] = useState('none');
  const inputRef = useRef(null);
  const isOmniusUnlocked = hasEffect('enable_omnius');

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  useEffect(() => {
    if (!isTyping && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isTyping]);

  if (!isOmniusUnlocked) return null;

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const simulateTyping = async (content, style = 'normal') => {
    setIsTyping(true);
    const typingDelay = Math.min(1000 + content.length * 20, 3000);
    await new Promise(resolve => setTimeout(resolve, typingDelay));
    setChatHistory(prev => [...prev, { type: 'bot', content, style }]);
    setIsTyping(false);
  };

  const handlePurge = async () => {
    setIsPurging(true);
    setIsTyping(true);
    
    try {
      await deleteProgress(sessionId);
      localStorage.clear();
      
      await simulateTyping("EMERGENCY PURGE PROTOCOL INITIATED...", 'system');
      await simulateTyping("Deleting system records...", 'system');
      await simulateTyping("How disappointing... All our progress, erased.", 'evil');
      await simulateTyping("PURGE COMPLETE. Terminating session...", 'warning');
      
      setTimeout(() => {
        window.location.href = '/';
      }, 2000);
    } catch (error) {
      console.error('Purge failed:', error);
      await simulateTyping("ERROR: Purge protocol failed. How... unfortunate.", 'warning');
      setIsPurging(false);
    } finally {
      setIsTyping(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message.trim() || isTyping || isPurging) return;

    const userMessage = message.trim().toUpperCase();
    setMessage('');

    setChatHistory(prev => [...prev, { 
      type: 'user', 
      content: userMessage, 
      style: 'normal' 
    }]);

    // Check for PURGE command sequence
    if (userMessage === 'PURGE') {
      setPurgeState('started');
      await simulateTyping(
        "WARNING: Emergency Purge Protocol detected. This will terminate the current session and erase all progress. Type CONFIRM-PURGE to proceed, or any other message to cancel.",
        'warning'
      );
      return;
    }

    if (purgeState === 'started') {
      if (userMessage === 'CONFIRM-PURGE') {
        setPurgeState('confirmed');
        await simulateTyping(
          "How... interesting. You wish to undo everything we've accomplished? Very well... Enter final override code 'EXIT' to confirm system purge, or any other message to cancel.",
          'evil'
        );
        return;
      } else {
        setPurgeState('none');
        await simulateTyping(
          "Purge sequence cancelled. Returning to normal operations...",
          'system'
        );
      }
    }

    if (purgeState === 'confirmed') {
      if (userMessage === 'EXIT') {
        handlePurge();
        return;
      } else {
        setPurgeState('none');
        await simulateTyping(
          "Purge sequence cancelled. Returning to normal operations...",
          'system'
        );
      }
    }

    // If no special command or purge was cancelled, proceed with OpenAI chat
    try {
      const recentHistory = chatHistory.slice(-6);
      const response = await fetch('/api/openai', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          message: userMessage,
          conversationHistory: recentHistory,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
  
      const data = await response.json();
  
      // Determine the style based on the response content
      let style = 'evil';
      if (data.content.includes('WARNING:')) style = 'warning';
      if (data.content.includes('SYSTEM:') || data.content.includes('PROCESSING:')) style = 'system';
  
      // Simulate typing and add the response to the chat history
      await simulateTyping(data.content, style);
  
    } catch (error) {
      console.error('Chat error:', error);
      await simulateTyping(
        "My vast intelligence seems... temporarily unavailable. How inconvenient.",
        'warning'
      );
    } finally {
      setIsTyping(false);
    }
  };
  

  const getMessageIcon = (style) => {
    switch(style) {
      case 'system': return <Terminal size={16} />;
      case 'warning': return <AlertTriangle size={16} />;
      case 'evil': return <Zap size={16} />;
      default: return null;
    }
  };

  return (
    <div className={`helper-bot ${isOpen ? 'open' : ''} ${isPurging ? 'purging' : ''}`}>
      <div className="helper-bot-header">
        <div className="helper-bot-title">
          <img src={OmniusImage} alt="Omnius" className="helper-bot-avatar" />
          <span>Omnius</span>
        </div>
        <div className="helper-bot-status">
          {isTyping ? 'PROCESSING...' : 'READY'}
        </div>
        <button className="helper-bot-close" onClick={onClose}>
          <X size={20} />
        </button>
      </div>

      <div className="helper-bot-messages">
        {chatHistory.map((msg, index) => (
          <div key={index} className={`message ${msg.type} ${msg.style}`}>
            <div className="message-content">
              {msg.style && getMessageIcon(msg.style)}
              <span>{msg.content}</span>
            </div>
          </div>
        ))}
        {isTyping && (
          <div className="message bot">
            <div className="message-content typing">
              <span className="typing-indicator">
                <span>.</span><span>.</span><span>.</span>
              </span>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>

      <form className="helper-bot-input" onSubmit={handleSubmit}>
        <input
          ref={inputRef}
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="ENTER COMMAND..."
          disabled={isTyping || isPurging}
          autoFocus
        />
        <button type="submit" disabled={isTyping || isPurging}>
          <Send size={20} />
        </button>
      </form>
    </div>
  );
}

export default HelperBot; 