import React from 'react';
import './Score.css';

function Score({ value = 0, maxValue = 100 }) {
  const percentage = (value / maxValue) * 100;
  
  return (
    <div className="score-container">
      <div className="score-grid">
        <div 
          className="score-fill"
          style={{ height: `${percentage}%` }}
        >
          <div className="score-value-container">
            <div className="score-value">{value}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Score; 