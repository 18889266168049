import React, { useEffect, useRef } from 'react';
import Globe from 'react-globe.gl';
import { motion } from 'framer-motion';
import { 
  Users, 
  Laptop, 
  Video,
  GraduationCap,
  Key,
  Settings
} from 'lucide-react';
import { useTheme } from '../../context/ThemeContext';

const locations = [
  { lat: 52.3676, lng: 4.9041, name: 'Amsterdam', population: 1.1e6 },
  { lat: 1.3521, lng: 103.8198, name: 'Singapore', population: 5.7e6 },
  { lat: 25.2048, lng: 55.2708, name: 'Dubai', population: 3.3e6 },
  { lat: -33.8688, lng: 151.2093, name: 'Sydney', population: 5.3e6 },
  { lat: 40.7128, lng: -74.0060, name: 'New York', population: 8.4e6 },
  { lat: 51.5074, lng: -0.1278, name: 'London', population: 9.0e6 },
  { lat: 48.8566, lng: 2.3522, name: 'Paris', population: 2.2e6 },
  { lat: 52.5200, lng: 13.4050, name: 'Berlin', population: 3.7e6 },
  { lat: 41.9028, lng: 12.4964, name: 'Rome', population: 4.3e6 },
  { lat: 40.4168, lng: -3.7038, name: 'Madrid', population: 3.2e6 },
  { lat: 55.7558, lng: 37.6173, name: 'Moscow', population: 11.9e6 },
  { lat: 59.3293, lng: 18.0686, name: 'Stockholm', population: 1.6e6 },
  { lat: 35.6762, lng: 139.6503, name: 'Tokyo', population: 13.9e6 },
  { lat: 22.3193, lng: 114.1694, name: 'Hong Kong', population: 7.4e6 },
  { lat: 31.2304, lng: 121.4737, name: 'Shanghai', population: 24.9e6 },
  { lat: 37.5665, lng: 126.9780, name: 'Seoul', population: 9.7e6 },
  { lat: 39.9042, lng: 116.4074, name: 'Beijing', population: 20.9e6 },
  { lat: 13.7563, lng: 100.5018, name: 'Bangkok', population: 8.3e6 },
  { lat: 34.0522, lng: -118.2437, name: 'Los Angeles', population: 4.0e6 },
  { lat: 41.8781, lng: -87.6298, name: 'Chicago', population: 2.7e6 },
  { lat: 29.7604, lng: -95.3698, name: 'Houston', population: 2.3e6 },
  { lat: 47.6062, lng: -122.3321, name: 'Seattle', population: 0.7e6 },
  { lat: 37.7749, lng: -122.4194, name: 'San Francisco', population: 0.9e6 },
  { lat: 25.7617, lng: -80.1918, name: 'Miami', population: 0.5e6 },
  { lat: 39.9526, lng: -75.1652, name: 'Philadelphia', population: 1.6e6 },
  { lat: -33.8688, lng: 151.2093, name: 'Sydney', population: 5.3e6 },
  { lat: -37.8136, lng: 144.9631, name: 'Melbourne', population: 5.0e6 },
  { lat: -23.5505, lng: -46.6333, name: 'São Paulo', population: 12.3e6 },
  { lat: 19.4326, lng: -99.1332, name: 'Mexico City', population: 9.2e6 },
  { lat: -34.6037, lng: -58.3816, name: 'Buenos Aires', population: 3.1e6 },
  { lat: -33.9249, lng: 18.4241, name: 'Cape Town', population: 4.6e6 },
  { lat: -26.2041, lng: 28.0473, name: 'Johannesburg', population: 5.7e6 },
  { lat: -1.2921, lng: 36.8219, name: 'Nairobi', population: 4.7e6 },
  { lat: 30.0444, lng: 31.2357, name: 'Cairo', population: 20.9e6 },
  { lat: 6.5244, lng: 3.3792, name: 'Lagos', population: 14.8e6 },
  { lat: 33.9716, lng: -6.8498, name: 'Rabat', population: 0.6e6 },
  { lat: 14.6928, lng: -17.4467, name: 'Dakar', population: 3.1e6 },
  { lat: 5.5557, lng: -0.1963, name: 'Accra', population: 2.5e6 },
  { lat: 28.6139, lng: 77.2090, name: 'New Delhi', population: 16.7e6 },
  { lat: 19.0760, lng: 72.8777, name: 'Mumbai', population: 12.4e6 },
  { lat: 12.9716, lng: 77.5946, name: 'Bangalore', population: 8.4e6 },
  { lat: 22.5726, lng: 88.3639, name: 'Kolkata', population: 14.8e6 },
  { lat: 13.0827, lng: 80.2707, name: 'Chennai', population: 7.1e6 },
  { lat: 17.3850, lng: 78.4867, name: 'Hyderabad', population: 6.8e6 }
];

const features = [
  {
    icon: Key,
    title: "Full Platform Access",
    description: "Get access to all our games and training materials. Run unlimited sessions with your teams.",
    gradient: "from-violet-500 to-purple-500"
  },
  {
    icon: Users,
    title: "Train Your Trainers",
    description: "We'll train your internal facilitators to deliver impactful sessions using our platform.",
    gradient: "from-blue-500 to-indigo-500"
  },
  {
    icon: Video,
    title: "Flexible Delivery",
    description: <>
      Run sessions in person or through{" "}
      <span className="bg-gradient-to-r from-orange-500 to-amber-500 
                    bg-clip-text text-transparent font-bold">
        MS Teams
      </span>
      . Use as facilitated sessions or self-paced learning.
    </>,
    gradient: "from-purple-500 to-pink-500"
  },
  {
    icon: Settings,
    title: "Customization Options",
    description: "Adapt the content to your needs - from branding to scenarios. Make the platform truly yours.",
    gradient: "from-fuchsia-500 to-pink-500"
  },
  {
    icon: Laptop,
    title: "Analytics Dashboard",
    description: "Track progress and engagement. Get insights into your teams' learning journey.",
    gradient: "from-pink-500 to-rose-500"
  },
  {
    icon: GraduationCap,
    title: "Continuous Support",
    description: "Technical support, regular updates, and access to new content as we release it.",
    gradient: "from-indigo-500 to-purple-500"
  }
];

const GlobeComponent = () => {
  const { isDark } = useTheme();
  const globeRef = useRef(null);

  useEffect(() => {
    if (globeRef.current) {
      const controls = globeRef.current.controls();
      controls.autoRotate = true;
      controls.autoRotateSpeed = 0.5;
      controls.enableZoom = false;
      controls.minDistance = 250;
      controls.maxDistance = 250;
      
      globeRef.current.pointOfView({ lat: 25, lng: 0, altitude: 2.5 });
    }
  }, []);

  return (
    <div className="w-full py-24">
      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="relative rounded-2xl overflow-hidden bg-gradient-to-br from-black via-game-dark to-black/95 p-20"
        >
          {/* Gradient Border */}
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-br from-game-accent/30 via-purple-500/20 to-pink-500/30 animate-[gradient-rotate_8s_linear_infinite]" />
            <div className="absolute inset-[1px] bg-gradient-to-br from-black via-game-dark to-black/95 rounded-2xl" />
          </div>

          <div className="relative space-y-32">
            {/* Header Section */}
            <div className="space-y-6">
              <h2 className="text-3xl font-bold font-['Space_Grotesk'] text-white text-center">
                Any geography, any language<sup className="text-game-accent">*</sup>
              </h2>
              <p className="text-lg font-['Space_Grotesk'] text-gray-400 text-center max-w-3xl mx-auto">
                Our games can be customized to speak your language and reflect your local context,
                ensuring relevance across global teams.
              </p>
            </div>

            {/* Globe Container */}
            <div className="flex justify-center items-center h-[600px]">
              <div className="relative w-[800px] h-[800px]">
                <Globe
                  ref={globeRef}
                  globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
                  backgroundColor="rgba(0,0,0,0)"
                  
                  // Points configuration
                  pointsData={locations}
                  pointAltitude={0}
                  pointRadius={d => Math.sqrt(d.population) * 4e-4}
                  pointColor={() => '#6C74FF'}
                  pointResolution={64}
                  pointsMerge={false}
                  pointLabel={d => `${d.name}: ${(d.population/1e6).toFixed(1)}M people`}
                  
                  // Atmosphere
                  atmosphereColor="#6C74FF"
                  atmosphereAltitude={0.15}
                  showAtmosphere={true}
                  
                  width={800}
                  height={800}
                />
              </div>
            </div>

            {/* Features Section */}
            <div className="space-y-16">
              {/* Features Header - Updated title and subtitle with responsive padding */}
              <div className="space-y-4 max-w-full sm:max-w-[50%] px-4 sm:px-0">
                <h2 className={`text-2xl sm:text-3xl font-bold font-['Space_Grotesk'] text-left
                  ${isDark ? 'text-white' : 'text-gray-900'}`}>
                  Deliver training your own way: classroom, virtual or self-paced elearning
                </h2>
                <p className={`text-sm sm:text-base font-['Space_Grotesk'] text-left
                  ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                  Our platform adapts to your preferred training method, ensuring the same engaging experience whether in person or online
                </p>
              </div>

              {/* Features Grid - Updated for better mobile layout */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-8 -mx-4 sm:mx-0">
                {features.map((feature, index) => (
                  <motion.div
                    key={feature.title}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    viewport={{ once: true }}
                    className={`relative group p-4 sm:p-6
                      ${index % 3 !== 2 ? `sm:border-r ${isDark ? 'border-game-accent/20' : 'border-gray-200'}` : ''} 
                      ${index < features.length - 3 ? `sm:border-b ${isDark ? 'border-game-accent/20' : 'border-gray-200'}` : ''}`}
                  >
                    <div className="relative space-y-3 sm:space-y-4">
                      <div className="flex items-center gap-3 sm:gap-4">
                        <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-lg bg-zinc-900 flex-shrink-0 
                                       flex items-center justify-center group-hover:scale-105 
                                       transition-all duration-300">
                          <feature.icon 
                            className="w-5 h-5 sm:w-6 sm:h-6" 
                            style={{
                              stroke: `url(#gradient-${index})`,
                              strokeWidth: 2
                            }}
                          />
                          <svg width="0" height="0">
                            <defs>
                              <linearGradient id={`gradient-${index}`} x1="0%" y1="0%" x2="100%" y2="100%">
                                <stop offset="0%" style={{ stopColor: feature.gradient.includes('blue') ? '#3B82F6' : 
                                                        feature.gradient.includes('purple') ? '#8B5CF6' : 
                                                        feature.gradient.includes('pink') ? '#EC4899' : 
                                                        feature.gradient.includes('violet') ? '#8B5CF6' : 
                                                        feature.gradient.includes('indigo') ? '#6366F1' : 
                                                        '#EC4899'}} />
                                <stop offset="100%" style={{ stopColor: feature.gradient.includes('indigo') ? '#6366F1' : 
                                                         feature.gradient.includes('pink') ? '#EC4899' : 
                                                         feature.gradient.includes('rose') ? '#F43F5E' : 
                                                         feature.gradient.includes('purple') ? '#8B5CF6' : 
                                                         '#8B5CF6'}} />
                              </linearGradient>
                            </defs>
                          </svg>
                        </div>
                        <h3 className="text-base sm:text-lg font-bold font-['Space_Grotesk'] text-white">
                          {feature.title}
                        </h3>
                      </div>
                      <p className="text-xs sm:text-sm text-gray-400 pl-0 sm:pl-16">
                        {feature.description}
                      </p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>

            {/* Asterisk explanation at the bottom */}
            <div className="text-center">
              <p className="text-sm font-['Space_Grotesk'] text-gray-500 italic">
                * We can customize any game to include the language you desire
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default GlobeComponent; 